/**
 * @fileoverview
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.option_pkg.EnmCmdID', null, global);
/**
 * @enum {number}
 */
proto.option_pkg.EnmCmdID = {
  CMD_NULL: 0,
  C2TS_LOGIN_REQ: 10001,
  C2TS_HEARTBEAT_REQ: 10002,
  C2TS_LOGOUT_REQ: 10003,
  C2TS_CONTRACT_LIST_REQ: 10004,
  C2TS_PERIOD_LIST_REQ: 10005,
  C2TS_AMOUNT_LIST_REQ: 10006,
  C2TS_ENTRUSTORDER_LIST_REQ: 10007,
  C2TS_OPEN_REQ: 10008,
  TS2C_LOGIN_RES: 11001,
  TS2C_HEARTBEAT_RES: 11002,
  TS2C_KICKOUT_RES: 11003,
  TS2C_UPDATEUSERINFO_RES: 11004,
  TS2C_CONTRACT_LIST_RES: 11005,
  TS2C_PERIOD_LIST_RES: 11006,
  TS2C_AMOUNT_LIST_RES: 11007,
  TS2C_ENTRUSTORDER_LIST_RES: 11008,
  TS2C_OPEN_RES: 11009,
  TS2C_ADDENTRUST_RES: 11010,
  TS2C_UPDATEENTRUST_RES: 11011,
  QI2QS_CANDLESTICK_RES: 12001,
  QI2QS_MARKETDEPTH_RES: 12002,
  QI2QS_MARKETDETAIL_RES: 12003,
  QI2QS_TRADEDETAIL_RES: 12004,
  QI2QS_TRADEBBO_RES: 12005,
  QC2QS_LOGIN_REQ: 13001,
  QC2QS_SUBMARKET_REQ: 13002,
  QC2QS_UNSUBMARKET_REQ: 13003,
  QC2QS_HEARTBEAT_REQ: 13004,
  QS2QC_LOGIN_RES: 13501,
  QS2QC_SUBMARKET_RET: 13502,
  QS2QC_UNSUBMARKET_RET: 13503,
  QS2QC_HEARTBEAT_RES: 13504,
  QS2C_CONTRACT_LIST_RES: 13552,
  TS2TP_BROKERAGE_REQ: 15001,
  TP2TS_BROKERAGE_RES: 15050,
  TP2TS_POSITIONBROKERAGE_RES: 15054,
  C2KS_KLINELIST_REQ: 15101,
  KS2C_KLINELIST_RET: 15201
};

goog.object.extend(exports, proto.option_pkg);
