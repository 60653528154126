<template>
    <div class="middle">
        <div class="ding"></div>
        <div class="user register" >
            <div class="user-gang">|</div>
            <h1> {{ i18n('yonghuzhuce') }} </h1><br>
            <div class="user-fbex"> 
                {{ i18n('huanyingdenglu') }} 
                <span style="color: #08BD98;"> {{ i18n('xiangmumingcheng') }} </span> 
                {{ i18n('guanfangwangzhan') }} 
            </div>
            <div class="user-id">
                {{ i18n('youxiang') }} <br>
                <!-- <input type="tetx" class="cell-phone" placeholder="请输入邮箱" /> -->
                <el-input 
                    v-model="useremail" 
                    type="text" 
                    size="medium" 
                    class="input-id"
                    :placeholder="i18n('qingshuruyouxiang')"></el-input>
            </div>
			<div class="password" style="margin-top: 6px;width: 300px;">
			    {{ i18n('tuxingyanzhengma') }} <br>
			    <div class="flex_row_left">
			        <!-- <input type="text" class="imgcodeReg flex1" placeholder="请输入图形验证码" /> -->
                    <el-input 
                        v-model="imgcode" 
                        size="medium" 
                        style="margin-top: 10px; width: 160px;"
                        :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
			        <img @click="getImgCode()" style="margin-top: 5px; margin-left: 10px; height: 44px; width: 130px;" :src="imgsrc" alt="">
			    </div>
			</div>
			<div class="password" style="margin-top: 6px;width: 300px;">
			    {{ i18n('yanzhengma') }} <br>
			    <div class="flex_row_left">
			        <!-- <input type="text" class="numcodeReg flex1" placeholder="请输入验证码" /> -->
                    <el-input 
                        v-model="numcodeReg" 
                        size="medium" 
                        style="margin-top: 10px; width: 160px;"
                        :placeholder="i18n('qingshuruyanzhengma')"></el-input>
			        <div :class="{'getcodeReg': true, 'noclick': !getcodecanClick}" style="margin-top: 5px !important; height: 44px;" @click="getcodeReg"> {{ getcodeTitle }} </div>
			    </div>
			</div>
            <div class="password" style="margin-top: 6px;">
                {{ i18n('shezhimima') }} <br>
                <!-- <input name="" type="password" class="n-txtCss" id="n-txtCss" placeholder="请输入您的密码" maxlength="16"> -->
                <!-- <img src="images/xianshi%20.png" class="fa fa-eye-slash"></i> -->
                <el-input 
                    v-model="password" 
                    size="medium" 
                    class="input-password"
                    :placeholder="i18n('qingshurunindemima')" 
                    show-password 
                    minlength="6" 
                    maxlength="16"></el-input>
            </div>
            <div class="password" style="margin-top: 6px;">
                {{ i18n('quedingmima') }} <br>
                <!-- <input name="" type="password" class="n-txtCss" id="txtCss" placeholder="请再次输入您的密码" maxlength="16"> -->
                <!-- <img src="images/xianshi%20.png" class="fa fa-eye-slash"></i> -->
                <el-input 
                    v-model="password2" 
                    size="medium" 
                    class="input-password"
                    :placeholder="i18n('qingzaicishurunindemima')" 
                    show-password 
                    minlength="6" 
                    maxlength="16"></el-input>
            </div>
            <div class="password" style="margin-top: 6px;">
                {{ i18n('nicheng') }} <br>
                <!-- <input type="text" class="user-idd" placeholder="请输入昵称"> -->
                <el-input 
                    v-model="nickname" 
                    type="text" 
                    size="medium" 
                    class="input-id"
                    :placeholder="i18n('qingshurunicheng')"></el-input>
            </div>
            <div class="password" style="margin-top: 6px;">
                {{ i18n('yaoqingma') }} <br>
                <!-- <input type="text" class="user-number" placeholder="选填"> -->
                <el-input 
                    v-model="InvitationCode" 
                    type="text" 
                    size="medium" 
                    class="input-id"
                    :placeholder="i18n('xuantian')"></el-input>
            </div>

            <div class="user-button flex_row_center" style="margin-top: 20px; cursor: pointer;" @click="registerClick"> {{ i18n('zhuce') }} </div>
            <div class="user-account">
                {{ i18n('yiyouzhanghao') }}
                <span class="button-Sign" style="cursor: pointer;" @click="btnloginclick"> {{ i18n('denglu') }} </span>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/utils/configs'

export default {
    data() {
        return {
            imgsrc: undefined,
            imgcodeid: undefined,
            useremail: undefined,
            imgcode: undefined,
            numcodeReg: undefined,
            password: undefined,
            password2: undefined,
            nickname: undefined,
            InvitationCode: undefined,
            cid: undefined,
            getcodecanClick: true,
            count: 0,
            countDowntimer: null,
            getcodeTitle: this.$t('register')['huoquyanzhengma'],
        };
    },
    mounted() {
        this.getImgCode();
    },
    beforeDestroy() {
        
    },
    computed: {
        i18n() {
            return function (name) {
                return this.$t("register")[name]
            }
        },
    },
    methods: {
        async getImgCode() {
            let data = {}
            this.$api.getmakeVerifyBase64(data).then((res)=>{
                console.log(res);
                if(res.data.code == 200){
                    this.imgsrc = res.data.img_base64;
                    this.imgcodeid = res.data.id;
                } else {
                    this.$message({
                        message: this.$t('register')['huoqutuxingyanzhengmashibaiqingchongxinqingqiu'],
                        type: 'warning'
                    });
                }
            });
        },
        countDown() {
            this.count = 60;
            clearInterval(this.countDowntimer);
            this.countDowntimer = setInterval(() => {
                this.count--;
                this.getcodeTitle = this.count + this.$t('register')['houchongxinfasong'] ;
                if (this.count === 0) {
                    this.count = 60;
                    this.getcodecanClick = true;
                    clearInterval(this.countDowntimer);
                    this.getcodeTitle = this.$t('register')['huoquyanzhengma'] ;
                }
            }, 1000);
        },
        async getEmailTextCodeNewPic(textcode, useremail) {
            let data = {
                textcode: textcode,
                codeuse: 1,
                useremail: useremail,
                id: this.imgcodeid,
            }
            this.$api.getEmailTextCodeNewPic(data).then((res)=>{
                console.log(res);
                if(res.data.code == 200){
                    this.cid = res.data.cid;
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                    this.countDown();
                } else {
                    this.$message({
                        message: this.$t('register')['huoqushibai'] + res.data.msg,
                        type: 'warning'
                    });
                }
            });
        },
        getcodeReg() {
            if (this.useremail === undefined || this.useremail === '') {
                this.$message({
                    message: this.$t('register')['qingshuruyouxiangdizhi'],
                    type: 'warning'
                });
                return;
            };
            if (this.imgcode === undefined || this.imgcode === '') {
                this.$message({
                    message: this.$t('register')['qingshurutuxingyanzhengma'],
                    type: 'warning'
                });
                return;
            };
            this.getEmailTextCodeNewPic(this.imgcode, this.useremail) ;
        },
        registerClick() {
            if (this.useremail === undefined || this.useremail === '') {
                this.$message({
                    message: this.$t('register')['qingshuruyouxiangdizhi'],
                    type: 'warning'
                });
                return;
            };
            if (this.imgcode === undefined || this.imgcode === '') {
                this.$message({
                    message: this.$t('register')['qingshurutuxingyanzhengma'],
                    type: 'warning'
                });
                return;
            };
            if (this.numcodeReg === undefined || this.numcodeReg === '') {
                this.$message({
                    message: this.$t('register')['qingshuruyanzhengma'],
                    type: 'warning'
                });
                return;
            };
            if (this.password === undefined || this.password === '') {
                this.$message({
                    message: this.$t('register')['qingshurunindemima'],
                    type: 'warning'
                });
                return;
            };
            if (this.password2 === undefined || this.password2 === '') {
                this.$message({
                    message: this.$t('register')['qingzaicishurunindemima'],
                    type: 'warning'
                });
                return;
            };
            if (this.password2 !== this.password) {
                this.$message({
                    message: this.$t('register')['liangcishurudemimabuyizhi'],
                    type: 'warning'
                });
                return;
            };
            if (this.nickname === undefined || this.nickname === '') {
                this.$message({
                    message: this.$t('register')['qingshurunicheng'],
                    type: 'warning'
                });
                return;
            };
            this.doRegisterNew(this.useremail, this.numcodeReg, this.password, this.nickname, this.InvitationCode);
        },
        async doRegisterNew(useremail, textcode, userpassword, username, invitecode) {
            let data = {
                useremail: useremail,
				id: this.imgcodeid,
				cid: this.cid,
				textcode: textcode,
                userpassword: userpassword,
                username: username,
                invitecode: invitecode,
				codeuse: 1
            }
            this.$api.doRegisterNew(data).then((res)=>{
                console.log(res);
                if(res.data.code == 200){
                    this.$message({
                        message: this.$t('register')['zhucechenggong'],
                        type: 'warning'
                    });
                    //跳转到登录界面
                    this.$router.replace({name: 'LoginLayout'});
                } else {
                    this.$message({
                        message: this.$t('register')['zhuceshibai'] + res.data.msg,
                        type: 'warning'
                    });
                }
            });
        },
        btnloginclick() {
            this.$router.replace({name: 'LoginLayout'});
        },
    },

};
</script>

<style scoped>
.middle {
    min-width: 686px;
    width: 100%;
    height: 1000px;
    background: url(@/assets/images/bg.png) no-repeat center;
    background-size: cover;
    position: relative;
}

.user {
    width: 364px;
    height: 750px;
    background-color: #0A162B;
    margin-left: 70%;
    /* margin-top: 25px; */
    padding-left: 32px;
    padding-top: 74px;
}

.ding {
    min-width: 686px;
    width: 100%;
    height: 130px;
}

.user-gang {
    font-size: 20px;
    color: #08BD98;
    margin-right: 16px;
    float: left;
}

.user h1 {
    color: #fff;
    font-size: 20px
}

.user-fbex {
    font-size: 16px;
    color: #ddd;
}

.input-id {
    margin-top: 10px;
    width: 300px;
    height: 44px;
    color: #fff;
    font-size: 16px;
}

.input-password {
    margin-top: 10px;
    width: 300px;
    height: 44px;
    color: #fff;
    font-size: 16px;
}

.user-id {
    margin-top: 35px;
    color: #B5BCCB;
    font-size: 14px;
    position: relative;
}

.password {
    margin-top: 35px;
    color: #B5BCCB;
    font-size: 14px;
    position: relative;
}

.user-forget {
    color: #08BD98;
    font-size: 14px;
    text-align: right;
    margin-right: 70px;
    line-height: 40px;
    cursor: pointer;
}

.user-button button {
    width: 300px;
    height: 44px;
}

.user-account {
    width: 300px;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
}

.user-account span {
    color: #08BD98;
}

.getcodeReg {
    background: #08BD98;
    box-shadow: 0px 2px 4px 0px rgb(21 85 73 / 35%);
    color: #fff;
    line-height: 44px;
    text-align: center;
    margin-top: 12px;
    width: 130px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.user-button {
    width: 300px;
    height: 44px;
    background-color: #08BD98;
    color: #fff;
    border-radius: 4px;
}
</style>