import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '../components/common/BasicLayout'
import homeLayout from '../views/homeLayout'
import LoginLayout from '../views/LoginLayout'
import contractTradeLayout from '../views/contractTradeLayout.vue'
import stocksTrade from '../views/stocksTrade.vue'
import fxTrade from '../views/fxTrade.vue'
import assetsLayout from '../views/assetsLayout.vue'
import withdrawLayout from '../views/withdrawLayout.vue'
import rechargeLayout from '../views/rechargeLayout.vue'
import basicsAccountRecordLayout from '../views/basicsAccountRecordLayout.vue'
import contractAccountRecordLayout from '../views/contractAccountRecordLayout.vue'
import contractTransactionRecordLayout from '../views/contractTransactionRecordLayout.vue'
import SecurityCenterLayout from '../views/SecurityCenterLayout.vue'
import verificationLayout from '../views/verificationLayout.vue'
import inviteLayout from '../views/inviteLayout.vue'
import treasure from '../views/treasure.vue'
import SpotTradeLayout from '../views/SpotTradeLayout.vue'
import treasureOrder from '../views/treasureOrder.vue'
import treasureAssets from '../views/treasureAssets.vue'
import subscription from '../views/subscription.vue'
import registerLayout from '../views/registerLayout.vue'
import subDetails from '../views/subDetails.vue'
import SpotAccountRecordLayout from '../views/SpotAccountRecordLayout.vue'
import SpotTransactionRecordLayout from '../views/SpotTransactionRecordLayout.vue'
import optionsTrade from '../views/optionsTrade.vue'
import StockTransactionRecordLayout from '../views/StockTransactionRecordLayout.vue'
import FXTransactionRecordLayout from '../views/FXTransactionRecordLayout.vue'


import store from '../store'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '',
        component: BasicLayout,
        meta: {
            requireAuth: true
        },
        children: [
            {
                path: '',
                name: 'homeLayout',
                component: homeLayout,
            },
            {
                path: 'LoginLayout',
                name: 'LoginLayout',
                component: LoginLayout,
            },
            {
                path: 'contractTradeLayout',
                name: 'contractTradeLayout',
                component: contractTradeLayout,
            },
            {
                path: 'stocksTrade',
                name: 'stocksTrade',
                component: stocksTrade,
            },
            {
                path: 'fxTrade',
                name: 'fxTrade',
                component: fxTrade,
            },
            {
                path: 'assetsLayout',
                name: 'assetsLayout',
                component: assetsLayout,
            },
            {
                path: 'withdrawLayout',
                name: 'withdrawLayout',
                component: withdrawLayout,
            },
            {
                path: 'rechargeLayout',
                name: 'rechargeLayout',
                component: rechargeLayout,
            },
            {
                path: 'basicsAccountRecordLayout',
                name: 'basicsAccountRecordLayout',
                component: basicsAccountRecordLayout,
            },
            {
                path: 'contractAccountRecordLayout',
                name: 'contractAccountRecordLayout',
                component: contractAccountRecordLayout,
            },
            {
                path: 'contractTransactionRecordLayout',
                name: 'contractTransactionRecordLayout',
                component: contractTransactionRecordLayout,
            },
            {
                path: 'SecurityCenterLayout',
                name: 'SecurityCenterLayout',
                component: SecurityCenterLayout,
            },
            {
                path: 'verificationLayout',
                name: 'verificationLayout',
                component: verificationLayout,
            },
            {
                path: 'inviteLayout',
                name: 'inviteLayout',
                component: inviteLayout,
            },
            {
                path: 'treasure',
                name: 'treasure',
                component: treasure,
            },
            {
                path: 'SpotTradeLayout',
                name: 'SpotTradeLayout',
                component: SpotTradeLayout,
            },

            {
                path: 'treasureOrder',
                name: 'treasureOrder',
                component: treasureOrder,
            },
            {
                path: 'treasureAssets',
                name: 'treasureAssets',
                component: treasureAssets,
            },
            {
                path: 'registerLayout',
                name: 'registerLayout',
                component: registerLayout,
            },
            {
                path: 'subscription',
                name: 'subscription',
                component: subscription,
            },
            {
                path: 'subDetails',
                name: 'subDetails',
                component: subDetails,
            },
            {
                path: 'SpotAccountRecordLayout',
                name: 'SpotAccountRecordLayout',
                component: SpotAccountRecordLayout,
            },
            {
                path: 'SpotTransactionRecordLayout',
                name: 'SpotTransactionRecordLayout',
                component: SpotTransactionRecordLayout,
            },
            {
                path: 'optionsTrade',
                name: 'optionsTrade',
                component: optionsTrade,
            },
            {
                path: 'StockTransactionRecordLayout',
                name: 'StockTransactionRecordLayout',
                component: StockTransactionRecordLayout,
            },
            {
                path: 'FXTransactionRecordLayout',
                name: 'FXTransactionRecordLayout',
                component: FXTransactionRecordLayout,
            },   
        ]
    }
]

const router = new VueRouter({
    routes
})
router.beforeEach(async(to, from, next) => {
    if(store.state.loginStatus){
        next();
    }else{
        if(to.name == "homeLayout" || to.name == "LoginLayout" || to.name == "contractTradeLayout" || to.name == "stocksTrade"|| to.name == "fxTrade"||  to.name == "SpotTradeLayout" ){
            next();
        }else{
            if (window.sessionStorage.getItem('tk')) {
                next();
            }else{
                if(to.path != 'LoginLayout' ) {
                    return next({
                        path:'LoginLayout',
                        NAVTYPE:'push',
                        
                    });
                }
            }
        }
    }
})

 
export default router
