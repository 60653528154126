import CryptoJS from 'crypto-js';
const pkey= "mmp/Z3Kw1gDwSeJ/";
const baseMethods = {
	showToast(title){
	    uni.showToast({
	        title: title,
	        icon: 'none',
			duration: 1500
	    })
	},
  // 获取传递的参数
  request() {
    const obj = {};
    const arr = window.location.search.slice(1).split('&');
    for (let i = 0, len = arr.length; i < len; i++) {
		    const nv = arr[i].split('=');
		    obj[unescape(nv[0]).toLowerCase()] = unescape(nv[1]);
    }
    return obj;
  },
  // 数组去重
  unique(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[i].code == arr[j].code && arr[i].mainName == arr[j].mainName) { // 第一个等同于第二个，splice方法删除第二个
				    arr.splice(j, 1);
				    j--;
        }
      }
    }
    return arr;
  },
  moneyFormat(x,n){    //货币强制两位小数  
    var f = parseFloat(x);
    var basenum=1;
    for (var i = 0; i < n; i++) {
        basenum=basenum+"0"
    }
    basenum=basenum*1;
    if (isNaN(f)) {
        return false;
    }
    f = Math.floor(x * basenum) / basenum;
    var s = f.toString();
    var rs = s.indexOf(".");
    if (rs < 0) {
        rs = s.length;
        s += ".";
    }
    while (s.length <= rs + n) {
        s += "0";
    }
    return s;
  },
  /*
		*以下为解决浮点数计算不准确
		*加法： accAdd(0.1, 0.2)
		*减法： accSub(1, 0.9)
		*除法： accDiv(2.2, 100)
		*乘法： accMul(7, 0.8)
		*baseMethods.countDecimals()方法：计算小数位的长度
		*convertToInt()方法：将小数转成整数
		*baseMethods.getCorrectResult()方法：确认我们的计算结果无误，以防万一
	*/
  accAdd(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    let dec1; let dec2; let
      times;
    try { dec1 = baseMethods.countDecimals(num1) + 1; } catch (e) { dec1 = 0; }
    try { dec2 = baseMethods.countDecimals(num2) + 1; } catch (e) { dec2 = 0; }
    times = Math.pow(10, Math.max(dec1, dec2));
    // var result = (num1 * times + num2 * times) / times;
    const result = (baseMethods.accMul(num1, times) + baseMethods.accMul(num2, times)) / times;
    return baseMethods.getCorrectResult('add', num1, num2, result);
    // return result;
  },
  accSub(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    let dec1; let dec2; let
      times;
    try { dec1 = baseMethods.countDecimals(num1) + 1; } catch (e) { dec1 = 0; }
    try { dec2 = baseMethods.countDecimals(num2) + 1; } catch (e) { dec2 = 0; }
    times = Math.pow(10, Math.max(dec1, dec2));
    // var result = Number(((num1 * times - num2 * times) / times);
    const result = Number((baseMethods.accMul(num1, times) - baseMethods.accMul(num2, times)) / times);
    return baseMethods.getCorrectResult('sub', num1, num2, result);
    // return result;
  },
  accDiv(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    let t1 = 0; let t2 = 0; let dec1; let
      dec2;
    try { t1 = baseMethods.countDecimals(num1); } catch (e) { }
    try { t2 = baseMethods.countDecimals(num2); } catch (e) { }
    dec1 = baseMethods.convertToInt(num1);
    dec2 = baseMethods.convertToInt(num2);
    const result = baseMethods.accMul((dec1 / dec2), Math.pow(10, t2 - t1));
    return baseMethods.getCorrectResult('div', num1, num2, result);
    // return result;
  },
  accMul(num1, num2) {
	    num1 = Number(num1);
	    num2 = Number(num2);
	    let times = 0; const s1 = num1.toString(); const
      s2 = num2.toString();
	    try { times += baseMethods.countDecimals(s1); } catch (e) { }
	    try { times += baseMethods.countDecimals(s2); } catch (e) { }
	    const result = baseMethods.convertToInt(s1) * baseMethods.convertToInt(s2) / Math.pow(10, times);
	    return baseMethods.getCorrectResult('mul', num1, num2, result);
	    // return result;
  },
	countDecimals(num) {
	  var len = 0;
	  try {
		  num = Number(num);
		  var str = num.toString().toUpperCase();
		  if (str.split('E').length === 2) { // scientific notation
			  var isDecimal = false;
			  if (str.split('.').length === 2) {
				  str = str.split('.')[1];
				  if (parseInt(str.split('E')[0]) !== 0) {
					  isDecimal = true;
				  }
			  }
			  let x = str.split('E');
			  if (isDecimal) {
				  len = x[0].length;
			  }
			  len -= parseInt(x[1]);
		  } else if (str.split('.').length === 2) { // decimal
			  if (parseInt(str.split('.')[1]) !== 0) {
				  len = str.split('.')[1].length;
			  }
		  }
	  } catch(e) {
		  throw e;
	  } finally {
		  if (isNaN(len) || len < 0) {
			  len = 0;
		  }
		  return len;
	  }
	},
	convertToInt(num) {
	    num = Number(num);
	    var newNum = num;
	    var times = baseMethods.countDecimals(num);
	    var temp_num = num.toString().toUpperCase();
	    if (temp_num.split('E').length === 2) {
	        newNum = Math.round(num * Math.pow(10, times));
	    } else {
	        newNum = Number(temp_num.replace(".", ""));
	    }
	    return newNum;
	},
	getCorrectResult(type, num1, num2, result) {
	    var temp_result = 0;
	    switch (type) {
	        case "add":
	            temp_result = num1 + num2;
	            break;
	        case "sub":
	            temp_result = num1 - num2;
	            break;
	        case "div":
	            temp_result = num1 / num2;
	            break;
	        case "mul":
	            temp_result = num1 * num2;
	            break;
	    }
	    if (Math.abs(result - temp_result) > 1) {
	        return temp_result;
	    }
	    return result;
	},
  // 格式转换
  Uint8ArrayToString(fileData) {
    let dataString = '';
    for (let i = 0; i < fileData.length; i++) {
      dataString += String.fromCharCode(fileData[i]);
    }
    return dataString;
  },
  Utf8ArrayToStr(array) {
    let out; let i; let len; let
      c;
    let char2; let
      char3;

    out = '';
    len = array.length;
    i = 0;
    while (i < len) {
      c = array[i++];
      switch (c >> 4) {
        case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c);
          break;
        case 12: case 13:
          // 110x xxxx   10xx xxxx
          char2 = array[i++];
          out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
          break;
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = array[i++];
          char3 = array[i++];
          out += String.fromCharCode(((c & 0x0F) << 12)
				| ((char2 & 0x3F) << 6)
				| ((char3 & 0x3F) << 0));
          break;
      }
    }

    return out;
  },
  stringToUint8Array(str) {
    const arr = [];
    for (let i = 0, j = str.length; i < j; ++i) {
      arr.push(str.charCodeAt(i));
    }
    const tmpUint8Array = new Uint8Array(arr);
    return tmpUint8Array;
  },
  base64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  },
  int8parse(u8arr) {
    // Shortcut
    const len = u8arr.length;
    // Convert
    const words = [];
    for (let i = 0; i < len; i++) {
      words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8);
    }
    return CryptoJS.lib.WordArray.create(words, len);
  },
  wordToByte(wordArray) {
    const { words } = wordArray;
    const { sigBytes } = wordArray;
    const u8 = new Uint8Array(sigBytes);
    for (let i = 0; i < sigBytes; i++) {
      const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
      u8[i] = byte;
    }
    return u8;
  },
  getCharCode(codeString) {
	    let result = '';
	    const codeStringArray = codeString.split(',');
	    for (let i = 0; i < codeStringArray.length; i++) {
	        result += String.fromCharCode(codeStringArray[i]);
	    }
	    return result;
  },
  bytesToBase64(bytes) {
	    // Use browser-native function if it exists
	    const base64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
	    for (var base64 = [], i = 0; i < bytes.length; i += 3) {
	        const triplet = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
	        for (let j = 0; j < 4; j++) {
	            if (i * 8 + j * 6 <= bytes.length * 8) { base64.push(base64map.charAt((triplet >>> 6 * (3 - j)) & 0x3F)); } else base64.push('=');
	        }
	    }
	    return base64.join('');
  },
  base64ToBytes(base64) {
	    // Use browser-native function if it exists
	    const base64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
	    // Remove non-base-64 characters
	    base64 = base64.replace(/[^A-Z0-9+\/]/ig, '');
	    for (var bytes = [], i = 0, imod4 = 0; i < base64.length; imod4 = ++i % 4) {
	        if (imod4 == 0) continue;
	        bytes.push(((base64map.indexOf(base64.charAt(i - 1)) & (Math.pow(2, -2 * imod4 + 8) - 1)) << (imod4 * 2))
	                   | (base64map.indexOf(base64.charAt(i)) >>> (6 - imod4 * 2)));
	    }
	    return bytes;
  },
  toDate(timestamp,type) {
	    const date = new Date(timestamp * 1000);// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    const Y = `${date.getFullYear()}-`;
    const M = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-`;
    const D = `${date.getDate()} `;
    const h = `${date.getHours()}:`;
    const m = `${date.getMinutes()}:`;
    const s = date.getSeconds();
    if(type){
       return  M + D ;
    }else{
      return Y + M + D + h + m + s;
    }
  },
  formatDate(now) {
	    const year = now.getFullYear();
	    let month = now.getMonth() + 1;
	    month = month < 10 ? `0${String(month)}` : month;
	    let date = now.getDate();
	    date = date < 10 ? `0${date}` : date;
	    let hour = now.getHours();
	    hour = hour < 10 ? `0${hour}` : hour;
	    let minute = now.getMinutes();
	    minute = minute < 10 ? `0${minute}` : minute;
	    let second = now.getSeconds();
	    second = second < 10 ? `0${second}` : second;
	    return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
  },
  // 加密
  aesKey: new Int8Array([30, 160, 0, 141, 190, 154, 240, 88, 123, 112, 198, 32, 219, 202, 121, 251]),
  encrypt(list, key) {
	    if (list.length == 0) {
	        var newArray = new Uint8Array(16);
	        newArray.fill(16);
	    } else {
	        let inter = Math.ceil(list.length / 16); // 向上取整数
	        const remainder = list.length % 16; // 余数
	        if (remainder == 0) {
	            var fillNum = 16;
	            inter++;
	        } else {
	            var fillNum = 16 - remainder;// 需补位的值
	        }
	        var newArray = new Uint8Array(inter * 16);// 创建新Uint8Array
	        newArray.set(list); // 复制list
	        newArray.fill(fillNum, list.length);// 完成补位
	    }
	    if (key) {
	        var key_Int = key;
	    } else {
	        var key_Int = this.aesKey;
	    }
	    const my = this.int8parse(key_Int);
	    const newArray1 = this.int8parse(newArray);
	    const encrypted = CryptoJS.AES.encrypt(newArray1, my, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
	    return this.wordToByte(encrypted.ciphertext);
  },
  // 解密
  decrypt(list, msg, key) {
    if (key) {
		    var key_Int = key;
    } else {
		     var key_Int = this.aesKey;
    }
    const my = this.int8parse(key_Int);
    const list1 = this.bytesToBase64(list);
    const decrypt = CryptoJS.AES.decrypt(list1, my, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
    const pwd1 = decrypt.toString();
    const pwd2 = this.base64ToBytes(pwd1);
    const data = this.wordToByte(decrypt);
    const Dlength = data.length;
    const lastNum = data[data.length - 1];
    let newData;
    if (data[Dlength - 1] <= 16 && lastNum == data[Dlength - lastNum]) {
		    newData = new Uint8Array(Dlength - lastNum);
		    const data_item = data.subarray(0, Dlength - lastNum);
		    newData.set(data_item);
    } else {
		   newData = data;
    }
    return newData;
  },
  // 解密持仓单
  decrypt_hold(list, msg) {
    const key_Int = this.aesKey;
    const my = this.int8parse(key_Int);
    const list1 = this.bytesToBase64(list);
    const decrypt = CryptoJS.AES.decrypt(list1, my, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
    const pwd1 = decrypt.toString();
    const pwd2 = this.base64ToBytes(pwd1);
    const data = this.wordToByte(decrypt);
    const Dlength = data.length;
    const lastNum = data[data.length - 1];
    let newData;
    if (data[Dlength - 1] <= 16 && lastNum == data[Dlength - lastNum]) {
		    newData = new Uint8Array(Dlength - lastNum);
		    const data_item = data.subarray(0, Dlength - lastNum);
		    newData.set(data_item);
    } else {
		   newData = data;
    }
    return newData;
  },
  // 解密委托单
  decrypt_entrust(list, msg) {
	    const key_Int = this.aesKey;
	    const my = this.int8parse(key_Int);
	    const list1 = this.bytesToBase64(list);
	    const decrypt = CryptoJS.AES.decrypt(list1, my, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
	    const pwd1 = decrypt.toString();
	    const pwd2 = this.base64ToBytes(pwd1);
	    const data = this.wordToByte(decrypt);
	    const Dlength = data.length;
	    const lastNum = data[data.length - 1];
	    let newData;
	    if (data[Dlength - 1] <= 16 && lastNum == data[Dlength - lastNum]) {
	        newData = new Uint8Array(Dlength - lastNum);
	        const data_item = data.subarray(0, Dlength - lastNum);
	        newData.set(data_item);
	    } else {
	       newData = data;
	    }
	    return newData;
  },
  //加密
  encryptMM(plaintText) {
      var plaintText = plaintText;
      var options = {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
      };
      var key = CryptoJS.enc.Utf8.parse(pkey);
      var encryptedData = CryptoJS.AES.encrypt(plaintText, key, options);
      var encryptedBase64Str = encryptedData.toString().replace(/\//g, "_");
      encryptedBase64Str = encryptedBase64Str.replace(/\+/g,"-");
      return encryptedBase64Str;
  },
  //解密
  decryptMM(encryptedBase64Str) {
      var vals = encryptedBase64Str.replace(/\-/g, '+').replace(/_/g, '/');
      var options = {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
      };
      var key = CryptoJS.enc.Utf8.parse(pkey);
      var decryptedData = CryptoJS.AES.decrypt(vals, key, options);
      var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData);
      return decryptedStr    
  }
};
export { baseMethods };
