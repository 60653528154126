<template>
    <div class="showpart">
        <div style="width: 1200px;">
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                    style="margin-left: 10px;"
                    @back="goBack" 
                    :content="i18n('chongbi')"
                    :title="i18n('fanhui')">
                </el-page-header>   
            </div>
            
            <div class="flex_row_left kuang">
                <div :class="ClassCoinUSDT()" @click="clickCoin('USDT')">USDT</div>
                <!-- <div :class="ClassCoinUSDC()" @click="clickCoin('USDC')">USDC</div> -->
            </div>
            <div class="showcontent">
                <div class="lname"> {{i18n('lianmingcheng')}} </div>
                <div class="flex_row_between">
                    <div :class="ClassTRC20()" @click="clickChain('TRC20')">TRC20</div>
                    <div :class="ClassERC20()" @click="clickChain('ERC20')">ERC20</div>
                    <div :class="ClassOMNI()" @click="clickChain('OMNI')">OMNI</div>
                </div>
                <div class="ewm flex_row_center">
                    <!-- <div id="qrcodemm" style="width: 150px;height: 150px;"></div> -->
                    <vue-qr
                    v-show = "textChain !== ''"
                    ref="qrCodeChain"
                    :text="textChain"
                    :logoSrc="logoSrc"
                    :color-dark="randomColor"
                    width="150"
                    height="150"
                    ></vue-qr>
                </div>
                <div class="flex_row_center fontSize16">
                    <div class="adress color_333"> {{ textChain }} </div>
                    <div class="copyicon flex_row_left">
                        <i class="color_green el-icon-copy-document" style="margin-left: 5px; margin-right: 5px;"></i>
                        <div class="color_green btn-copy" @click="copyaddress()"> {{ i18n('fuzhilianjie') }} </div>
                    </div>

                </div>
                <div class="rowline"></div>
                <div>
                    <div class="tip color_999 fontSize12">
                        <div> {{ i18n('tishixinxi1') }} </div>
                        <div> {{ i18n('tishixinxi2') }} </div>
                        <div> {{ i18n('tishixinxi3') }} </div>
                        <div> {{ i18n('tishixinxi4') }} </div>
                        <div> {{ i18n('tishixinxi5') }} </div>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="orderlist" style="width: 1200px; border: 1px solid #ddd;">
            <el-table
                :data="recordListData"
                style="width: 100%;"
                :empty-text="i18n('zanwushuju')"
                :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                :row-style="{background:'#F9F9F8'}">
                <el-table-column
                    :label="i18n('shijian')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{scope.row.receivedTime | timeFormat('ymdhms')}} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('leixing')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> {{i18n('putongchongbi')}} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('shuliang')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.rechargeVolumes | filter_moneyFormat(8) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('zhuangtai')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.status === '1' ? i18n('jiaoyichenggong') : i18n('jiaoyishibai') }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('caozuo')"
                    min-width="100">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            style="background-color: #1772F8; border-color: #1772F8; color: #fff; height: 30px; width: 100px;"
                            @click="seedetail(scope.row)"> {{i18n('chakanxiangqing')}} </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="block">
                <el-pagination
                    background
                    hide-on-single-page
                    :page-size=page_size
                    :pager-count="11"
                    layout="prev, pager, next"
                    :current-page.sync=page
                    @current-change="handleCurrentChange"
                    class="flex_row_right"
                    :total=totalCount>
                </el-pagination>
            </div>
        </div>

        <el-dialog class="dialogdetail" :title="i18n('dialogtitle')" :visible.sync="dialogdetail" width="30%"> 
            <div class="flex_row_center">
                <div class="show-box">
                    <div class="color_333 fontSize25 text_center"> {{ dialogvolue | filter_moneyFormat(8) }} USDT</div>
                    <div class="delist">
                        <div class="flex_row_between">
                            <div> {{ i18n('leixing') }} </div>
                            <div> {{ i18n('putongchongbi') }} </div>
                        </div>
                        <div class="flex_row_between">
                            <div> {{ i18n('zhuangtai') }} </div>
                            <div> {{ dialogstatus }} </div>
                        </div>
                        <div class="flex_row_between">
                            <div> {{ i18n('shijian') }} </div>
                            <div> {{ dialogtime | timeFormat('ymdhms') }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>    
    </div>
</template>

<script>
import ClipboardJS from 'clipboard';
import { moneyFormat } from '@/utils/filters.js'

export default {
    data() {
        return {
            CoinSelect: 'USDT',
            chainSelect: 'TRC20',
            logoSrc: require('@/assets/logo.png'),
            textChain: '',
            randomColor: 'black',
            recordListData: [],
            page: 1,
            page_size: 10,
            totalCount: 0,
            dialogdetail: false,
            dialogvolue: 0,
            dialogstatus: undefined,
            dialogtime: undefined,

        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getAddressInfo();
                this.getList();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getAddressInfo();
            this.getList();
        };
        
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("recharge")[name];
            }
		},
    },
    filters: {
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() < 10 ? '0' + needdate.getDate() : needdate.getDate();
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + ' ' + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        ClassCoinUSDT() {
            if (this.CoinSelect === 'USDT') {
                return ['CoinUSDT', 'onselect'];
            } else {
                return ['CoinUSDT'];
            } 
        },
        ClassCoinUSDC() {
            if (this.CoinSelect === 'USDC') {
                return ['CoinUSDC', 'onselect'];
            } else {
                return ['CoinUSDC'];
            }
        },
        ClassTRC20() {
            if (this.chainSelect === 'TRC20') {
                return ['lbtn', 'llist', 'on'];
            } else {
                return ['lbtn', 'llist'];
            }
        },
        ClassERC20() {
            if (this.chainSelect === 'ERC20') {
                return ['lbtn', 'llist', 'on'];
            } else {
                return ['lbtn', 'llist'];
            }
        },
        ClassOMNI() {
            if (this.chainSelect === 'OMNI') {
                return ['lbtn', 'llist', 'on'];
            } else {
                return ['lbtn', 'llist'];
            }
        },
        clickCoin(Coin) {
            this.CoinSelect = Coin;
        },
        clickChain(Chain) {
            if (Chain === 'ERC20') {
                this.getAddressInfo('erc')
            } else if (Chain === 'OMNI') {
                this.$message({
                    message: this.$t('recharge')['OMNIweihuzhong'],
                    type: 'warning'
                });
                // this.textChain = '';
                return;
            } else if (Chain === 'TRC20') {
                this.getAddressInfo('trc')
            }
            this.chainSelect = Chain;
        },
        async getAddressInfo(Chainname) {
            let data = {
                token: sessionStorage.getItem("tk"),
                name: Chainname ? Chainname: 'trc'
            }
            this.$api.getaddressInfo(data).then(res => {
                if(res.data.code === 200){
                    this.textChain = res.data.data.address;
                } else {
                    this.$message({
                        message: this.$t('recharge')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        copyaddress() {
            const clipboard = new ClipboardJS('.btn-copy', {
                text: () => this.textChain
            });
            
            clipboard.on('success', (e) => {
                e.clearSelection();
            });

            clipboard.on('error', (e) => {
                console.error('Action failed', e.action);
            });
        },
        seedetail(row) {
            this.dialogvolue = row.rechargeVolumes;
            if (row.status === '1') {
                this.dialogstatus = this.$t("recharge")['jiaoyichenggong'];
                console.log(this.dialogstatus);
            } else if (row.status === '2') {
                this.dialogstatus = this.$t("recharge")['jiaoyishibai'];
            }
            this.dialogtime = row.receivedTime;

            this.dialogdetail = true;
        },
        recvdateParse(list) {
            this.recordListData = [];
            list.forEach(item => {
                var obj = {};
                obj.receivedTime = item.receivedTime;
                obj.rechargeVolumes = item.rechargeVolumes;
                obj.status = item.status;
                this.recordListData.push(obj);
            });
        },
        async getList() {
            this.page = 1;
            let data = {
                token: sessionStorage.getItem("tk"),
                page: this.page,
                page_size: this.page_size,
            }
            this.$api.getrechargeorderList(data).then((res)=>{  
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                    this.totalCount = Number(res.data.data.count);
                } else {
                    this.$message({
                        message: this.$t('recharge')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        async getmoreList() {
            let data = {
                token: sessionStorage.getItem("tk"),
                page: this.page,
                rows: this.page_size,
            }
            this.$api.getrechargeorderList(data).then((res)=>{
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                } else {
                    this.$message({
                        message: this.$t('recharge')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        handleCurrentChange() {
            this.getmoreList();
        },
    },
}
</script>

<style>

.showpart {
    background: #F9F9FA;
    height: auto;
    min-width: 1200px;
    margin: 0 auto;
}

.showcontent {
    padding: 0 150px;
}

.lname {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
}

.lbtn {
    width: 260px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    border: 1px solid #E6ECF2;
    color: #333333;
    cursor: pointer;
}

.on {
    border: 1px solid #0059FF;
    color: #0059FF;
}

.ewm {
    margin-top: 50px;
    margin-bottom: 20px;
}

.copyicon {
    cursor: pointer;
}

.rowline {
    height: 1px;
    border-bottom: 1px solid #E6ECF2;
    margin: 54px 0 30px 0;
}

.tip {
    margin-left: 50px;
    text-align: left;
    line-height: 20px;
}

.show-box {
    width: 400px;
    background-color: #fff;
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.show-box::-webkit-scrollbar {
    display: none;
}

.delist {
    margin: 10px 0;
    font-size: 16px;
    line-height: 40px;
}

.delist .type2 {
    display: none;
}

.delist .flex_row_between div:nth-child(1) {
    color: #9aa5b5;
}

.CoinUSDT,
.CoinUSDC{
    width: 88px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    color: #666666;
    cursor: pointer;
}

.kuang {
    padding: 6px;
    background: #FFFFFF;
    border: 1px solid #E6ECF2;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
}

.onselect {
    color: #FFFFFF;
    background: #1772F8;
}

.dialogdetail .el-dialog {
        background: #fff !important;
    }

.dialogdetail .el-dialog__title {
    color: #999 !important;
    font-weight: 900;
}

</style>

<style scoped>


</style>