<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import config from '@/utils/configs'

export default {
  name: 'app',
  created () {
    this.$store.dispatch('quotesWS/startWebSocket', config.quotationAddress); 
    this.$store.dispatch('quotesSpotWS/startWebSocket', config.quotationSpotAddress);

    //自动登录
    let username = sessionStorage.getItem('username');
    let userpassword = sessionStorage.getItem('userpass');
    if (username && userpassword && username !== '' && userpassword !== '') {
      let conn_data = {
        url: config.tradeAddress,
        user: username,
        userpass: userpassword
      }
      this.$store.dispatch('tradeWS/startWebSocket', conn_data);
      let conn_data_spot = {
        url: config.tradeSpotAddress,
        user: username,
        userpass: userpassword
      }
      this.$store.dispatch('tradeSpotWS/startWebSocket', conn_data_spot);
      let conn_data_options = {
          url: config.tradeOptionsAddress,
          user: username,
          userpass:userpassword
      }
      this.$store.dispatch('tradeOptionsWS/startWebSocket', conn_data_options);
    }
  },
  beforeDestroy(){
    this.$store.dispatch('quotesWS/close');
    this.$store.dispatch('tradeWS/close');
    this.$store.dispatch('quotesSpotWS/close');
    this.$store.dispatch('tradeSpotWS/close');
    this.$store.dispatch('tradeOptionsWS/close');
  }
}
</script>