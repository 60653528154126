
    /**
     * 组包
     * 1.uint8Array-原数组
     * 2.megType-消息类型
     * 最后写入长度返回
     */
    export function EncodePack(uint8Array, megType) {
        var newUint8Array = new Uint8Array(uint8Array.length + 2);
        var arrayBuffer = new ArrayBuffer(2);
        var dv = new DataView(arrayBuffer);
        dv.setUint16(0, megType, true);
        var uint8Array0 = new Uint8Array(arrayBuffer);
        newUint8Array.set(uint8Array0);
        newUint8Array.set(uint8Array, uint8Array0.length);
        // console.log("写类型newUint8Array", newUint8Array);
        return WriteToMsgLenght(newUint8Array);
    };
    /**
     * 写入消息长度
     * 1.uint8Array-原数组
     */
    export function WriteToMsgLenght(uint8Array) {
        var newUint8Array = new Uint8Array(uint8Array.length + 4);
        var arrayBuffer = new ArrayBuffer(4);
        var dv = new DataView(arrayBuffer);
        dv.setInt32(0, uint8Array.length-2, true);
        var uint8Array0 = new Uint8Array(arrayBuffer);
        newUint8Array.set(uint8Array0);
        newUint8Array.set(uint8Array, uint8Array0.length);
        // console.log("写长度newUint8Array", newUint8Array);
        return newUint8Array;
    };
    /**
     * 拆包
     * 获取到正确长度后直接获取消息类型与实际消息体
     */
    export function DecodePack(arrayBuffer) {
        var readLengthArryBuffer = null;
        var dv = new DataView(arrayBuffer);
        var length = dv.getInt32(0, true);
        readLengthArryBuffer = arrayBuffer.slice(4, arrayBuffer.byteLength);
        if (length > readLengthArryBuffer.byteLength) {
            console.log("未收到正确消息长度", length, readLengthArryBuffer.byteLength);
        }
        return ReadOfMsgType(readLengthArryBuffer);
    };
    /**
     * 获取消息类型与消息体
     */
    export function ReadOfMsgType(arrayBuffer) {
        var readMsgArryBuffer = null;
        var dv = new DataView(arrayBuffer);
        var mt = dv.getUint16(0, true);
        readMsgArryBuffer = arrayBuffer.slice(2, arrayBuffer.byteLength);
        // console.log("消息体", length, readMsgArryBuffer.byteLength);
        return { msgType: mt, data: new Uint8Array(readMsgArryBuffer) };
    };
   
