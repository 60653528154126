import { teadeWSparse } from '@/utils/parseTrade'
import { teadeWSReq } from '@/utils/packedTrade'
import { DecodePack } from '@/utils/ByteArray.js'
import store from '@/store'
import router from '../router'
const tradeWSModule = {
    namespaced: true,
    state: {
        ws: null,
        wsHart: 0,
    },
    mutations: {
        SET_WS(state, ws) {
            state.ws = ws;
            // console.log("SET_WS", state.ws);
        },
        CLEAR_WS(state) {
            state.ws = null;
        },
        INC_HARTCOUNT(state) {
            state.wsHart++;
        },
        ZERO_HARTCOUNT(state) {
            state.wsHart = 0;
        },
    },
    actions: {
        startWebSocket({commit, state}, connData) {
            // commit('SET_LOGINDATA', loginMsg);
            if (state.ws) state.ws.close();
            const ws = new WebSocket(connData.url);
            ws.onopen = function () {
                console.log('trade WebSocket connected');

                commit('SET_WS', ws);
        
                teadeWSReq.login(connData.user, connData.userpass);
            };
            ws.onmessage = function (event) {
                // console.log('Received:', event.data);
                // 处理接收到的消息

                // let message = {
                //     loginStatus: true,
                // }
                // this.$emit('trade:11001', message);

                var recvData = event.data;
                // 将 Blob 转换为 ArrayBuffer
                if (recvData instanceof Blob) {
                    var reader = new FileReader();
                    reader.onload = function() {
                        var  arrayBuffer = this.result;
                        var bbt = DecodePack(arrayBuffer);
                        teadeWSparse.parseData(bbt);
                    };
                    reader.readAsArrayBuffer(recvData);
                } else if (recvData instanceof ArrayBuffer) {
                    var bbt = DecodePack(recvData);
                    teadeWSparse.parseData(bbt);
                } else {
                    console.error("Unsupported data type in event.data");
                }
            };
            ws.onerror = function (error) {
                console.error('trade WebSocket error:', error);
            };
            ws.onclose = function (error) {
                console.log('trade WebSocket closed');

                store.state.holdOrderList = [];
                store.state.EntrustorderList = [];
                store.state.DealOrderList = [];
                store.state.ConditionOrderList = [];
                // 可能需要重连逻辑
                store.state.username = '';
                store.state.userpass = '';
                store.state.userWallet.balance = 0;           //余额
                store.state.userWallet.marginBalance = 0;     //账户权益
                store.state.userWallet.marginPosition = 0;    //持仓保证金
                store.state.userWallet.marginFrozen = 0;      //冻结保证金
                store.state.userWallet.marginAvailable = 0;   //可用保证金
                store.state.userWallet.profitReal = 0;        //已实现盈亏
                store.state.userWallet.profitUnreal = 0;      //未实现盈亏
                store.state.userWallet.riskRate = 0;          //保证金率
                store.state.userWallet.followBalance = 0;     //跟单账户余额
                store.state.userWallet.liquidationPrice = 0;  //预估强平价
                store.state.userWallet.canuseMoney = 0;       //可用余额
                store.state.userWallet.userType = 0;
                store.state.userWallet.LegalCurrency = 0;     //法币账户余额通过API获取
                store.state.userWallet.calcprofit = 0;        //本地计算浮动盈亏
                store.state.userWallet.calcprofitWh = 0;      //本地计算浮动盈亏
                store.state.userWallet.calcprofitMg = 0;      //本地计算浮动盈亏
                store.state.loginStatus = false;
                console.log(error)
                if(error.code !=1005){
                    router.push({
                        path:'/LoginLayout'
                    });
                }
            };
        },
        sendMessage({state}, message) {
            if (state.ws && state.ws.readyState === WebSocket.OPEN) {
                state.ws.send(message);
            } else {
                console.warn('WebSocket not connected, unable to send message');
            }
        },
        close({state}) {
            if (state.ws) state.ws.close();
        },
    }
}

export default tradeWSModule


