<template>
    <div class="spot-trade-layout">
        <div class="pub-detail flex_row_around bg_black">
            <div class="pub-name flex_row_left" style="width: 250px;"> 
                <el-dropdown  @command="eldropdownCommand">
                    <span class="el-dropdown-link" style="color: #ffffff;" v-if = "activeObj && activeObj.code">
                        <i class="el-icon-caret-bottom" ></i>
                        {{ activeObj.code.toUpperCase() }} 
                    </span>
                    <span class="el-dropdown-link" style="color: #ffffff" v-else>
                        <i class="el-icon-caret-bottom" ></i>
                        --
                    </span>
                    <el-dropdown-menu slot="dropdown" style="background-color: #161C20; border: none; width: 400px; margin-left: 20px;">
                        <el-dropdown-item disabled style="color: #ffffff">
                            <span class="option-span" style="width:100px;"> {{i18n('hangqingheyue')}} </span>
                            <span class="option-span-short" style="width:100px;"> {{i18n('hangqingzuixinjia')}} </span>
                            <span class="option-span-long"> {{i18n('hangqingzhangdiefu')}} </span>
                        </el-dropdown-item>

                        <el-dropdown-item v-for="(item, index) in hqlist" 
                            :key="index" 
                            :class = "dropdown_itemClass(item)"  
                            :disabled = "item.code === activeContract"
                            :command = "item.code">
                            <span class="option-span" style="color: #ffffff; width:100px;"> {{ item.code.toUpperCase() }} </span>
                            <span class = "option-span-short" style="width:100px;" v-if = "item.price <= 0">
                                - - </span>
                            <span :class = "dropdown_priceClass(item)" style="width:100px;" v-else> 
                                {{item.price | filter_newPriceFormat(item.float_num)}} </span>
                            <span  :class = "dropdown_rateClass(item)" v-if = "item.price > 0"> 
                                <el-image style="width: 12px; height: 10px; margin-right: 10px;" 
                                :src = "require('@/assets/images/up-icon.png')" v-if = "dropdown_imgClass(item) === 'color_up'"></el-image>
                                <el-image style="width: 12px; height: 10px; margin-right: 10px;" 
                                :src = "require('@/assets/images/down-icon.png')" v-if = "dropdown_imgClass(item) === 'color_down'"></el-image>
                                {{item.price | filter_classUpOrDown(item.open,'rate')}} </span>
                            <span class="color_up option-span-long" v-else> 
                                +0.00% </span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="flex_row_left pub-price" style="width: 120px;">
                <span :class = "nowPrice1_class(activeObj)" v-if = "activeObj && activeObj.price && activeObj.price !== 0"> {{ activeObj.price }} </span>
                <span class="actPrice" v-else> -- </span>
            </div>
            <div class="pub-price pub-updown" style="width: 120px;">
                <span :class = "nowPrice1_class(activeObj)" v-if = "activeObj && activeObj.price && activeObj.open && activeObj.price !== 0 && activeObj.open !== 0">
                    {{ activeObj.price | filter_classUpOrDown(activeObj.open,'rate')}}
                </span>
                <span class="actPrice" v-else>--</span>
            </div>

            <div class="flex_row_center manyprice">
                <div class="sub-part" style="width: 70px;">
                    <div class="sub-title"> {{i18n('hangqingzuigao24h')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.high && activeObj.high !== 0"> {{ activeObj.high }} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
                <div class="sub-part" style="width: 70px;">
                    <div class="sub-title"> {{i18n('hangqingzuidi24h')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.low && activeObj.low !== 0"> {{ activeObj.low }} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
                <div class="sub-part" style="width: 120px;">
                    <div class="sub-title"> {{i18n('hangqingchengjiaoliang24h')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.dealnum && activeObj.dealnum !== 0"> {{ activeObj.dealnum }} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
                <div class="sub-part" style="width: 120px;">
                    <div class="sub-title"> {{i18n('hangqingchengjiabishu')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.Totalnum && activeObj.Totalnum !== 0"> {{ activeObj.Totalnum }} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
            </div>
            <div class="flex_row_center mysubnum">
                <div class="sub-part">
                    <div class="sub-title"> {{i18n('zhanghuquanyi')}} </div>
                    <div class="sub-price zhqy"> {{ (this.userWallet.marginBalance + this.userWallet.calcprofit) | filter_moneyFormat(8) }}</div>
                </div>
                <div class="sub-part">
                    <div class="sub-title"> {{i18n('zhanghukeyongshouyi')}} </div>
                    <div class="sub-price kyqy"> {{ (this.userWallet.marginAvailable + this.userWallet.calcprofit) | filter_moneyFormat(8) }} </div>
                </div>
            </div>
        </div>

        <!-- k线图 展示等 -->
        <div class="flex_row_left" style="align-items: flex-start;padding-bottom: 10px;">
            <div class="left-part flex1">
                <div class="flex_row_left" style="align-items: flex-start;">
                    <!-- k线图 -->
                    <div class="flex1 bg_black kline">
                        <klineChat :parentMessage="activeObj"></klineChat>
                    </div>
                    <div class="delegationlist bg_black">
                        <div class="delist-title"> {{i18n('hangqingweituoliebiao')}} </div>
                        <div class="flex_row_between delist-price">
                            <div class=""> {{i18n('hangqingjiage')}}(USDT) </div>
                            <div class=""> {{i18n('hangqingshuliang')}} </div>
                        </div>
                        <div class="delegationshow">
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice4 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol4 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth4 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice3 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol3 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth3 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice2 || '--'| filter_newPriceFormat(activeObj.float_num) }}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol2 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth2 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice1 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol1 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth1 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice0 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol0 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth0 + '%' }"></div>
                            </div>
                            <div class="flex_row_between up-row">
                                <div :class = "nowPrice2_class(activeObj)"> {{ activeObj && activeObj.price || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <span :class = "nowRate2_class(activeObj)" v-if = "activeObj && activeObj.price && activeObj.open && activeObj.price !== 0 && activeObj.open !== 0">
                                    {{ activeObj.price | filter_classUpOrDown(activeObj.open,'rate')}}
                                </span>
                                <span class="actPrice" v-else>--</span>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice0 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol0 || '--'}} </div>
                                <div class="progress-up" :style="{ width: progressupWidth0 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice1 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol1 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth1 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice2 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol2 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth2 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice3 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol3 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth3 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice4 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol4 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth4 + '%' }"></div>
                            </div>
                        </div>

                        <div class="delist-title"> {{i18n('hangqingzuixinchengjiao')}} </div>
                        <div class="flex_row_between delist-price">
                            <div class="flex1"> {{i18n('hangqingjiage1')}}(USDT) </div>
                            <div class="flex1"> {{i18n('hangqingshuliang1')}} </div>
                            <div class="flex1"> {{i18n('hangqingshijian')}} </div>
                        </div>
                        <div class="latestdeal">
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price0 !== 0"> 
                                        {{ activeObj.latestdealList.price0 }} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num0 !== 0"> 
                                        {{ activeObj.latestdealList.num0 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time0 !== 0">
                                        {{activeObj.latestdealList.time0 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price1 !== 0"> 
                                        {{ activeObj.latestdealList.price1 }} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num1 !== 0"> 
                                        {{ activeObj.latestdealList.num1 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time1 !== 0">
                                        {{activeObj.latestdealList.time1 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price2 !== 0"> 
                                        {{ activeObj.latestdealList.price2 }} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num2 !== 0"> 
                                        {{ activeObj.latestdealList.num2 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time2 !== 0">
                                        {{activeObj.latestdealList.time2 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price3 !== 0"> 
                                        {{ activeObj.latestdealList.price3 }} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num3 !== 0"> 
                                        {{ activeObj.latestdealList.num3 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time3 !== 0">
                                        {{activeObj.latestdealList.time3 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price4 !== 0"> 
                                        {{ activeObj.latestdealList.price4 }} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num4 !== 0"> 
                                        {{ activeObj.latestdealList.num4 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time4 !== 0">
                                        {{activeObj.latestdealList.time4 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price5 !== 0"> 
                                        {{ activeObj.latestdealList.price5 }} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num5 !== 0"> 
                                        {{ activeObj.latestdealList.num5 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time5 !== 0">
                                        {{activeObj.latestdealList.time5 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 左下部分 -->
                <div class="holdlist bg_black">
                    <div class="hold-title flex_row_between">
                        <el-tabs class="flex_row_left flex1" v-model="orderTabName"
                        :stretch="false"
                        @tab-click="orderTabClick"
                        style="color: white; height: 300px; width: 100%; caret-color: transparent; margin-left: 50px; margin-top: 13px;">
                            <el-tab-pane :label="i18n('dingdanchiyoucangwei')" name="first"></el-tab-pane>
                            <el-tab-pane :label="i18n('dingdandangqianweituo')" name="second"></el-tab-pane>
                            <el-tab-pane :label="i18n('dingdanchengjiaojilu')" name="third"></el-tab-pane>
                        </el-tabs>
                    </div>
                    <!-- 持有仓位 -->
                    <div class="dataViewTable">
                        <el-table
                        v-show="orderTabName === 'first'"
                        :data="holdOrderList"
                        empty-text="no data"
                        @row-click="HoldorderListClick"
                        :row-style="HoldorderListStyle"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height: '30px'}"
                        :cell-style="{padding: '0', height: '30px'}">   
                            <el-table-column
                                :label="i18n('chicangheyue')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.Contractcode.toUpperCase() }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangduokong')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.direction === 0 ? i18n('chicangduo') : i18n('chicangkong') }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangkaicangjunjia')"
                                min-width="80">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Costopen | filter_moneyFormat(scope.row.wave)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangchicangliang')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.volume }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangchengjiaojine')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ (scope.row.Costopen * scope.row.volume) | filter_moneyFormat(8) }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangfudongyingkui')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span :class="scope.row.Profit > 0 ? 'color_green' : 'color_red'"> 
                                        {{scope.row.Profit | filter_moneyFormat(8)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangcaozuo')"
                                min-width="150">
                                <template slot-scope="scope">
                                    <el-button
                                    size="mini"
                                    type="primary"
                                    style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                    @click="holdclose(scope.row)"> {{i18n('chicangpingcang')}} </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 当前委托 -->
                        <el-table
                        v-show="orderTabName === 'second'"
                        :data="EntrustorderList"
                        empty-text="no data"
                        :row-style="{background:'#161c20', 'border-color':'#20282E'}"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height:'30px'}"
                        :cell-style="{padding: '0', height: '30px'}">     
                            <el-table-column
                                :label="i18n('weituoheyue')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.Contractcode.toUpperCase() }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituoshuliang')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.volume}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituojiage')"
                                min-width="100">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Price | filter_moneyFormat(scope.row.wave)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituofangxiang')"
                                min-width="80">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === scope.row.offset ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.DirectionText }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituochengjiaoshuliang')"
                                min-width="130">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradevolume}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituoweichengjiaoshuliang')"
                                min-width="60">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.volume - scope.row.Tradevolume}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituozhuangtai')"
                                min-width="100">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.StatusText }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituoxiadanshijian')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.createDate | timeFormat('ymdhms') }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituocaozuo')"
                                min-width="100">
                                <template slot-scope="scope">
                                    <el-button
                                    v-show="scope.row.Status === 2"
                                    size="mini"
                                    type="primary"
                                    style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                    @click="EntrustorderCancle(scope.row)"> {{i18n('weituochedan')}} </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 成交记录 -->
                        <el-table
                        v-show="orderTabName === 'third'"
                        :data="DealOrderList"
                        empty-text="no data"
                        :row-style="{background:'#161c20', 'border-color':'#20282E'}"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height:'30px'}"
                        :cell-style="{padding: '0', height: '30px'}">     
                            <el-table-column
                                :label="i18n('chengjiaoheyue')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.Contractcode.toUpperCase() }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaofangxiang')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === scope.row.offset ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.DirectionText }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaoshuliang')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradevolume}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaojiage')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradeprice | filter_moneyFormat(scope.row.wave)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaoshouxufei')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradefee | filter_moneyFormat(8)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaoshijian')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Createdate | timeFormat('ymdhms') }} </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 条件单 -->
                    </div>
                </div>
            </div>
            <!-- 右边部分 -->
            <div class="right-part">
                <div class="bg_black" style="height: 463px;">
                    <div class="right-title flex_row_between">
                        <el-tabs class="flex_row_left flex1" v-model="OpenOrClose"
                        :stretch="false"
                        @tab-click="OpenClosetabClick"
                        style="color: white; height: 300px; width: 100%; caret-color: transparent; margin-top: 12px; margin-left: 10px;">
                            <el-tab-pane :label="i18n('xiadankaicang')" name="Open"></el-tab-pane>
                            <el-tab-pane :label="i18n('xiadanpingcang')" name="Close"></el-tab-pane>
                        </el-tabs>
                    </div>
                    <!-- 开仓展示 -->
                    <div class="showopen">
                        <div class="flex_row_left">
                            <div style="margin: 20px 10px; font-size: 14px;" class="kyusdt" v-show="OpenOrClose === 'Open'"> {{ i18n('xiadankeyong') }} USDT: {{ this.userWallet.marginAvailable | filter_moneyFormat(8) }} </div>
						    <div style="margin: 20px 10px; font-size: 14px;" class="kmusdt" v-show="OpenOrClose === 'Close'"> {{ i18n('xiadankemai') }} {{ CloseContract }}: {{ canCloseNum }} </div>
                        </div>
                        <div class="flex_row_between" style="height:60px;" >
                            <div style="width:30%; margin-left: 15px; font-size: 14px;"> {{i18n('xiadanweituoleixing')}} </div>
                            <el-select v-model="priceType" :placeholder="i18n('xiadanqingxuanze')"
                            size="mini"
                            @change="priceTypeChange" 
                            style="width: 45%; font-size: 14px; margin-right:15px;"
                            class="select_trade" popper-class="selectpopper_trade">
                                <el-option :label="i18n('xiadanshijia')" value="1"></el-option>
                                <el-option :label="i18n('xiadanxianjia')" value="0"></el-option>
                            </el-select>
                        </div> 
                        <div class="flex_row_left inputarea">
                            <div class="inputname" style="width: 48px;"> {{i18n('xiadanjiage')}} </div>
                            <el-input-number v-model="orderPrice" size="mini" 
                            :disabled="priceType === '1'? true:false"
                            :precision="activeprecision" :step="activestep" :min="activemin" :max="activemax" 
                            style="margin-left: 10px; background-color: #1C2328; width: 170px;" 
                            :placeholder="priceType === '1'? i18n('xiadanshijia') : i18n('xiadanqingshurujiage')"></el-input-number>
                        </div>
                        <!-- :precision="0" :step="1" @change="orderNumChange"  -->
                        <div class="flex_row_left inputarea">
                            <div class="inputname" style="width: 48px;"> {{i18n('xiadanshuliang')}} </div>
                            <el-input-number 
                            v-model="orderNum" 
                            size="mini" 
                            :min="0" :max="OpenOrClose === 'Open'? canOpenNum : canCloseNum"
                            :controls="false"
                            style="margin-left: 10px; background-color: #1C2328; width: 170px;" 
                            ref="reforderNum"
                            @input.native="orderNuminputNative"
                            :placeholder="i18n('xiadanqingshurushuliang')"></el-input-number>
                            <div style="margin-left: 10px;"> {{ (activeContract && activeContract !== '') ? activeContract.toUpperCase().split("USDT")[0] : '' }} </div>
                        </div>
                        <!-- :precision="0" :step="1" :min="0" :max="OpenOrClose === 'Open'? canOpenNum : canCloseNum"  -->
                        <div class="flex_row_left inputarea">
                            <div class="inputname" style="width: 48px;"> {{i18n('xiadanjine')}} </div>
                            <el-input-number v-model="orderSummoney" size="mini" 
                            :controls="false"
                            :disabled="true"
                            style="margin-left: 10px; background-color: #1C2328; width: 170px;" >
                            </el-input-number>
                            <div style="margin-left: 10px;"> USDT </div>
                        </div>
                        <div class="inputarea_slider">
                            <el-slider
                                v-model="slidervalue"
                                :step="10"
                                :marks="marks"
                                :show-tooltip="false"
                                @change="sliderChange"
                                show-stops>
                            </el-slider>
                        </div>
                        <div class="equal" v-show="OpenOrClose === 'Open'"> {{i18n('xiadankekaishuliang') + ' ' + canOpenNum + ' ' + i18n('xiadanzhang')}} </div>
                        <div class="flex_row_center opbtn" v-show="OpenOrClose === 'Open'">
                            <el-button
                            size="medium"
                            type="primary"
                            style="background-color: #1763B6; border-color: #1763B6; color: #EEEEEE; 
                                    border-radius: 2px; width: 45%; height: 34px; font-size: 14px; font-weight: 400;"
                            @click="openLong"> 
                            {{ i18n('xiadanzuoduo') }} </el-button>
                        </div>
                        <div class="flex_row_center opbtn" v-show="OpenOrClose === 'Close'">
                            <el-button
                            size="medium"
                            type="primary"
                            style="background-color: #E12343; border-color: #E12343; color: #EEEEEE; 
                                    border-radius: 2px; width: 45%; height: 34px; font-size: 14px; font-weight: 400;"
                            @click="Closeposition"> 
                            {{ i18n('xiadanpingcang') }} </el-button>
                        </div>
                    </div>
                </div>
                <!-- 合约资产 -->
                <div class="assets bg_black">
                    <div class="assets-title"> {{ i18n('zhanghuheyuezichan') }} </div>
                    <div class="flex_row_around">
                        <div class="opbsbtn" @click="rechargeClick"> {{ i18n('zhanghuchongbi') }} </div>
                        <div class="opbsbtn" @click="withdrawClick"> {{ i18n('zhanghutibi') }} </div>
                        <div class="opbsbtn" @click="transferDialogShow()"> {{ i18n('zhanghuhuazhuan') }} </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghuzhanghuquanyi') }} </div>
                        <div class="assets-num"> {{ (this.userWallet.marginBalance + this.userWallet.calcprofit) | filter_moneyFormat(8) }} </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghufudongyingkui') }} </div>
                        <div class="assets-num">{{ this.userWallet.calcprofit | filter_moneyFormat(8) }}</div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghucangweibaozhengjin') }} </div>
                        <div class="assets-num">{{ this.userWallet.marginPosition | filter_moneyFormat(8) }}</div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghudongjiebaozhengjin') }} </div>
                        <div class="assets-num">{{ this.userWallet.marginFrozen | filter_moneyFormat(8) }}</div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghukeyongquanyi') }} </div>
                        <div class="assets-num"> {{ (this.userWallet.marginAvailable) | filter_moneyFormat(8) }} </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghubaozhengjinshiyonglv') }} </div>
                        <div class="assets-num">{{ this.userWallet.riskRate | filter_moneyFormat(8) }}</div>
                    </div>
                </div>
                <!-- 合约信息 -->
                <div class="assets bg_black">
                    <div class="assets-title"> {{ i18n('hangqingheyuexinxi') }} </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingheyuemingcheng') }} </div>
                        <div class="assets-num assets-infoname">
                            <span v-if = "activeObj && activeObj.code">
                                {{ activeObj.code.toUpperCase() }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingjijiahuobi') }} </div>
                        <div class="assets-num assets-infomoney">
                            <span v-if = "activeObj && activeObj.code">USDT</span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingheyuedaxiao') }} </div>
                        <div class="assets-num assets-infosiaze">
                            <span v-if = "activeObj && activeObj.val">
                                {{ activeObj.val }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingzuixiaojiagebodong') }} </div>
                        <div class="assets-num assets-infomin">
                            <span v-if = "activeObj && activeObj.wave">
                                {{ activeObj.wave }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 划转 -->
        <el-dialog :title="i18n('dialogtransferzichanhuazhuan')" :visible.sync="dialogtransfer" width="30%">
            <div class="color_aaa"> {{ i18n('dialogtransferbizhong') }} </div>
            <div class="currency flex_row_left">
                <div class="flex1 currencyvalue" style="color: #eee;">USDT</div>
            </div>
       
            <div class="flex_row_around color_aaa">
                <div class="flex1"> {{i18n('dialogtransfercong')}} </div>
                <div class="transfericon"></div>
                <div class="flex1"> {{i18n('dialogtransferdao')}} </div>
            </div>
            <div class="flex_row_around">
                <div class="from flex_row_left flex1">
                    <div class="flex1" style="color: #eee;"> {{ transferfromvalue }} </div>
                </div>
                <img class="transfericon" :src="require('@/assets/images/transfer.png')" alt="" @click="transfericonClick">
                <div class="to flex_row_left flex1">
                    <div class="flex1" style="color: #eee;"> {{ transfertovalue }} </div>
                </div>
            </div>
   
            <div class="color_aaa"> {{i18n('dialogtransfershuliang')}} </div>
            <div class="flex_row_left trsnum">
                <el-input-number v-model="transferNum" size="mini" 
                :precision="8" :step="1" :min="1" :max="transdirection === 6 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable"
                style="margin-left: 20px; background-color: #1C2328; width: 70%; margin-right: 20px;" 
                :placeholder="i18n('dialogtransferqingshuruhuazhuanshuliang')"></el-input-number>
                <div class="currency-show" style="color: #eee;">USDT</div>
                <div class="color_red tarnsall" style="margin-left: 10px;cursor: pointer;" @click="transferAllclick"> {{i18n('dialogtransferquanbu')}} </div>
            </div>
            <div class="fontSize12 color_aaa tipss">{{i18n('dialogtransferkeyong')}} {{ (transdirection === 6 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable) | filter_moneyFormat(8) }} </div>
            <div class="flex_row_center">
                <div class="transferbtn" @click="transferbtnclick"> {{i18n('dialogtransferhuazhuan')}}</div>
            </div>
        </el-dialog>
        
        <!-- 平仓 -->
        <el-dialog :title="i18n('dialogCloseOrderpingcang')" :visible.sync="dialogCloseOrder" width="30%"> 
            <div class="flex_row_center fontSize14">
                <div style="width: 450px;">
                    <div class="flex_row_left"  style="width: 100%; height: 40px; background: #1C2328;">
                        <div style="width:25%; margin-left: 15px; font-size: 14px;"> {{i18n('dialogCloseOrderweituoleixing')}} </div>
                        <el-select v-model="CloseOrderpriceType" :placeholder="i18n('dialogCloseOrderqingxuanze')"
                        size="mini" 
                        @change="CloseOrderpriceTypeChange"
                        style="width: 50%; font-size: 14px;"
                        class="select_trade" popper-class="selectpopper_trade">
                            <el-option :label="i18n('dialogCloseOrdershijia')" value="1"></el-option>
                            <el-option :label="i18n('dialogCloseOrderxianjia')" value="0"></el-option>
                        </el-select>
                    </div>
                    <div class="flex_row_left" style="width: 100%; height: 40px; background: #1C2328; margin-top: 15px;">
                        <div style="width:25%; margin-left: 15px; font-size: 14px;"> {{i18n('dialogCloseOrderjiage')}} </div>
                        <el-input-number v-model="closeorderPrice" size="mini" 
                            :disabled="CloseOrderpriceType === '1'? true:false"
                            :precision="CloseOrderprecision" :step="CloseOrderstep" 
                            style="background-color: #1C2328; width: 50%;" 
                            :placeholder="CloseOrderpriceType === '1'? i18n('dialogCloseOrdershijia') : i18n('dialogCloseOrderqingshurujiage')"></el-input-number>
                    </div>
                    <div class="flex_row_left" style="width: 100%; height: 40px; background: #1C2328; margin-top: 15px;">
                        <div style="width:25%; margin-left: 15px; font-size: 14px;"> {{i18n('dialogCloseOrdershuliang')}} </div>
                        <!-- :precision="8" :step="0.00000001" -->
                        <el-input-number v-model="closeorderNum" size="mini" 
                            :controls="false"
                            :min="0" :max="closeordercanCloseNum" 
                            style="background-color: #1C2328; width: 50%;" 
                            :placeholder="i18n('dialogCloseOrderqingshurushuliang')"></el-input-number>
                        <div style="margin-left:10px;"> {{i18n('dialogCloseOrderzhang')}} </div>
                    </div>
                    <div class="equal1" style="margin-top: 15px;"> {{i18n('dialogCloseOrderkepingshuliang')}} {{ closeordercanCloseNum }} {{i18n('dialogCloseOrderzhang')}} </div>
                    <div class="flex_row_center">
                        <div class="pingcangbtn" @click="pingcangbtnclick"> {{i18n('dialogCloseOrderqueding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import { newPriceFormat, classUpOrDown, moneyFormat } from '@/utils/filters.js'
import { teadeSpotWSReq } from '@/utils/packedTradeSpot'
import { teadeWSReq } from '@/utils/packedTrade'
import { baseMethods } from '@/utils/methods.js'
import klineChat from '@/components/common/klineChat'

export default {
    data() {
        return {
            hqlist: this.$store.state.hqlistSpot,
            userWallet: this.$store.state.userWalletSpot,
            tradeFee: this.$store.state.tradeFee,
            activeObj: {}, // 当前选中合约对象
            activeContract: '',
            orderTabName: 'first',
            activeprecision: 0,                 //最小波动小数位
            activestep: 1,                      //最小波动
            activemin: 0,                       //品种最低价
            activemax: 9999999,                 //品种最高价
            selectHoldorder: undefined,         //选中的持仓单
            selectHoldorderRow: undefined,      //选中的持仓单对象
            OpenOrClose: 'Open',
            dialogtransfer: false,
            dialogCloseOrder: false,
            priceType: '1',
            orderPrice: undefined,              //下单价格
            orderNum: undefined,                //下单数量
            orderSummoney: undefined,           //下单金额
            slidervalue: 0,                     //滑块值
            marks: {
                0: '0%',
                20: '20%',
                40: '40%',
                60: '60%',
                80: '80%',
                100: '100%'
            },
            canorderNum: 0,                     //可下单数量
            canOpenNum: 0,                      //可开仓数量
            CloseContract: undefined,           //平仓合约名称
            canCloseNum: 0,                     //可平仓数量
            opendeposit: 0,                     //开仓保证金
            buyliquidation: 0,                 //做多强平价格
            transferfromvalue: this.$t("spot")['dialogtransferjichuzhanghu'],
            transfertovalue: this.$t("spot")['dialogtransferheyuezhanghu'],
            transdirection: 6,                  //6 法币往币币账户划转
            transferNum: undefined,
            progressWidth4: 0,
            progressWidth3: 0,
            progressWidth2: 0,
            progressWidth1: 0,
            progressWidth0: 0,
            progressupWidth0: 0,
            progressupWidth1: 0,
            progressupWidth2: 0,
            progressupWidth3: 0,
            progressupWidth4: 0,
            CloseOrderpriceType: '1',           //平仓对话框价格类型1市价0限价
            closeorderPrice: undefined,         //平仓对话框价格
            CloseOrderprecision: 0,             //平仓对话框价格小数位
            CloseOrderstep: 1,                  //平仓对话框价格最小波动
            closeorderNum: undefined,           //平仓对话框平仓数量
            closeordercanCloseNum: 0,           //平仓对话框可平仓数量
        };
    },
    components:{
        klineChat
    },
    watch: {
        'activeObj.fiveHqList': {
            handler (newVal, oldVal) {
                if (newVal) {
                    if (newVal.selltotal && newVal.selltotal > 0) {
                        if (newVal.salevol4 && newVal.salevol4 > 0)
                            this.progressWidth4 = newVal.salevol4 / newVal.selltotal * 100;
                        if (newVal.salevol3 && newVal.salevol3 > 0)
                            this.progressWidth3 = newVal.salevol3 / newVal.selltotal * 100;
                        if (newVal.salevol2 && newVal.salevol2 > 0)
                            this.progressWidth2 = newVal.salevol2 / newVal.selltotal * 100;
                        if (newVal.salevol1 && newVal.salevol1 > 0)
                            this.progressWidth1 = newVal.salevol1 / newVal.selltotal * 100;
                        if (newVal.salevol0 && newVal.salevol0 > 0)
                            this.progressWidth0 = newVal.salevol0 / newVal.selltotal * 100;
                    }
                
                    if (newVal.buytotal && newVal.buytotal > 0) {
                        if (newVal.buyvol0 && newVal.buyvol0 > 0) 
                            this.progressupWidth0 = newVal.buyvol0 / newVal.buytotal * 100;
                        if (newVal.buyvol1 && newVal.buyvol1 > 0) 
                            this.progressupWidth1 = newVal.buyvol1 / newVal.buytotal * 100;
                        if (newVal.buyvol2 && newVal.buyvol2 > 0) 
                            this.progressupWidth2 = newVal.buyvol2 / newVal.buytotal * 100;
                        if (newVal.buyvol3 && newVal.buyvol3 > 0) 
                            this.progressupWidth3 = newVal.buyvol3 / newVal.buytotal * 100;
                        if (newVal.buyvol4 && newVal.buyvol4 > 0) 
                            this.progressupWidth4 = newVal.buyvol4 / newVal.buytotal * 100;
                    }   
                }
            },
            deep: true,
            immediate: true,
        },
        'activeObj.price': {
            handler (newVal, oldVal) {
                if (newVal) {
                    if (this.$store.state.loginStatusSpot && this.userWallet && this.userWallet.marginAvailable) {
                        if (this.priceType === '1') {
                            var canKnum = baseMethods.accDiv(this.userWallet.marginAvailable, newVal);
                            this.canOpenNum = Math.floor(canKnum * 100000000) / 100000000;

                            // if (this.orderNum !== undefined && this.orderNum > 0)
                            //     this.orderSummoney = baseMethods.accMul(this.orderNum, this.activeObj.price);
                        } else {
                            if (this.orderPrice !== undefined && this.orderPrice > 0) {
                                var canKnum = baseMethods.accDiv(this.userWallet.marginAvailable, this.orderPrice);
                                this.canOpenNum = Math.floor(canKnum * 100000000) / 100000000;

                                // if (this.orderNum !== undefined && this.orderNum > 0)
                                //     this.orderSummoney = baseMethods.accMul(this.orderNum, this.orderPrice);
                            } else {
                                var canKnum = baseMethods.accDiv(this.userWallet.marginAvailable, newVal);
                                this.canOpenNum = Math.floor(canKnum * 100000000) / 100000000;
                            }
                        }
                    } else {
                        this.canOpenNum = 0;
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        i18n() {
            return function(name) {
                return this.$t("spot")[name];
            }
		},
        holdOrderList() {
            return this.$store.state.holdOrderListSpot;
        },
        EntrustorderList() {
            return this.$store.state.EntrustorderListSpot;
        },
        DealOrderList() {
            return this.$store.state.DealOrderListSpot;
        },
        ConditionOrderList() {
            return this.$store.state.ConditionOrderListSpot;
        },
    },
    mounted() {
        if (this.$store.state.hqlistSpot.length > 0) {
            if (this.$route.params.codename !== undefined){
                this.activeObj = this.hqlist.find(item => item.code === this.$route.params.codename);
                this.activeContract = this.$route.params.codename;
            }else{
                this.activeObj = sessionStorage.getItem('activeSpot') ? this.hqlist.find(item => item.code === sessionStorage.getItem('activeSpot')) : this.hqlist[0]
                this.activeContract = sessionStorage.getItem('activeSpot') ? sessionStorage.getItem('activeSpot') : this.hqlist[0].code
            }
            this.changeactiveContract(this.activeContract);
        } else {
            this.$root.$on('QuotesContractListSpot', (msg) => {
                let defaultContract = '';
                if (this.$store.state.hqlistSpot.length > 0)
                    defaultContract = this.$store.state.hqlistSpot[0].code;
                let Contract = sessionStorage.getItem('activeSpot') ? sessionStorage.getItem('activeSpot') : defaultContract;
                
                if (Contract !== undefined) {
                    this.activeContract = Contract;
                    this.activeObj = this.hqlist.find(item => item.code === Contract);
                    this.changeactiveContract(this.activeContract);
                }
            });
        }

        this.$root.$on('TS2COpenResSpot', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('spot')['jsMessageReskaicangchenggong'],
                    type: 'success'
                });
            } else {
                var resmessage = '';
                if (msg.Resultcode === 1)
                    resmessage = this.$t('trade')['jsMessageReskaicang1'];  //禁止开仓
                if (msg.Resultcode === 2)
                    resmessage = this.$t('trade')['jsMessageReskaicang2'];  //合约不存在
                if (msg.Resultcode === 3)
                    resmessage = this.$t('trade')['jsMessageReskaicang3'];  //开仓数量超过上限
                if (msg.Resultcode === 4)
                    resmessage = this.$t('trade')['jsMessageReskaicang4'];  //价格为0
                if (msg.Resultcode === 5)
                    resmessage = this.$t('trade')['jsMessageReskaicang5'];  //开仓保证金不足
                this.$message({
                    message: this.$t('spot')['jsMessageReskaicangshibai'] + resmessage,
                    type: 'success'
                });
            }
            this.orderNum = undefined;
            this.orderSummoney = undefined;
        });

        this.$root.$on('TS2CCloseResSpot', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('spot')['jsMessageRespingcangchenggong'],
                    type: 'success'
                });
            } else {
                var resmessage = '';
                if (msg.Resultcode === 1)
                    resmessage = this.$t('trade')['jsMessageRespingcang1'];  //合约不存在
                if (msg.Resultcode === 2)
                    resmessage = this.$t('trade')['jsMessageRespingcang2'];  //持仓单不存在
                if (msg.Resultcode === 3)
                    resmessage = this.$t('trade')['jsMessageRespingcang3'];  //可平量不足
                this.$message({
                    message: this.$t('spot')['jsMessageRespingcangshibai'] + resmessage,
                    type: 'success'
                });
            }

            if (this.dialogCloseOrder) {
                this.dialogCloseOrder = false;
            } else {
                this.orderNum = undefined;
                this.orderSummoney = undefined;
            }
                
            this.calccanCloseNum();
        });

        this.$root.$on('TS2CCancelResSpot', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('spot')['jsMessageResweituodanchedanchenggong'],
                    type: 'success'
                });
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageResweituodanchedanshibai'] ,
                    type: 'success'
                });
            }
        });

        this.$root.$on('TS2CTransferCapitalRes', (msg) => {
            if (this.dialogtransfer) {
                if (msg.Resultcode == 0) {
                    this.$message({
                        message: this.$t('spot')['jsMessageReszijinhuazhuanchenggong'],
                        type: 'success'
                    });
                    // this.getMyLegalCurrencyAccountInfo();
                    this.$store.dispatch('getMyLegalCurrencyAccountInfo');
                    this.transferNum = undefined;
                    this.dialogtransfer = false;
                } else {
                    this.$message({
                        message: this.$t('spot')['jsMessageReszijinhuazhuanshibai'] ,
                        type: 'success'
                    });
                }
            }  
        });
    },
    beforeDestroy() {
        
        this.$root.$off('QuotesContractListSpot');
        this.$root.$off('TS2COpenResSpot');
        this.$root.$off('TS2CCloseResSpot');
        this.$root.$off('TS2CCancelResSpot');
        this.$root.$off('TS2CTransferCapitalRes');
    },
    filters: {
        filter_newPriceFormat: function (price, n) {
            if(price && price!='--'){
                return newPriceFormat(price, n);
            }else{
                return '--'
            }
        },
        filter_classUpOrDown: function (nprice, openprice, useType) {
            return classUpOrDown(nprice, openprice, useType);
        },
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() + ' ';
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
	},
    methods: {
        dropdown_itemClass(item) {
            if (item.code === this.activeContract)
                return ['dropdownList', 'activeContract']
            else
                return 'dropdownList';
        },
        dropdown_priceClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return ['color_up','option-span-short']; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return [
                classUpOrDown(price, open, 'color'),
                'option-span-short'
            ];
        },
        dropdown_rateClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return ['color_up','option-span-long']; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return [
                classUpOrDown(price, open, 'color'),
                'option-span-long'
            ];
        },
        dropdown_priceClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return ['color_up','option-span-short']; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return [
                classUpOrDown(price, open, 'color'),
                'option-span-short'
            ];
        },
        dropdown_imgClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return 'color_non'; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return classUpOrDown(price, open, 'color');
        },
        nowPrice1_class(obj) {
            if (obj && obj.price && obj.open) {
                if (obj.price > obj.open) {
                    return ['actPrice', 'color_green'];
                } else if (obj.price < obj.open) {
                    return ['actPrice', 'color_red'];
                } else {
                    return 'actPrice';
                }
            } else {
                return 'actPrice';
            }
        },  
        nowPrice2_class(obj) {
            if (obj && obj.price && obj.open) {
                if (obj.price > obj.open) {
                    return ['nowprice', 'color_green'];
                } else if (obj.price < obj.open) {
                    return ['nowprice', 'color_red'];
                } else {
                    return 'nowprice';
                }
            } else {
                return 'nowprice';
            }
        },  
        nowRate2_class(obj) {
            if (obj && obj.price && obj.open) {
                if (obj.price > obj.open) {
                    return ['uplv', 'color_green'];
                } else if (obj.price < obj.open) {
                    return ['uplv', 'color_red'];
                } else {
                    return 'uplv';
                }
            } else {
                return 'uplv';
            }
        },
        changeactiveContract(Contractcode) {
            if (this.activeObj) {
                this.activestep = this.activeObj.wave;              //最小波动
                var len = this.activeObj.wave.toString().split('.').pop().length;
                this.activeprecision = len;
                if (this.activeObj.high > 0) {
                    this.activemin = this.activeObj.high * 0.8;    //品种最低价 80%
                } else {
                    this.activemin = 0;
                }
                
                if (this.activeObj.low > 0) {
                    this.activemax = this.activeObj.low * 1.2;     //品种最高价 120%
                } else {
                    this.activemax = 9999999;
                } 
            } else {
                this.activestep = 1;
                this.activeprecision = 0;
                this.activemin = 0;
                this.activemax = 9999999;
            }
            this.priceType = '1';
            this.orderPrice = undefined;
            this.orderNum = undefined;
            this.orderSummoney = undefined;
            
            //如果登录，计算可开数量
            // this.calccanOpenNum();
            // this.calcliquidationPrice();
        },
        eldropdownCommand(command) {
            //选中合约
            this.activeContract = command;
            this.activeObj = this.hqlist.find(item => item.code === command);
            sessionStorage.setItem('activeSpot', command);
            this.changeactiveContract(this.activeContract);
            this.selectHoldorder = undefined;
            this.CloseContract = undefined;
            this.canCloseNum = 0;
            this.selectHoldorderRow = undefined;
            //进入开仓界面
            this.OpenOrClose =  'Open';
        },
        orderTabClick() {
            this.selectHoldorder = undefined;
            this.CloseContract = undefined;
            this.canCloseNum = 0;
            this.selectHoldorderRow = undefined;
            //进入开仓界面
            this.OpenOrClose =  'Open';
        },
        HoldorderListStyle(obj) {
            if (this.selectHoldorder && this.selectHoldorder === obj.row.id) {
                return {
                    background:'#192126', 
                    'border-color':'#20282E'
                }
            } else {
                return {
                    background:'#161c20', 
                    'border-color':'#20282E'
                }
            }
        },
        HoldorderListClick(row) {
            this.selectHoldorder = row.id;
            this.CloseContract = row.Contractcode.toUpperCase().split("USDT")[0];
            this.canCloseNum = row.available;
            this.selectHoldorderRow = row;
            //点击持仓单切换合约，在此处理
            this.activeContract = row.Contractcode;
            this.activeObj = this.hqlist.find(item => item.code === row.Contractcode);
            sessionStorage.setItem('activeSpot', row.Contractcode);
            this.changeactiveContract(this.activeContract);
            //进入平仓界面
            this.OpenOrClose =  'Close';
        },
        holdclose(row) {
            //持仓单平仓
            if (this.$store.state.loginStatusSpot) {
                this.CloseOrderpriceType = '1';           //平仓对话框价格类型1市价0限价
                this.closeorderPrice = undefined;         //平仓对话框价格
                this.CloseOrderprecision = row.wave;      //平仓对话框价格小数位
                this.CloseOrderstep = row.pricetick;      //平仓对话框价格最小波动
                this.closeorderNum = row.available;          //平仓对话框平仓数量
                this.closeordercanCloseNum= row.available;   //平仓对话框可平仓数量
                this.selectHoldorderRow = row;

                this.dialogCloseOrder = true;
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        EntrustorderCancle(row) {
            //委托单撤单操作
            if (this.$store.state.loginStatusSpot) {
                this.$confirm(this.$t('spot')['jsconfirmquedingchedanma'], this.$t('spot')['jsconfirmtishi'], {
                    confirmButtonText: this.$t('spot')['jsconfirmqueding'],
                    cancelButtonText: this.$t('spot')['jsconfirmquxiao'],
                    type: 'warning'
                }).then(() => {
                    var idArray = [];
                    idArray[0] = row.id;
                    teadeSpotWSReq.C2TSCancelReq(idArray);
                }).catch(() => {
                    
                });
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }  
        },
        CloseOrderpriceTypeChange() {
            this.closeorderPrice = undefined;
        },
        pingcangbtnclick() {
            if (this.$store.state.loginStatusSpot) {
                if (this.selectHoldorder && this.selectHoldorderRow){
                    if (this.selectHoldorderRow.direction == 0) {
                        var dire = 1;
                    } else {
                        var dire = 0;
                    }

                    if (this.CloseOrderpriceType === '1') {  //市价下单
                        if (this.closeorderNum !== undefined && this.closeorderNum > 0) {
                            var code = this.selectHoldorderRow.Contractcode;
                            var strategyID = this.selectHoldorderRow.strategyID;
                            var traderUserID = this.selectHoldorderRow.traderUserID;   
                            teadeSpotWSReq.C2TSCloseReq(strategyID, code, this.closeorderNum, 0, dire, 1, traderUserID);
                        } else {
                            this.$message({
                                message: this.$t('spot')['jsMessageqingshuruzhengquedeshuliang'],
                                type: 'warning'
                            });
                        }
                    } else {
                        if (this.closeorderPrice !== undefined && this.closeorderPrice > 0) {
                            if (this.closeorderNum !== undefined && this.closeorderNum > 0) {
                                var code = this.selectHoldorderRow.Contractcode;
                                var strategyID = this.selectHoldorderRow.strategyID;
                                var traderUserID = this.selectHoldorderRow.traderUserID;   
                                teadeSpotWSReq.C2TSCloseReq(strategyID, code, this.closeorderNum, this.closeorderPrice, dire, 0, traderUserID);
                            } else {
                                this.$message({
                                    message: this.$t('spot')['jsMessageqingshuruzhengquedeshuliang'],
                                    type: 'warning'
                                });
                            }
                        } else {
                            this.$message({
                                message: this.$t('spot')['jsMessageqingshuruzhengquedejiage'],
                                type: 'warning'
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: this.$t('spot')['jsMessageqingxianxuanzechicang'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        OpenClosetabClick() {
            this.priceType = '1';
            this.orderPrice = undefined;
            this.orderNum = undefined;
            this.slidervalue = undefined;
            this.orderSummoney = undefined;

            if (this.OpenOrClose === 'Close') {
                this.CloseContract = this.activeContract.toUpperCase().split("USDT")[0];
                this.calccanCloseNum();
            }
        },
        priceTypeChange(value) {
            // 切换委托类型把价格输入框清空
            this.orderPrice = undefined;
        },
        openLong() {
            //开多
            if (this.$store.state.loginStatusSpot && this.activeContract && this.activeContract !== '') {
                //判断是否登录
                if (this.priceType === '1') {  //市价下单
                    if (this.orderNum !== undefined && this.orderNum > 0) {
                        var code = this.activeContract;
                        teadeSpotWSReq.C2TSOpenReq(code, this.orderNum, 0, 0, 1);
                    } else {
                        this.$message({
                            message: this.$t('spot')['jsMessageqingshuruzhengquedeshuliang'],
                            type: 'warning'
                        });
                    }
                } else {
                    if (this.orderPrice !== undefined && this.orderPrice > 0) {
                        if (this.orderNum !== undefined && this.orderNum > 0) {
                            var code = this.activeContract;
                            teadeSpotWSReq.C2TSOpenReq(code, this.orderNum, this.orderPrice, 0, 0);
                        } else {
                            this.$message({
                                message: this.$t('spot')['jsMessageqingshuruzhengquedeshuliang'],
                                type: 'warning'
                            });
                        }
                    } else {
                        this.$message({
                            message: this.$t('spot')['jsMessageqingshuruzhengquedejiage'],
                            type: 'warning'
                        });
                    }
                }
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            }
        },
        Closeposition() {
            //平仓
            if (this.$store.state.loginStatusSpot) {
                if (this.selectHoldorder && this.selectHoldorderRow){
                    if (this.selectHoldorderRow.direction == 0) {
                        var dire = 1;
                    } else {
                        var dire = 0;
                    }

                    if (this.priceType === '1') {  //市价下单
                        if (this.orderNum !== undefined && this.orderNum > 0) {
                            var code = this.selectHoldorderRow.Contractcode;
                            var strategyID = this.selectHoldorderRow.strategyID;
                            var traderUserID = this.selectHoldorderRow.traderUserID;   
                            teadeSpotWSReq.C2TSCloseReq(strategyID, code, this.orderNum, 0, dire, 1, traderUserID);
                        } else {
                            this.$message({
                                message: this.$t('spot')['jsMessageqingshuruzhengquedeshuliang'],
                                type: 'warning'
                            });
                        }
                    } else {
                        if (this.orderPrice !== undefined && this.orderPrice > 0) {
                            if (this.orderNum !== undefined && this.orderNum > 0) {
                                var code = this.selectHoldorderRow.Contractcode;
                                var strategyID = this.selectHoldorderRow.strategyID;
                                var traderUserID = this.selectHoldorderRow.traderUserID;   
                                teadeSpotWSReq.C2TSCloseReq(strategyID, code, this.orderNum, this.orderPrice, dire, 0, traderUserID);
                            } else {
                                this.$message({
                                    message: this.$t('spot')['jsMessageqingshuruzhengquedeshuliang'],
                                    type: 'warning'
                                });
                            }
                        } else {
                            this.$message({
                                message: this.$t('spot')['jsMessageqingshuruzhengquedejiage'],
                                type: 'warning'
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: this.$t('spot')['jsMessageqingxianxuanzechicang'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        calccanCloseNum() {
            if (this.activeContract && this.activeContract !== '') {
                var num = 0;
                this.holdOrderList.forEach(element => {
                    if (element.Contractcode === this.activeContract) {
                        num += element.available;
                    }
                });
                this.canCloseNum = num;
            }
        },
        orderNuminputNative(value) {
            const key = this.$refs.reforderNum.displayValue;   
            if (/^-?\d+(\.\d+)?$/.test(key)) {
                if (Number.isFinite(Number(key))) {
                    this.orderNum = Number(key);
                    this.calcorderSummoney(1);
                }
            } 
        },
        calcorderSummoney(clearsilder) {
            if (clearsilder === 1)
                this.slidervalue = 0;

            if (this.priceType === '1') {
                if (this.orderNum !== undefined && this.orderNum > 0 && this.activeObj && this.activeObj.price) {
                    this.orderSummoney = baseMethods.accMul(this.orderNum, this.activeObj.price);
                } else {
                    this.orderSummoney = undefined;
                }
            } else {
                if (this.orderPrice !== undefined && this.orderPrice > 0 && this.orderNum !== undefined && this.orderNum > 0) {
                    this.orderSummoney = baseMethods.accMul(this.orderNum, this.activeObj.orderPrice);
                } else {
                    this.orderSummoney = undefined;
                }
            }
        },
        sliderChange() {
            if (this.OpenOrClose === 'Open') {
                if (this.canOpenNum !== undefined && this.canOpenNum > 0) {
                    var number = baseMethods.accMul(this.canOpenNum, baseMethods.accDiv(this.slidervalue, 100));
                    this.orderNum = Math.floor(number * 100000000) / 100000000;
                    this.calcorderSummoney(0);
                } else {
                    this.orderNum = undefined;
                    this.orderSummoney = undefined;
                }
            } else {
                if (this.canCloseNum !== undefined && this.canCloseNum > 0) {
                    var number = baseMethods.accMul(this.canCloseNum, baseMethods.accDiv(this.slidervalue, 100));
                    this.orderNum = Math.floor(number * 100000000) / 100000000;
                    this.calcorderSummoney(0);
                } else {
                    this.orderNum = undefined;
                    this.orderSummoney = undefined;
                }
            }
            
        },
        transfericonClick() {
            var temp = this.transfertovalue;
            this.transfertovalue = this.transferfromvalue;
            this.transferfromvalue = temp;
            if (this.transdirection === 6) {
                this.transdirection = 7;
            } else {
                this.transdirection = 6;
            }
            this.transferNum = undefined;
        },
        transferAllclick() {
            if (this.transdirection === 6) {
                this.transferNum = this.userWallet.LegalCurrency;
            } else {
                this.transferNum = this.userWallet.marginAvailable;
            }
        },
        transferbtnclick() {
            if (this.$store.state.loginStatusSpot && this.$store.state.loginStatus) {
                if (this.transferNum && this.transferNum > 0) {
                    // 6法币转币币 7币币转法币
                    teadeWSReq.exchangeCurrency(this.transdirection, this.transferNum);
                } else {
                    this.$message({
                        message: this.$t('spot')['jsMessageqingshuruhuazhuanshuliang'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            }
            
        },
        transferDialogShow() {
            if (this.$store.state.loginStatusSpot) {
                this.dialogtransfer = true;
            } else {
                this.$message({
                    message: this.$t('spot')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            } 
        },
        rechargeClick() {
            this.$router.push("/rechargeLayout");
        },
        withdrawClick() {
            this.$router.push("/withdrawLayout");
        },
    },
}
</script>

<style>
/* 下拉框 */
.selectpopper_trade.el-select-dropdown{
    border: none !important;
    background-color: #202930 !important;
}

.select_trade.el-select .el-input__inner{
    border: none;
    color:#eee;
    border-color: #202930;
    background-color: #202930;
}

/* 聚焦时的样式 */
.select_trade.el-select .el-input.is-focus .el-input__inner{
    background-color: #202930;
    color:#00D3E9;
}
/* 下拉框选中 */
.selectpopper_trade .el-select-dropdown__item{
    font-size: 12px;
    color: #eee;
    background-color: #202930 !important;
}

.selectpopper_trade .el-select-dropdown__item.selected{
    background-color: #1e7ac5 !important;
}

.el-input-number .el-input__inner{
    border: none;
    color:#eee;
    background-color: #1C2328;
}

.el-dialog {
    background: #161C20 !important;
}

.el-dialog__title {
    color: rgb(255, 255, 255) !important;
    font-weight: 900;
}


/* 日历样式 */
.datepickerPopper.el-picker-panel {
    background: #1C2328 !important;
    color: #ffffff;
    border: 1px solid #293f6f !important;
}

.datepickerPopper .el-date-picker__time-header {
    border-bottom: 1px solid #293f6f !important;
}

.datepickerPopper .el-date-picker__header--bordered {
    border-bottom: 1px solid #293f6f !important;
}

.datepickerPopper .el-picker-panel__footer {
    border-top: 1px solid #293f6f !important;
}

.datepickerPopper.el-picker-panel .el-date-range-picker__time-header {
    border-bottom: 1px solid #93bee7 !important;
}

.datepickerPopper .el-picker-panel__icon-btn {
    width: 8px;
    height: 10px;
    color: #5a81fd !important;
}

.datepickerPopper.el-picker-panel .el-input__inner {
    background-color: #1C2328;
    border: 1px solid #293f6f;
    color: #e8fdff;
}

.datepickerPopper.el-picker-panel .el-input__inner:focus,
.datepickerPopper.el-picker-panel .el-input__inner:hover {
    border: 1px solid #293f6f;
}

.datepickerPopper.el-picker-panel .el-date-range-picker__content.is-left,
.datepickerPopper.el-picker-panel .el-date-range-picker__content.is-right {
    border-right: 1px solid #93bee7 !important;
    background-size: 100% 100%;
}

.datepickerPopper.el-picker-panel .el-date-range-picker__header {
    color: #75ebf2;
}

.datepickerPopper.el-picker-panel .el-date-table th {
    color: #c3e3ec;
}

.datepickerPopper.el-picker-panel .el-date-table td.available {
    width: 14px;
    height: 14px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #6ce6cb;
}

.datepickerPopper.el-picker-panel .el-date-table td.available:hover {
    color: #ffffff;
}

.datepickerPopper .el-date-table td.in-range div,
.datepickerPopper .el-date-table td.in-range div:hover,
.datepickerPopper .el-date-table.is-week-mode .el-date-table__row.current div,
.datepickerPopper .el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #1C2328 !important;
}

.datepickerPopper .el-time-panel {
    background-color: #1b3e6f !important;
    border: 1px solid #93bee7 !important;
}

.datepickerPopper .el-time-spinner__item {
    color: #c3e3ec !important;
}

.datepickerPopper .el-time-spinner__item:hover:not(.disabled):not(.active) {
    background: #4c77b3 !important;
}

.datepickerPopper .el-time-panel__btn {
    color: #adcfff !important;
}

.datepickerPopper .el-picker-panel__footer {
    background-color: #1C2328 !important;
}

.datepickerPopper.el-picker-panel .el-button {
    width: 36px;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #eee;
    background: #0059FF !important;
}

.datepickerPopper.el-picker-panel .el-button:hover {
    color: #eee;
}

.datepickerPopper .el-icon-d-arrow-left {
    color: #1475e3 !important;
}

.datepickerPopper .el-button.is-disabled,
.datepickerPopper .el-button.is-disabled:focus,
.datepickerPopper .el-button.is-disabled:hover {
    color: #1475e3 !important;
    margin-left: -20px !important;
}

.datepickerPopper .el-button + .el-button {
    width: 92px;
    height: 40px;
    background-size: 115% 130% !important;
    border: 1px solid #1475e3 !important;
    border-radius: 4px !important;
}

.datepickerPopper .el-button {
    width: 92px;
    height: 40px;
    border: 1px solid #1475e3;
    border-radius: 4px;
}

.datepickerPopper .el-button--text {
    display: none;
    width: 92px;
    height: 40px;
    border: 1px solid #1475e3;
    border-radius: 4px;
}

.datepickerPopper .el-button.is-disabled.is-plain,
.datepickerPopper .el-button.is-disabled.is-plain:focus,
.datepickerPopper .el-button.is-disabled.is-plain:hover {
    width: 92px;
    height: 40px;
    /* background: url(./images/圆角矩形.png) center center !important; */
    background-size: 115% 130% !important;
    border: 1px solid #1475e3 !important;
    border-radius: 4px !important;
}

.datepickerPopper .el-button--text,
.datepickerPopper .el-button--text.is-disabled,
.datepickerPopper .el-button--text.is-disabled:focus,
.datepickerPopper .el-button--text.is-disabled:hover,
.datepickerPopper .el-button--text:active {
    display: none !important;
}

.datepickerPopper .el-date-table th {
    border-bottom: 1px solid #293f6f !important;
}
/* 时间筛选 */

.datepickerPopper .el-time-panel__content::after,
.datepickerPopper .el-time-panel__content::before {
    border-bottom: 1px solid #293f6f !important;
    border-top: 1px solid #293f6f !important;
}

.datepickerPopper .el-time-panel {
    background-color: #1C2328 !important;
    border: 1px solid #293f6f !important;
}

.datepickerPopper .el-time-panel__btn.confirm {
    background-color: #0059FF !important;
    color: #eee !important;
    border-radius: 4px;
}

.datepickerPopper .el-time-panel__btn.cancel {
    background-color: #0059FF !important;
    color: #eee !important;
    border-radius: 4px;
}

.datepickerPopper .el-time-panel__footer {
    border-top: none !important;
}

.datepickerPopper .el-time-panel {
    border: none;
    border-top: 2px solid #293f6f !important;
}

.datepickerSelect .el-input__inner {
    background: #1C2328 !important;
    border: none;
    color: #eee;
}

.datepickerPopper .el-date-picker__header-label,
.datepickerPopper .el-picker-panel__icon-btn,
.datepickerPopper .el-date-table th {
    color: #eee !important;
}


</style>

<style scoped>
@import "../css/SpotTradeLayout.css";

.spot-trade-layout {
    min-width: 1200px;
    background: #101417;
    color: #ffffff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropdownList {
    background-color: #161C20;
    color: white;
}

.dropdownList:hover {
    background-color: #111111 !important;
}

.activeContract {
    background-color: #111111;
}

.option-span {
    display: inline-block;
    padding-right: 10px;
    width: 40%;
}

.option-span-short {
    display: inline-block;
    padding-right: 10px;
    width: 30%;
}

.option-span-long {
    display: inline-block;
}

.bg_up{
    background: #088F23;
}

.bg_down{
    background: #1763B6;
}

.color_up{
    color: #088F23;
}

.color_down{
    color: #1763B6;
}

.color_border_up{
    color: #088F23;
    border-color: #088F23;
}

.color_border_down{
    color: #A21616;
    border-color: #A21616;
}

::v-deep .el-tabs__content {
    overflow: visible;
}  

::v-deep .el-tabs__item {
    color: white;
}

::v-deep .el-tabs__item.is-active {
    color: #15cbf3;
}

::v-deep .el-icon-arrow-left {
    color: white;
}

::v-deep .el-icon-arrow-right {
    color: white;
}

::v-deep .el-tabs__nav-wrap::after {
    height: 0;
}

::v-deep .el-tabs__active-bar {
    background-color: #15cbf3;
}

.dataViewTable >>> .el-table__row>td{ 
    /* border: none;  */
    border-color: #20282E;
}

/* 去掉最下面的那一条线 */
.dataViewTable >>> .el-table::before {
    /* height: 0px; */
    background-color: #20282E;
}

.dataViewTable >>> .el-table tbody tr:hover>td {
    background-color: transparent !important;
}

.el-input-number.is-disabled /deep/ .el-input__inner{
    border: none;
    color:#eee;
    background-color: #1C2328;
}

::v-deep .el-input-number__decrease{
    border: none;
    color: #eee;
    background-color: #202930;
}

::v-deep .el-input-number__increase{
    border: none;
    color: #eee;
    background-color: #202930;
}

::v-deep .el-input-number__decrease.is-disabled{
    color: #4b4a4a;
}

::v-deep .el-input-number__increase.is-disabled{
    color: #4b4a4a;
}

::v-deep .el-input-number.is-disabled .el-input-number__decrease{
    color: #4b4a4a;
}

::v-deep .el-input-number.is-disabled .el-input-number__increase{
    color: #4b4a4a;
}

/* 滑块样式 */
::v-deep .el-slider__button {
    width: 8px;
    height: 8px;
}
::v-deep .el-slider__marks-text {
    font-size: 10px;
}
::v-deep .el-slider__runway {
    background-color: #444;
}

</style>