import { teadeSpotWSparse } from '@/utils/parseTradeSpot'
import { teadeSpotWSReq } from '@/utils/packedTradeSpot'
import { DecodePack } from '@/utils/ByteArray.js'
import store from '@/store'

const tradeSpotWSModule = {
    namespaced: true,
    state: {
        ws: null,
        wsHart: 0,
    },
    mutations: {
        SET_WS(state, ws) {
            state.ws = ws;
        },
        CLEAR_WS(state) {
            state.ws = null;
        },
        INC_HARTCOUNT(state) {
            state.wsHart++;
        },
        ZERO_HARTCOUNT(state) {
            state.wsHart = 0;
        },
    },
    actions: {
        startWebSocket({commit, state}, connData) {
            if (state.ws) state.ws.close();
            const ws = new WebSocket(connData.url);
            ws.onopen = function () {
                console.log('tradeSpot WebSocket connected');

                commit('SET_WS', ws);
        
                teadeSpotWSReq.login(connData.user, connData.userpass);
            };
            ws.onmessage = function (event) {
                // 处理接收到的消息
                var recvData = event.data;
                // 将 Blob 转换为 ArrayBuffer
                if (recvData instanceof Blob) {
                    var reader = new FileReader();
                    reader.onload = function() {
                        var  arrayBuffer = this.result;
                        var bbt = DecodePack(arrayBuffer);
                        teadeSpotWSparse.parseData(bbt);
                    };
                    reader.readAsArrayBuffer(recvData);
                } else if (recvData instanceof ArrayBuffer) {
                    var bbt = DecodePack(recvData);
                    teadeSpotWSparse.parseData(bbt);
                } else {
                    console.error("Unsupported data type in event.data");
                }
            };
            ws.onerror = function (error) {
                console.error('tradeSpot WebSocket error:', error);
            };
            ws.onclose = function () {
                console.log('tradeSpot WebSocket closed');

                // 可能需要重连逻辑
                store.state.holdOrderListSpot = [];         //交易页面使用的持仓单
                store.state.EntrustorderListSpot = [];      //交易页面使用的委托单
                store.state.DealOrderListSpot = [];         //交易页面使用的成交单
                store.state.ConditionOrderListSpot = [];    //交易页面使用的条件单
                store.state.userWalletSpot.balance = 0;                 //余额
                store.state.userWalletSpot.marginBalance = 0;           //账户权益
                store.state.userWalletSpot.marginPosition = 0;          //持仓保证金
                store.state.userWalletSpot.marginFrozen = 0;            //冻结保证金
                store.state.userWalletSpot.marginAvailable = 0;         //可用保证金
                store.state.userWalletSpot.profitReal = 0;              //已实现盈亏
                store.state.userWalletSpot.profitUnreal = 0;            //未实现盈亏
                store.state.userWalletSpot.riskRate = 0;                //保证金率
                store.state.userWalletSpot.followBalance = 0;           //跟单账户余额
                store.state.userWalletSpot.liquidationPrice = 0;        //预估强平价
                store.state.userWalletSpot.calcprofit = 0;              //本地计算浮动盈亏
                store.state.userWalletSpot.LegalCurrency = 0;           //法币账户余额通过API获取
                store.state.loginStatusSpot = false;
            };
        },
        sendMessage({state}, message) {
            if (state.ws && state.ws.readyState === WebSocket.OPEN) {
                state.ws.send(message);
            } else {
                console.warn('WebSocket not connected, unable to send message');
            }
        },
        close({state}) {
            if (state.ws) state.ws.close();
        },
    }
}

export default tradeSpotWSModule


