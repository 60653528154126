import ctsTrade from '@/utils/cts_msg_pb.js';
import { baseMethods } from '@/utils/methods.js';
import { hex_md5 } from '@/utils/md5.js';
import { EncodePack } from '@/utils/ByteArray.js';
import store from '@/store'

const teadeWSReq = {
    login (account, pwd) {
        var message = new proto.cts.C2TSLoginReq();
        message.setUsername(account);
        message.setPassword(hex_md5(pwd));
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes)
        var note = EncodePack(bytes_encry,'10001');
        store.dispatch('tradeWS/sendMessage', note);
    },
    getHoldOrder(){		//持仓
		var message = new proto.cts.C2TSHoldOrderListReq();
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes)
		var note = EncodePack(bytes_encry,'10007');
        store.dispatch('tradeWS/sendMessage', note);
	},
    getLever(){
        var message = new proto.cts.C2TSLeverRateListReq();
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10006');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 修改杠杆
    setLever(code, num){
        var message = new proto.cts.C2TSModifyLeverRateReq();
        var message1 = new proto.cts.LeverRate(); 
        message1.setContractcode(code); 
        message1.setLeverrate(num);
        message.setLeverrate(message1)
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10053');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 交易开仓
    trade_methods(id, num, price, type, pricetype){
        var message = new proto.cts.C2TSOpenReq(); 
        message.setContractcode(id); 
        message.setPrice(price); 
        message.setVolume(num); 
        message.setDirection(type); 
        message.setOrderpricetype(pricetype);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10050');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 交易平仓(合约列表)
    trade_pc_methods_list(holdtype, id, num, price, type, pricetype, followId){
        var message1 = new proto.cts.C2TSCloseReq();
        message1.setStrategyid(holdtype);  
        message1.setTraderuserid(followId);  
        message1.setContractcode(id);  
        message1.setVolume(num);  
        message1.setPrice(price);  
        message1.setDirection(type);  
        message1.setOrderpricetype(pricetype);
        var bytes = message1.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10051');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 交易撤单
    trade_back_methods(idList){
        var message = new proto.cts.C2TSCancelReq();  
        message.setOrderidList(idList); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10052');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //市价反手
    backHand(id){
        var message = new proto.cts.C2TSBackHandReq(); 
        message.setId(id);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10055');
        store.dispatch('tradeWS/sendMessage', note);
    },
     // 获取委托单
     getEntrust(){
        var message = new proto.cts.C2TSEntrustOrderListReq();
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10008');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 获取成功单
    getOrderList(){
        var message = new proto.cts.C2TSDealOrderListReq();
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10009');
        store.dispatch('tradeWS/sendMessage', note);
    },
     //获取条件单列表
     getConditionList(){
        var message = new proto.cts.C2TSConditionOrderListReq();
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10080');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 增加条件单
    addCondition(symbol, contractCode, type, priceType, compare, price, time, openClose, buySell, num){
        var message = new proto.cts.ConditionOrderInfo(); 
        message.setSymbol(symbol); 
        message.setContractcode(contractCode); 
        message.setVolume(num); 
        message.setDirection(buySell); 
        message.setOffset(openClose); 
        message.setType(type); 
        message.setPricetype(priceType); 
        message.setCompare(compare); 
        message.setEndprice(price); 
        message.setEndtime(time); 
        var message1 = new proto.cts.C2TSAddConditionOrderReq(); 
        message1.setInfo(message);
        var bytes = message1.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10081');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //修改条件单
    changeCondition(id, symbol, contractCode, type, priceType, compare, price, time, openClose, buySell, num){
        var message = new proto.cts.ConditionOrderInfo(); 
        message.setId(id * 1); 
        message.setSymbol(symbol); 
        message.setContractcode(contractCode); 
        message.setVolume(num); 
        message.setDirection(buySell); 
        message.setOffset(openClose); 
        message.setType(type); 
        message.setPricetype(priceType); 
        message.setCompare(compare); 
        message.setEndprice(price); 
        message.setEndtime(time); 
        var message1 = new proto.cts.C2TSModityConditionOrderReq(); 
        message1.setInfo(message);
        var bytes = message1.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10082');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //删除条件单(指定id,全部) id=0 删除全部
    delCondition(id){
        var message = new proto.cts.C2TSDelConditionOrderReq(); 
        message.setId(id); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10083');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //启动条件单(指定id,全部)
    starCondition(id){
        var message = new proto.cts.C2TSRunConditionOrderReq(); 
        message.setId(id); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10084');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //暂停条件单(指定id,全部)
    stopCondition(id){
        var message = new proto.cts.C2TSPauseConditionOrderReq(); 
        message.setId(id); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10085');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //立即下单
    orderNow(id){
        var message = new proto.cts.C2TSImmediatelyConditionOrderReq(); 
        message.setId(id); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10088');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 持仓单止盈止损
    holdOrderSetproless(id, pf, pl){
        var message = new proto.cts.C2TSSetProfitLossReq();  
        message.setId(id);  
        message.setProfit(pf); 
        message.setLoss(pl); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes)
        var note = EncodePack(bytes_encry,'10054');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 资金划转
    exchangeCurrency(type,num){
        var message = new proto.cts.C2TSTransferCapitalReq(); 
        message.setDirection(type); 
        message.setVolume(num * 1);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10012');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 获取交易员信息
    shoperMess(){
        var message = new proto.cts.C2TSSelfTraderReq(); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10120');
       store.dispatch('tradeWS/sendMessage', note);
    },
    // 交易员修改添加
    addOrEditShopper(type, imgpath, noteMess, num, profit, maxnum){
        if(type == 0){      //添加
            var message = new proto.cts.C2TSApplyTraderReq();
            message.setFace(imgpath);
            message.setSharerate(profit*1);
            message.setOnevolume(num*1);
            message.setDayvolume(maxnum);
            message.setIntroduction(noteMess);
            var bytes = message.serializeBinary();
            var bytes_encry = baseMethods.encrypt(bytes);
            var note = EncodePack(bytes_encry,'10121');
            store.dispatch('tradeWS/sendMessage', note);
        }else{  //修改
            var message = new proto.cts.C2TSModifyTraderReq();
            message.setFace(imgpath);
            message.setSharerate(profit);
            message.setOnevolume(num);
            message.setDayvolume(maxnum);
            message.setIntroduction(noteMess);
            var bytes = message.serializeBinary();
            var bytes_encry = baseMethods.encrypt(bytes);
            var note = EncodePack(bytes_encry,'10122');
            store.dispatch('tradeWS/sendMessage', note);
        }
    },
    //交易员列表
    getFollowList(){
        var message = new proto.cts.C2TSTraderListReq();
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10123');
        store.dispatch('tradeWS/sendMessage', note);  
    },
    //我的跟单列表
    getMyFollowAttr(){
        var message = new proto.cts.C2TSFollowListReq();
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10124');
        store.dispatch('tradeWS/sendMessage', note); 
    },
    //添加跟单
    addFollow(id,num,state){
        var message = new proto.cts.FollowInfo();
        message.setUserid(id);
        message.setMultiple(num);
        message.setStatus(state);
        var message1 = new proto.cts.TS2CFollowListRes();
        message1 .addInfo(message);
        var bytes = message1.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10125');
        store.dispatch('tradeWS/sendMessage', note); 
    },
    //修改
    editFollow(id, num, state){
        var message = new proto.cts.FollowInfo();
        message.setUserid(id);
        message.setMultiple(num);
        message.setStatus(state);
        var message1 = new proto.cts.C2TSModifyFollowReq();
        message1.setInfo(message);
        var bytes = message1.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10126');
        store.dispatch('tradeWS/sendMessage', note); 
    },
    //跟随者
    getFollowPeople(id){
        var message = new proto.cts.C2TSTraderFollowerListReq();
        message.setUserid(id);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10127');
        store.dispatch('tradeWS/sendMessage', note); 
    },
    // 对冲列表
    getDcList(type, num){
        var message = new proto.cts.C2TSApiListReq(); 
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10090');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 对冲添加
    addDc(id, name, ak, sk, pp){
        var message1 = new proto.cts.UserApiInfo();
        message1.setApitype(id);
        message1.setStatus(0);
        message1.setName(name);
        message1.setAccesskey(ak);
        message1.setSecretkey(sk);
        message1.setPassphrase(pp);
        var message = new proto.cts.C2TSAddApiReq(); 
        message.setInfo(message1);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10091');
        store.dispatch('tradeWS/sendMessage', note);
    },

    openDc(id){
        var message = new proto.cts.C2TSOpenApiReq(); 
        message.setId(id);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10094');
        store.dispatch('tradeWS/sendMessage', note);
    },

    closeDc(id){
        var message = new proto.cts.C2TSCloseApiReq(); 
        message.setId(id);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10095');
        store.dispatch('tradeWS/sendMessage', note);
    },

    delDc(id){
        var message = new proto.cts.C2TSDelApiReq(); 
        message.setId(id);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10093');
        store.dispatch('tradeWS/sendMessage', note);
    },

    editDc(orderid,name,ak,sk,pp){
        var message1 = new proto.cts.UserApiInfo();
        message1.setId(orderid);
        message1.setName(name);
        message1.setAccesskey(ak);
        message1.setSecretkey(sk);
        message1.setPassphrase(pp);
        var message = new proto.cts.C2TSModityApiReq(); 
        message.setInfo(message1);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10092');
        store.dispatch('tradeWS/sendMessage', note);
    },
    // 修改密码
    changepwd(p1, p2){
        var message = new proto.cts.C2TSModifyPwdReq();  
        message.setOldpassword(hex_md5(p1));  
        message.setNewpassword(hex_md5(p2));
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes)
        var note = EncodePack(bytes_encry,'10002');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //获取量化列表
	getLhList(){
		var message = new proto.cts.C2TSStrategyListReq();
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes);
		var note = EncodePack(bytes_encry,'10110');
		store.dispatch('tradeWS/sendMessage', note);
	},
    //用户策略列表请求
	getUserClList(){
		var message = new proto.cts.C2TSUserStrategyListReq();
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes);
		var note = EncodePack(bytes_encry,'10111');
		store.dispatch('tradeWS/sendMessage', note);
	},
    //新用户启动策略请求
	openStrategy(cid, hyid, count, state){
		var message = new proto.cts.C2TSAddUserStrategyReq();
		var message1 = new proto.cts.UserStrategyInfo();
		message1.setStrategyid(cid);
		message1.setContractidList(hyid);
		message1.setRate(count);
		message1.setUserapiid(0);
		message1.setStatus(state);
		var message = new proto.cts.C2TSAddUserStrategyReq(); 
		message.setInfo(message1);
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes);
		var note = EncodePack(bytes_encry,'10112');
		store.dispatch('tradeWS/sendMessage', note);
	},
    //修改用户策略请求
	editStrategy(cid, hyid, count, state){
		var message = new proto.cts.C2TSModifyUserStrategyReq();
		var message1 = new proto.cts.UserStrategyInfo();
		message1.setStrategyid(cid);
		message1.setContractidList(hyid);
		message1.setRate(count);
		message1.setUserapiid(0);
		message1.setStatus(state);
		var message = new proto.cts.C2TSModifyUserStrategyReq(); 
		message.setInfo(message1);
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes);
		var note = EncodePack(bytes_encry,'10113');
		store.dispatch('tradeWS/sendMessage', note);
	},
    //跟随者
    getFollowPeople(id){
        var message = new proto.cts.C2TSTraderFollowerListReq();
        message.setUserid(id);
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes);
        var note = EncodePack(bytes_encry,'10127');
        store.dispatch('tradeWS/sendMessage', note);
    },
    //获取止盈止损列表
	gethypzList(){
		var message = new proto.cts.C2TSAutoProfitLossListReq();
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes);
		var note = EncodePack(bytes_encry,'10010');
		store.dispatch('tradeWS/sendMessage', note);
	},
    //提交设置止盈止损
	editHypzData(arr){
		var message1 = new proto.cts.C2TSSetAutoProfitLossListReq();
		for(let i = 0; i < arr.length; i++){
			var message = new proto.cts.AutoProfitLossInfo();
			message.setContractcode(arr[i].contractCode)
			message.setProfit(arr[i].Profit)
			message.setLoss(arr[i].Loss)
			message1.addInfo(message)
		}
		var bytes = message1.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes);
		var note = EncodePack(bytes_encry,'10011');
		store.dispatch('tradeWS/sendMessage', note);
	},
    //退出登录
	exit(){
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('pwd');
        store.state.loginStatus = false;
        //关闭套接字
        store.dispatch('tradeWS/close');

        // getApp().globalData.loginStatusQh =false;
		// app.trade_ws.socketTask1.close();
        
		// uni.showLoading({
		// 	mask:true
		// });
		// var timer = setTimeout(function(){
		// 	uni.reLaunch({
		// 		url:'/pages/index/index'
		// 	})
		// 	clearTimeout(timer);
		// 	uni.hideLoading();
		// }, 500);
	}
}

export { 
    teadeWSReq 
};