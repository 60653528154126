import Vue from "vue";
import Vuex from "vuex";
import { writeNewStyle } from "@/tools/theme/index";
import variables from "@/assets/scss/var.scss";
import tradeWSModule from "@/store/trade-ws";
import quotesWSModule from "@/store/quotes-ws";
import api from '@/utils/api'
import quotesSpotWSModule from "@/store/quotes-spot-ws";
import tradeSpotWSModule from "@/store/trade-spot-ws";
import optionsWSModule from "@/store/trade-options";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        themeColor: sessionStorage.getItem("themeColor") || variables.themeColor,
        userWallet: {
            balance: 0, //余额
            marginBalance: 0, //账户权益
            marginPosition: 0, //持仓保证金
            marginFrozen: 0, //冻结保证金
            marginAvailable: 0, //可用保证金
            profitReal: 0, //已实现盈亏
            profitUnreal: 0, //未实现盈亏
            riskRate: 0, //保证金率
            followBalance: 0, //跟单账户余额
            liquidationPrice: 0, //预估强平价
            canuseMoney: 0, //可用余额
            userType: 0,
            LegalCurrency: 0, //法币账户余额通过API获取
            calcprofit: 0, //本地计算浮动盈亏
            calcprofitWh: 0, //本地计算浮动盈亏
            calcprofitMg: 0, //本地计算浮动盈亏
        },
        hqlist: [], //行情列表
        hqlistWh: [], //行情列表
        hqlistMg: [], //行情列表
        contractList: [], //合约列表
        contractListMg: [], //美股合约列表
        contractListWh: [], //外汇合约列表
        leverList: [], //杠杆
        holdList: [], //持仓单
        holdOrderList: [], //交易页面使用的持仓单
        holdOrderListWh: [], //交易页面使用的持仓单
        holdOrderListMg: [], //交易页面使用的持仓单
        EntrustList: [], //委托单
        EntrustorderList: [], //交易页面使用的委托单
        EntrustorderListWh: [], //交易页面使用的委托单
        EntrustorderListMg: [], //交易页面使用的委托单
        DealList: [], //成交单
        DealOrderList: [], //交易页面使用的成交单
        DealOrderListWh: [], //交易页面使用的成交单
        DealOrderListMg: [], //交易页面使用的成交单
        ConditionList: [], //条件单
        ConditionOrderList: [], //交易页面使用的条件单
        ConditionOrderListWh: [], //交易页面使用的条件单
        ConditionOrderListMg: [], //交易页面使用的条件单
        AutoProfitLossList: [], //自动止盈止损列表

        // fiveHqList: [],	            //五档行情

        tradeFee: {
            //交易手续费
            open: 0,
            close: 0,
        },
        exchangeRate: 6.5, //汇率
        loginStatus: false,
        username: "",
        userpass: "",
        loginStatusOptions:false,
        loginStatusSpot: false,
        userWalletSpot: {
            balance: 0, //余额
            marginBalance: 0, //账户权益
            marginPosition: 0, //持仓保证金
            marginFrozen: 0, //冻结保证金
            marginAvailable: 0, //可用保证金
            profitReal: 0, //已实现盈亏
            profitUnreal: 0, //未实现盈亏
            riskRate: 0, //保证金率
            followBalance: 0, //跟单账户余额
            liquidationPrice: 0, //预估强平价
            calcprofit: 0, //本地计算浮动盈亏
            LegalCurrency: 0, //法币账户余额通过API获取
        },
        userWalletOptions:{
            balance:0
        },
        hqlistSpot: [], //行情列表
        contractListSpot: [], //合约列表
        conditionListSpot: [], //条件单
        holdListSpot: [], //持仓单
        EntrustListSpot: [], //委托单
        DealListSpot: [], //成交单
        holdOrderListSpot: [], //交易页面使用的持仓单
        EntrustorderListSpot: [], //交易页面使用的委托单
        DealOrderListSpot: [], //交易页面使用的成交单
        ConditionOrderListSpot: [], //交易页面使用的条件单
        periodList:[],
        amountList:[],
        entrustOptionsList:[]
    },
    mutations: {
        setTheme(state, data) {
            state.themeColor = data || variables.themeColor;
            sessionStorage.setItem("themeColor", state.themeColor);
        },
    },
    actions: {
        changeThemeStyle({ state }) {
            writeNewStyle(state.themeColor);
        },
        logout({ state }) {
            state.loginStatus = false;
            state.loginStatusSpot = false;
        },
        async getMyLegalCurrencyAccountInfo({ state }) {
            let data = {
                token: sessionStorage.getItem("tk"),
            }
            api.getMyLegalCurrencyAccountInfo(data).then((res)=>{
                if(res.data.code == 200){
                    // console.log(res.data.data);
                    state.userWallet.LegalCurrency = Number(res.data.data.legalBalance);
                    state.userWalletSpot.LegalCurrency = Number(res.data.data.legalBalance);
                }
            })
        },
    },
    modules: {
        tradeWS: tradeWSModule,
        quotesWS: quotesWSModule,
        quotesSpotWS: quotesSpotWSModule,
        tradeSpotWS: tradeSpotWSModule,
        tradeOptionsWS:optionsWSModule
    },
});
