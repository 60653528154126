import spot_pkg from '@/utils/spot_msg_pb.js'
import { baseMethods } from '@/utils/methods.js'
import store from '@/store'
import { teadeSpotWSReq } from '@/utils/packedTradeSpot'
import { EncodePack } from '@/utils/ByteArray.js';
import context from '../main'
import i18n from '@/i18n' 

function getContractinfo(Contractcode) {
    var obj = store.state.contractListSpot.find(item => item.getContractcode() === Contractcode) ;
    return obj;
};
function flashHoldOrderListSpot() {
    store.state.holdOrderListSpot = [];
    store.state.holdListSpot.forEach((item) => {
        var obj= {};
        obj.id = item.getId();
        obj.strategyID = item.getStrategyid();
        obj.traderUserID = item.getTraderuserid();
        obj.Contractcode = item.getContractcode();
        obj.Costopen = item.getCostopen();
        obj.Profit = item.getProfit();
        obj.volume = item.getVolume();
        obj.available = item.getAvailable();
        obj.direction = item.getDirection();
        var Contractinfo = getContractinfo(item.getContractcode());
        if (Contractinfo) {
            obj.wave = Contractinfo.getPricetick().toString().split('.')[1].length;
            obj.hysize = Contractinfo.getContractsize();
            obj.pricetick = Contractinfo.getPricetick();
            // obj.fee = baseMethods.accMul(baseMethods.accMul(baseMethods.accMul(obj.Costopen, obj.volume), obj.hysize), store.state.tradeFee.open);
        } else {
            obj.wave = 0;
            obj.hysize = 0;
            obj.pricetick = 0;
            // obj.fee = 0;
        }

        store.state.holdOrderListSpot.push(obj);
    });
};

function pushEntrustOrderListSpot(item) {
    var obj= {};
    obj.id = item.getId();
    obj.symbol = item.getSymbol();
    obj.Contractcode = item.getContractcode();
    obj.volume = item.getVolume();
    obj.Tradevolume = item.getTradevolume();
    obj.Price = item.getPrice();
    obj.direction = item.getDirection();
    obj.offset = item.getOffset();
    obj.Status = item.getStatus();
    obj.createDate = item.getCreatedate();

    var status = '';
    if (item.getStatus() == 0) {
        status = i18n.t("spot")['weituotijiaoshibai'];
    } else if (item.getStatus() == 1) {
        status = i18n.t("spot")['weituozhilingtijiao'];
    } else if (item.getStatus() == 2) {
        status = i18n.t("spot")['weituoyitijiao']; //可撤单操作
    } else if (item.getStatus() == 3) {
        status = i18n.t("spot")['weituobufenchengjiao'];
    } else if (item.getStatus() == 4) {
        status = i18n.t("spot")['weituobufenchengjiaoyichedan'];
    } else if (item.getStatus() == 5) {
        status = i18n.t("spot")['weituowanquanchengjiao'];
    } else if (item.getStatus() == 6) {
        status = i18n.t("spot")['weituowanquanchedan'];
    } else if (item.getStatus() == 11) {
        status = i18n.t("spot")['weituochedanzhong'];
    }
    obj.StatusText = status; 
    var DirectionOffset = '';
    if (item.getDirection() == 0) {
        if (item.getOffset() == 0) {
            DirectionOffset = i18n.t("spot")['weituokaiduo'];
        } else if (item.getOffset() == 1) {
            DirectionOffset = i18n.t("spot")['weituopingkong'];
        }
    } else if (item.getDirection() == 1) {
        if (item.getOffset() == 0) {
            DirectionOffset = i18n.t("spot")['weituokaikong'];
        } else if (item.getOffset() == 1) {
            DirectionOffset = i18n.t("spot")['weituopingduo'];
        }
    }
    obj.DirectionText = DirectionOffset;
    var Contractinfo = getContractinfo(item.getContractcode());
    if (Contractinfo) {
        obj.wave = Contractinfo.getPricetick().toString().split('.')[1].length;
    } else {
        obj.wave = 0;
    }

    store.state.EntrustorderListSpot.push(obj); 
};

function flashEntrustOrderListSpot() {
    store.state.EntrustorderListSpot = [];
    store.state.EntrustListSpot.forEach((item) => {
        //先插入Status == 2的元素
        //再插入Status != 2的元素
        if (item.getStatus() === 2) {
            pushEntrustOrderListSpot(item);
        }
    });

    store.state.EntrustListSpot.forEach((item) => {
        //先插入Status == 2的元素
        //再插入Status != 2的元素
        if (item.getStatus() !== 2) {
            pushEntrustOrderListSpot(item);
        }
    });
};

function flashDealOrderListSpot() {
    store.state.DealOrderListSpot = [];
    store.state.DealListSpot.forEach((item) => {
        var obj= {};
        obj.id = item.getId();
        obj.symbol = item.getSymbol();
        obj.Contractcode = item.getContractcode();
        obj.Tradevolume = item.getTradevolume();
        obj.Tradeprice = item.getTradeprice();
        obj.Createdate = item.getCreatedate();
        obj.direction = item.getDirection();
        obj.offset = item.getOffset();
        obj.Tradefee = item.getTradefee();
        // obj.Offsetprofitloss = item.getOffsetprofitloss();
        var DirectionOffset = '';   
        if (item.getDirection() == 0) {
            if (item.getOffset() == 0) {
                DirectionOffset = i18n.t("spot")['chengjiaokaiduo'];
            } else if (item.getOffset() == 1) {
                DirectionOffset = i18n.t("spot")['chengjiaopingkong'];
            }
        } else if (item.getDirection() == 1) {
            if (item.getOffset() == 0) {
                DirectionOffset = i18n.t("spot")['chengjiaokaikong'];
            } else if (item.getOffset() == 1) {
                DirectionOffset = i18n.t("spot")['chengjiaopingduo'];
            }
        }
        obj.DirectionText = DirectionOffset;
        var Contractinfo = getContractinfo(item.getContractcode());
        if (Contractinfo) {
            obj.wave = Contractinfo.getPricetick().toString().split('.')[1].length;
        } else {
            obj.wave = 0;
        }

        store.state.DealOrderListSpot.push(obj);
    });
};


const teadeSpotWSparse = { 
    parseData(decPack) {
        switch (decPack.msgType) {
            case 11001:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CLoginRes.deserializeBinary(data_msg);
                if (res.getResultcode() == 0) {
                    var loginBackRes = res.getUser();
                    
                    store.state.userWalletSpot.balance = loginBackRes.getBalance();
                    store.state.userWalletSpot.marginBalance = loginBackRes.getMarginbalance();
                    store.state.userWalletSpot.marginPosition = loginBackRes.getMarginposition();
                    store.state.userWalletSpot.marginFrozen = loginBackRes.getMarginfrozen();
                    store.state.userWalletSpot.marginAvailable = loginBackRes.getMarginavailable();
                    store.state.userWalletSpot.profitReal= loginBackRes.getProfitreal();                //已实现盈亏
                    store.state.userWalletSpot.profitUnreal= loginBackRes.getProfitunreal();            //未实现盈亏
                    store.state.userWalletSpot.riskRate= loginBackRes.getRiskrate();                    //保证金率
                    store.state.userWalletSpot.followBalance = loginBackRes.getFollowbalance();

                    store.state.loginStatusSpot = true;
                    let message = {
                        loginStatus: true,
                    }
                    context.$root.$emit('tradeSpotlogin', message);
                
                    this.heartCheck();
                    teadeSpotWSReq.C2TSHoldOrderListReq();
                    teadeSpotWSReq.C2TSEntrustOrderListReq();
                    teadeSpotWSReq.C2TSDealOrderListReq();
                } else {
                    let message = {
                        loginStatus: false,
                    }
                    context.$root.$emit('tradeSpotlogin', message);
                    store.dispatch('tradeSpotWS/close');
                }
                break;
            case 11003:
                store.commit('tradeSpotWS/ZERO_HARTCOUNT');
                break;
            case 11004:
                let message = {
                    loginStatus: false,
                }
                context.$root.$emit('tradeSpotlogin', message);
                store.dispatch('tradeSpotWS/close');
                break;
            case 11005:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CUpdateUserInfoRes.deserializeBinary(data_msg);
                var list = res.getUser();
                store.state.userWalletSpot.balance = list.getBalance();
                store.state.userWalletSpot.marginBalance = list.getMarginbalance();
                store.state.userWalletSpot.marginPosition = list.getMarginposition();
                store.state.userWalletSpot.marginFrozen = list.getMarginfrozen();
                store.state.userWalletSpot.marginAvailable = list.getMarginavailable();//可用保证金
                store.state.userWalletSpot.followBalance = list.getFollowbalance();
                break;
            //持仓单列表
            case 11008:
                var data_msg = baseMethods.decrypt(decPack.data);
				var res = proto.spot_pkg.TS2CHoldOrderListRes.deserializeBinary(data_msg);
				var list = res.getOrderList();
				store.state.holdListSpot = list;
                flashHoldOrderListSpot();
                break;
            //委托单列表
            case 11009:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CEntrustOrderListRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                store.state.EntrustListSpot = list;
                flashEntrustOrderListSpot();
                break;
            //成交单列表
            case 11010:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CDealOrderListRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                store.state.DealListSpot = list;
                flashDealOrderListSpot();
                break;
            case 11050:     //开仓返回
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2COpenRes.deserializeBinary(data_msg);
                var OpenRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2COpenResSpot', OpenRes);
                break;
            case 11051:     //平仓返回
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CCloseRes.deserializeBinary(data_msg);
                var CloseRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CCloseResSpot', CloseRes);
                break;    
            case 11052:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CCancelRes.deserializeBinary(data_msg);
                var CancelRes = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                context.$root.$emit('TS2CCancelResSpot', CancelRes);
                break;    
            case 11053:     //添加委托单
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CAddEntrustRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.EntrustListSpot.push(items);
                });
                flashEntrustOrderListSpot();
                break;
            case 11054:     //更新委托单
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CUpdateEntrustRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.EntrustListSpot.forEach((item, index) => {
                        if (item.getId() == items.getId()) {
                            store.state.EntrustListSpot.splice(index, 1);
                            store.state.EntrustListSpot.push(items);
                        }
                    })
                });
                flashEntrustOrderListSpot();
                break;
            case 11055:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CAddHoldRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach((item, index) => {
                    store.state.holdListSpot.push(item);
                });
                flashHoldOrderListSpot();
                break;
            case 11056:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CUpdateHoldRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.holdListSpot.forEach((item, index) => {
                        if (item.getId() == items.getId()) {
                            store.state.holdListSpot.splice(index, 1);
                            store.state.holdListSpot.push(items);
                        }
                    })
                }) ;
                flashHoldOrderListSpot();
                break;
            case 11057:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CDeleteHoldRes.deserializeBinary(data_msg);
                var list = res.getOrderidList();
                list.forEach(items => {
                    store.state.holdListSpot.forEach((item, index) => {
                        if (item.getId() == items) {
                            store.state.holdListSpot.splice(index, 1);
                        }
                    })
                });
                flashHoldOrderListSpot();
                break;
            case 11058:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res = proto.spot_pkg.TS2CAddDealRes.deserializeBinary(data_msg);
                var list = res.getOrderList();
                list.forEach(items => {
                    store.state.DealListSpot.push(items);
                }); 
                flashDealOrderListSpot();
                break;
            
            default:
                console.log('unKnown type:' + decPack.msgType);
                break;
        }
    },
    heartCheck() {
        //心跳包
		var interval = setInterval(function(){
			if (store.state.tradeWS.ws.readyState === WebSocket.OPEN) {
                var message = new proto.spot_pkg.C2TSHeartbeatReq();
                var bytes = message.serializeBinary();
                var bytes_encry = baseMethods.encrypt(bytes)
                var heart_note = EncodePack(bytes_encry,'10003');
                store.dispatch('tradeSpotWS/sendMessage', heart_note);
                //心跳计数加1
                store.commit('tradeSpotWS/INC_HARTCOUNT');
				if (store.state.tradeSpotWS.wsHart > 3) {
					clearInterval(interval);
                    //关闭套接字
                    store.dispatch('tradeSpotWS/close');
                    //心跳计数置0
                    store.commit('tradeSpotWS/ZERO_HARTCOUNT');
                    //登录状态置为未登录
                    store.state.loginStatusSpot = false;
				}
			} else {
                store.commit('tradeSpotWS/INC_HARTCOUNT');
				if (store.state.tradeSpotWSHart > 3) {
					clearInterval(interval);
                    store.dispatch('tradeSpotWS/close');
                    store.commit('tradeSpotWS/ZERO_HARTCOUNT');
                    store.state.loginStatusSpot = false;
				}
            }
		}, 30000)
    }
};

export {
    teadeSpotWSparse,
}