<template>
    <div class="TransactionRecord">
        <div>
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                    @back="goBack" 
                    :content="i18n('heyuedingdan')"
                    :title="i18n('fanhui')"
                    style="margin-left: 10px;">
                </el-page-header>   
            </div>

            <div class="bg_white">
                <div class=" selectpart">
                    <div class="flex_row_left">
                        <div class="selecttitle"> {{i18n('heyuedaima')}} </div>
                        <el-select 
                            v-model="contractcode" 
                            :placeholder="i18n('qingxuanze')" 
                            size="mini" 
                            style="margin-left: 10px; width: 150px;"
                            @change="contractcodeChange">
                            <el-option
                                key="0"
                                :label="i18n('quanbu')"
                                value="">
                            </el-option>
                            <el-option
                                v-for="item in contractList"
                                :key="item.getId()"
                                :label="item.getContractcode()"
                                :value="item.getContractcode()">
                            </el-option>
                        </el-select>
                        <div class="selecttitle margin15"> {{i18n('fangxiang')}} </div>
                        <el-select 
                            v-model="buyoffset" 
                            :placeholder="i18n('qingxuanze')" 
                            size="mini" 
                            style="margin-left: 10px; width: 90px;"
                            @change="buyoffsetChange">
                            <el-option
                                key="0"
                                :label="i18n('quanbu')"
                                value="">
                            </el-option>
                            <el-option
                                key="1"
                                :label="i18n('kaiduo')"
                                value="0,0">
                            </el-option>
                            <el-option
                                key="2"
                                :label="i18n('pingkong')"
                                value="0,1">
                            </el-option>
                            <el-option
                                key="3"
                                :label="i18n('kaikong')"
                                value="1,0">
                            </el-option>
                            <el-option
                                key="4"
                                :label="i18n('pingduo')"
                                value="1,1">
                            </el-option>
                        </el-select>
                        <div class="selecttitle margin15"> {{i18n('baojialeixing')}} </div>
                        <el-select 
                            v-model="pricetype" 
                            :placeholder="i18n('qingxuanze')" 
                            size="mini" 
                            style="margin-left: 10px; width: 100px;"
                            @change="pricetypeChange">
                            <el-option
                                key="0"
                                :label="i18n('quanbu')"
                                value="">
                            </el-option>
                            <el-option
                                key="1"
                                :label="i18n('xianjiaweituo')"
                                value="0">
                            </el-option>
                            <el-option
                                key="2"
                                :label="i18n('shijiaweituo')"
                                value="1">
                            </el-option>
                        </el-select>
                        <div class="selecttitle margin15"> {{i18n('zhuangtai')}} </div>
                        <el-select 
                            v-model="status" 
                            :placeholder="i18n('qingxuanze')" 
                            size="mini" 
                            style="margin-left: 10px; width: 100px;"
                            @change="statusChange">
                            <el-option
                                key="0"
                                :label="i18n('suoyouzhuangtai')"
                                value="">
                            </el-option>
                            <el-option
                                key="1"
                                :label="i18n('tijiaoshibai')"
                                value="0">
                            </el-option>
                            <el-option
                                key="2"
                                :label="i18n('zhunbeitijiao')"
                                value="1">
                            </el-option>
                            <el-option
                                key="3"
                                :label="i18n('yitijiao')"
                                value="2">
                            </el-option>
                            <el-option
                                key="4"
                                :label="i18n('bufentijiao')"
                                value="3">
                            </el-option>
                            <el-option
                                key="5"
                                :label="i18n('bufentijiaoyichedan')"
                                value="4">
                            </el-option>
                            <el-option
                                key="6"
                                :label="i18n('quanbuchengjiao')"
                                value="5">
                            </el-option>
                            <el-option
                                key="7"
                                :label="i18n('yichedan')"
                                value="6">
                            </el-option>
                        </el-select>
                        <div class="selecttitle margin15"> {{i18n('xiadanshijian')}} </div>
                        <el-date-picker
                            v-model="queryDate"
                            size="mini"
                            type="date"
                            style="margin-left: 10px; width: 130px;" 
                            @change="queryDatechange"
                            placeholder="yyyy-MM-dd"></el-date-picker>
                    </div>
                </div>
            </div>
        </div>

        <div class="orderlist" style="width: 1200px; border: 1px solid #ddd;">
            <el-table
                :data="recordListData"
                style="width: 100%;"
                :empty-text="i18n('zanwushuju')"
                :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                :row-style="{background:'#F9F9F8'}">
                <el-table-column
                    :label="i18n('heyuedaima')"
                    min-width="90">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.contractCode }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('ganggan')"
                    min-width="60">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.leverRate }}X </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('xiadanshijian')"
                    min-width="160">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.createDate | timeFormat('ymdhms') }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('chengjiaoshijian')"
                    min-width="160">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.tradeDate | timeFormat('ymdhms') }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('fangxiang')"
                    min-width="90">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.directionoffset }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('baojialeixing')"
                    min-width="120">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.orderPriceType }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('weituoliang')"
                    min-width="150">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.volume }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('weituojia')"
                    min-width="190">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.price }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('chengjiaoliang')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.tradeVolume }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('chengjiaojunjia')"
                    min-width="130">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.tradeAvgPrice }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('yingkui')"
                    min-width="120">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.profit }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('shouxufei')"
                    min-width="120">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.fee }} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('zhuangtai')"
                    min-width="130">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{ scope.row.status }} </span>
                    </template>
                </el-table-column>
            </el-table>

            <div class="block">
                <el-pagination
                    background
                    hide-on-single-page
                    :page-size=page_size
                    :pager-count="11"
                    layout="prev, pager, next"
                    :current-page.sync=page
                    @current-change="handleCurrentChange"
                    class="flex_row_right"
                    :total=totalCount>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            contractList: this.$store.state.contractList,
            contractcode: '',
            buyoffset: '',
            pricetype: '',
            status: '',
            queryDate: undefined,
            recordListData: [],
            page: 1,
            page_size: 10,
            startime: '',
            endtime: '',
            totalCount: 0,
            direction: '',
            offset: '',
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getList();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getList();
        };
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("contractTransactionRecord")[name];
            }
		},
    },
    filters: {
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() < 10 ? '0' + needdate.getDate() : needdate.getDate();
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + ' ' + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        contractcodeChange() {
            this.getList();
        },
        buyoffsetChange() {
            // console.log(this.direction);
            if (this.buyoffset !== undefined && this.buyoffset !== '') {
                var arr = this.buyoffset.split(',');
                this.direction = arr[0];
                this.offset = arr[1];
                this.getList();
            } else {
                this.direction = '';
                this.offset = '';
                this.getList();
            }
        },
        pricetypeChange() {
            this.getList();
        },
        statusChange() {
            this.getList();
        },
        queryDatechange() {
            console.log(this.queryDate);
            if (this.queryDate !== null) {
                this.startime = this.queryDate / 1000;
                this.endtime = this.startime + 24 * 60 * 60 - 1;
            } else {
                this.startime = '';
                this.endtime = '';
            }
            this.getList();
        },
        handleCurrentChange() {
            this.getmoreList();
        },
        recvdateParse(list) {
            this.recordListData = [];
            list.forEach(item => {
                var dof = '';
                if (item.direction === '0') {
                    if (item.offset === '0') {
                        dof = this.$t("contractTransactionRecord")['kaiduo'];
                    } else if (item.offset === '1') {
                        dof = this.$t("contractTransactionRecord")['pingkong'];
                    }
                } else if (item.direction === '1') {
                    if (item.offset === '0') {
                        dof = this.$t("contractTransactionRecord")['kaikong'];
                    } else if (item.offset === '1') {
                        dof = this.$t("contractTransactionRecord")['pingduo'];
                    }
                }

                var orderPriceType = '';
                if (item.orderPriceType == 0) { 
                    orderPriceType = this.$t("contractTransactionRecord")['xianjiaweituo'];
                } else if (item.orderPriceType == 1) {
                    orderPriceType = this.$t("contractTransactionRecord")['shijiaweituo'];
                }

                var status = '';
                if (item.status === '0') { //0:提交失败;1准备提交;2已提交;3部分成交;4部分成交已撤单;5全部成交;6已撤单
                    status = this.$t("contractTransactionRecord")['tijiaoshibai'];
                } else if (item.status === '1') {
                    status = this.$t("contractTransactionRecord")['zhunbeitijiao'];
                } else if (item.status === '2') {
                    status = this.$t("contractTransactionRecord")['yitijiao'];
                } else if (item.status === '3') {
                    status = this.$t("contractTransactionRecord")['bufentijiao'];
                } else if (item.status === '4') {
                    status = this.$t("contractTransactionRecord")['bufentijiaoyichedan'];
                } else if (item.status === '5') {
                    status = this.$t("contractTransactionRecord")['quanbuchengjiao'];
                } else if (item.status === '6') {
                    status = this.$t("contractTransactionRecord")['yichedan'];
                }

                var obj = {};
                obj.contractCode = item.contractCode;
                obj.leverRate = item.leverRate;
                obj.createDate = item.createDate;
                obj.tradeDate = item.tradeDate;
                obj.directionoffset = dof;
                obj.orderPriceType = orderPriceType;
                obj.volume = item.volume;
                obj.price = item.price;
                obj.tradeVolume = item.tradeVolume;
                obj.tradeAvgPrice = item.tradeAvgPrice;
                obj.profit = item.profit;
                obj.fee = item.fee;
                obj.status = status;
                this.recordListData.push(obj);
            });
        },
        async getList() {
            let data = {
                token: sessionStorage.getItem("tk"),
                page: 1,
                rows: this.page_size,
                contractCode: this.contractcode,
                direction: this.direction,
                offset: this.offset,
                orderPriceType: this.pricetype,
                status: this.status,
                createStartTime: this.startime,
                createEndTime: this.endtime,
				symbol:'COIN'
            }
            this.$api.contractTransactionRecordList(data).then((res)=>{
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                    this.totalCount = Number(res.data.data.count);
                } else {
                    this.$message({
                        message: this.$t('contractTransactionRecord')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        async getmoreList() {
            let data = {
                token: sessionStorage.getItem("tk"),
                page: this.page,
                rows: this.page_size,
                contractCode: this.contractcode,
                direction: this.direction,
                offset: this.offset,
                orderPriceType: this.pricetype,
                status: this.status,
                createStartTime: this.startime,
                createEndTime: this.endtime,
				symbol:'COIN'
            }
            this.$api.contractTransactionRecordList(data).then((res)=>{
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                } else {
                    this.$message({
                        message: this.$t('contractTransactionRecord')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
    },
}
</script>

<style>

.TransactionRecord {
    background: #F9F9FA;
    height: auto;
    min-width: 1200px;
    margin: 0 auto;
}

.selectpart {
    padding: 15px 0 20px 0;
    margin: 0 auto;
    width: 1200px;
}

.selecttitle {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
}

.margin15 {
    margin-left: 15px;
}


</style>

<style scoped>


</style>