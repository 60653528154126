/**
 * @fileoverview
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.option_pkg.C2KSKlineListReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSAmountListReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSContractListReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSEntrustOrderListReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSHeartbeatReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSLoginReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSLogoutReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSOpenReq', null, global);
goog.exportSymbol('proto.option_pkg.C2TSPeriodListReq', null, global);
goog.exportSymbol('proto.option_pkg.Contract', null, global);
goog.exportSymbol('proto.option_pkg.EStampType', null, global);
goog.exportSymbol('proto.option_pkg.EntrustOrder', null, global);
goog.exportSymbol('proto.option_pkg.KS2CKlineListRet', null, global);
goog.exportSymbol('proto.option_pkg.Kline', null, global);
goog.exportSymbol('proto.option_pkg.Period', null, global);
goog.exportSymbol('proto.option_pkg.PositionBrokerage', null, global);
goog.exportSymbol('proto.option_pkg.QC2QSHeartbeatReq', null, global);
goog.exportSymbol('proto.option_pkg.QC2QSLoginReq', null, global);
goog.exportSymbol('proto.option_pkg.QC2QSSubMarketReq', null, global);
goog.exportSymbol('proto.option_pkg.QC2QSUnSubMarketReq', null, global);
goog.exportSymbol('proto.option_pkg.QI2QSCandlestickRes', null, global);
goog.exportSymbol('proto.option_pkg.QI2QSMarketDepthRes', null, global);
goog.exportSymbol('proto.option_pkg.QI2QSMarketDetailRes', null, global);
goog.exportSymbol('proto.option_pkg.QI2QSTradeBboRes', null, global);
goog.exportSymbol('proto.option_pkg.QI2QSTradeDetailRes', null, global);
goog.exportSymbol('proto.option_pkg.QS2CContractListRes', null, global);
goog.exportSymbol('proto.option_pkg.QS2QCHeartbeatRes', null, global);
goog.exportSymbol('proto.option_pkg.QS2QCLoginRet', null, global);
goog.exportSymbol('proto.option_pkg.QS2QCSubMarketRet', null, global);
goog.exportSymbol('proto.option_pkg.QS2QCUnSubMarketRet', null, global);
goog.exportSymbol('proto.option_pkg.QuotePriceQty', null, global);
goog.exportSymbol('proto.option_pkg.TP2TSBrokerageRes', null, global);
goog.exportSymbol('proto.option_pkg.TP2TSPositionBrokerageRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CAddEntrustRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CAmountListRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CContractListRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CEntrustOrderListRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CHeartbeatRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CKickoutRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CLoginRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2COpenRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CPeriodListRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CUpdateEntrustRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2CUpdateUserInfoRes', null, global);
goog.exportSymbol('proto.option_pkg.TS2TPBrokerageReq', null, global);
goog.exportSymbol('proto.option_pkg.TradeDetail', null, global);
goog.exportSymbol('proto.option_pkg.User', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.User.displayName = 'proto.option_pkg.User';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.User.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.User} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balance: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.User}
 */
proto.option_pkg.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.User;
  return proto.option_pkg.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.User}
 */
proto.option_pkg.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.User} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.User.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.User.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.option_pkg.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.User.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.option_pkg.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.User.prototype.setName = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double balance = 3;
 * @return {number}
 */
proto.option_pkg.User.prototype.getBalance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.User.prototype.setBalance = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSLoginReq.displayName = 'proto.option_pkg.C2TSLoginReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSLoginReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSLoginReq}
 */
proto.option_pkg.C2TSLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSLoginReq;
  return proto.option_pkg.C2TSLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSLoginReq}
 */
proto.option_pkg.C2TSLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSLoginReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSLoginReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.option_pkg.C2TSLoginReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.option_pkg.C2TSLoginReq.prototype.setUsername = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.option_pkg.C2TSLoginReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.C2TSLoginReq.prototype.setPassword = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CLoginRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TS2CLoginRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CLoginRes.displayName = 'proto.option_pkg.TS2CLoginRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CLoginRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CLoginRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CLoginRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CLoginRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    openfeerate: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    user: (f = msg.getUser()) && proto.option_pkg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CLoginRes}
 */
proto.option_pkg.TS2CLoginRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CLoginRes;
  return proto.option_pkg.TS2CLoginRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CLoginRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CLoginRes}
 */
proto.option_pkg.TS2CLoginRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenfeerate(value);
      break;
    case 5:
      var value = new proto.option_pkg.User;
      reader.readMessage(value,proto.option_pkg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CLoginRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CLoginRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CLoginRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CLoginRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getOpenfeerate();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.option_pkg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.option_pkg.TS2CLoginRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.TS2CLoginRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.option_pkg.TS2CLoginRes.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.TS2CLoginRes.prototype.setToken = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 userType = 3;
 * @return {number}
 */
proto.option_pkg.TS2CLoginRes.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.option_pkg.TS2CLoginRes.prototype.setUsertype = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double openFeeRate = 4;
 * @return {number}
 */
proto.option_pkg.TS2CLoginRes.prototype.getOpenfeerate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.option_pkg.TS2CLoginRes.prototype.setOpenfeerate = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional User user = 5;
 * @return {?proto.option_pkg.User}
 */
proto.option_pkg.TS2CLoginRes.prototype.getUser = function() {
  return /** @type{?proto.option_pkg.User} */ (
    jspb.Message.getWrapperField(this, proto.option_pkg.User, 5));
};


/** @param {?proto.option_pkg.User|undefined} value */
proto.option_pkg.TS2CLoginRes.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.option_pkg.TS2CLoginRes.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.option_pkg.TS2CLoginRes.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSHeartbeatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSHeartbeatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSHeartbeatReq.displayName = 'proto.option_pkg.C2TSHeartbeatReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSHeartbeatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSHeartbeatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSHeartbeatReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSHeartbeatReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSHeartbeatReq}
 */
proto.option_pkg.C2TSHeartbeatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSHeartbeatReq;
  return proto.option_pkg.C2TSHeartbeatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSHeartbeatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSHeartbeatReq}
 */
proto.option_pkg.C2TSHeartbeatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSHeartbeatReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSHeartbeatReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSHeartbeatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSHeartbeatReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CHeartbeatRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TS2CHeartbeatRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CHeartbeatRes.displayName = 'proto.option_pkg.TS2CHeartbeatRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CHeartbeatRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CHeartbeatRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CHeartbeatRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CHeartbeatRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CHeartbeatRes}
 */
proto.option_pkg.TS2CHeartbeatRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CHeartbeatRes;
  return proto.option_pkg.TS2CHeartbeatRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CHeartbeatRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CHeartbeatRes}
 */
proto.option_pkg.TS2CHeartbeatRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CHeartbeatRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CHeartbeatRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CHeartbeatRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CHeartbeatRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.option_pkg.TS2CHeartbeatRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.TS2CHeartbeatRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSLogoutReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSLogoutReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSLogoutReq.displayName = 'proto.option_pkg.C2TSLogoutReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSLogoutReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSLogoutReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSLogoutReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSLogoutReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSLogoutReq}
 */
proto.option_pkg.C2TSLogoutReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSLogoutReq;
  return proto.option_pkg.C2TSLogoutReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSLogoutReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSLogoutReq}
 */
proto.option_pkg.C2TSLogoutReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSLogoutReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSLogoutReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSLogoutReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSLogoutReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CKickoutRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TS2CKickoutRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CKickoutRes.displayName = 'proto.option_pkg.TS2CKickoutRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CKickoutRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CKickoutRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CKickoutRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CKickoutRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: msg.getIp_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CKickoutRes}
 */
proto.option_pkg.TS2CKickoutRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CKickoutRes;
  return proto.option_pkg.TS2CKickoutRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CKickoutRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CKickoutRes}
 */
proto.option_pkg.TS2CKickoutRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CKickoutRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CKickoutRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CKickoutRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CKickoutRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getIp_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes ip = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.TS2CKickoutRes.prototype.getIp = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes ip = 1;
 * This is a type-conversion wrapper around `getIp()`
 * @return {string}
 */
proto.option_pkg.TS2CKickoutRes.prototype.getIp_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIp()));
};


/**
 * optional bytes ip = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIp()`
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CKickoutRes.prototype.getIp_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIp()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.TS2CKickoutRes.prototype.setIp = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CUpdateUserInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TS2CUpdateUserInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CUpdateUserInfoRes.displayName = 'proto.option_pkg.TS2CUpdateUserInfoRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CUpdateUserInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CUpdateUserInfoRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.option_pkg.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CUpdateUserInfoRes}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CUpdateUserInfoRes;
  return proto.option_pkg.TS2CUpdateUserInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CUpdateUserInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CUpdateUserInfoRes}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.User;
      reader.readMessage(value,proto.option_pkg.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CUpdateUserInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CUpdateUserInfoRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CUpdateUserInfoRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.option_pkg.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.option_pkg.User}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.prototype.getUser = function() {
  return /** @type{?proto.option_pkg.User} */ (
    jspb.Message.getWrapperField(this, proto.option_pkg.User, 1));
};


/** @param {?proto.option_pkg.User|undefined} value */
proto.option_pkg.TS2CUpdateUserInfoRes.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.option_pkg.TS2CUpdateUserInfoRes.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.option_pkg.TS2CUpdateUserInfoRes.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.Contract.displayName = 'proto.option_pkg.Contract';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.Contract} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contractsize: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    pricetick: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    sort: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.Contract}
 */
proto.option_pkg.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.Contract;
  return proto.option_pkg.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.Contract}
 */
proto.option_pkg.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractsize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricetick(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.Contract} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.Contract.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.Contract.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getContractsize();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getPricetick();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getSort();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.option_pkg.Contract.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.Contract.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.option_pkg.Contract.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.Contract.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.option_pkg.Contract.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.option_pkg.Contract.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double contractSize = 4;
 * @return {number}
 */
proto.option_pkg.Contract.prototype.getContractsize = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.option_pkg.Contract.prototype.setContractsize = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double priceTick = 5;
 * @return {number}
 */
proto.option_pkg.Contract.prototype.getPricetick = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.option_pkg.Contract.prototype.setPricetick = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 sort = 6;
 * @return {number}
 */
proto.option_pkg.Contract.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.option_pkg.Contract.prototype.setSort = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSContractListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSContractListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSContractListReq.displayName = 'proto.option_pkg.C2TSContractListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSContractListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSContractListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSContractListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSContractListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSContractListReq}
 */
proto.option_pkg.C2TSContractListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSContractListReq;
  return proto.option_pkg.C2TSContractListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSContractListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSContractListReq}
 */
proto.option_pkg.C2TSContractListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSContractListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSContractListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSContractListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSContractListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CContractListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TS2CContractListRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TS2CContractListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CContractListRes.displayName = 'proto.option_pkg.TS2CContractListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TS2CContractListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CContractListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CContractListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CContractListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CContractListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractList: jspb.Message.toObjectList(msg.getContractList(),
    proto.option_pkg.Contract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CContractListRes}
 */
proto.option_pkg.TS2CContractListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CContractListRes;
  return proto.option_pkg.TS2CContractListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CContractListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CContractListRes}
 */
proto.option_pkg.TS2CContractListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.Contract;
      reader.readMessage(value,proto.option_pkg.Contract.deserializeBinaryFromReader);
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CContractListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CContractListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CContractListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CContractListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.option_pkg.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Contract contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.Contract>}
 */
proto.option_pkg.TS2CContractListRes.prototype.getContractList = function() {
  return /** @type{!Array.<!proto.option_pkg.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.Contract, 1));
};


/** @param {!Array.<!proto.option_pkg.Contract>} value */
proto.option_pkg.TS2CContractListRes.prototype.setContractList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.option_pkg.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.Contract}
 */
proto.option_pkg.TS2CContractListRes.prototype.addContract = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.option_pkg.Contract, opt_index);
};


proto.option_pkg.TS2CContractListRes.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.Period = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.Period, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.Period.displayName = 'proto.option_pkg.Period';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.Period.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.Period.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.Period} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.Period.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    period: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profitrate: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    minamount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.Period}
 */
proto.option_pkg.Period.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.Period;
  return proto.option_pkg.Period.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.Period} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.Period}
 */
proto.option_pkg.Period.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriod(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.Period} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.Period.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.Period.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.Period.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getPeriod();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getProfitrate();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getMinamount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.option_pkg.Period.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.Period.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 period = 2;
 * @return {number}
 */
proto.option_pkg.Period.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.Period.prototype.setPeriod = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double profitRate = 3;
 * @return {number}
 */
proto.option_pkg.Period.prototype.getProfitrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.Period.prototype.setProfitrate = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 minAmount = 4;
 * @return {number}
 */
proto.option_pkg.Period.prototype.getMinamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.option_pkg.Period.prototype.setMinamount = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSPeriodListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSPeriodListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSPeriodListReq.displayName = 'proto.option_pkg.C2TSPeriodListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSPeriodListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSPeriodListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSPeriodListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSPeriodListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSPeriodListReq}
 */
proto.option_pkg.C2TSPeriodListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSPeriodListReq;
  return proto.option_pkg.C2TSPeriodListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSPeriodListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSPeriodListReq}
 */
proto.option_pkg.C2TSPeriodListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSPeriodListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSPeriodListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSPeriodListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSPeriodListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CPeriodListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TS2CPeriodListRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TS2CPeriodListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CPeriodListRes.displayName = 'proto.option_pkg.TS2CPeriodListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TS2CPeriodListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CPeriodListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CPeriodListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CPeriodListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CPeriodListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodList: jspb.Message.toObjectList(msg.getPeriodList(),
    proto.option_pkg.Period.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CPeriodListRes}
 */
proto.option_pkg.TS2CPeriodListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CPeriodListRes;
  return proto.option_pkg.TS2CPeriodListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CPeriodListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CPeriodListRes}
 */
proto.option_pkg.TS2CPeriodListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.Period;
      reader.readMessage(value,proto.option_pkg.Period.deserializeBinaryFromReader);
      msg.addPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CPeriodListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CPeriodListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CPeriodListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CPeriodListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getPeriodList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.option_pkg.Period.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Period period = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.Period>}
 */
proto.option_pkg.TS2CPeriodListRes.prototype.getPeriodList = function() {
  return /** @type{!Array.<!proto.option_pkg.Period>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.Period, 1));
};


/** @param {!Array.<!proto.option_pkg.Period>} value */
proto.option_pkg.TS2CPeriodListRes.prototype.setPeriodList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.option_pkg.Period=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.Period}
 */
proto.option_pkg.TS2CPeriodListRes.prototype.addPeriod = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.option_pkg.Period, opt_index);
};


proto.option_pkg.TS2CPeriodListRes.prototype.clearPeriodList = function() {
  this.setPeriodList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSAmountListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSAmountListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSAmountListReq.displayName = 'proto.option_pkg.C2TSAmountListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSAmountListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSAmountListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSAmountListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSAmountListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSAmountListReq}
 */
proto.option_pkg.C2TSAmountListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSAmountListReq;
  return proto.option_pkg.C2TSAmountListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSAmountListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSAmountListReq}
 */
proto.option_pkg.C2TSAmountListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSAmountListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSAmountListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSAmountListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSAmountListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CAmountListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TS2CAmountListRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TS2CAmountListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CAmountListRes.displayName = 'proto.option_pkg.TS2CAmountListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TS2CAmountListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CAmountListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CAmountListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CAmountListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CAmountListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountList: jspb.Message.getField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CAmountListRes}
 */
proto.option_pkg.TS2CAmountListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CAmountListRes;
  return proto.option_pkg.TS2CAmountListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CAmountListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CAmountListRes}
 */
proto.option_pkg.TS2CAmountListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array.<number>} */ (reader.readPackedInt32());
      msg.setAmountList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CAmountListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CAmountListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CAmountListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CAmountListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getAmountList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 amount = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<number>}
 */
proto.option_pkg.TS2CAmountListRes.prototype.getAmountList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getField(this, 1));
};


/** @param {!Array.<number>} value */
proto.option_pkg.TS2CAmountListRes.prototype.setAmountList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.option_pkg.TS2CAmountListRes.prototype.addAmount = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.option_pkg.TS2CAmountListRes.prototype.clearAmountList = function() {
  this.setAmountList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.EntrustOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.EntrustOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.EntrustOrder.displayName = 'proto.option_pkg.EntrustOrder';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.EntrustOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.EntrustOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.EntrustOrder} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.EntrustOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    openprice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    period: jspb.Message.getFieldWithDefault(msg, 6, 0),
    profitrate: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    begintime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    fee: +jspb.Message.getFieldWithDefault(msg, 11, 0.0),
    endprice: +jspb.Message.getFieldWithDefault(msg, 12, 0.0),
    profit: +jspb.Message.getFieldWithDefault(msg, 13, 0.0),
    endtime: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.EntrustOrder}
 */
proto.option_pkg.EntrustOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.EntrustOrder;
  return proto.option_pkg.EntrustOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.EntrustOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.EntrustOrder}
 */
proto.option_pkg.EntrustOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriod(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitrate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBegintime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndprice(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.EntrustOrder} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.EntrustOrder.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.EntrustOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.EntrustOrder.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getPeriod();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getProfitrate();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getBegintime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = this.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = this.getEndprice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = this.getEndtime();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.option_pkg.EntrustOrder.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.EntrustOrder.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.option_pkg.EntrustOrder.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.option_pkg.EntrustOrder.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double openPrice = 4;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getOpenprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setOpenprice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 period = 6;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setPeriod = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double profitRate = 7;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getProfitrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setProfitrate = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double amount = 8;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setAmount = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional int32 beginTime = 9;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setBegintime = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional int32 status = 10;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional double fee = 11;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getFee = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 11, 0.0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setFee = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional double endPrice = 12;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getEndprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 12, 0.0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setEndprice = function(value) {
  jspb.Message.setField(this, 12, value);
};


/**
 * optional double profit = 13;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 13, 0.0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 13, value);
};


/**
 * optional int32 endTime = 14;
 * @return {number}
 */
proto.option_pkg.EntrustOrder.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.option_pkg.EntrustOrder.prototype.setEndtime = function(value) {
  jspb.Message.setField(this, 14, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSEntrustOrderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSEntrustOrderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSEntrustOrderListReq.displayName = 'proto.option_pkg.C2TSEntrustOrderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSEntrustOrderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSEntrustOrderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSEntrustOrderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSEntrustOrderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSEntrustOrderListReq}
 */
proto.option_pkg.C2TSEntrustOrderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSEntrustOrderListReq;
  return proto.option_pkg.C2TSEntrustOrderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSEntrustOrderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSEntrustOrderListReq}
 */
proto.option_pkg.C2TSEntrustOrderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSEntrustOrderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSEntrustOrderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSEntrustOrderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSEntrustOrderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CEntrustOrderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TS2CEntrustOrderListRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TS2CEntrustOrderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CEntrustOrderListRes.displayName = 'proto.option_pkg.TS2CEntrustOrderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TS2CEntrustOrderListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CEntrustOrderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CEntrustOrderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CEntrustOrderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CEntrustOrderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.option_pkg.EntrustOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CEntrustOrderListRes}
 */
proto.option_pkg.TS2CEntrustOrderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CEntrustOrderListRes;
  return proto.option_pkg.TS2CEntrustOrderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CEntrustOrderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CEntrustOrderListRes}
 */
proto.option_pkg.TS2CEntrustOrderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.EntrustOrder;
      reader.readMessage(value,proto.option_pkg.EntrustOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CEntrustOrderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CEntrustOrderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CEntrustOrderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CEntrustOrderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.option_pkg.EntrustOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntrustOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.EntrustOrder>}
 */
proto.option_pkg.TS2CEntrustOrderListRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.option_pkg.EntrustOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.EntrustOrder, 1));
};


/** @param {!Array.<!proto.option_pkg.EntrustOrder>} value */
proto.option_pkg.TS2CEntrustOrderListRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.option_pkg.EntrustOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.EntrustOrder}
 */
proto.option_pkg.TS2CEntrustOrderListRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.option_pkg.EntrustOrder, opt_index);
};


proto.option_pkg.TS2CEntrustOrderListRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2TSOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2TSOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2TSOpenReq.displayName = 'proto.option_pkg.C2TSOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2TSOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2TSOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2TSOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2TSOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    periodid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2TSOpenReq}
 */
proto.option_pkg.C2TSOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2TSOpenReq;
  return proto.option_pkg.C2TSOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2TSOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2TSOpenReq}
 */
proto.option_pkg.C2TSOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriodid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2TSOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2TSOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2TSOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPeriodid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string contractCode = 1;
 * @return {string}
 */
proto.option_pkg.C2TSOpenReq.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.option_pkg.C2TSOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 periodID = 2;
 * @return {number}
 */
proto.option_pkg.C2TSOpenReq.prototype.getPeriodid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.C2TSOpenReq.prototype.setPeriodid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.option_pkg.C2TSOpenReq.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.C2TSOpenReq.prototype.setAmount = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 direction = 4;
 * @return {number}
 */
proto.option_pkg.C2TSOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.option_pkg.C2TSOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2COpenRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TS2COpenRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2COpenRes.displayName = 'proto.option_pkg.TS2COpenRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2COpenRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2COpenRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2COpenRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2COpenRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2COpenRes}
 */
proto.option_pkg.TS2COpenRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2COpenRes;
  return proto.option_pkg.TS2COpenRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2COpenRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2COpenRes}
 */
proto.option_pkg.TS2COpenRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2COpenRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2COpenRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2COpenRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2COpenRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.option_pkg.TS2COpenRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.TS2COpenRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.TS2COpenRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.option_pkg.TS2COpenRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2COpenRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.TS2COpenRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CAddEntrustRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TS2CAddEntrustRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TS2CAddEntrustRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CAddEntrustRes.displayName = 'proto.option_pkg.TS2CAddEntrustRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TS2CAddEntrustRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CAddEntrustRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CAddEntrustRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CAddEntrustRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CAddEntrustRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.option_pkg.EntrustOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CAddEntrustRes}
 */
proto.option_pkg.TS2CAddEntrustRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CAddEntrustRes;
  return proto.option_pkg.TS2CAddEntrustRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CAddEntrustRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CAddEntrustRes}
 */
proto.option_pkg.TS2CAddEntrustRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.EntrustOrder;
      reader.readMessage(value,proto.option_pkg.EntrustOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CAddEntrustRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CAddEntrustRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CAddEntrustRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CAddEntrustRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.option_pkg.EntrustOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntrustOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.EntrustOrder>}
 */
proto.option_pkg.TS2CAddEntrustRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.option_pkg.EntrustOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.EntrustOrder, 1));
};


/** @param {!Array.<!proto.option_pkg.EntrustOrder>} value */
proto.option_pkg.TS2CAddEntrustRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.option_pkg.EntrustOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.EntrustOrder}
 */
proto.option_pkg.TS2CAddEntrustRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.option_pkg.EntrustOrder, opt_index);
};


proto.option_pkg.TS2CAddEntrustRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2CUpdateEntrustRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TS2CUpdateEntrustRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TS2CUpdateEntrustRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2CUpdateEntrustRes.displayName = 'proto.option_pkg.TS2CUpdateEntrustRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TS2CUpdateEntrustRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2CUpdateEntrustRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2CUpdateEntrustRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2CUpdateEntrustRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2CUpdateEntrustRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.option_pkg.EntrustOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2CUpdateEntrustRes}
 */
proto.option_pkg.TS2CUpdateEntrustRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2CUpdateEntrustRes;
  return proto.option_pkg.TS2CUpdateEntrustRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2CUpdateEntrustRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2CUpdateEntrustRes}
 */
proto.option_pkg.TS2CUpdateEntrustRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.EntrustOrder;
      reader.readMessage(value,proto.option_pkg.EntrustOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2CUpdateEntrustRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CUpdateEntrustRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2CUpdateEntrustRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2CUpdateEntrustRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.option_pkg.EntrustOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntrustOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.EntrustOrder>}
 */
proto.option_pkg.TS2CUpdateEntrustRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.option_pkg.EntrustOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.EntrustOrder, 1));
};


/** @param {!Array.<!proto.option_pkg.EntrustOrder>} value */
proto.option_pkg.TS2CUpdateEntrustRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.option_pkg.EntrustOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.EntrustOrder}
 */
proto.option_pkg.TS2CUpdateEntrustRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.option_pkg.EntrustOrder, opt_index);
};


proto.option_pkg.TS2CUpdateEntrustRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TS2TPBrokerageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TS2TPBrokerageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TS2TPBrokerageReq.displayName = 'proto.option_pkg.TS2TPBrokerageReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TS2TPBrokerageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TS2TPBrokerageReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TS2TPBrokerageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fee: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    time: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TS2TPBrokerageReq}
 */
proto.option_pkg.TS2TPBrokerageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TS2TPBrokerageReq;
  return proto.option_pkg.TS2TPBrokerageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TS2TPBrokerageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TS2TPBrokerageReq}
 */
proto.option_pkg.TS2TPBrokerageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TS2TPBrokerageReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2TPBrokerageReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getTime();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.TS2TPBrokerageReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.TS2TPBrokerageReq.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double fee = 3;
 * @return {number}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.getFee = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.TS2TPBrokerageReq.prototype.setFee = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 orderID = 4;
 * @return {number}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.option_pkg.TS2TPBrokerageReq.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 time = 5;
 * @return {number}
 */
proto.option_pkg.TS2TPBrokerageReq.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.option_pkg.TS2TPBrokerageReq.prototype.setTime = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TP2TSBrokerageRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TP2TSBrokerageRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TP2TSBrokerageRes.displayName = 'proto.option_pkg.TP2TSBrokerageRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TP2TSBrokerageRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TP2TSBrokerageRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TP2TSBrokerageRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    brokerage: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    time: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TP2TSBrokerageRes}
 */
proto.option_pkg.TP2TSBrokerageRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TP2TSBrokerageRes;
  return proto.option_pkg.TP2TSBrokerageRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TP2TSBrokerageRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TP2TSBrokerageRes}
 */
proto.option_pkg.TP2TSBrokerageRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBrokerage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TP2TSBrokerageRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TP2TSBrokerageRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getBrokerage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getTime();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.TP2TSBrokerageRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.TP2TSBrokerageRes.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double brokerage = 3;
 * @return {number}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.getBrokerage = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.TP2TSBrokerageRes.prototype.setBrokerage = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 orderID = 4;
 * @return {number}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.option_pkg.TP2TSBrokerageRes.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 time = 5;
 * @return {number}
 */
proto.option_pkg.TP2TSBrokerageRes.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.option_pkg.TP2TSBrokerageRes.prototype.setTime = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.PositionBrokerage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.PositionBrokerage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.PositionBrokerage.displayName = 'proto.option_pkg.PositionBrokerage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.PositionBrokerage.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.PositionBrokerage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.PositionBrokerage} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.PositionBrokerage.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brokerage: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.PositionBrokerage}
 */
proto.option_pkg.PositionBrokerage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.PositionBrokerage;
  return proto.option_pkg.PositionBrokerage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.PositionBrokerage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.PositionBrokerage}
 */
proto.option_pkg.PositionBrokerage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBrokerage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.PositionBrokerage} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.PositionBrokerage.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.PositionBrokerage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.PositionBrokerage.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getBrokerage();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.option_pkg.PositionBrokerage.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.PositionBrokerage.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double brokerage = 2;
 * @return {number}
 */
proto.option_pkg.PositionBrokerage.prototype.getBrokerage = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.option_pkg.PositionBrokerage.prototype.setBrokerage = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TP2TSPositionBrokerageRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.TP2TSPositionBrokerageRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.TP2TSPositionBrokerageRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TP2TSPositionBrokerageRes.displayName = 'proto.option_pkg.TP2TSPositionBrokerageRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.TP2TSPositionBrokerageRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TP2TSPositionBrokerageRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TP2TSPositionBrokerageRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.option_pkg.PositionBrokerage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TP2TSPositionBrokerageRes}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TP2TSPositionBrokerageRes;
  return proto.option_pkg.TP2TSPositionBrokerageRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TP2TSPositionBrokerageRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TP2TSPositionBrokerageRes}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.option_pkg.PositionBrokerage;
      reader.readMessage(value,proto.option_pkg.PositionBrokerage.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TP2TSPositionBrokerageRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TP2TSPositionBrokerageRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TP2TSPositionBrokerageRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.option_pkg.PositionBrokerage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PositionBrokerage info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.PositionBrokerage>}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.option_pkg.PositionBrokerage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.PositionBrokerage, 1));
};


/** @param {!Array.<!proto.option_pkg.PositionBrokerage>} value */
proto.option_pkg.TP2TSPositionBrokerageRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.option_pkg.PositionBrokerage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.PositionBrokerage}
 */
proto.option_pkg.TP2TSPositionBrokerageRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.option_pkg.PositionBrokerage, opt_index);
};


proto.option_pkg.TP2TSPositionBrokerageRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.Kline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.Kline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.Kline.displayName = 'proto.option_pkg.Kline';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.Kline.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.Kline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.Kline} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.Kline.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openprice: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    closeprice: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    highprice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    lowprice: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    volume: +jspb.Message.getFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.Kline}
 */
proto.option_pkg.Kline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.Kline;
  return proto.option_pkg.Kline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.Kline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.Kline}
 */
proto.option_pkg.Kline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.Kline} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.Kline.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.Kline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.Kline.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = this.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getHighprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.option_pkg.Kline.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.Kline.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double openPrice = 2;
 * @return {number}
 */
proto.option_pkg.Kline.prototype.getOpenprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.option_pkg.Kline.prototype.setOpenprice = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double closePrice = 3;
 * @return {number}
 */
proto.option_pkg.Kline.prototype.getCloseprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.Kline.prototype.setCloseprice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double highPrice = 4;
 * @return {number}
 */
proto.option_pkg.Kline.prototype.getHighprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.option_pkg.Kline.prototype.setHighprice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double lowPrice = 5;
 * @return {number}
 */
proto.option_pkg.Kline.prototype.getLowprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.option_pkg.Kline.prototype.setLowprice = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double volume = 6;
 * @return {number}
 */
proto.option_pkg.Kline.prototype.getVolume = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.option_pkg.Kline.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QI2QSCandlestickRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QI2QSCandlestickRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QI2QSCandlestickRes.displayName = 'proto.option_pkg.QI2QSCandlestickRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QI2QSCandlestickRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QI2QSCandlestickRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QI2QSCandlestickRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    kline: (f = msg.getKline()) && proto.option_pkg.Kline.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QI2QSCandlestickRes}
 */
proto.option_pkg.QI2QSCandlestickRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QI2QSCandlestickRes;
  return proto.option_pkg.QI2QSCandlestickRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QI2QSCandlestickRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QI2QSCandlestickRes}
 */
proto.option_pkg.QI2QSCandlestickRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {!proto.option_pkg.EStampType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.option_pkg.Kline;
      reader.readMessage(value,proto.option_pkg.Kline.deserializeBinaryFromReader);
      msg.setKline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QI2QSCandlestickRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSCandlestickRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = this.getKline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.option_pkg.Kline.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QI2QSCandlestickRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional EStampType type = 2;
 * @return {!proto.option_pkg.EStampType}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.getType = function() {
  return /** @type {!proto.option_pkg.EStampType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.option_pkg.EStampType} value */
proto.option_pkg.QI2QSCandlestickRes.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional Kline kline = 3;
 * @return {?proto.option_pkg.Kline}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.getKline = function() {
  return /** @type{?proto.option_pkg.Kline} */ (
    jspb.Message.getWrapperField(this, proto.option_pkg.Kline, 3));
};


/** @param {?proto.option_pkg.Kline|undefined} value */
proto.option_pkg.QI2QSCandlestickRes.prototype.setKline = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.option_pkg.QI2QSCandlestickRes.prototype.clearKline = function() {
  this.setKline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.option_pkg.QI2QSCandlestickRes.prototype.hasKline = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QuotePriceQty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QuotePriceQty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QuotePriceQty.displayName = 'proto.option_pkg.QuotePriceQty';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QuotePriceQty.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QuotePriceQty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QuotePriceQty} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QuotePriceQty.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbidprice: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    qbidqty: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    qaskprice: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    qaskqty: +jspb.Message.getFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QuotePriceQty}
 */
proto.option_pkg.QuotePriceQty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QuotePriceQty;
  return proto.option_pkg.QuotePriceQty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QuotePriceQty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QuotePriceQty}
 */
proto.option_pkg.QuotePriceQty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQbidprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQbidqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQaskprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQaskqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QuotePriceQty} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QuotePriceQty.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QuotePriceQty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QuotePriceQty.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getQbidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = this.getQbidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getQaskprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getQaskqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double qBidPrice = 1;
 * @return {number}
 */
proto.option_pkg.QuotePriceQty.prototype.getQbidprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.option_pkg.QuotePriceQty.prototype.setQbidprice = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double qBidQty = 2;
 * @return {number}
 */
proto.option_pkg.QuotePriceQty.prototype.getQbidqty = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.option_pkg.QuotePriceQty.prototype.setQbidqty = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double qAskPrice = 3;
 * @return {number}
 */
proto.option_pkg.QuotePriceQty.prototype.getQaskprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.QuotePriceQty.prototype.setQaskprice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double qAskQty = 4;
 * @return {number}
 */
proto.option_pkg.QuotePriceQty.prototype.getQaskqty = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.option_pkg.QuotePriceQty.prototype.setQaskqty = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QI2QSMarketDepthRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.QI2QSMarketDepthRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.QI2QSMarketDepthRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QI2QSMarketDepthRes.displayName = 'proto.option_pkg.QI2QSMarketDepthRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.QI2QSMarketDepthRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QI2QSMarketDepthRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QI2QSMarketDepthRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QI2QSMarketDepthRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quotepriceqtyList: jspb.Message.toObjectList(msg.getQuotepriceqtyList(),
    proto.option_pkg.QuotePriceQty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QI2QSMarketDepthRes}
 */
proto.option_pkg.QI2QSMarketDepthRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QI2QSMarketDepthRes;
  return proto.option_pkg.QI2QSMarketDepthRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QI2QSMarketDepthRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QI2QSMarketDepthRes}
 */
proto.option_pkg.QI2QSMarketDepthRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new proto.option_pkg.QuotePriceQty;
      reader.readMessage(value,proto.option_pkg.QuotePriceQty.deserializeBinaryFromReader);
      msg.addQuotepriceqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QI2QSMarketDepthRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSMarketDepthRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = this.getQuotepriceqtyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.option_pkg.QuotePriceQty.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QI2QSMarketDepthRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDepthRes.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * repeated QuotePriceQty quotePriceQty = 3;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.QuotePriceQty>}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.getQuotepriceqtyList = function() {
  return /** @type{!Array.<!proto.option_pkg.QuotePriceQty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.QuotePriceQty, 3));
};


/** @param {!Array.<!proto.option_pkg.QuotePriceQty>} value */
proto.option_pkg.QI2QSMarketDepthRes.prototype.setQuotepriceqtyList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.option_pkg.QuotePriceQty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.QuotePriceQty}
 */
proto.option_pkg.QI2QSMarketDepthRes.prototype.addQuotepriceqty = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.option_pkg.QuotePriceQty, opt_index);
};


proto.option_pkg.QI2QSMarketDepthRes.prototype.clearQuotepriceqtyList = function() {
  this.setQuotepriceqtyList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QI2QSMarketDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QI2QSMarketDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QI2QSMarketDetailRes.displayName = 'proto.option_pkg.QI2QSMarketDetailRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QI2QSMarketDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QI2QSMarketDetailRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QI2QSMarketDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    openprice: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    closeprice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    highprice: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    lowprice: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    volume: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QI2QSMarketDetailRes}
 */
proto.option_pkg.QI2QSMarketDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QI2QSMarketDetailRes;
  return proto.option_pkg.QI2QSMarketDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QI2QSMarketDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QI2QSMarketDetailRes}
 */
proto.option_pkg.QI2QSMarketDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QI2QSMarketDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSMarketDetailRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = this.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getHighprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = this.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double openPrice = 3;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getOpenprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setOpenprice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double closePrice = 4;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getCloseprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setCloseprice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double highPrice = 5;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getHighprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setHighprice = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double lowPrice = 6;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getLowprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setLowprice = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double amount = 7;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setAmount = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double volume = 8;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getVolume = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional int64 count = 9;
 * @return {number}
 */
proto.option_pkg.QI2QSMarketDetailRes.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.option_pkg.QI2QSMarketDetailRes.prototype.setCount = function(value) {
  jspb.Message.setField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.TradeDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.TradeDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.TradeDetail.displayName = 'proto.option_pkg.TradeDetail';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.TradeDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.TradeDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.TradeDetail} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.TradeDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.TradeDetail}
 */
proto.option_pkg.TradeDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.TradeDetail;
  return proto.option_pkg.TradeDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.TradeDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.TradeDetail}
 */
proto.option_pkg.TradeDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.TradeDetail} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TradeDetail.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.TradeDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.TradeDetail.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.option_pkg.TradeDetail.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.TradeDetail.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.option_pkg.TradeDetail.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.option_pkg.TradeDetail.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.option_pkg.TradeDetail.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.option_pkg.TradeDetail.prototype.setAmount = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 direction = 4;
 * @return {number}
 */
proto.option_pkg.TradeDetail.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.option_pkg.TradeDetail.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QI2QSTradeDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.QI2QSTradeDetailRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.QI2QSTradeDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QI2QSTradeDetailRes.displayName = 'proto.option_pkg.QI2QSTradeDetailRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.QI2QSTradeDetailRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QI2QSTradeDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QI2QSTradeDetailRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QI2QSTradeDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    tradedetailList: jspb.Message.toObjectList(msg.getTradedetailList(),
    proto.option_pkg.TradeDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QI2QSTradeDetailRes}
 */
proto.option_pkg.QI2QSTradeDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QI2QSTradeDetailRes;
  return proto.option_pkg.QI2QSTradeDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QI2QSTradeDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QI2QSTradeDetailRes}
 */
proto.option_pkg.QI2QSTradeDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = new proto.option_pkg.TradeDetail;
      reader.readMessage(value,proto.option_pkg.TradeDetail.deserializeBinaryFromReader);
      msg.addTradedetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QI2QSTradeDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSTradeDetailRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTradedetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.option_pkg.TradeDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QI2QSTradeDetailRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated TradeDetail tradeDetail = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.TradeDetail>}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.getTradedetailList = function() {
  return /** @type{!Array.<!proto.option_pkg.TradeDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.TradeDetail, 2));
};


/** @param {!Array.<!proto.option_pkg.TradeDetail>} value */
proto.option_pkg.QI2QSTradeDetailRes.prototype.setTradedetailList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.option_pkg.TradeDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.TradeDetail}
 */
proto.option_pkg.QI2QSTradeDetailRes.prototype.addTradedetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.option_pkg.TradeDetail, opt_index);
};


proto.option_pkg.QI2QSTradeDetailRes.prototype.clearTradedetailList = function() {
  this.setTradedetailList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QI2QSTradeBboRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QI2QSTradeBboRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QI2QSTradeBboRes.displayName = 'proto.option_pkg.QI2QSTradeBboRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QI2QSTradeBboRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QI2QSTradeBboRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QI2QSTradeBboRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quotepriceqty: (f = msg.getQuotepriceqty()) && proto.option_pkg.QuotePriceQty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QI2QSTradeBboRes}
 */
proto.option_pkg.QI2QSTradeBboRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QI2QSTradeBboRes;
  return proto.option_pkg.QI2QSTradeBboRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QI2QSTradeBboRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QI2QSTradeBboRes}
 */
proto.option_pkg.QI2QSTradeBboRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new proto.option_pkg.QuotePriceQty;
      reader.readMessage(value,proto.option_pkg.QuotePriceQty.deserializeBinaryFromReader);
      msg.setQuotepriceqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QI2QSTradeBboRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSTradeBboRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = this.getQuotepriceqty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.option_pkg.QuotePriceQty.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QI2QSTradeBboRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.QI2QSTradeBboRes.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional QuotePriceQty quotePriceQty = 3;
 * @return {?proto.option_pkg.QuotePriceQty}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.getQuotepriceqty = function() {
  return /** @type{?proto.option_pkg.QuotePriceQty} */ (
    jspb.Message.getWrapperField(this, proto.option_pkg.QuotePriceQty, 3));
};


/** @param {?proto.option_pkg.QuotePriceQty|undefined} value */
proto.option_pkg.QI2QSTradeBboRes.prototype.setQuotepriceqty = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.option_pkg.QI2QSTradeBboRes.prototype.clearQuotepriceqty = function() {
  this.setQuotepriceqty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.option_pkg.QI2QSTradeBboRes.prototype.hasQuotepriceqty = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QC2QSLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QC2QSLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QC2QSLoginReq.displayName = 'proto.option_pkg.QC2QSLoginReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QC2QSLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QC2QSLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QC2QSLoginReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QC2QSLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authcode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QC2QSLoginReq}
 */
proto.option_pkg.QC2QSLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QC2QSLoginReq;
  return proto.option_pkg.QC2QSLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QC2QSLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QC2QSLoginReq}
 */
proto.option_pkg.QC2QSLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QC2QSLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSLoginReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QC2QSLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSLoginReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getAuthcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string accountName = 1;
 * @return {string}
 */
proto.option_pkg.QC2QSLoginReq.prototype.getAccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.option_pkg.QC2QSLoginReq.prototype.setAccountname = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.option_pkg.QC2QSLoginReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.QC2QSLoginReq.prototype.setPassword = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string authCode = 3;
 * @return {string}
 */
proto.option_pkg.QC2QSLoginReq.prototype.getAuthcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.option_pkg.QC2QSLoginReq.prototype.setAuthcode = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QS2QCLoginRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QS2QCLoginRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QS2QCLoginRet.displayName = 'proto.option_pkg.QS2QCLoginRet';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QS2QCLoginRet.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QS2QCLoginRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QS2QCLoginRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QS2QCLoginRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QS2QCLoginRet}
 */
proto.option_pkg.QS2QCLoginRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QS2QCLoginRet;
  return proto.option_pkg.QS2QCLoginRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QS2QCLoginRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QS2QCLoginRet}
 */
proto.option_pkg.QS2QCLoginRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QS2QCLoginRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCLoginRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2QCLoginRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCLoginRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.option_pkg.QS2QCLoginRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.QS2QCLoginRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.option_pkg.QS2QCLoginRet.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.QS2QCLoginRet.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QC2QSSubMarketReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.QC2QSSubMarketReq.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.QC2QSSubMarketReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QC2QSSubMarketReq.displayName = 'proto.option_pkg.QC2QSSubMarketReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.QC2QSSubMarketReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QC2QSSubMarketReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QC2QSSubMarketReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QC2QSSubMarketReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractList: msg.getContractList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QC2QSSubMarketReq}
 */
proto.option_pkg.QC2QSSubMarketReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QC2QSSubMarketReq;
  return proto.option_pkg.QC2QSSubMarketReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QC2QSSubMarketReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QC2QSSubMarketReq}
 */
proto.option_pkg.QC2QSSubMarketReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QC2QSSubMarketReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSSubMarketReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.getContractList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getField(this, 1));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<string>}
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.getContractList_asB64 = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.bytesListAsB64(
      this.getContractList()));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<!Uint8Array>}
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.getContractList_asU8 = function() {
  return /** @type {!Array.<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getContractList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.option_pkg.QC2QSSubMarketReq.prototype.setContractList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.option_pkg.QC2QSSubMarketReq.prototype.addContract = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.option_pkg.QC2QSSubMarketReq.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QS2QCSubMarketRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QS2QCSubMarketRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QS2QCSubMarketRet.displayName = 'proto.option_pkg.QS2QCSubMarketRet';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QS2QCSubMarketRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QS2QCSubMarketRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QS2QCSubMarketRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: msg.getContract_asB64(),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QS2QCSubMarketRet}
 */
proto.option_pkg.QS2QCSubMarketRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QS2QCSubMarketRet;
  return proto.option_pkg.QS2QCSubMarketRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QS2QCSubMarketRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QS2QCSubMarketRet}
 */
proto.option_pkg.QS2QCSubMarketRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QS2QCSubMarketRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCSubMarketRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContract_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bytes contract = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.getContract = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contract = 1;
 * This is a type-conversion wrapper around `getContract()`
 * @return {string}
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.getContract_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContract()));
};


/**
 * optional bytes contract = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContract()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.getContract_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContract()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QS2QCSubMarketRet.prototype.setContract = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.option_pkg.QS2QCSubMarketRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.QS2QCSubMarketRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QC2QSUnSubMarketReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.QC2QSUnSubMarketReq.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.QC2QSUnSubMarketReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QC2QSUnSubMarketReq.displayName = 'proto.option_pkg.QC2QSUnSubMarketReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.QC2QSUnSubMarketReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QC2QSUnSubMarketReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QC2QSUnSubMarketReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QC2QSUnSubMarketReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractList: msg.getContractList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QC2QSUnSubMarketReq}
 */
proto.option_pkg.QC2QSUnSubMarketReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QC2QSUnSubMarketReq;
  return proto.option_pkg.QC2QSUnSubMarketReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QC2QSUnSubMarketReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QC2QSUnSubMarketReq}
 */
proto.option_pkg.QC2QSUnSubMarketReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QC2QSUnSubMarketReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSUnSubMarketReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.getContractList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getField(this, 1));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<string>}
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.getContractList_asB64 = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.bytesListAsB64(
      this.getContractList()));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<!Uint8Array>}
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.getContractList_asU8 = function() {
  return /** @type {!Array.<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getContractList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.setContractList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.option_pkg.QC2QSUnSubMarketReq.prototype.addContract = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.option_pkg.QC2QSUnSubMarketReq.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QS2QCUnSubMarketRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QS2QCUnSubMarketRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QS2QCUnSubMarketRet.displayName = 'proto.option_pkg.QS2QCUnSubMarketRet';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QS2QCUnSubMarketRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QS2QCUnSubMarketRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QS2QCUnSubMarketRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: msg.getContract_asB64(),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QS2QCUnSubMarketRet}
 */
proto.option_pkg.QS2QCUnSubMarketRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QS2QCUnSubMarketRet;
  return proto.option_pkg.QS2QCUnSubMarketRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QS2QCUnSubMarketRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QS2QCUnSubMarketRet}
 */
proto.option_pkg.QS2QCUnSubMarketRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QS2QCUnSubMarketRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCUnSubMarketRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContract_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bytes contract = 1;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.getContract = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contract = 1;
 * This is a type-conversion wrapper around `getContract()`
 * @return {string}
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.getContract_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContract()));
};


/**
 * optional bytes contract = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContract()`
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.getContract_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContract()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.setContract = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.option_pkg.QS2QCUnSubMarketRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QC2QSHeartbeatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QC2QSHeartbeatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QC2QSHeartbeatReq.displayName = 'proto.option_pkg.QC2QSHeartbeatReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QC2QSHeartbeatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QC2QSHeartbeatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QC2QSHeartbeatReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QC2QSHeartbeatReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QC2QSHeartbeatReq}
 */
proto.option_pkg.QC2QSHeartbeatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QC2QSHeartbeatReq;
  return proto.option_pkg.QC2QSHeartbeatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QC2QSHeartbeatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QC2QSHeartbeatReq}
 */
proto.option_pkg.QC2QSHeartbeatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QC2QSHeartbeatReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSHeartbeatReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QC2QSHeartbeatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QC2QSHeartbeatReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QS2QCHeartbeatRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.QS2QCHeartbeatRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QS2QCHeartbeatRes.displayName = 'proto.option_pkg.QS2QCHeartbeatRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QS2QCHeartbeatRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QS2QCHeartbeatRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QS2QCHeartbeatRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QS2QCHeartbeatRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QS2QCHeartbeatRes}
 */
proto.option_pkg.QS2QCHeartbeatRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QS2QCHeartbeatRes;
  return proto.option_pkg.QS2QCHeartbeatRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QS2QCHeartbeatRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QS2QCHeartbeatRes}
 */
proto.option_pkg.QS2QCHeartbeatRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QS2QCHeartbeatRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCHeartbeatRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2QCHeartbeatRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2QCHeartbeatRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.option_pkg.QS2QCHeartbeatRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.QS2QCHeartbeatRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.QS2CContractListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.QS2CContractListRes.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.QS2CContractListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.QS2CContractListRes.displayName = 'proto.option_pkg.QS2CContractListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.QS2CContractListRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.QS2CContractListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.QS2CContractListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.QS2CContractListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.QS2CContractListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    rate: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    contractList: jspb.Message.toObjectList(msg.getContractList(),
    proto.option_pkg.Contract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.QS2CContractListRes}
 */
proto.option_pkg.QS2CContractListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.QS2CContractListRes;
  return proto.option_pkg.QS2CContractListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.QS2CContractListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.QS2CContractListRes}
 */
proto.option_pkg.QS2CContractListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    case 2:
      var value = new proto.option_pkg.Contract;
      reader.readMessage(value,proto.option_pkg.Contract.deserializeBinaryFromReader);
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.QS2CContractListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2CContractListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.QS2CContractListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.QS2CContractListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = this.getContractList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.option_pkg.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional double rate = 1;
 * @return {number}
 */
proto.option_pkg.QS2CContractListRes.prototype.getRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.option_pkg.QS2CContractListRes.prototype.setRate = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated Contract contract = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.Contract>}
 */
proto.option_pkg.QS2CContractListRes.prototype.getContractList = function() {
  return /** @type{!Array.<!proto.option_pkg.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.Contract, 2));
};


/** @param {!Array.<!proto.option_pkg.Contract>} value */
proto.option_pkg.QS2CContractListRes.prototype.setContractList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.option_pkg.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.Contract}
 */
proto.option_pkg.QS2CContractListRes.prototype.addContract = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.option_pkg.Contract, opt_index);
};


proto.option_pkg.QS2CContractListRes.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.C2KSKlineListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.option_pkg.C2KSKlineListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.C2KSKlineListReq.displayName = 'proto.option_pkg.C2KSKlineListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.C2KSKlineListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.C2KSKlineListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.C2KSKlineListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.C2KSKlineListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    codenum: msg.getCodenum_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.C2KSKlineListReq}
 */
proto.option_pkg.C2KSKlineListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.C2KSKlineListReq;
  return proto.option_pkg.C2KSKlineListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.C2KSKlineListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.C2KSKlineListReq}
 */
proto.option_pkg.C2KSKlineListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {!proto.option_pkg.EStampType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.C2KSKlineListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2KSKlineListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.C2KSKlineListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.C2KSKlineListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string accountName = 1;
 * @return {string}
 */
proto.option_pkg.C2KSKlineListReq.prototype.getAccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.option_pkg.C2KSKlineListReq.prototype.setAccountname = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.option_pkg.C2KSKlineListReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.option_pkg.C2KSKlineListReq.prototype.setPassword = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional EStampType type = 3;
 * @return {!proto.option_pkg.EStampType}
 */
proto.option_pkg.C2KSKlineListReq.prototype.getType = function() {
  return /** @type {!proto.option_pkg.EStampType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.option_pkg.EStampType} value */
proto.option_pkg.C2KSKlineListReq.prototype.setType = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes codeNum = 4;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.C2KSKlineListReq.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes codeNum = 4;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.C2KSKlineListReq.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.C2KSKlineListReq.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.C2KSKlineListReq.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.option_pkg.KS2CKlineListRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.option_pkg.KS2CKlineListRet.repeatedFields_, null);
};
goog.inherits(proto.option_pkg.KS2CKlineListRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.option_pkg.KS2CKlineListRet.displayName = 'proto.option_pkg.KS2CKlineListRet';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.option_pkg.KS2CKlineListRet.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.option_pkg.KS2CKlineListRet.prototype.toObject = function(opt_includeInstance) {
  return proto.option_pkg.KS2CKlineListRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.option_pkg.KS2CKlineListRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.option_pkg.KS2CKlineListRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    codenum: msg.getCodenum_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.option_pkg.Kline.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.option_pkg.KS2CKlineListRet}
 */
proto.option_pkg.KS2CKlineListRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.option_pkg.KS2CKlineListRet;
  return proto.option_pkg.KS2CKlineListRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.option_pkg.KS2CKlineListRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.option_pkg.KS2CKlineListRet}
 */
proto.option_pkg.KS2CKlineListRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 3:
      var value = /** @type {!proto.option_pkg.EStampType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.option_pkg.Kline;
      reader.readMessage(value,proto.option_pkg.Kline.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.option_pkg.KS2CKlineListRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.KS2CKlineListRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.option_pkg.KS2CKlineListRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.option_pkg.KS2CKlineListRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.option_pkg.Kline.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.option_pkg.KS2CKlineListRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.option_pkg.KS2CKlineListRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes codeNum = 2;
 * @return {!(string|Uint8Array)}
 */
proto.option_pkg.KS2CKlineListRet.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes codeNum = 2;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.option_pkg.KS2CKlineListRet.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.option_pkg.KS2CKlineListRet.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.option_pkg.KS2CKlineListRet.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional EStampType type = 3;
 * @return {!proto.option_pkg.EStampType}
 */
proto.option_pkg.KS2CKlineListRet.prototype.getType = function() {
  return /** @type {!proto.option_pkg.EStampType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.option_pkg.EStampType} value */
proto.option_pkg.KS2CKlineListRet.prototype.setType = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * repeated Kline list = 4;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.option_pkg.Kline>}
 */
proto.option_pkg.KS2CKlineListRet.prototype.getListList = function() {
  return /** @type{!Array.<!proto.option_pkg.Kline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.option_pkg.Kline, 4));
};


/** @param {!Array.<!proto.option_pkg.Kline>} value */
proto.option_pkg.KS2CKlineListRet.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.option_pkg.Kline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.option_pkg.Kline}
 */
proto.option_pkg.KS2CKlineListRet.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.option_pkg.Kline, opt_index);
};


proto.option_pkg.KS2CKlineListRet.prototype.clearListList = function() {
  this.setListList([]);
};


/**
 * @enum {number}
 */
proto.option_pkg.EStampType = {
  ENUMEST1MIN: 0,
  ENUMEST3MIN: 1,
  ENUMEST5MIN: 2,
  ENUMEST15MIN: 3,
  ENUMEST30MIN: 4,
  ENUMEST1HOUR: 5,
  ENUMEST2HOUR: 6,
  ENUMEST4HOUR: 7,
  ENUMEST1DAY: 8,
  ENUMEST1WEEK: 9,
  ENUMEST1MON: 10
};

goog.object.extend(exports, proto.option_pkg);
