<template>
    <div>
        <!-- 列表部分 -->
        <div class="treasure">
            <div class="head-part">
                <div class="head-top">
                    <div class="flex_col_center" style="align-items: flex-start;">
                        <div class="title font_bold">coin{{i18n('jijin')}}</div>
                        <div class="title-desc flex_row_around">
                            <div>{{i18n('yijianchongbi')}}</div>
                            <div>{{i18n('ririshouyi')}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="flex_row_left">
                            <div class="flex_col_center zcpart" style="align-items: flex-start;">
                                <div class="subtitle">coin{{i18n('jijinzongzichang')}}(USDT)</div>
                                <div class="bignum">{{lcMoney.productMoney}}</div>
                                <!-- <div class="smallnum">≈ 0.00 CNY</div> -->
                            </div>
                            <div class="flex_col_center zcpart" style="align-items: flex-start;">
                                <div class="subtitle">{{i18n('lishizongshouyi')}}(USDT)</div>
                                <div class="bignum">{{lcMoney.productAccumIncomeMoney}}</div>
                                <!-- <div class="smallnum">≈ 0.00 CNY</div> -->
                            </div>
                        </div>
                        <div class="flex_row_left AssetsOrder">
                            <div class="detailbtn" @click="goAssets">{{i18n('zichanxiangqing')}}</div>
                            <div class="detailbtn" @click="goOrder">{{i18n('dingdanxiangqing')}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="content-wrap">
                <div class="flex_row_left changepart">
                    <!-- <div class="changebtn on" onclick="changeTab(3)">赠U活动</div> -->
                    <div class="changebtn" :class="tabType==1?'ons':''" @click="changeTab(1)">{{i18n('linghuolicai')}}</div>
                    <div class="changebtn" :class="tabType==2?'ons':''" @click="changeTab(2)">{{i18n('dingqilicai')}}</div>
                    <div class="changebtn" :class="tabType==3?'ons':''" @click="changeTab(3)">{{i18n('dingelicai')}}</div>
                </div>
                <div class="nt1">
                    <ul>
                    <!--  <li>
                            <div class="content-title">
                                <div class="flex_row_left" style="align-items: flex-end;">
                                    <div class="fontSize30 color_333">灵活理财</div>
                                    <div class="fontSize16 color_999" style="margin-left: 16px;">1USDT起投，随时赎回</div>
                                </div>
                            </div>
                            <table class="trlist">
                                <tr>
                                    <th>币种</th>
                                    <th>参考年化收益率</th>
                                    <th>万分参考日投收益</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="flex_row_left">
                                            <img src="./images/USDT.png" alt=""> USDT
                                        </div>
                                    </td>
                                    <td class="color_green">6.11%</td>
                                    <td>1.23455678 USDT</td>
                                    <td>
                                        <div class="flex_row_right">
                                            <div class="gobtn">立即划转</div>
                                        </div>
                                    </td>
                                </tr>
                            </table>    
                        </li> -->
                    </ul>
                    
                </div>
                <div class="ntss2">
                    
                    <div class="content-title myyy">
                        <div class="flex_row_left" style="align-items: flex-end;" v-if="tabType==1">
                            <div class="fontSize30  mmm">{{i18n('linghuolicai')}}</div>
                            <div class="fontSize16  mmmm" style="margin-left: 16px;">{{i18n('suishishuhui')}}</div>
                        </div>
                        <div class="flex_row_left" style="align-items: flex-end;" v-if="tabType==2">
                            <div class="fontSize30  mmm">{{i18n('dingqilicai')}}</div>
                            <div class="fontSize16  mmmm" style="margin-left: 16px;">{{i18n('qixianfengfu')}}</div>
                        </div>
                        <div class="flex_row_left" style="align-items: flex-end;" v-if="tabType==3">
                            <div class="fontSize30  mmm">{{i18n('dingelicai')}}</div>
                            <div class="fontSize16  mmmm" style="margin-left: 16px;">{{i18n('qixianfengfu')}}</div>
                        </div>
                    </div>
                    <table class="trlist">
                        <tr>
                            <th>{{i18n('bizhong')}}</th>
                            <th v-if="tabType!=3">{{i18n('cankaonianhuashouyilv')}}（%）</th>
                            <th v-if="tabType==3">{{i18n('meifenmeirishouyi')}}（USDT）</th>
                            <th v-if="tabType == 1">{{i18n('wanfencankaoritoushouyi')}}（USDT）</th>
                            <th v-if="tabType != 1">{{i18n('touruqixian')}}（{{i18n('tian')}}）</th>
                            <th></th>
                        </tr>
                        <tr v-for="item in proList">
                            <td>
                                <div class="flex_row_left">
                                    <img src="@/assets/images/USDT.png" alt=""> USDT
                                </div>
                            </td>
                            <td class="color_green">{{item.productRateReturn}}</td>
                            <td v-if="tabType==1">{{item.productReferenceReturn}} </td>
                             <td v-if="tabType!=1">{{item.productPeriod}}</td>
                            <td>
                                <div class="flex_row_right">
                                    <div class="gobtn" @click='openStep1Show(item)'>{{i18n('lijihuazhuan')}}</div>
                                </div>
                            </td>
                        </tr>
                    </table>   
                </div>
                
            </div>

        </div>
        <!-- 弹窗部分 -->
        <!-- 第一步选择金额购买或数量 -->
        <div class="step1 flex_row_center" v-if="step1Show">
            <div class="show-box">
                <div class="box-title flex_row_between pos_relative">
                    <div>{{proDetails.productName}}</div>
                    <img src="@/assets/images/guanbi.png" class="close" @click="step1Show=false">
                </div>
                <div class="show-list">
                    <div class="flex_row_between">
                        <div v-if="tabType!=3">{{i18n('goumaijine')}}</div>
                        <div v-if="tabType==3">{{i18n('goumaifenshu')}}</div>
                        <div>{{i18n('keyongjine')}}<span class="avaluemoney">{{legalBalance}}</span> USDT</div>
                    </div>
                    <div class="flex_row_left MT12">
                        <div class="flex1 flex_row_between inputparts">
                            <el-input :placeholder="tabType==3?i18n('goumaifenshu'):i18n('goumaijine')" v-model="buyNum">
                                <template slot="append">
                                <el-button  @click="maxMoney()">{{i18n('zuida')}}</el-button>
                                </template>
                            </el-input>
                        </div>
                    </div>
                    <div class="bglist">
                        <div class="flex_row_between" v-if="tabType==3">
                            <div>{{i18n('meifenjine')}}</div>
                            <div class="color_green">{{proDetails.productUserMin}}USDT</div>
                        </div>
                        <div class="flex_row_between">
                            <div>{{i18n('cankaonianhuashouyilv')}}</div>
                            <div class="color_green">{{proDetails.productRateReturn}}%</div>
                        </div>
                        <div class="flex_row_between">
                            <div>{{i18n('wanfencankaoritoushouyi')}}</div>
                            <div>{{proDetails.productReferenceReturn}}</div>
                        </div>
                        <div class="flex_row_between">
                            <div>{{i18n('gerengoumaishangxian')}}</div>
                            <div>{{proDetails.productUserMax}} USDT</div>
                        </div>
                        <div class="flex_row_between" v-if="tabType!=3">
                            <div>{{i18n('shengxiaori')}}</div>
                            <div>{{proDetails.effectiveDate | formatDate}}</div>
                        </div>
                        <div class="flex_row_between type2" v-if="tabType!=3">
                            <div>{{i18n('daoqiri')}}</div>
                            <div v-if="proDetails.endDate">{{proDetails.endDate | formatDate}}</div>
                            <div v-if="!proDetails.endDate">{{i18n('wuqixian')}}</div>
                        </div>
                    <!-- <div class="flex_row_between">
                            <div>赎回方式</div>
                            <div>--</div>
                        </div>
                        <div class="flex_row_between type2">
                            <div>估算收益</div>
                            <div>--</div>
                        </div> -->
                    </div>
                    <!-- <div class="flex_row_between type2" style="margin-top: 6px;">
                        <div>到期自动转入灵活充币</div>
                        <form class="layui-form" action="" lay-filter="stepform"><input type="checkbox" name="autot" lay-skin="switch" lay-filter="is_auto"></form>
                    </div> -->
                    <div class="flex_row_left" style="margin-top: 20px;">
                        <div class="common_radio"></div>
                        <div class="isure">{{i18n('wwoyiliaojiebingtongyi')}}<span class="color_green " @click="step11Show = true">《Coin{{i18n('jijinxieyi')}}》</span></div>
                    </div>
                    <div class="orderbtn">
                        <button class="but-cancel" @click="step1Show=false">
                            {{i18n('quxiao')}}
                        </button>
                        <button class="but-determine" @click="buyNum?goBuy():''" :class="buyNum?'':'noclick'">
                            {{i18n('lijihuazhuan')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="step11 flex_row_center" v-if="step11Show">
            <div class="show-box">
                <div class="box-title flex_row_between pos_relative">
                    <div >Coin{{i18n('jijinxieyi')}}</div>
                    <img src="@/assets/images/guanbi.png" @click="step11Show = false" class="close11">
                </div>
                <div style="padding: 0 20px;line-height: 30px">
                    Financial management rules explained</br>
                    Product introduction</br>
                    Investment and financial management is a currency value-added product created by SGCEX that can be deposited and withdrawn at any time. By transferring idle currency into investment and financial management, users can enjoy the benefits brought by investment and financial management every day, with deposit and withdrawal, interest calculated by day, and the minimum deposit currency is 2000usdt. Investment and financial management relies on SGCEX's strict risk control system to fully ensure the safety and security of users' assets</br>
                    Enjoy the benefits.</br>
                    Product rules</br>
                    Investment and financial assets will be used for SGCEX digital currency high-frequency trading and mineral business; Investment and wealth management only supports usdt currency storage on SGCEX platform.</br>
                    Transfer in and transfer out rules</br>
                    Users can transfer in and out at any time and receive accounts in real time.</br>
                    When users transfer in investment and financial assets, they choose to transfer in regularly without interest on the same day, and calculate the income arrival on the next day.</br>
                    Interest distribution rules</br>
                    The high-frequency quantitative transaction and mineral income will be used for the interest distribution of investment and wealth management users. SGCEX will distribute the interest every day according to the regular quota signed by users. At present, all the income of this platform will be injected into the mineral capital pool to increase the layout of the mineral industry of the platform.</br>
                    Specific income statement (calculated according to the user's storage quota)</br>
                    Example:</br>
                    2000 (USDT) 30 day dividend 8USDT</br>
                    5000 (USDT) 30 day dividend 26USDT</br>
                    20000 (USDT) 60 day dividend 125USDT</br>
                    50000 (USDT) 90 day dividend 350USDT</br>
                    100000 (USDT) 120 day dividend 900USDT</br>
                    500000 (USDT) 180 day dividend 6500USDT</br>
                    1000000 (USDT) 360 day dividend 15000USD
                </div>
            </div>
        </div>
        <!-- 第七步交易成功 -->
        <div class="step7 flex_row_center text_center" v-if="step7Show">
            <div class="show-box">
                <div class="box-title flex_row_between pos_relative">
                    <div class="color_white">{{i18n('chenggong')}}</div>
                    <img src="@/assets/images/guanbi.png" class="close">
                    
                </div>
                <div class="show-list">
                    <div class="flex_row_center">
                        <img class="sucessimg" src="@/assets/images/success.png" alt="">
                    </div>
                    <div class="color_333 fontSize14">USDT<span class="suctype" v-if='tabType==1'>{{i18n('linghuolicai')}}</span><span class="suctype" v-if='tabType==2'>{{i18n('dingqilicai')}}</span><span class="suctype" v-if='tabType==3'>{{i18n('dingelicai')}}</span>{{i18n('chenggong')}}</div>
                    <div class="totlusdt">0份</div>
                    <div class="fontSize14 desc color_666">{{i18n('yichenggongchongbi')}}</div>
                    <div class="orderbtn flex_row_center">
                        <button class="orderdetail"><a class="flex_row_left" data-v-f36733ea="" href="treasureAssets.html">{{i18n('zichanxiangqing')}}</a></button>
                        <button class="lookmoney" onclick="getList()">{{i18n('jixuchongbi')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    data() {
        return {
           tabType:1,
           step11Show:false,
           step7Show:false,
           step1Show:false,
           proList:[],
           lcMoney:{},
           proDetails:{},
           buyNum:'',
           legalBalance:'0.0000'
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getProductList();
                this.getLcAccountInfo();
                this.getAllAccountInfo()
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getProductList();
            this.getLcAccountInfo();
            this.getAllAccountInfo()
        };
        
    },
    beforeDestroy() {
        
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    methods: {
        changeTab(val){
            this.tabType = val;
            this.getProductList()
        },
        openStep1Show(item){
            
            let data = {
                product_id:item.id,
                product_number:1
            }
            this.$api.getProductInfo(data).then((res)=>{
                this.step1Show = true;
                this.proDetails = res.data.data
            })

        },
        goOrder(){
            this.$router.push("/treasureOrder");
        },
        goAssets(){
            this.$router.push("/treasureAssets");
        },
        getProductList(){
            let data = {
                product_type:this.tabType
            }
            this.$api.productList(data).then((res)=>{
                this.proList = res.data.data.list
            })
        },
        getLcAccountInfo(){
            this.$api.getUserCunbiAccount().then((res)=>{
                this.lcMoney = res.data.data
            })
        },
        getAllAccountInfo(){
            this.$api.getMyLegalCurrencyAccountInfo({}).then((res)=>{
                this.legalBalance = res.data.data.legalBalance
            })
        },
        maxMoney(){
            if(this.tabType!=3){
                this.buyNum = parseFloat(this.legalBalance)
            }else{
                this.buyNum = parseInt(parseFloat(this.legalBalance)/parseFloat(this.proDetails.productUserMin))
            }
            
        },
        goBuy(){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = {
                product_id: this.proDetails.id,
                product_number: this.tabType==3?this.buyNum*parseFloat(this.proDetails.productUserMin):this.buyNum,
                is_auto_transfer_agility: 1
            }
            this.$api.doProduct(data).then((res)=>{
                if(res.data.code==200){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.getAllAccountInfo();
                    this.getLcAccountInfo();
                    this.step1Show = false;
                    setTimeout(()=>{
                        this.goAssets()
                    },1500)
                }else{
                    this.$message.error(res.data.msg);
                }
                loading.close();
            })
            
        }
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("treasure")[name];
            }
		},
    },
}
</script>
<style scoped>
@import '@/css/treasure.css';
</style> 