import spot_pkg from '@/utils/spot_msg_pb.js'
import { baseMethods } from '@/utils/methods.js';
import { hex_md5 } from '@/utils/md5.js';
import { EncodePack } from '@/utils/ByteArray.js';
import store from '@/store'

const teadeSpotWSReq = {
    login (account, pwd) {
        var message = new proto.spot_pkg.C2TSLoginReq();
        message.setUsername(account);
        message.setPassword(hex_md5(pwd));
        var bytes = message.serializeBinary();
        var bytes_encry = baseMethods.encrypt(bytes)
        var note = EncodePack(bytes_encry,'10001');
        store.dispatch('tradeSpotWS/sendMessage', note);
    },
    C2TSHoldOrderListReq(){		//持仓
		var message = new proto.spot_pkg.C2TSHoldOrderListReq();
		var bytes = message.serializeBinary();
		var bytes_encry = baseMethods.encrypt(bytes)
		var note = EncodePack(bytes_encry,'10007');
		store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSOpenReq(id,num,price,type,pricetype){
	    var message = new proto.spot_pkg.C2TSOpenReq(); 
	    message.setContractcode(id);  
	    message.setPrice(price);  
	    message.setVolume(num);  
	    message.setDirection(type);  
	    message.setOrderpricetype(pricetype);
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10050');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSCloseReq(holdtype,id,num,price,type,pricetype,followId){
	    var message1 = new proto.spot_pkg.C2TSCloseReq();
	    message1.setStrategyid(holdtype);  
	    message1.setTraderuserid(followId);  
	    message1.setContractcode(id);  
	    message1.setVolume(num);  
	    message1.setPrice(price);  
	    message1.setDirection(type);  
	    message1.setOrderpricetype(pricetype);
	    var bytes = message1.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10051');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSCancelReq(idList){
	    var message = new proto.spot_pkg.C2TSCancelReq();  
	    message.setOrderidList(idList); 
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10052');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSBackHandReq(id){
	    var message = new proto.spot_pkg.C2TSBackHandReq(); 
	    message.setId(id);
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10055');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSEntrustOrderListReq(){
	    var message = new proto.spot_pkg.C2TSEntrustOrderListReq();
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10008');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSDealOrderListReq(){
	    var message = new proto.spot_pkg.C2TSDealOrderListReq();
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10009');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSConditionOrderListReq(){
	    var message = new proto.spot_pkg.C2TSConditionOrderListReq();
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10080');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSAddConditionOrderReq(symbol,contractCode,type,priceType,compare,price,time,openClose,buySell,num){
	    var message = new proto.spot_pkg.ConditionOrderInfo(); 
	    message.setSymbol(symbol); 
	    message.setContractcode(contractCode); 
	    message.setVolume(num); 
	    message.setDirection(buySell); 
	    message.setOffset(openClose); 
	    message.setType(type); 
	    message.setPricetype(priceType); 
	    message.setCompare(compare); 
	    message.setEndprice(price); 
	    message.setEndtime(time); 
	    var message1 = new proto.spot_pkg.C2TSAddConditionOrderReq(); 
	    message1.setInfo(message);
	    var bytes = message1.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10081');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSModityConditionOrderReq(id,symbol,contractCode,type,priceType,compare,price,time,openClose,buySell,num){
	    var message = new proto.spot_pkg.ConditionOrderInfo(); 
	    message.setId(id*1); 
	    message.setSymbol(symbol); 
	    message.setContractcode(contractCode); 
	    message.setVolume(num); 
	    message.setDirection(buySell); 
	    message.setOffset(openClose); 
	    message.setType(type); 
	    message.setPricetype(priceType); 
	    message.setCompare(compare); 
	    message.setEndprice(price); 
	    message.setEndtime(time); 
	    var message1 = new proto.spot_pkg.C2TSModityConditionOrderReq(); 
	    message1.setInfo(message);
	    var bytes = message1.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10082');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSDelConditionOrderReq(id){
	    var message = new proto.spot_pkg.C2TSDelConditionOrderReq(); 
	    message.setId(id); 
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10083');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSRunConditionOrderReq(id){
	    var message = new proto.spot_pkg.C2TSRunConditionOrderReq(); 
	    message.setId(id); 
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10084');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSPauseConditionOrderReq(id){
	    var message = new proto.spot_pkg.C2TSPauseConditionOrderReq(); 
	    message.setId(id); 
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10085');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSImmediatelyConditionOrderReq(id){
	    var message = new proto.spot_pkg.C2TSImmediatelyConditionOrderReq(); 
	    message.setId(id); 
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes);
	    var note = EncodePack(bytes_encry,'10088');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
    C2TSSetProfitLossReq(id,pf,pl){
	    var message = new proto.spot_pkg.C2TSSetProfitLossReq();  
	    message.setId(id);  
	    message.setProfit(pf); 
	    message.setLoss(pl); 
	    var bytes = message.serializeBinary();
	    var bytes_encry = baseMethods.encrypt(bytes)
	    var note = EncodePack(bytes_encry,'10054');
	    store.dispatch('tradeSpotWS/sendMessage', note);
	},
}

export { 
    teadeSpotWSReq 
};