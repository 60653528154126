import option_pkg from '@/utils/option_msg_pb.js'
import option_type from '@/utils/option_type_pb.js';
import { baseMethods } from '@/utils/methods.js'
import store from '@/store'
import { teadeOptionsWSReq } from '@/utils/packedTradeOptions'
import { EncodePack } from '@/utils/ByteArray.js';
import context from '../main'
import i18n from '@/i18n' 

const teadeOptionsWSparse = { 
    parseData(decPack) {
        switch (decPack.msgType) {
           
            case 11001:
                var data_msg = baseMethods.decrypt(decPack.data);
                var res=proto.option_pkg.TS2CLoginRes.deserializeBinary(data_msg);
                if (res.getResultcode() == 0) {
                    var loginBackRes = res.getUser();
                    store.state.userWalletOptions.balance = loginBackRes.getBalance();
                    store.state.loginStatusOptions = true;
                    teadeOptionsWSReq.getZqList();
                    teadeOptionsWSReq.getmrjeList();
                    teadeOptionsWSReq.getEntrust();
                    // let message = {
                    //     loginStatus: true,
                    // }
                    // context.$root.$emit('tradeOptionslogin', message);
                    this.heartCheck();
                } else {
                    // let message = {
                    //     loginStatus: false,
                    // }
                    // context.$root.$emit('tradeSpotlogin', message);
                    store.dispatch('tradeOptionsWS/close');
                }
                break;
           
            case 11004:
                var data_msg=baseMethods.decrypt(decPack.data);
	            var res=proto.option_pkg.TS2CUpdateUserInfoRes.deserializeBinary(data_msg);
	            var list=res.getUser();
	            store.state.userWalletOptions.balance = list.getBalance();
                break;
            case proto.option_pkg.EnmCmdID.TS2C_OPEN_RES:
                var data_msg=baseMethods.decrypt(decPack.data);
			    var res=proto.option_pkg.TS2COpenRes.deserializeBinary(data_msg);
                var openSuccess = {
                    Resultcode: res.getResultcode(),
                    describe: baseMethods.Utf8ArrayToStr(res.getDescribe()),
                }
                console.log(12312312)
                context.$root.$emit('TS2COpenResOptions', openSuccess);
                break;
            case proto.option_pkg.EnmCmdID.TS2C_PERIOD_LIST_RES:
                var data_msg=baseMethods.decrypt(decPack.data);
                var res=proto.option_pkg.TS2CPeriodListRes.deserializeBinary(data_msg);
                store.state.periodList = res.getPeriodList();
                break;
            case proto.option_pkg.EnmCmdID.TS2C_AMOUNT_LIST_RES:
                var data_msg=baseMethods.decrypt(decPack.data);
                var res=proto.option_pkg.TS2CAmountListRes.deserializeBinary(data_msg);
                store.state.amountList = res.getAmountList()
                break;
            case  proto.option_pkg.EnmCmdID.TS2C_ENTRUSTORDER_LIST_RES:
                var data_msg=baseMethods.decrypt(decPack.data);
                var res=proto.option_pkg.TS2CEntrustOrderListRes.deserializeBinary(data_msg);
                var entrust_array = []
                res.getOrderList().forEach((item)=>{
                    entrust_array.push(item)
                })
                store.state.entrustOptionsList=entrust_array.reverse();
                break;
            default:
                console.log('unKnown type:' + decPack.msgType);
                break;
        }
    },
    heartCheck() {
        //心跳包
		var interval = setInterval(function(){
			if (store.state.tradeOptionsWS.ws.readyState === WebSocket.OPEN) {
                var message = new proto.option_pkg.C2TSHeartbeatReq();
                var bytes = message.serializeBinary();
                var bytes_encry = baseMethods.encrypt(bytes)
                var heart_note = EncodePack(bytes_encry,'10003');
                store.dispatch('tradeOptionsWS/sendMessage', heart_note);
                //心跳计数加1
                store.commit('tradeOptionsWS/INC_HARTCOUNT');
				if (store.state.tradeSpotWS.wsHart > 3) {
					clearInterval(interval);
                    //关闭套接字
                    store.dispatch('tradeOptionsWS/close');
                    //心跳计数置0
                    store.commit('tradeOptionsWS/ZERO_HARTCOUNT');
                    //登录状态置为未登录
                    store.state.loginStatusOptions = false;
				}
			} else {
                store.commit('tradeSpotWS/INC_HARTCOUNT');
				if (store.state.tradeSpotWSHart > 3) {
					clearInterval(interval);
                    store.dispatch('tradeSpotWS/close');
                    store.commit('tradeSpotWS/ZERO_HARTCOUNT');
                    store.state.loginStatusSpot = false;
				}
            }
		}, 30000)
    }
};

export {
    teadeOptionsWSparse,
}