import Vue from 'vue'
import VueI18n from 'vue-i18n';
import en from './locales/en.js'
import zhCN from './locales/zh-CN.js'
import enLocale from 'element-ui/lib/locale/lang/en.js'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN.js'
import Cookies from 'js-cookie'

const messages = {
    en:{
        ...en,
        ...enLocale
    },
    zh:{
        ...zhCN,
        ...zhLocale
    }
    // 其他语言...
};

Vue.use(VueI18n);

// 创建i18n实例
// let lang = localStorage.getItem('lang');
// let locale = lang =='en_US'?'en':'zh';

// 给一个函数用来获取cookie里存储的语言
export function getLanguage() {
    const chooseLanguage = localStorage.getItem('lang');
    if (chooseLanguage) return chooseLanguage

    // 如果没有找到存储语言 已浏览器国际语言为准
    const language = (navigator.language || navigator.browserLanguage).toLowerCase()
    //定义对应的语言键 展开对应的键值对应表
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
        return locale
        }
    }
    return 'zh'
};

const i18nInstance = new VueI18n({
    locale: getLanguage(), // 设置初始语言
    messages, // 从i18n导入的消息对象
    silentFallbackWarn: true
});

export default i18nInstance