<template>
    <div>
        <div style="width: 85%;margin: 0 auto;margin-top: 20px;">	
            <div style="overflow: hidden;">
                <div class="left_hea">{{i18n('rengouliebiao')}}</div>
                <div class="right_sw" style="">
                    <div class="right_sw_li" @click="selectType(2)" :class="selType==2?'right_hover':''"  style="border-right: 1px solid #1763B6;">{{i18n('jijiangdaolai')}}</div>
                    <div class="right_sw_li" @click="selectType(1)" :class="selType==1?'right_hover':''" style="border-right: 1px solid #1763B6;">{{i18n('jinxingzhong')}}</div>
                    <div class="right_sw_li" @click="selectType('')" :class="!selType?'right_hover':''" style="">{{i18n('quanbu')}}</div>
                </div>
            </div>
            <div class="allOuter"> 
                <div class="iconOuter" v-for="item in subList" @click="goDetails(item)">
                    <div class="iconOuter_header">
                        <img src="@/assets/images/subind.png">
                        <div class="iconOuter_header_box">{{item.fucProductName}}</div>
                    </div>
                    <div class="iconOuter_bottom">
                        <div>{{i18n('keshiyonghuobi')}}：USDT </div>
                        <div>{{i18n('zongshuliang')}}：{{parseFloat(item.fucProductQuota)?item.fucProductQuota:i18n('meiyouxianzhi')}} </div>
                        <div>{{i18n('shengyushuliang')}}：{{item.fucProductSurplus}} </div>
                        <div>{{i18n('kaishishijian')}}：{{item.startTime | formatDate}} </div>
                        <div>{{i18n('zhuangtai')}}：{{i18n('jinxingzhong')}}</div>
                    </div>
                    <div class="bottom_box1"></div>
                </div>
                
            </div>
            <!-- <a href="images/buy_agrees-new1.pdf"><div class="jihuaBut">SGCB商業計畫書</div></a> -->
            
        </div>
        
        <div class="tablepart" style="width: 85%; margin-top: 100px;">
            <div class="tabhead">{{i18n('jilu')}}</div>
            <div class="nolist" v-if="recordList.length<=0">
                <img class="nolistimg" src="@/assets/images/wudingdan.png" alt="">
                <div class="fontSize14 color_666 text_center" style="margin-bottom: 150px;">{{i18n('zanwujilu')}}</div>
            </div>
            <div class="hilists" v-if="recordList.length>0">
                <table class="retab ">
                    <tr>
                        <th >{{i18n('leixing')}}</th>
                        <th >{{$t("treasure")['bizhong']}}</th>
                        <th>{{i18n('shuliang')}}</th>
                        <th>{{i18n('goumaijine')}}(USDT)</th>
                        <th>{{i18n('shijian')}}</th>
                    </tr>
                    <tr v-for="item in recordList">
                        <td v-if="item.type==1">{{i18n('goumai')}}</td>
                        <td v-if="item.type==2">{{i18n('duichu')}}</td>
                        <td v-if="item.type==3">{{i18n('jiangli')}}</td>
                        <td v-if="item.type==4">{{i18n('tibi')}}</td>
                        <td>{{item.coinCodeNo}}</td>
                        <td>{{item.changeNum}}</td>
                        <td>{{item.changeAmount}}</td>
                        <td>{{item.time | formatDate}}</td>
                    </tr>
                </table>
                
                <div class="text_right" id="orderpaging"></div>
            </div>
        </div>
    </div>
</template>
<script>
  

export default {
    data() {
        return {
           selType:'',
           subList:[],
           recordList:[]
        };
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getProductList();
                this.getRecordList()
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getProductList();
            this.getRecordList()
        };
      
    },
    beforeDestroy() {
        
    },
    
    methods: {
        selectType(val){
            this.selType = val;
            this.getProductList()
            // this.getProductOrderList()
        },
        goDetails(item){
            this.$router.push({
                name:'subDetails',
                query:{cid:item.id}
            });
        },
        goBack(){
            this.$router.go(-1);
        },
        getProductList(){
            let data = {
                fucId:1,
				status:this.selType
            }
            this.$api.fucproductList(data).then((res)=>{
                this.subList = res.data.data.list
            })
        },
        getRecordList(){
            let data = {
                    fid:1,
					page:1,
					page_size:50
            }
            this.$api.getUserFucCoinAccountRecord(data).then((res)=>{
                this.recordList = res.data.data
            })
        },
        goBuy(){
            let data = {
                product_id: this.proDetails.id,
                product_number: this.type==3?this.buyNum*parseFloat(this.proDetails.productUserMin):this.buyNum,
                is_auto_transfer_agility: 1
            }
            this.$api.doProduct(data).then((res)=>{
                if(res.data.code==200){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.getAllAccountInfo();
                    this.getLcAccountInfo();
                    this.step1Show = false;
                }else{
                    this.$message.error(res.data.msg);
                }
            })
            
        }
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("subscription")[name];
            }
		},
    },
}
</script>

<style scoped>
@import '@/css/recharge.css';
	body{
		background-color: rgb(38,34,57) ;
		color: white;
		padding-bottom: 100px;
	}
	.right_sw{
		float: right;
		border-left: 1px solid #1763B6;
		display: flex;
		border-right: 1px solid #1763B6;
	}
	.right_sw div{
		width: 100px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		color: #1763B6;
		border-top: 1px solid #1763B6;
		border-bottom: 1px solid #1763B6;
		cursor: pointer;
	}
	.left_hea{
		font-size: 30px;
		font-weight: bold;
		line-height: 40px;
		float: left;
	}
	.right_hover{
		background-color: #1763B6;
		color: white !important;
	}
	.allOuter{
		width: 100%;
		margin-top: 30px;
		overflow: hidden;
	}
	.iconOuter{
		width: 22.5%;
		float: left;
		border: 1px solid #dddddd;
		margin-bottom: 10px;
		margin-right: 1%;
		margin-top: 20px;
		margin-left: 1%;
		position: relative;
	}
	.bottom_box1{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background-color: #08BD98;
	}
	.bottom_box2{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background-color: #E12343;
	}
	.bottom_box3{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background-color: #BBBBBB;
	}
	.iconOuter_bottom{
		padding: 20px 20px 20px 20px;
		background-color: #fff;
	}
	.iconOuter_bottom div{
		line-height: 30px;
		color: #000000;
	}
	a{
		color: white;
	}
	.iconOuter_header{
		display: flex;
		position:relative
	}
	.iconOuter_header img{
		width:100%;
		height:150px
	}
	.iconOuter_header_box{
		position: absolute;
		top: 0;
		left: 0;
		line-height: 150px;
		text-align: center;
		width:100%;
		font-weight: 600;
        color: white;
	}
	.jihuaBut{
		margin: 30px 10px;
		padding: 10px 10px;
		width: 150px;
		background-color: #1763B6;
		text-align: center;
	}
	.tablepart{
	    clear: both;
	}
	.retab th,.retab td{
	}

    </style>
