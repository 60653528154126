<template>

  <div class="table-hqList" >
    <div class="scrollNew" style="width: 80%; margin: auto;">
      <div class="news">
        <img src="@/assets/images/notice.png" width="16" style="vertical-align: -30%;">

        <ul ref="news" :class="{ animate: flag }">
          <li v-for="item in newsList" :key="item.id">
            <el-link :href="'https://help.sgcex.top/detail.html?id='+ item.id" target="_blank"> {{item.newsTitle}} </el-link>
          </li>
        </ul>
      </div>
    </div>

    <el-carousel :interval="4000" height="200px" type="card" style="width: 80%; margin: auto; ">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image style="width: 100%; height: 100%;" :fit="fit" :src="bannerimgAddr + JSON.parse(item.imgPath).web"></el-image>
      </el-carousel-item>
    </el-carousel>

    <el-row class="table-header" :gutter="20" style="width: 80%; margin: auto; ">
      <el-col :span="5">
        <div class="grid-content-header bg-purple"> {{ i18n('mingcheng') }} </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content-header bg-purple"> {{ i18n('zuixinjia') }} </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content-header bg-purple" @click="goLift()"> 
          {{ i18n('zhangdiefu24h') }} 
          <el-image
          style="width: 14px; height: 22px; float: right; margin-left: 10px;"
          :src="require('@/assets/images/trading' + option_lift + '.png')"></el-image>  
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content-header bg-purple"> {{ i18n('zuigaojia24h') }} </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content-header bg-purple"> {{ i18n('zuidijia24h') }} </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content-header bg-purple"> {{ i18n('caozuo') }} </div>
      </el-col>
    </el-row>

    <div v-if="hqlist.length > 0">
      <div v-for="(item, index) in hqlist" :key="item.id || index">  
        <el-row :gutter="20" style="width: 80%; margin: auto; ">
          <el-card class="box-card" shadow="hover" :body-style="{ padding: '0px' }" style = "border: none;">  
            <el-col :span="5">
              <div class="grid-content bg-purple"> 
                <div style="display: flex; align-items: center;">
                  <el-image
                    style="width: 30px; height: 30px"
                    :src="require('@/assets/codelogo/' + item.code + '.png')"></el-image>  
                  <span style="margin-left: 10px; color: black;">{{item.code}}</span>
                  <span style="margin-left: 10px;">{{ i18n('yongxuheyue') }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple"> 
                <span class = "" 
                v-if = "item.price <= 0"> - - </span>
                <span style="color: black;" v-else> 
                {{item.price | newPriceFormat(item.float_num)}} </span>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <span :class = "item.price | classUpOrDown(item.open,'color')" 
                v-if = "item.price > 0"> 
                {{item.price | classUpOrDown(item.open,'rate')}} </span>
                <span class="color_up" v-else> +0.00% </span>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <span class = "" 
                v-if = "item.high <= 0"> - - </span>
                <span style="color: black;" v-else> 
                {{item.high | newPriceFormat(item.float_num)}} </span>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <span class = "" 
                v-if = "item.low <= 0"> - - </span>
                <span style="color: black;" v-else> 
                {{item.low | newPriceFormat(item.float_num)}} </span>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple">
                <el-button
                size="mini"
                type="primary"
                @click="handleTrade(item.code)"> {{ i18n('kaishijiaoyi') }} </el-button>
              </div>
            </el-col>
          </el-card>
        </el-row>
      </div>
    </div>
    <div v-else> 
      <div class="no-data flex-center">
        <p> {{ i18n('zanwushuju') }} </p>
      </div>
    </div>

    <el-row :gutter="20" style="width: 80%; margin: auto; margin-top: 100px; border: none; ">
      <el-col :span="12">
        <div style="font-size: 20px; font-weight: bold; color: #606266;"> {{i18n('shoujixiazaitishi1')}} </div>
        <div style="font-size: 12px; margin-top: 10px; color: #606266;"> {{i18n('shoujixiazaitishi2')}} </div>

        <el-row :gutter="20" style="margin-top: 20px; border: none;">
          <el-col :span="8" style="text-align: center; color: #606266;" >
            <div class="IOS-download flex-center" @mouseover="hoverIOS = true" @mouseleave="hoverIOS = false"> 
              <img class="homeicon" :src="require('@/assets/images/homeios.png')" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
              {{ i18n('IPhoneXiaZai') }}   
            </div>

            <div style="display: flex; margin-top: 10px; height: 150px;">
              <transition name="el-fade-in-IOS">
                <div v-show="hoverIOS" class="transition-box-IOS">
                  <div class="qrcodeIOS" style="margin-left: 30px;">
                    <vue-qr
                      ref="qrCodeIOS"
                      :text="textIOS"
                      :logoSrc="logoSrc"
                      :color-dark="randomColor"
                      width="128"
                      height="128"
                    ></vue-qr>
                    <div> {{i18n('saomaxiazai')}} </div>
                  </div>
                </div>
              </transition>
            </div>
          </el-col>
          <el-col :span="8" style="text-align: center; color: #606266;">
            <div class="android-download flex-center" @mouseover="hoverAndroid = true" @mouseleave="hoverAndroid = false"> 
              <img class="homeicon" :src="require('@/assets/images/homeandroid.png')" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
              {{ i18n('AndroidXiaZai') }}  
            </div>
            <div style="display: flex; margin-top: 10px; height: 150px;">
              <transition name="el-fade-in-android">
                <div v-show="hoverAndroid" class="transition-box-android">
                  <div class="qrcodeAndroid" style="margin-left: 30px;">
                    <vue-qr
                      ref="qrCodeAndroid"
                      :text="textAndroid"
                      :logoSrc="logoSrc"
                      :color-dark="randomColor"
                      width="128"
                      height="128"
                    ></vue-qr>
                    <div> {{i18n('saomaxiazai')}} </div>
                  </div>
                </div>
              </transition>
            </div>
          </el-col>

          <el-col :span="8" style="text-align: center;">
          </el-col>
        </el-row>
 
      </el-col>

      <el-col :span="12">
        <div class="image-container"> 
          <el-image
            @error="handleImageError"
            style="width: 567px; height: 351px; "
            :src="require('@/assets/images/downlogo.png')"></el-image>  
        </div>
      </el-col>
    </el-row> 

    <div style="display: flex; width: 100%;"></div>
    
    <div style="position: relative;">
    	<img :src="require('@/assets/images/bs1.png')" width="100%">
    	<div style="position: absolute;top: 100px;left: 0;width: 100%;text-align: center;color: white;font-size: 20px;font-weight: 600;">
    		<div> {{i18n('xitongjieshao1')}} </div>
    		<div style="margin-top: 10px;"> {{i18n('xitongjieshao2')}} </div>
    	</div>
    </div>

    <div class="cardpart">
      <div class="flex_row_center carditem">
        <div class="flex1 flex_col_center">
            <img :src="require('@/assets/images/zy.png')" alt="" style="width: 200px; height: 120px;">
            <div class="cardtitle"> {{i18n('xitongtedian1')}} </div>
        </div>
        <div class="flex1 flex_col_center">
            <img :src="require('@/assets/images/xl.png')" alt="" style="width: 200px; height: 120px;">
            <div class="cardtitle"> {{i18n('xitongtedian2')}} </div>
        </div>
        <div class="flex1 flex_col_center">
            <img :src="require('@/assets/images/gx.png')" alt="" style="width: 200px; height: 120px;">
            <div class="cardtitle"> {{i18n('xitongtedian3')}} </div>
        </div>
      </div>
    </div>

    <div class="totrade">
      <div class="flex_row_center paizhao_main">
          <div> {{i18n('paizhao')}} </div>
          <ul class="paizhao">
            <li>
              <img :src="require('@/assets/images/fb1_1.webp')">
            </li>
            <li>
              <img :src="require('@/assets/images/fb2_1.webp')">
            </li>
            <li>
              <img :src="require('@/assets/images/fb3_1.svg')">
            </li>
            <li>
              <img :src="require('@/assets/images/fb4_1.svg')">
            </li>
          </ul>
      </div>
    </div>
  </div>
  
</template>

<script>
import colorMixin from '@/mixin/colorMixin'
import config from '@/utils/configs'
import { newPriceFormat, classUpOrDown } from '@/utils/filters.js'

export default {
  name: 'homePage',
  mixins: [colorMixin],
  data() {
    return {
      hqlist: this.$store.state.hqlist,
      option_active: 1,
      option_lift: 0,
      hoverIOS: false,
      hoverAndroid: false,
      textIOS: config.appDownloadIos,
      textAndroid: config.appDownloadAndroid,
      logoSrc: require('@/assets/logo.png'),
      randomColor: 'black',
      qrCodeUrl: '', // 存放生成的二维码url
      bannerList: [],
      bannerimgAddr: config.apiAddress,
      fit: "fill",
      newsList: [],
      settime: null,
      flag: false,
    }
  },
  onLoad() {
			// that = this;
			// console.log(this.hqlistQh)
	},
  mounted(){
    
  },
  computed: {
    i18n() {
      return function(name) {
        // index找到对应页面的obj name是onj的key
        return this.$t("home")[name]
      }
		},
    primaryColor() {
      return this.colors['primary']
    },
    alphaColors() {
      return Object.entries(this.colors).filter(item => item[0].indexOf('alpha-') > -1).reverse()
    },
    lightColors() {
      return Object.entries(this.colors).filter(item => item[0].indexOf('light-') > -1)
    },
    shadeColors() {
      return Object.entries(this.colors).filter(item => item[0].indexOf('shade-') > -1)
    },
  },
  filters: {
			contractCode: function (contract) {
				var attr = contract.split("-");
				return attr[0];
			},
			newPriceFormat: function (price, n) {
				return newPriceFormat(price, n);
			},
      classUpOrDown: function (nprice, openprice, useType) {
        return classUpOrDown(nprice, openprice, useType);
      }
	},
  methods:{
    async getBannerhttps(){
      let data = {
        position: 2,
      }
      this.$api.getBanner(data).then((res)=>{
        if(res.data.code == 200){
					this.bannerList = res.data.data.list;
				}
      })
    },
    async getNoticehttps(){
      let lang = localStorage.getItem('lang');

      let data = {
        newstypeid: 21,
        page: 1,
        page_size: 5,
      }
      this.$api.getNewsList(data).then((res)=>{
        if (res.data.code == 200) {
          this.newsList = res.data.data;
          // console.log(this.newsList);
          this.settime = setInterval(this.scrollNews, 3000);
        }
      })
    },
    scrollNews() {
      //通过设置ref获取dom元素
      //console.log(this.$refs.news);
      let newsul = this.$refs.news;
      //ul向上移动一个title
      newsul.style.top = "-4rem";
      //设置一个动画 动画开始
      this.flag = !this.flag;
      //设置个定时器，循环滚动
      setTimeout(() => {
        // 向末尾添加第一条数据
        this.newsList.push(this.newsList[0]);
        //删除第一条数据
        this.newsList.shift();
        //退回到top等于0
        newsul.style.top = "0rem";
        //动画结束
        this.flag = !this.flag;
      }, 500);
    },
    // changeMylist(i){
		// 		this.option_active = i;
		// 		this.option_lift = 0
		// },
    goLift(){
				this.option_lift++;
        console.log(this.option_lift);
				if (this.option_active == 1) {
					if (this.option_lift == 1) {
						this.hqlist = [];
						var list = [...this.$store.state.hqlist];
						list.sort(function (a, b) {
							var num1 = (b.price - b.open)/b.open.toFixed(2);
							var num2 = (a.price - a.open)/a.open.toFixed(2);
							return num1 - num2
						});
						this.hqlist = list;
					} else if(this.option_lift == 2){
						this.hqlist = [];
						var list = [...this.$store.state.hqlist];
						list.sort(function (a, b) {
							var num1 = (b.price - b.open)/b.open.toFixed(2);
							var num2 = (a.price - a.open)/a.open.toFixed(2);
							return num2 - num1
						});
						this.hqlist = list;
					} else {
						this.option_lift = 0
						this.hqlist = [];
						var list = [...this.$store.state.hqlist];
						this.hqlist = list;
					}
				} else {
					// if (this.option_lift == 1) {
					// 	this.hqlistQh = [];
					// 	var list = [...this.$store.state.hqlistQh];
					// 	list.sort(function (a, b) {
					// 		var num1 = (b.price - b.open)/b.open.toFixed(2);
					// 		var num2 = (a.price - a.open)/a.open.toFixed(2);
					// 		return num1 - num2
					// 	});
					// 	this.hqlistQh = list;
					// } else if (this.option_lift == 2) {
					// 	this.hqlistQh = [];
					// 	var list = [...this.$store.state.hqlistQh];
					// 	list.sort(function (a, b) {
					// 		var num1 = (b.price - b.open)/b.open.toFixed(2);
					// 		var num2 = (a.price - a.open)/a.open.toFixed(2);
					// 		return num2 - num1
					// 	});
					// 	this.hqlistQh = list;
					// } else {
					// 	this.option_lift = 0;
					// 	this.hqlistQh = [];
					// 	var list = [...this.$store.state.hqlistQh];
					// 	this.hqlistQh = list;
					// }
				}
			},
      handleTrade(codename) {
        // console.log(codename);
        this.$router.push({name:'contractTradeLayout', params:{codename:codename}});
      },
      handleImageError() {
        // 为了增加鲁棒性，这里使用this.$el.src而不是直接修改属性
        // 适用于Vue的编译器语法，确保在Vue的环境中正确处理DOM元素
        this.$el.src = '../assets/error.png';
      },
  },
  created () {
    this.getBannerhttps();
    this.getNoticehttps();

  },
  beforeDestroy(){
    clearInterval(this.settime);
  },
}
</script>

<style lang="scss">
//新闻样式
  .scrollNew{
      width: 722px;
      margin: 0 auto 10px;
  }

  .news {
    height: 3rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      float: left;
      margin-top: 4px;
      width: 18px;
      height: 18px;
    }
    .animate {
      transition: all 1s linear;
    }
    ul {
      position: relative;
      float: left;
      overflow: hidden;
      height: 2rem;
      li {
        line-height: 2rem;
        margin-left: 2rem;
      }
    }
  }

//banner样式
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
//涨跌幅颜色

  .bg_up{
		background: #088F23;
	}
	.bg_down{
		background: #A21616;
	}
	.color_up{
		color: #088F23;
	}
	.color_down{
		color: #A21616;
	}
	.color_border_up{
		color: #088F23;
	    border-color: #088F23;
	}
	.color_border_down{
		color: #A21616;
		border-color: #A21616;
	}

//表格部分样式

  .table-header {
    border: 1px solid #F3F3F3;
  }

  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    border-bottom: 1px solid #F3F3F3;
    border-left: 1px solid #F3F3F3;
    border-right: 1px solid #F3F3F3;
    height: 60px;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  // .bg-purple {
  //   background: #d3dce6;
  // }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 55px;
    display: flex; 
    align-items: center; 
    justify-content: center;
  }

  .grid-content-header {
    border-radius: 4px;
    min-height: 59px;
    display: flex; 
    align-items: center; 
    justify-content: center;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

//el-card样式

  .box-card:hover {
    -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    z-index: 2;
    border-radius: 2px;
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    z-index: 2;
  }

//底部下载本部分样式
  .image-container {
    /* 使用Flex布局并居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    /* 设置容器的宽高，根据需要调整 */
    width: 100%;
    // height: 100vh; /* 或具体高度，例如351px */
  }

  .IOS-download {
    border: 1px solid #f3f3f3;
    border-radius: 4px; /* 添加圆角边框 */
    height: 60px;
    width: 180px;
    font-size: 12px;
  }

  .android-download {
    border: 1px solid #f3f3f3;
    border-radius: 4px; /* 添加圆角边框 */
    height: 60px;
    width: 180px;
    font-size: 12px;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardpart {
    width: 100%;
    min-width: 1200px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 50px 0 0px;
  }

  .carditem {
    width: 1200px;
    margin: 0 auto;
  }

  .cardpart img {
    width: 160px;
    margin-bottom: 10px;
  }

  .cardtitle {
    font-size: 20px;
    font-weight: 500;
    color: #194386;
    line-height: 100px;
  }

  .flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .flex1 {
    flex: 1;
  }

  .totrade {
    width: 100%;
    min-width: 1200px;
    padding: 50px 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .paizhao_main{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .paizhao_main div{
    font-size: 18px;
    font-weight: 700;
    color: #606266;
  }
  .paizhao li:hover img{
    opacity: 1;
  }

  .paizhao{
    display: flex;
  }
  .paizhao li{
    text-align: center;
    margin-left: 35px;
  }
  .paizhao li img{
    width: 100%;
    opacity: 0;
    transition: 0.3s;
  }
  .paizhao li:nth-child(1){
    width: 115px;
    background:url(@/assets/images/fb1.webp) no-repeat center;
    background-size: 100%;
  }
  .paizhao li:nth-child(2){
    width: 115px;
    background:url(@/assets/images/fb2.webp) no-repeat center;
    background-size: 100%;
  }
  .paizhao li:nth-child(3){
    width: 50px;
     background:url(@/assets/images/fb3.svg) no-repeat center;
    background-size: 100%;
  }
  .paizhao li:nth-child(4){
    width: 50px;
     background:url(@/assets/images/fb4.svg) no-repeat center;
    background-size: 100%;
  }

</style>

