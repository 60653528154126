<template>
    <div>
        <div class="treasureOrder">
        <div class="backpart">
            <div class="flex_row_left back" @click="goBack()">
                <img src="@/assets/images/jt_back.png" alt="">
                <div>coin{{i18n('dingdanxiangqing')}}</div>
            </div>
        </div>
        <div class="content-wrap">
            <div class="flex_row_left changepart flex1">
                <div class="changebtn" :class="productType==1?'ons':''" @click="changeTab(1)">{{i18n('goumai')}}</div>
                <div class="changebtn" :class="productType==2?'ons':''"  @click="changeTab(2)">{{i18n('shuhui')}}</div>
                <div class="changebtn" :class="productType==3?'ons':''" @click="changeTab(3)">{{i18n('shouyi')}}</div>
            </div>
            <div class="nolist" v-if="orderList.length==0">
                <table class="trlist">
                    <tr>
                        <th>{{i18n('chanpinmingcheng')}}</th>
                        <th>{{i18n('goumaijine')}}(USDT)</th>
                         <!-- <th>付款金額(USDT)</th> -->
                        <th>{{i18n('goumaishijian')}}</th>
                    </tr>
                </table>
                <img class="nolistimg" src="@/assets/images/wudingdan.png" alt="">
                <div class="fontSize14 color_666 text_center" style="margin-bottom: 150px;">{{i18n('zanwushujui')}}</div>
            </div>
            <div class="orlistss" v-if="orderList.length>0">
                <table class="trlist">
                    <tr>
                        <th>{{i18n('chanpinmingcheng')}}</th>
                        <th>{{i18n('goumaijine')}}(USDT)</th>
                         <!-- <th>付款金額(USDT)</th> -->
                        <th>{{i18n('goumaishijian')}}</th>
                    </tr>
                    <tr v-for="item in orderList">
                        <td>{{item.productName}}</td>
                        <td class="color_green">{{item.opNumber}}</td>
                        <td>{{item.createTime | formatDate}}</td>
                       
                 </tr>
                </table>
                <div class="text_right" id="orderpaging"></div>
            </div>
        </div>

    </div>
       
    </div>
</template>
<script>


export default {
    data() {
        return {
           productType:1,
           orderList:[]
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getProductOrderList()
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getProductOrderList()
        };
        
    },
    beforeDestroy() {
        
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    methods: {
        changeTab(val){
            this.productType = val;
            this.getProductOrderList()
        },
        goBack(){
            this.$router.go(-1);
        },
        getProductOrderList(){
            let data = {
                product_type: this.productType,
                page: 1,
                page_size: 100
            }
            this.$api.userProductOrderList(data).then((res)=>{
                this.orderList = res.data.data.list
            })
        },
       
        goBuy(){
            let data = {
                product_id: this.proDetails.id,
                product_number: this.type==3?this.buyNum*parseFloat(this.proDetails.productUserMin):this.buyNum,
                is_auto_transfer_agility: 1
            }
            this.$api.doProduct(data).then((res)=>{
                if(res.data.code==200){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.getAllAccountInfo();
                    this.getLcAccountInfo();
                    this.step1Show = false;
                }else{
                    this.$message.error(res.data.msg);
                }
            })
            
        }
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("treasure")[name];
            }
		},
    },
}
</script>
<style scoped>
@import '@/css/treasure.css';
</style> 