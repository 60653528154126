import option_pkg from '@/utils/option_msg_pb.js'
import option_type from '@/utils/option_type_pb.js';
import { baseMethods } from '@/utils/methods.js';
import { hex_md5 } from '@/utils/md5.js';
import { EncodePack } from '@/utils/ByteArray.js';
import store from '@/store'
const teadeOptionsWSReq = { 
    login(account,pwd){
		var message = new proto.option_pkg.C2TSLoginReq();   
		message.setUsername(account);  
		message.setPassword(hex_md5(pwd)); 
		// console.log(message)
		var bytes = message.serializeBinary();
		var bytes_encry=baseMethods.encrypt(bytes)
		var note=EncodePack(bytes_encry,'10001');
		store.dispatch('tradeOptionsWS/sendMessage', note);	
	},
    trade_methods(id,period,num,type){
	    var message = new proto.option_pkg.C2TSOpenReq(); 
	    message.setContractcode(id);  
	    message.setPeriodid(period);  
	    message.setAmount(num);  
	    message.setDirection(type);  
	    var bytes = message.serializeBinary();
	    var bytes_encry=baseMethods.encrypt(bytes);
	    var note=EncodePack(bytes_encry,proto.option_pkg.EnmCmdID.C2TS_OPEN_REQ);
        store.dispatch('tradeOptionsWS/sendMessage', note);	
	},
    getEntrust(){
	    var message = new proto.option_pkg.C2TSEntrustOrderListReq();
	    var bytes = message.serializeBinary();
	    var bytes_encry=baseMethods.encrypt(bytes);
	    var note=EncodePack(bytes_encry,proto.option_pkg.EnmCmdID.C2TS_ENTRUSTORDER_LIST_REQ);
	    store.dispatch('tradeOptionsWS/sendMessage', note);	
	},
    getZqList(){
	    var message = new proto.option_pkg.C2TSPeriodListReq();
	    var bytes = message.serializeBinary();
	    var bytes_encry=baseMethods.encrypt(bytes);
	    var note=EncodePack(bytes_encry,proto.option_pkg.EnmCmdID.C2TS_PERIOD_LIST_REQ);
        store.dispatch('tradeOptionsWS/sendMessage', note);	
	},
	//获取期权买入数量列表
	getmrjeList(){
	    var message = new proto.option_pkg.C2TSAmountListReq();
	    var bytes = message.serializeBinary();
	    var bytes_encry=baseMethods.encrypt(bytes);
	    var note=EncodePack(bytes_encry,proto.option_pkg.EnmCmdID.C2TS_AMOUNT_LIST_REQ);
	    store.dispatch('tradeOptionsWS/sendMessage', note);	
	},
}
export { 
    teadeOptionsWSReq 
};