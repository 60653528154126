/**
 * @fileoverview
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.cts.AutoProfitLossInfo', null, global);
goog.exportSymbol('proto.cts.C2KSKlineListReq', null, global);
goog.exportSymbol('proto.cts.C2TSAddApiReq', null, global);
goog.exportSymbol('proto.cts.C2TSAddConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.C2TSAddFollowReq', null, global);
goog.exportSymbol('proto.cts.C2TSAddUserStrategyReq', null, global);
goog.exportSymbol('proto.cts.C2TSApiListReq', null, global);
goog.exportSymbol('proto.cts.C2TSApplyTraderReq', null, global);
goog.exportSymbol('proto.cts.C2TSAutoProfitLossListReq', null, global);
goog.exportSymbol('proto.cts.C2TSBackHandReq', null, global);
goog.exportSymbol('proto.cts.C2TSCancelReq', null, global);
goog.exportSymbol('proto.cts.C2TSCloseApiReq', null, global);
goog.exportSymbol('proto.cts.C2TSCloseReq', null, global);
goog.exportSymbol('proto.cts.C2TSConditionOrderListReq', null, global);
goog.exportSymbol('proto.cts.C2TSContractListReq', null, global);
goog.exportSymbol('proto.cts.C2TSDealOrderListReq', null, global);
goog.exportSymbol('proto.cts.C2TSDelApiReq', null, global);
goog.exportSymbol('proto.cts.C2TSDelConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.C2TSEntrustOrderListReq', null, global);
goog.exportSymbol('proto.cts.C2TSFollowListReq', null, global);
goog.exportSymbol('proto.cts.C2TSHeartbeatReq', null, global);
goog.exportSymbol('proto.cts.C2TSHoldOrderListReq', null, global);
goog.exportSymbol('proto.cts.C2TSImmediatelyConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.C2TSLeverRateListReq', null, global);
goog.exportSymbol('proto.cts.C2TSLoginReq', null, global);
goog.exportSymbol('proto.cts.C2TSLogoutReq', null, global);
goog.exportSymbol('proto.cts.C2TSModifyFollowReq', null, global);
goog.exportSymbol('proto.cts.C2TSModifyLeverRateReq', null, global);
goog.exportSymbol('proto.cts.C2TSModifyPwdReq', null, global);
goog.exportSymbol('proto.cts.C2TSModifyTraderReq', null, global);
goog.exportSymbol('proto.cts.C2TSModifyUserStrategyReq', null, global);
goog.exportSymbol('proto.cts.C2TSModityApiReq', null, global);
goog.exportSymbol('proto.cts.C2TSModityConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.C2TSOpenApiReq', null, global);
goog.exportSymbol('proto.cts.C2TSOpenReq', null, global);
goog.exportSymbol('proto.cts.C2TSPauseConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.C2TSRunConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.C2TSSelfTraderReq', null, global);
goog.exportSymbol('proto.cts.C2TSSetAutoProfitLossListReq', null, global);
goog.exportSymbol('proto.cts.C2TSSetProfitLossReq', null, global);
goog.exportSymbol('proto.cts.C2TSStrategyListReq', null, global);
goog.exportSymbol('proto.cts.C2TSTraderFollowerListReq', null, global);
goog.exportSymbol('proto.cts.C2TSTraderListReq', null, global);
goog.exportSymbol('proto.cts.C2TSTransferCapitalReq', null, global);
goog.exportSymbol('proto.cts.C2TSUserStrategyListReq', null, global);
goog.exportSymbol('proto.cts.CO2TSAddConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.CO2TSCloseReq', null, global);
goog.exportSymbol('proto.cts.CO2TSConditionOrderListRes', null, global);
goog.exportSymbol('proto.cts.CO2TSModifyLeverRateRes', null, global);
goog.exportSymbol('proto.cts.CO2TSOpenReq', null, global);
goog.exportSymbol('proto.cts.CO2TSSetConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.CO2TSUpdateConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.ConditionOrderInfo', null, global);
goog.exportSymbol('proto.cts.Contract', null, global);
goog.exportSymbol('proto.cts.DealOrder', null, global);
goog.exportSymbol('proto.cts.DealUnit', null, global);
goog.exportSymbol('proto.cts.EStampType', null, global);
goog.exportSymbol('proto.cts.EntrustOrder', null, global);
goog.exportSymbol('proto.cts.FollowInfo', null, global);
goog.exportSymbol('proto.cts.FollowSettlement', null, global);
goog.exportSymbol('proto.cts.FollowerInfo', null, global);
goog.exportSymbol('proto.cts.HoldOrder', null, global);
goog.exportSymbol('proto.cts.KS2CKlineListRet', null, global);
goog.exportSymbol('proto.cts.Kline', null, global);
goog.exportSymbol('proto.cts.LeverRate', null, global);
goog.exportSymbol('proto.cts.PerPrincipalInfo', null, global);
goog.exportSymbol('proto.cts.PositionBrokerage', null, global);
goog.exportSymbol('proto.cts.QC2QSHeartbeatReq', null, global);
goog.exportSymbol('proto.cts.QC2QSLoginReq', null, global);
goog.exportSymbol('proto.cts.QC2QSSubMarketReq', null, global);
goog.exportSymbol('proto.cts.QC2QSUnSubMarketReq', null, global);
goog.exportSymbol('proto.cts.QI2QSCandlestickRes', null, global);
goog.exportSymbol('proto.cts.QI2QSMarketDepthRes', null, global);
goog.exportSymbol('proto.cts.QI2QSMarketDetailRes', null, global);
goog.exportSymbol('proto.cts.QI2QSTradeBboRes', null, global);
goog.exportSymbol('proto.cts.QI2QSTradeDetailRes', null, global);
goog.exportSymbol('proto.cts.QS2CContractListRes', null, global);
goog.exportSymbol('proto.cts.QS2QCHeartbeatRes', null, global);
goog.exportSymbol('proto.cts.QS2QCLoginRet', null, global);
goog.exportSymbol('proto.cts.QS2QCSubMarketRet', null, global);
goog.exportSymbol('proto.cts.QS2QCUnSubMarketRet', null, global);
goog.exportSymbol('proto.cts.QuotePriceQty', null, global);
goog.exportSymbol('proto.cts.StrategyInfo', null, global);
goog.exportSymbol('proto.cts.TI2TSCancelRes', null, global);
goog.exportSymbol('proto.cts.TI2TSCloseRes', null, global);
goog.exportSymbol('proto.cts.TI2TSOpenRes', null, global);
goog.exportSymbol('proto.cts.TI2TSUpdateState', null, global);
goog.exportSymbol('proto.cts.TP2TSBrokerageRes', null, global);
goog.exportSymbol('proto.cts.TP2TSFollowSettlementRes', null, global);
goog.exportSymbol('proto.cts.TP2TSPositionBrokerageRes', null, global);
goog.exportSymbol('proto.cts.TP2TSStrategyInfoRes', null, global);
goog.exportSymbol('proto.cts.TP2TSTraderSettlementRes', null, global);
goog.exportSymbol('proto.cts.TS2BINANTICloseReq', null, global);
goog.exportSymbol('proto.cts.TS2BINANTIOpenReq', null, global);
goog.exportSymbol('proto.cts.TS2CAddApiRes', null, global);
goog.exportSymbol('proto.cts.TS2CAddConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.TS2CAddDealRes', null, global);
goog.exportSymbol('proto.cts.TS2CAddEntrustRes', null, global);
goog.exportSymbol('proto.cts.TS2CAddFollowRes', null, global);
goog.exportSymbol('proto.cts.TS2CAddHoldRes', null, global);
goog.exportSymbol('proto.cts.TS2CAddUserStrategyRes', null, global);
goog.exportSymbol('proto.cts.TS2CApiListRes', null, global);
goog.exportSymbol('proto.cts.TS2CApplyTraderRes', null, global);
goog.exportSymbol('proto.cts.TS2CAutoProfitLossListRes', null, global);
goog.exportSymbol('proto.cts.TS2CBackHandRes', null, global);
goog.exportSymbol('proto.cts.TS2CCancelRes', null, global);
goog.exportSymbol('proto.cts.TS2CCloseRes', null, global);
goog.exportSymbol('proto.cts.TS2CConditionOrderListRes', null, global);
goog.exportSymbol('proto.cts.TS2CContractListRes', null, global);
goog.exportSymbol('proto.cts.TS2CDealOrderListRes', null, global);
goog.exportSymbol('proto.cts.TS2CDelApiRes', null, global);
goog.exportSymbol('proto.cts.TS2CDeleteHoldRes', null, global);
goog.exportSymbol('proto.cts.TS2CEntrustOrderListRes', null, global);
goog.exportSymbol('proto.cts.TS2CFollowListRes', null, global);
goog.exportSymbol('proto.cts.TS2CHeartbeatRes', null, global);
goog.exportSymbol('proto.cts.TS2CHoldOrderListRes', null, global);
goog.exportSymbol('proto.cts.TS2CKickoutRes', null, global);
goog.exportSymbol('proto.cts.TS2CLeverRateListRes', null, global);
goog.exportSymbol('proto.cts.TS2CLoginRes', null, global);
goog.exportSymbol('proto.cts.TS2CModifyFollowRes', null, global);
goog.exportSymbol('proto.cts.TS2CModifyLeverRateRes', null, global);
goog.exportSymbol('proto.cts.TS2CModifyPwdRes', null, global);
goog.exportSymbol('proto.cts.TS2CModifyTraderRes', null, global);
goog.exportSymbol('proto.cts.TS2CModifyUserStrategyRes', null, global);
goog.exportSymbol('proto.cts.TS2COAddConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.TS2COConditionOrderListReq', null, global);
goog.exportSymbol('proto.cts.TS2CODelConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.TS2COImmediatelyConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.TS2COModifyLeverRateReq', null, global);
goog.exportSymbol('proto.cts.TS2COModityConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.TS2COPauseConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.TS2CORunConditionOrderReq', null, global);
goog.exportSymbol('proto.cts.TS2COUpdateConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.TS2COpenRes', null, global);
goog.exportSymbol('proto.cts.TS2CSelfTraderRes', null, global);
goog.exportSymbol('proto.cts.TS2CSetAutoProfitLossListRes', null, global);
goog.exportSymbol('proto.cts.TS2CSetConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.TS2CSetProfitLossRes', null, global);
goog.exportSymbol('proto.cts.TS2CStrategyListRes', null, global);
goog.exportSymbol('proto.cts.TS2CTraderFollowerListRes', null, global);
goog.exportSymbol('proto.cts.TS2CTraderListRes', null, global);
goog.exportSymbol('proto.cts.TS2CTransferCapitalRes', null, global);
goog.exportSymbol('proto.cts.TS2CUpdateApiRes', null, global);
goog.exportSymbol('proto.cts.TS2CUpdateConditionOrderRes', null, global);
goog.exportSymbol('proto.cts.TS2CUpdateEntrustRes', null, global);
goog.exportSymbol('proto.cts.TS2CUpdateHoldRes', null, global);
goog.exportSymbol('proto.cts.TS2CUpdateUserInfoRes', null, global);
goog.exportSymbol('proto.cts.TS2CUserStrategyListRes', null, global);
goog.exportSymbol('proto.cts.TS2HUOBITICloseReq', null, global);
goog.exportSymbol('proto.cts.TS2HUOBITIOpenReq', null, global);
goog.exportSymbol('proto.cts.TS2OKEXTICloseReq', null, global);
goog.exportSymbol('proto.cts.TS2OKEXTIOpenReq', null, global);
goog.exportSymbol('proto.cts.TS2TICancelReq', null, global);
goog.exportSymbol('proto.cts.TS2TICloseReq', null, global);
goog.exportSymbol('proto.cts.TS2TIOpenReq', null, global);
goog.exportSymbol('proto.cts.TS2TPBrokerageReq', null, global);
goog.exportSymbol('proto.cts.TradeDetail', null, global);
goog.exportSymbol('proto.cts.TraderInfo', null, global);
goog.exportSymbol('proto.cts.User', null, global);
goog.exportSymbol('proto.cts.UserApiInfo', null, global);
goog.exportSymbol('proto.cts.UserStrategyInfo', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.User.displayName = 'proto.cts.User';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.User.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.User} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balance: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    marginbalance: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    marginposition: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    marginfrozen: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    marginavailable: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    profitreal: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    profitunreal: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    riskrate: +jspb.Message.getFieldWithDefault(msg, 10, 0.0),
    followbalance: +jspb.Message.getFieldWithDefault(msg, 11, 0.0),
    liquidationprice: +jspb.Message.getFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.User}
 */
proto.cts.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.User;
  return proto.cts.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.User}
 */
proto.cts.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginbalance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginposition(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginfrozen(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginavailable(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitreal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitunreal(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRiskrate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFollowbalance(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLiquidationprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.User} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.User.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.User.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getMarginbalance();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getMarginposition();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getMarginfrozen();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = this.getMarginavailable();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getProfitreal();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getProfitunreal();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = this.getRiskrate();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = this.getFollowbalance();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = this.getLiquidationprice();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.User.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cts.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.User.prototype.setName = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double balance = 3;
 * @return {number}
 */
proto.cts.User.prototype.getBalance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setBalance = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double marginBalance = 4;
 * @return {number}
 */
proto.cts.User.prototype.getMarginbalance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setMarginbalance = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double marginPosition = 5;
 * @return {number}
 */
proto.cts.User.prototype.getMarginposition = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setMarginposition = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double marginFrozen = 6;
 * @return {number}
 */
proto.cts.User.prototype.getMarginfrozen = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setMarginfrozen = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double marginAvailable = 7;
 * @return {number}
 */
proto.cts.User.prototype.getMarginavailable = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setMarginavailable = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double profitReal = 8;
 * @return {number}
 */
proto.cts.User.prototype.getProfitreal = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setProfitreal = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional double profitUnreal = 9;
 * @return {number}
 */
proto.cts.User.prototype.getProfitunreal = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setProfitunreal = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional double riskRate = 10;
 * @return {number}
 */
proto.cts.User.prototype.getRiskrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 10, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setRiskrate = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional double followBalance = 11;
 * @return {number}
 */
proto.cts.User.prototype.getFollowbalance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 11, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setFollowbalance = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional double liquidationPrice = 12;
 * @return {number}
 */
proto.cts.User.prototype.getLiquidationprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 12, 0.0));
};


/** @param {number} value */
proto.cts.User.prototype.setLiquidationprice = function(value) {
  jspb.Message.setField(this, 12, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSLoginReq.displayName = 'proto.cts.C2TSLoginReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSLoginReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSLoginReq}
 */
proto.cts.C2TSLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSLoginReq;
  return proto.cts.C2TSLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSLoginReq}
 */
proto.cts.C2TSLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSLoginReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSLoginReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.cts.C2TSLoginReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.C2TSLoginReq.prototype.setUsername = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.cts.C2TSLoginReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.C2TSLoginReq.prototype.setPassword = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CLoginRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CLoginRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CLoginRes.displayName = 'proto.cts.TS2CLoginRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CLoginRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CLoginRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CLoginRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CLoginRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    openfeerate: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    closefeerate: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    rate: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    user: (f = msg.getUser()) && proto.cts.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CLoginRes}
 */
proto.cts.TS2CLoginRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CLoginRes;
  return proto.cts.TS2CLoginRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CLoginRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CLoginRes}
 */
proto.cts.TS2CLoginRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenfeerate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClosefeerate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    case 7:
      var value = new proto.cts.User;
      reader.readMessage(value,proto.cts.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CLoginRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CLoginRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CLoginRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CLoginRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getOpenfeerate();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getClosefeerate();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = this.getUser();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cts.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CLoginRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CLoginRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.cts.TS2CLoginRes.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CLoginRes.prototype.setToken = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 userType = 3;
 * @return {number}
 */
proto.cts.TS2CLoginRes.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.TS2CLoginRes.prototype.setUsertype = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double openFeeRate = 4;
 * @return {number}
 */
proto.cts.TS2CLoginRes.prototype.getOpenfeerate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.TS2CLoginRes.prototype.setOpenfeerate = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double closeFeeRate = 5;
 * @return {number}
 */
proto.cts.TS2CLoginRes.prototype.getClosefeerate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.TS2CLoginRes.prototype.setClosefeerate = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double rate = 6;
 * @return {number}
 */
proto.cts.TS2CLoginRes.prototype.getRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cts.TS2CLoginRes.prototype.setRate = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional User user = 7;
 * @return {?proto.cts.User}
 */
proto.cts.TS2CLoginRes.prototype.getUser = function() {
  return /** @type{?proto.cts.User} */ (
    jspb.Message.getWrapperField(this, proto.cts.User, 7));
};


/** @param {?proto.cts.User|undefined} value */
proto.cts.TS2CLoginRes.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.cts.TS2CLoginRes.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CLoginRes.prototype.hasUser = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModifyPwdReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModifyPwdReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModifyPwdReq.displayName = 'proto.cts.C2TSModifyPwdReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModifyPwdReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModifyPwdReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModifyPwdReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModifyPwdReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldpassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newpassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModifyPwdReq}
 */
proto.cts.C2TSModifyPwdReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModifyPwdReq;
  return proto.cts.C2TSModifyPwdReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModifyPwdReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModifyPwdReq}
 */
proto.cts.C2TSModifyPwdReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldpassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModifyPwdReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyPwdReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModifyPwdReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyPwdReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOldpassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getNewpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string oldPassword = 1;
 * @return {string}
 */
proto.cts.C2TSModifyPwdReq.prototype.getOldpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.C2TSModifyPwdReq.prototype.setOldpassword = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string newPassword = 2;
 * @return {string}
 */
proto.cts.C2TSModifyPwdReq.prototype.getNewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.C2TSModifyPwdReq.prototype.setNewpassword = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CModifyPwdRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CModifyPwdRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CModifyPwdRes.displayName = 'proto.cts.TS2CModifyPwdRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CModifyPwdRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CModifyPwdRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CModifyPwdRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CModifyPwdRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CModifyPwdRes}
 */
proto.cts.TS2CModifyPwdRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CModifyPwdRes;
  return proto.cts.TS2CModifyPwdRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CModifyPwdRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CModifyPwdRes}
 */
proto.cts.TS2CModifyPwdRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CModifyPwdRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyPwdRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CModifyPwdRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyPwdRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CModifyPwdRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CModifyPwdRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSHeartbeatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSHeartbeatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSHeartbeatReq.displayName = 'proto.cts.C2TSHeartbeatReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSHeartbeatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSHeartbeatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSHeartbeatReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSHeartbeatReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSHeartbeatReq}
 */
proto.cts.C2TSHeartbeatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSHeartbeatReq;
  return proto.cts.C2TSHeartbeatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSHeartbeatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSHeartbeatReq}
 */
proto.cts.C2TSHeartbeatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSHeartbeatReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSHeartbeatReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSHeartbeatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSHeartbeatReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CHeartbeatRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CHeartbeatRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CHeartbeatRes.displayName = 'proto.cts.TS2CHeartbeatRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CHeartbeatRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CHeartbeatRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CHeartbeatRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CHeartbeatRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CHeartbeatRes}
 */
proto.cts.TS2CHeartbeatRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CHeartbeatRes;
  return proto.cts.TS2CHeartbeatRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CHeartbeatRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CHeartbeatRes}
 */
proto.cts.TS2CHeartbeatRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CHeartbeatRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CHeartbeatRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CHeartbeatRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CHeartbeatRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CHeartbeatRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CHeartbeatRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSLogoutReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSLogoutReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSLogoutReq.displayName = 'proto.cts.C2TSLogoutReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSLogoutReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSLogoutReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSLogoutReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSLogoutReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSLogoutReq}
 */
proto.cts.C2TSLogoutReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSLogoutReq;
  return proto.cts.C2TSLogoutReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSLogoutReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSLogoutReq}
 */
proto.cts.C2TSLogoutReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSLogoutReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSLogoutReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSLogoutReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSLogoutReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CKickoutRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CKickoutRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CKickoutRes.displayName = 'proto.cts.TS2CKickoutRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CKickoutRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CKickoutRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CKickoutRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CKickoutRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: msg.getIp_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CKickoutRes}
 */
proto.cts.TS2CKickoutRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CKickoutRes;
  return proto.cts.TS2CKickoutRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CKickoutRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CKickoutRes}
 */
proto.cts.TS2CKickoutRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CKickoutRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CKickoutRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CKickoutRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CKickoutRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getIp_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes ip = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CKickoutRes.prototype.getIp = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes ip = 1;
 * This is a type-conversion wrapper around `getIp()`
 * @return {string}
 */
proto.cts.TS2CKickoutRes.prototype.getIp_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIp()));
};


/**
 * optional bytes ip = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIp()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CKickoutRes.prototype.getIp_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIp()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CKickoutRes.prototype.setIp = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CUpdateUserInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CUpdateUserInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CUpdateUserInfoRes.displayName = 'proto.cts.TS2CUpdateUserInfoRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CUpdateUserInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CUpdateUserInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CUpdateUserInfoRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CUpdateUserInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.cts.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CUpdateUserInfoRes}
 */
proto.cts.TS2CUpdateUserInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CUpdateUserInfoRes;
  return proto.cts.TS2CUpdateUserInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CUpdateUserInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CUpdateUserInfoRes}
 */
proto.cts.TS2CUpdateUserInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.User;
      reader.readMessage(value,proto.cts.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CUpdateUserInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateUserInfoRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CUpdateUserInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateUserInfoRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.cts.User}
 */
proto.cts.TS2CUpdateUserInfoRes.prototype.getUser = function() {
  return /** @type{?proto.cts.User} */ (
    jspb.Message.getWrapperField(this, proto.cts.User, 1));
};


/** @param {?proto.cts.User|undefined} value */
proto.cts.TS2CUpdateUserInfoRes.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.TS2CUpdateUserInfoRes.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CUpdateUserInfoRes.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.Contract.displayName = 'proto.cts.Contract';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.Contract} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contractsize: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    pricetick: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    sort: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.Contract}
 */
proto.cts.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.Contract;
  return proto.cts.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.Contract}
 */
proto.cts.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractsize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricetick(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.Contract} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.Contract.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.Contract.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getContractsize();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getPricetick();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getSort();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.Contract.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.Contract.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.cts.Contract.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.Contract.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.cts.Contract.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.Contract.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double contractSize = 4;
 * @return {number}
 */
proto.cts.Contract.prototype.getContractsize = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.Contract.prototype.setContractsize = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double priceTick = 5;
 * @return {number}
 */
proto.cts.Contract.prototype.getPricetick = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.Contract.prototype.setPricetick = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 sort = 6;
 * @return {number}
 */
proto.cts.Contract.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.Contract.prototype.setSort = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSContractListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSContractListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSContractListReq.displayName = 'proto.cts.C2TSContractListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSContractListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSContractListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSContractListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSContractListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSContractListReq}
 */
proto.cts.C2TSContractListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSContractListReq;
  return proto.cts.C2TSContractListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSContractListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSContractListReq}
 */
proto.cts.C2TSContractListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSContractListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSContractListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSContractListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSContractListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CContractListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CContractListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CContractListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CContractListRes.displayName = 'proto.cts.TS2CContractListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CContractListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CContractListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CContractListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CContractListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CContractListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractList: jspb.Message.toObjectList(msg.getContractList(),
    proto.cts.Contract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CContractListRes}
 */
proto.cts.TS2CContractListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CContractListRes;
  return proto.cts.TS2CContractListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CContractListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CContractListRes}
 */
proto.cts.TS2CContractListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.Contract;
      reader.readMessage(value,proto.cts.Contract.deserializeBinaryFromReader);
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CContractListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CContractListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CContractListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CContractListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Contract contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.Contract>}
 */
proto.cts.TS2CContractListRes.prototype.getContractList = function() {
  return /** @type{!Array.<!proto.cts.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.Contract, 1));
};


/** @param {!Array.<!proto.cts.Contract>} value */
proto.cts.TS2CContractListRes.prototype.setContractList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.Contract}
 */
proto.cts.TS2CContractListRes.prototype.addContract = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.Contract, opt_index);
};


proto.cts.TS2CContractListRes.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.LeverRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.LeverRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.LeverRate.displayName = 'proto.cts.LeverRate';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.LeverRate.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.LeverRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.LeverRate} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.LeverRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leverrate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.LeverRate}
 */
proto.cts.LeverRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.LeverRate;
  return proto.cts.LeverRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.LeverRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.LeverRate}
 */
proto.cts.LeverRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeverrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.LeverRate} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.LeverRate.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.LeverRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.LeverRate.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getLeverrate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string contractCode = 1;
 * @return {string}
 */
proto.cts.LeverRate.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.LeverRate.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 leverRate = 2;
 * @return {number}
 */
proto.cts.LeverRate.prototype.getLeverrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.LeverRate.prototype.setLeverrate = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSLeverRateListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSLeverRateListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSLeverRateListReq.displayName = 'proto.cts.C2TSLeverRateListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSLeverRateListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSLeverRateListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSLeverRateListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSLeverRateListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSLeverRateListReq}
 */
proto.cts.C2TSLeverRateListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSLeverRateListReq;
  return proto.cts.C2TSLeverRateListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSLeverRateListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSLeverRateListReq}
 */
proto.cts.C2TSLeverRateListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSLeverRateListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSLeverRateListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSLeverRateListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSLeverRateListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CLeverRateListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CLeverRateListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CLeverRateListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CLeverRateListRes.displayName = 'proto.cts.TS2CLeverRateListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CLeverRateListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CLeverRateListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CLeverRateListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CLeverRateListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CLeverRateListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    leverrateList: jspb.Message.toObjectList(msg.getLeverrateList(),
    proto.cts.LeverRate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CLeverRateListRes}
 */
proto.cts.TS2CLeverRateListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CLeverRateListRes;
  return proto.cts.TS2CLeverRateListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CLeverRateListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CLeverRateListRes}
 */
proto.cts.TS2CLeverRateListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.LeverRate;
      reader.readMessage(value,proto.cts.LeverRate.deserializeBinaryFromReader);
      msg.addLeverrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CLeverRateListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CLeverRateListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CLeverRateListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CLeverRateListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getLeverrateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.LeverRate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LeverRate leverRate = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.LeverRate>}
 */
proto.cts.TS2CLeverRateListRes.prototype.getLeverrateList = function() {
  return /** @type{!Array.<!proto.cts.LeverRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.LeverRate, 1));
};


/** @param {!Array.<!proto.cts.LeverRate>} value */
proto.cts.TS2CLeverRateListRes.prototype.setLeverrateList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.LeverRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.LeverRate}
 */
proto.cts.TS2CLeverRateListRes.prototype.addLeverrate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.LeverRate, opt_index);
};


proto.cts.TS2CLeverRateListRes.prototype.clearLeverrateList = function() {
  this.setLeverrateList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.HoldOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.HoldOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.HoldOrder.displayName = 'proto.cts.HoldOrder';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.HoldOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.HoldOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.HoldOrder} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.HoldOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    strategyid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    traderuserid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    costopen: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    positionmargin: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    profitrate: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    profit: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 10, 0),
    available: jspb.Message.getFieldWithDefault(msg, 11, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 12, 0),
    profitprice: +jspb.Message.getFieldWithDefault(msg, 13, 0.0),
    lossprice: +jspb.Message.getFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.HoldOrder}
 */
proto.cts.HoldOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.HoldOrder;
  return proto.cts.HoldOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.HoldOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.HoldOrder}
 */
proto.cts.HoldOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStrategyid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTraderuserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCostopen(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionmargin(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitrate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailable(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfitprice(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLossprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.HoldOrder} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.HoldOrder.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.HoldOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.HoldOrder.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getStrategyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getTraderuserid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = this.getCostopen();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = this.getPositionmargin();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getProfitrate();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = this.getAvailable();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = this.getProfitprice();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = this.getLossprice();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 strategyID = 2;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getStrategyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setStrategyid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 traderUserID = 3;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getTraderuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setTraderuserid = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional string symbol = 4;
 * @return {string}
 */
proto.cts.HoldOrder.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cts.HoldOrder.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional string contractCode = 5;
 * @return {string}
 */
proto.cts.HoldOrder.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cts.HoldOrder.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double costOpen = 6;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getCostopen = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setCostopen = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double positionMargin = 7;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getPositionmargin = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setPositionmargin = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double profitRate = 8;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getProfitrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setProfitrate = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional double profit = 9;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional int32 volume = 10;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional int32 available = 11;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getAvailable = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setAvailable = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional int32 direction = 12;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 12, value);
};


/**
 * optional double profitPrice = 13;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getProfitprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 13, 0.0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setProfitprice = function(value) {
  jspb.Message.setField(this, 13, value);
};


/**
 * optional double lossPrice = 14;
 * @return {number}
 */
proto.cts.HoldOrder.prototype.getLossprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 14, 0.0));
};


/** @param {number} value */
proto.cts.HoldOrder.prototype.setLossprice = function(value) {
  jspb.Message.setField(this, 14, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSHoldOrderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSHoldOrderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSHoldOrderListReq.displayName = 'proto.cts.C2TSHoldOrderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSHoldOrderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSHoldOrderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSHoldOrderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSHoldOrderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSHoldOrderListReq}
 */
proto.cts.C2TSHoldOrderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSHoldOrderListReq;
  return proto.cts.C2TSHoldOrderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSHoldOrderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSHoldOrderListReq}
 */
proto.cts.C2TSHoldOrderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSHoldOrderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSHoldOrderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSHoldOrderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSHoldOrderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CHoldOrderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CHoldOrderListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CHoldOrderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CHoldOrderListRes.displayName = 'proto.cts.TS2CHoldOrderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CHoldOrderListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CHoldOrderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CHoldOrderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CHoldOrderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CHoldOrderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.HoldOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CHoldOrderListRes}
 */
proto.cts.TS2CHoldOrderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CHoldOrderListRes;
  return proto.cts.TS2CHoldOrderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CHoldOrderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CHoldOrderListRes}
 */
proto.cts.TS2CHoldOrderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.HoldOrder;
      reader.readMessage(value,proto.cts.HoldOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CHoldOrderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CHoldOrderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CHoldOrderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CHoldOrderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.HoldOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HoldOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.HoldOrder>}
 */
proto.cts.TS2CHoldOrderListRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.HoldOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.HoldOrder, 1));
};


/** @param {!Array.<!proto.cts.HoldOrder>} value */
proto.cts.TS2CHoldOrderListRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.HoldOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.HoldOrder}
 */
proto.cts.TS2CHoldOrderListRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.HoldOrder, opt_index);
};


proto.cts.TS2CHoldOrderListRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.EntrustOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.EntrustOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.EntrustOrder.displayName = 'proto.cts.EntrustOrder';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.EntrustOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.EntrustOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.EntrustOrder} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.EntrustOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    strategyid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    traderuserid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    orderpricetype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 9, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 10, 0),
    levelrate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdate: jspb.Message.getFieldWithDefault(msg, 12, 0),
    tradevolume: jspb.Message.getFieldWithDefault(msg, 13, 0),
    tradeturnover: +jspb.Message.getFieldWithDefault(msg, 14, 0.0),
    fee: +jspb.Message.getFieldWithDefault(msg, 15, 0.0),
    tradeavgprice: +jspb.Message.getFieldWithDefault(msg, 16, 0.0),
    marginfrozen: +jspb.Message.getFieldWithDefault(msg, 17, 0.0),
    profit: +jspb.Message.getFieldWithDefault(msg, 18, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 19, 0),
    orderoperatetype: jspb.Message.getFieldWithDefault(msg, 20, 0),
    canceleddate: jspb.Message.getFieldWithDefault(msg, 21, 0),
    tradedate: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.EntrustOrder}
 */
proto.cts.EntrustOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.EntrustOrder;
  return proto.cts.EntrustOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.EntrustOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.EntrustOrder}
 */
proto.cts.EntrustOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStrategyid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTraderuserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderpricetype(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevelrate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedate(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradevolume(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeturnover(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeavgprice(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginfrozen(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderoperatetype(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCanceleddate(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradedate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.EntrustOrder} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.EntrustOrder.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.EntrustOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.EntrustOrder.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getStrategyid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getTraderuserid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getOrderpricetype();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = this.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = this.getLevelrate();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = this.getCreatedate();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = this.getTradevolume();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = this.getTradeturnover();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = this.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = this.getTradeavgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = this.getMarginfrozen();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = this.getOrderoperatetype();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = this.getCanceleddate();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = this.getTradedate();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 strategyID = 2;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getStrategyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setStrategyid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 traderUserID = 3;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getTraderuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setTraderuserid = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional string symbol = 4;
 * @return {string}
 */
proto.cts.EntrustOrder.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cts.EntrustOrder.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional string contractCode = 5;
 * @return {string}
 */
proto.cts.EntrustOrder.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cts.EntrustOrder.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional int32 orderPriceType = 8;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getOrderpricetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setOrderpricetype = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional int32 direction = 9;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional int32 offset = 10;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setOffset = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional int32 levelRate = 11;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getLevelrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setLevelrate = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional int32 createDate = 12;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getCreatedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setCreatedate = function(value) {
  jspb.Message.setField(this, 12, value);
};


/**
 * optional int32 tradeVolume = 13;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getTradevolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setTradevolume = function(value) {
  jspb.Message.setField(this, 13, value);
};


/**
 * optional double tradeTurnover = 14;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getTradeturnover = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 14, 0.0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setTradeturnover = function(value) {
  jspb.Message.setField(this, 14, value);
};


/**
 * optional double fee = 15;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getFee = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 15, 0.0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setFee = function(value) {
  jspb.Message.setField(this, 15, value);
};


/**
 * optional double tradeAvgPrice = 16;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getTradeavgprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 16, 0.0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setTradeavgprice = function(value) {
  jspb.Message.setField(this, 16, value);
};


/**
 * optional double marginFrozen = 17;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getMarginfrozen = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 17, 0.0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setMarginfrozen = function(value) {
  jspb.Message.setField(this, 17, value);
};


/**
 * optional double profit = 18;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 18, 0.0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 18, value);
};


/**
 * optional int32 status = 19;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 19, value);
};


/**
 * optional int32 orderOperateType = 20;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getOrderoperatetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setOrderoperatetype = function(value) {
  jspb.Message.setField(this, 20, value);
};


/**
 * optional int32 canceledDate = 21;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getCanceleddate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setCanceleddate = function(value) {
  jspb.Message.setField(this, 21, value);
};


/**
 * optional int32 tradeDate = 22;
 * @return {number}
 */
proto.cts.EntrustOrder.prototype.getTradedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.cts.EntrustOrder.prototype.setTradedate = function(value) {
  jspb.Message.setField(this, 22, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSEntrustOrderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSEntrustOrderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSEntrustOrderListReq.displayName = 'proto.cts.C2TSEntrustOrderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSEntrustOrderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSEntrustOrderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSEntrustOrderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSEntrustOrderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSEntrustOrderListReq}
 */
proto.cts.C2TSEntrustOrderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSEntrustOrderListReq;
  return proto.cts.C2TSEntrustOrderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSEntrustOrderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSEntrustOrderListReq}
 */
proto.cts.C2TSEntrustOrderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSEntrustOrderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSEntrustOrderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSEntrustOrderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSEntrustOrderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CEntrustOrderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CEntrustOrderListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CEntrustOrderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CEntrustOrderListRes.displayName = 'proto.cts.TS2CEntrustOrderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CEntrustOrderListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CEntrustOrderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CEntrustOrderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CEntrustOrderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CEntrustOrderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.EntrustOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CEntrustOrderListRes}
 */
proto.cts.TS2CEntrustOrderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CEntrustOrderListRes;
  return proto.cts.TS2CEntrustOrderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CEntrustOrderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CEntrustOrderListRes}
 */
proto.cts.TS2CEntrustOrderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.EntrustOrder;
      reader.readMessage(value,proto.cts.EntrustOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CEntrustOrderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CEntrustOrderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CEntrustOrderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CEntrustOrderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.EntrustOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntrustOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.EntrustOrder>}
 */
proto.cts.TS2CEntrustOrderListRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.EntrustOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.EntrustOrder, 1));
};


/** @param {!Array.<!proto.cts.EntrustOrder>} value */
proto.cts.TS2CEntrustOrderListRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.EntrustOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.EntrustOrder}
 */
proto.cts.TS2CEntrustOrderListRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.EntrustOrder, opt_index);
};


proto.cts.TS2CEntrustOrderListRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.DealOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.DealOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.DealOrder.displayName = 'proto.cts.DealOrder';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.DealOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.DealOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.DealOrder} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.DealOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderentrustid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tradevolume: jspb.Message.getFieldWithDefault(msg, 7, 0),
    tradeprice: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    tradeturnover: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    createdate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    offsetprofitloss: +jspb.Message.getFieldWithDefault(msg, 11, 0.0),
    tradefee: +jspb.Message.getFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.DealOrder}
 */
proto.cts.DealOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.DealOrder;
  return proto.cts.DealOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.DealOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.DealOrder}
 */
proto.cts.DealOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderentrustid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradevolume(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeprice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeturnover(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOffsetprofitloss(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradefee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.DealOrder} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.DealOrder.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.DealOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.DealOrder.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getOrderentrustid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getTradevolume();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = this.getTradeprice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getTradeturnover();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = this.getCreatedate();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = this.getOffsetprofitloss();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = this.getTradefee();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 orderEntrustID = 2;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getOrderentrustid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setOrderentrustid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.cts.DealOrder.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.DealOrder.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional string contractCode = 4;
 * @return {string}
 */
proto.cts.DealOrder.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cts.DealOrder.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 offset = 6;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setOffset = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional int32 tradeVolume = 7;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getTradevolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setTradevolume = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double tradePrice = 8;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getTradeprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setTradeprice = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional double tradeTurnover = 9;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getTradeturnover = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setTradeturnover = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional int32 createDate = 10;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getCreatedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setCreatedate = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional double offsetProfitloss = 11;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getOffsetprofitloss = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 11, 0.0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setOffsetprofitloss = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional double tradeFee = 12;
 * @return {number}
 */
proto.cts.DealOrder.prototype.getTradefee = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 12, 0.0));
};


/** @param {number} value */
proto.cts.DealOrder.prototype.setTradefee = function(value) {
  jspb.Message.setField(this, 12, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSDealOrderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSDealOrderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSDealOrderListReq.displayName = 'proto.cts.C2TSDealOrderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSDealOrderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSDealOrderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSDealOrderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSDealOrderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSDealOrderListReq}
 */
proto.cts.C2TSDealOrderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSDealOrderListReq;
  return proto.cts.C2TSDealOrderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSDealOrderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSDealOrderListReq}
 */
proto.cts.C2TSDealOrderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSDealOrderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSDealOrderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSDealOrderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSDealOrderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CDealOrderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CDealOrderListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CDealOrderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CDealOrderListRes.displayName = 'proto.cts.TS2CDealOrderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CDealOrderListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CDealOrderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CDealOrderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CDealOrderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CDealOrderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.DealOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CDealOrderListRes}
 */
proto.cts.TS2CDealOrderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CDealOrderListRes;
  return proto.cts.TS2CDealOrderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CDealOrderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CDealOrderListRes}
 */
proto.cts.TS2CDealOrderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.DealOrder;
      reader.readMessage(value,proto.cts.DealOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CDealOrderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CDealOrderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CDealOrderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CDealOrderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.DealOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DealOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.DealOrder>}
 */
proto.cts.TS2CDealOrderListRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.DealOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.DealOrder, 1));
};


/** @param {!Array.<!proto.cts.DealOrder>} value */
proto.cts.TS2CDealOrderListRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.DealOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.DealOrder}
 */
proto.cts.TS2CDealOrderListRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.DealOrder, opt_index);
};


proto.cts.TS2CDealOrderListRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.AutoProfitLossInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.AutoProfitLossInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.AutoProfitLossInfo.displayName = 'proto.cts.AutoProfitLossInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.AutoProfitLossInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.AutoProfitLossInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.AutoProfitLossInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.AutoProfitLossInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profit: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    loss: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.AutoProfitLossInfo}
 */
proto.cts.AutoProfitLossInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.AutoProfitLossInfo;
  return proto.cts.AutoProfitLossInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.AutoProfitLossInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.AutoProfitLossInfo}
 */
proto.cts.AutoProfitLossInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLoss(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.AutoProfitLossInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.AutoProfitLossInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.AutoProfitLossInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.AutoProfitLossInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getLoss();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string contractCode = 1;
 * @return {string}
 */
proto.cts.AutoProfitLossInfo.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.AutoProfitLossInfo.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double profit = 2;
 * @return {number}
 */
proto.cts.AutoProfitLossInfo.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.AutoProfitLossInfo.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double loss = 3;
 * @return {number}
 */
proto.cts.AutoProfitLossInfo.prototype.getLoss = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.AutoProfitLossInfo.prototype.setLoss = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSAutoProfitLossListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSAutoProfitLossListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSAutoProfitLossListReq.displayName = 'proto.cts.C2TSAutoProfitLossListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSAutoProfitLossListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSAutoProfitLossListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSAutoProfitLossListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSAutoProfitLossListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSAutoProfitLossListReq}
 */
proto.cts.C2TSAutoProfitLossListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSAutoProfitLossListReq;
  return proto.cts.C2TSAutoProfitLossListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSAutoProfitLossListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSAutoProfitLossListReq}
 */
proto.cts.C2TSAutoProfitLossListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSAutoProfitLossListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAutoProfitLossListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSAutoProfitLossListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAutoProfitLossListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAutoProfitLossListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CAutoProfitLossListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CAutoProfitLossListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAutoProfitLossListRes.displayName = 'proto.cts.TS2CAutoProfitLossListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CAutoProfitLossListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAutoProfitLossListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAutoProfitLossListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAutoProfitLossListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAutoProfitLossListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.AutoProfitLossInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAutoProfitLossListRes}
 */
proto.cts.TS2CAutoProfitLossListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAutoProfitLossListRes;
  return proto.cts.TS2CAutoProfitLossListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAutoProfitLossListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAutoProfitLossListRes}
 */
proto.cts.TS2CAutoProfitLossListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.AutoProfitLossInfo;
      reader.readMessage(value,proto.cts.AutoProfitLossInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAutoProfitLossListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAutoProfitLossListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAutoProfitLossListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAutoProfitLossListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.AutoProfitLossInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutoProfitLossInfo info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.AutoProfitLossInfo>}
 */
proto.cts.TS2CAutoProfitLossListRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.AutoProfitLossInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.AutoProfitLossInfo, 1));
};


/** @param {!Array.<!proto.cts.AutoProfitLossInfo>} value */
proto.cts.TS2CAutoProfitLossListRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.AutoProfitLossInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.AutoProfitLossInfo}
 */
proto.cts.TS2CAutoProfitLossListRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.AutoProfitLossInfo, opt_index);
};


proto.cts.TS2CAutoProfitLossListRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSSetAutoProfitLossListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.C2TSSetAutoProfitLossListReq.repeatedFields_, null);
};
goog.inherits(proto.cts.C2TSSetAutoProfitLossListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSSetAutoProfitLossListReq.displayName = 'proto.cts.C2TSSetAutoProfitLossListReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.C2TSSetAutoProfitLossListReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSSetAutoProfitLossListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSSetAutoProfitLossListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSSetAutoProfitLossListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSSetAutoProfitLossListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.AutoProfitLossInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSSetAutoProfitLossListReq}
 */
proto.cts.C2TSSetAutoProfitLossListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSSetAutoProfitLossListReq;
  return proto.cts.C2TSSetAutoProfitLossListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSSetAutoProfitLossListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSSetAutoProfitLossListReq}
 */
proto.cts.C2TSSetAutoProfitLossListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.AutoProfitLossInfo;
      reader.readMessage(value,proto.cts.AutoProfitLossInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSSetAutoProfitLossListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSSetAutoProfitLossListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSSetAutoProfitLossListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSSetAutoProfitLossListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.AutoProfitLossInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutoProfitLossInfo info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.AutoProfitLossInfo>}
 */
proto.cts.C2TSSetAutoProfitLossListReq.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.AutoProfitLossInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.AutoProfitLossInfo, 1));
};


/** @param {!Array.<!proto.cts.AutoProfitLossInfo>} value */
proto.cts.C2TSSetAutoProfitLossListReq.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.AutoProfitLossInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.AutoProfitLossInfo}
 */
proto.cts.C2TSSetAutoProfitLossListReq.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.AutoProfitLossInfo, opt_index);
};


proto.cts.C2TSSetAutoProfitLossListReq.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CSetAutoProfitLossListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CSetAutoProfitLossListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CSetAutoProfitLossListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CSetAutoProfitLossListRes.displayName = 'proto.cts.TS2CSetAutoProfitLossListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CSetAutoProfitLossListRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CSetAutoProfitLossListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CSetAutoProfitLossListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CSetAutoProfitLossListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    describe: msg.getDescribe_asB64(),
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.AutoProfitLossInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CSetAutoProfitLossListRes}
 */
proto.cts.TS2CSetAutoProfitLossListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CSetAutoProfitLossListRes;
  return proto.cts.TS2CSetAutoProfitLossListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CSetAutoProfitLossListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CSetAutoProfitLossListRes}
 */
proto.cts.TS2CSetAutoProfitLossListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    case 2:
      var value = new proto.cts.AutoProfitLossInfo;
      reader.readMessage(value,proto.cts.AutoProfitLossInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CSetAutoProfitLossListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSetAutoProfitLossListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cts.AutoProfitLossInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes describe = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes describe = 1;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated AutoProfitLossInfo info = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.AutoProfitLossInfo>}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.AutoProfitLossInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.AutoProfitLossInfo, 2));
};


/** @param {!Array.<!proto.cts.AutoProfitLossInfo>} value */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cts.AutoProfitLossInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.AutoProfitLossInfo}
 */
proto.cts.TS2CSetAutoProfitLossListRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cts.AutoProfitLossInfo, opt_index);
};


proto.cts.TS2CSetAutoProfitLossListRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSTransferCapitalReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSTransferCapitalReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSTransferCapitalReq.displayName = 'proto.cts.C2TSTransferCapitalReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSTransferCapitalReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSTransferCapitalReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSTransferCapitalReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSTransferCapitalReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    direction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    volume: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSTransferCapitalReq}
 */
proto.cts.C2TSTransferCapitalReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSTransferCapitalReq;
  return proto.cts.C2TSTransferCapitalReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSTransferCapitalReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSTransferCapitalReq}
 */
proto.cts.C2TSTransferCapitalReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSTransferCapitalReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSTransferCapitalReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSTransferCapitalReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSTransferCapitalReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 direction = 1;
 * @return {number}
 */
proto.cts.C2TSTransferCapitalReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSTransferCapitalReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double volume = 2;
 * @return {number}
 */
proto.cts.C2TSTransferCapitalReq.prototype.getVolume = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.C2TSTransferCapitalReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CTransferCapitalRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CTransferCapitalRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CTransferCapitalRes.displayName = 'proto.cts.TS2CTransferCapitalRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CTransferCapitalRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CTransferCapitalRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CTransferCapitalRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CTransferCapitalRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CTransferCapitalRes}
 */
proto.cts.TS2CTransferCapitalRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CTransferCapitalRes;
  return proto.cts.TS2CTransferCapitalRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CTransferCapitalRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CTransferCapitalRes}
 */
proto.cts.TS2CTransferCapitalRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CTransferCapitalRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CTransferCapitalRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CTransferCapitalRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CTransferCapitalRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CTransferCapitalRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CTransferCapitalRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CTransferCapitalRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CTransferCapitalRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CTransferCapitalRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CTransferCapitalRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSOpenReq.displayName = 'proto.cts.C2TSOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 3, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderpricetype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSOpenReq}
 */
proto.cts.C2TSOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSOpenReq;
  return proto.cts.C2TSOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSOpenReq}
 */
proto.cts.C2TSOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderpricetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getOrderpricetype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string contractCode = 1;
 * @return {string}
 */
proto.cts.C2TSOpenReq.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.C2TSOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.cts.C2TSOpenReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.C2TSOpenReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 volume = 3;
 * @return {number}
 */
proto.cts.C2TSOpenReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.C2TSOpenReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 direction = 4;
 * @return {number}
 */
proto.cts.C2TSOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.C2TSOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 orderPriceType = 5;
 * @return {number}
 */
proto.cts.C2TSOpenReq.prototype.getOrderpricetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.C2TSOpenReq.prototype.setOrderpricetype = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COpenRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COpenRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COpenRes.displayName = 'proto.cts.TS2COpenRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COpenRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COpenRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COpenRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COpenRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COpenRes}
 */
proto.cts.TS2COpenRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COpenRes;
  return proto.cts.TS2COpenRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COpenRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COpenRes}
 */
proto.cts.TS2COpenRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COpenRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COpenRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COpenRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COpenRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2COpenRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COpenRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2COpenRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2COpenRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2COpenRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2COpenRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSCloseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSCloseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSCloseReq.displayName = 'proto.cts.C2TSCloseReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSCloseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSCloseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSCloseReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSCloseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    strategyid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    traderuserid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 5, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 6, 0),
    orderpricetype: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSCloseReq}
 */
proto.cts.C2TSCloseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSCloseReq;
  return proto.cts.C2TSCloseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSCloseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSCloseReq}
 */
proto.cts.C2TSCloseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStrategyid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTraderuserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVolume(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderpricetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSCloseReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSCloseReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSCloseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSCloseReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getStrategyid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getTraderuserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getOrderpricetype();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 strategyID = 1;
 * @return {number}
 */
proto.cts.C2TSCloseReq.prototype.getStrategyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSCloseReq.prototype.setStrategyid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 traderUserID = 2;
 * @return {number}
 */
proto.cts.C2TSCloseReq.prototype.getTraderuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.C2TSCloseReq.prototype.setTraderuserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.cts.C2TSCloseReq.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.C2TSCloseReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.cts.C2TSCloseReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.C2TSCloseReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int64 volume = 5;
 * @return {number}
 */
proto.cts.C2TSCloseReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.C2TSCloseReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 direction = 6;
 * @return {number}
 */
proto.cts.C2TSCloseReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.C2TSCloseReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional int32 orderPriceType = 7;
 * @return {number}
 */
proto.cts.C2TSCloseReq.prototype.getOrderpricetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cts.C2TSCloseReq.prototype.setOrderpricetype = function(value) {
  jspb.Message.setField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CCloseRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CCloseRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CCloseRes.displayName = 'proto.cts.TS2CCloseRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CCloseRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CCloseRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CCloseRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CCloseRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CCloseRes}
 */
proto.cts.TS2CCloseRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CCloseRes;
  return proto.cts.TS2CCloseRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CCloseRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CCloseRes}
 */
proto.cts.TS2CCloseRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CCloseRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CCloseRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CCloseRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CCloseRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CCloseRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CCloseRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CCloseRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CCloseRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CCloseRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CCloseRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSCancelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.C2TSCancelReq.repeatedFields_, null);
};
goog.inherits(proto.cts.C2TSCancelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSCancelReq.displayName = 'proto.cts.C2TSCancelReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.C2TSCancelReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSCancelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSCancelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSCancelReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSCancelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderidList: jspb.Message.getField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSCancelReq}
 */
proto.cts.C2TSCancelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSCancelReq;
  return proto.cts.C2TSCancelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSCancelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSCancelReq}
 */
proto.cts.C2TSCancelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array.<number>} */ (reader.readPackedInt32());
      msg.setOrderidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSCancelReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSCancelReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSCancelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSCancelReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderidList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 orderID = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<number>}
 */
proto.cts.C2TSCancelReq.prototype.getOrderidList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getField(this, 1));
};


/** @param {!Array.<number>} value */
proto.cts.C2TSCancelReq.prototype.setOrderidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cts.C2TSCancelReq.prototype.addOrderid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cts.C2TSCancelReq.prototype.clearOrderidList = function() {
  this.setOrderidList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CCancelRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CCancelRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CCancelRes.displayName = 'proto.cts.TS2CCancelRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CCancelRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CCancelRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CCancelRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CCancelRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CCancelRes}
 */
proto.cts.TS2CCancelRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CCancelRes;
  return proto.cts.TS2CCancelRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CCancelRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CCancelRes}
 */
proto.cts.TS2CCancelRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CCancelRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CCancelRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CCancelRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CCancelRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CCancelRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CCancelRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CCancelRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CCancelRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CCancelRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CCancelRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModifyLeverRateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModifyLeverRateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModifyLeverRateReq.displayName = 'proto.cts.C2TSModifyLeverRateReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModifyLeverRateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModifyLeverRateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModifyLeverRateReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModifyLeverRateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    leverrate: (f = msg.getLeverrate()) && proto.cts.LeverRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModifyLeverRateReq}
 */
proto.cts.C2TSModifyLeverRateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModifyLeverRateReq;
  return proto.cts.C2TSModifyLeverRateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModifyLeverRateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModifyLeverRateReq}
 */
proto.cts.C2TSModifyLeverRateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.LeverRate;
      reader.readMessage(value,proto.cts.LeverRate.deserializeBinaryFromReader);
      msg.setLeverrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModifyLeverRateReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyLeverRateReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModifyLeverRateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyLeverRateReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getLeverrate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.LeverRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional LeverRate leverRate = 1;
 * @return {?proto.cts.LeverRate}
 */
proto.cts.C2TSModifyLeverRateReq.prototype.getLeverrate = function() {
  return /** @type{?proto.cts.LeverRate} */ (
    jspb.Message.getWrapperField(this, proto.cts.LeverRate, 1));
};


/** @param {?proto.cts.LeverRate|undefined} value */
proto.cts.C2TSModifyLeverRateReq.prototype.setLeverrate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSModifyLeverRateReq.prototype.clearLeverrate = function() {
  this.setLeverrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSModifyLeverRateReq.prototype.hasLeverrate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COModifyLeverRateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COModifyLeverRateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COModifyLeverRateReq.displayName = 'proto.cts.TS2COModifyLeverRateReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COModifyLeverRateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COModifyLeverRateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COModifyLeverRateReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COModifyLeverRateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    leverrate: (f = msg.getLeverrate()) && proto.cts.LeverRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COModifyLeverRateReq}
 */
proto.cts.TS2COModifyLeverRateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COModifyLeverRateReq;
  return proto.cts.TS2COModifyLeverRateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COModifyLeverRateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COModifyLeverRateReq}
 */
proto.cts.TS2COModifyLeverRateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    case 3:
      var value = new proto.cts.LeverRate;
      reader.readMessage(value,proto.cts.LeverRate.deserializeBinaryFromReader);
      msg.setLeverrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COModifyLeverRateReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COModifyLeverRateReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COModifyLeverRateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COModifyLeverRateReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getLeverrate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.LeverRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COModifyLeverRateReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COModifyLeverRateReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userConnType = 2;
 * @return {number}
 */
proto.cts.TS2COModifyLeverRateReq.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COModifyLeverRateReq.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional LeverRate leverRate = 3;
 * @return {?proto.cts.LeverRate}
 */
proto.cts.TS2COModifyLeverRateReq.prototype.getLeverrate = function() {
  return /** @type{?proto.cts.LeverRate} */ (
    jspb.Message.getWrapperField(this, proto.cts.LeverRate, 3));
};


/** @param {?proto.cts.LeverRate|undefined} value */
proto.cts.TS2COModifyLeverRateReq.prototype.setLeverrate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2COModifyLeverRateReq.prototype.clearLeverrate = function() {
  this.setLeverrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2COModifyLeverRateReq.prototype.hasLeverrate = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CModifyLeverRateRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CModifyLeverRateRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CModifyLeverRateRes.displayName = 'proto.cts.TS2CModifyLeverRateRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CModifyLeverRateRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CModifyLeverRateRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CModifyLeverRateRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CModifyLeverRateRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    leverrate: (f = msg.getLeverrate()) && proto.cts.LeverRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CModifyLeverRateRes}
 */
proto.cts.TS2CModifyLeverRateRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CModifyLeverRateRes;
  return proto.cts.TS2CModifyLeverRateRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CModifyLeverRateRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CModifyLeverRateRes}
 */
proto.cts.TS2CModifyLeverRateRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = new proto.cts.LeverRate;
      reader.readMessage(value,proto.cts.LeverRate.deserializeBinaryFromReader);
      msg.setLeverrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CModifyLeverRateRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyLeverRateRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CModifyLeverRateRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyLeverRateRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getLeverrate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cts.LeverRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CModifyLeverRateRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CModifyLeverRateRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional LeverRate leverRate = 2;
 * @return {?proto.cts.LeverRate}
 */
proto.cts.TS2CModifyLeverRateRes.prototype.getLeverrate = function() {
  return /** @type{?proto.cts.LeverRate} */ (
    jspb.Message.getWrapperField(this, proto.cts.LeverRate, 2));
};


/** @param {?proto.cts.LeverRate|undefined} value */
proto.cts.TS2CModifyLeverRateRes.prototype.setLeverrate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cts.TS2CModifyLeverRateRes.prototype.clearLeverrate = function() {
  this.setLeverrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CModifyLeverRateRes.prototype.hasLeverrate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSModifyLeverRateRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.CO2TSModifyLeverRateRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSModifyLeverRateRes.displayName = 'proto.cts.CO2TSModifyLeverRateRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSModifyLeverRateRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSModifyLeverRateRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSModifyLeverRateRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    leverrate: (f = msg.getLeverrate()) && proto.cts.LeverRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSModifyLeverRateRes}
 */
proto.cts.CO2TSModifyLeverRateRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSModifyLeverRateRes;
  return proto.cts.CO2TSModifyLeverRateRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSModifyLeverRateRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSModifyLeverRateRes}
 */
proto.cts.CO2TSModifyLeverRateRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    case 4:
      var value = new proto.cts.LeverRate;
      reader.readMessage(value,proto.cts.LeverRate.deserializeBinaryFromReader);
      msg.setLeverrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSModifyLeverRateRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSModifyLeverRateRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getLeverrate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cts.LeverRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSModifyLeverRateRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.CO2TSModifyLeverRateRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 userConnType = 3;
 * @return {number}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.CO2TSModifyLeverRateRes.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional LeverRate leverRate = 4;
 * @return {?proto.cts.LeverRate}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.getLeverrate = function() {
  return /** @type{?proto.cts.LeverRate} */ (
    jspb.Message.getWrapperField(this, proto.cts.LeverRate, 4));
};


/** @param {?proto.cts.LeverRate|undefined} value */
proto.cts.CO2TSModifyLeverRateRes.prototype.setLeverrate = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.cts.CO2TSModifyLeverRateRes.prototype.clearLeverrate = function() {
  this.setLeverrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.CO2TSModifyLeverRateRes.prototype.hasLeverrate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSSetProfitLossReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSSetProfitLossReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSSetProfitLossReq.displayName = 'proto.cts.C2TSSetProfitLossReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSSetProfitLossReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSSetProfitLossReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSSetProfitLossReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSSetProfitLossReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profit: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    loss: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSSetProfitLossReq}
 */
proto.cts.C2TSSetProfitLossReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSSetProfitLossReq;
  return proto.cts.C2TSSetProfitLossReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSSetProfitLossReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSSetProfitLossReq}
 */
proto.cts.C2TSSetProfitLossReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLoss(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSSetProfitLossReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSSetProfitLossReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSSetProfitLossReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSSetProfitLossReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getLoss();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSSetProfitLossReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSSetProfitLossReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double profit = 2;
 * @return {number}
 */
proto.cts.C2TSSetProfitLossReq.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.C2TSSetProfitLossReq.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double loss = 3;
 * @return {number}
 */
proto.cts.C2TSSetProfitLossReq.prototype.getLoss = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.C2TSSetProfitLossReq.prototype.setLoss = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CSetProfitLossRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CSetProfitLossRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CSetProfitLossRes.displayName = 'proto.cts.TS2CSetProfitLossRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CSetProfitLossRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CSetProfitLossRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CSetProfitLossRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CSetProfitLossRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CSetProfitLossRes}
 */
proto.cts.TS2CSetProfitLossRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CSetProfitLossRes;
  return proto.cts.TS2CSetProfitLossRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CSetProfitLossRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CSetProfitLossRes}
 */
proto.cts.TS2CSetProfitLossRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CSetProfitLossRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSetProfitLossRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CSetProfitLossRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSetProfitLossRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CSetProfitLossRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CSetProfitLossRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CSetProfitLossRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CSetProfitLossRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CSetProfitLossRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CSetProfitLossRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSBackHandReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSBackHandReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSBackHandReq.displayName = 'proto.cts.C2TSBackHandReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSBackHandReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSBackHandReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSBackHandReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSBackHandReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSBackHandReq}
 */
proto.cts.C2TSBackHandReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSBackHandReq;
  return proto.cts.C2TSBackHandReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSBackHandReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSBackHandReq}
 */
proto.cts.C2TSBackHandReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSBackHandReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSBackHandReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSBackHandReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSBackHandReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSBackHandReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSBackHandReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CBackHandRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CBackHandRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CBackHandRes.displayName = 'proto.cts.TS2CBackHandRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CBackHandRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CBackHandRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CBackHandRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CBackHandRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CBackHandRes}
 */
proto.cts.TS2CBackHandRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CBackHandRes;
  return proto.cts.TS2CBackHandRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CBackHandRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CBackHandRes}
 */
proto.cts.TS2CBackHandRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CBackHandRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CBackHandRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CBackHandRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CBackHandRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CBackHandRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CBackHandRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CBackHandRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CBackHandRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CBackHandRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CBackHandRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddEntrustRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CAddEntrustRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CAddEntrustRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddEntrustRes.displayName = 'proto.cts.TS2CAddEntrustRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CAddEntrustRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddEntrustRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddEntrustRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddEntrustRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddEntrustRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.EntrustOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddEntrustRes}
 */
proto.cts.TS2CAddEntrustRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddEntrustRes;
  return proto.cts.TS2CAddEntrustRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddEntrustRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddEntrustRes}
 */
proto.cts.TS2CAddEntrustRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.EntrustOrder;
      reader.readMessage(value,proto.cts.EntrustOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddEntrustRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddEntrustRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddEntrustRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddEntrustRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.EntrustOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntrustOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.EntrustOrder>}
 */
proto.cts.TS2CAddEntrustRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.EntrustOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.EntrustOrder, 1));
};


/** @param {!Array.<!proto.cts.EntrustOrder>} value */
proto.cts.TS2CAddEntrustRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.EntrustOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.EntrustOrder}
 */
proto.cts.TS2CAddEntrustRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.EntrustOrder, opt_index);
};


proto.cts.TS2CAddEntrustRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CUpdateEntrustRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CUpdateEntrustRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CUpdateEntrustRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CUpdateEntrustRes.displayName = 'proto.cts.TS2CUpdateEntrustRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CUpdateEntrustRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CUpdateEntrustRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CUpdateEntrustRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CUpdateEntrustRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CUpdateEntrustRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.EntrustOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CUpdateEntrustRes}
 */
proto.cts.TS2CUpdateEntrustRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CUpdateEntrustRes;
  return proto.cts.TS2CUpdateEntrustRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CUpdateEntrustRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CUpdateEntrustRes}
 */
proto.cts.TS2CUpdateEntrustRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.EntrustOrder;
      reader.readMessage(value,proto.cts.EntrustOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CUpdateEntrustRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateEntrustRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CUpdateEntrustRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateEntrustRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.EntrustOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntrustOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.EntrustOrder>}
 */
proto.cts.TS2CUpdateEntrustRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.EntrustOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.EntrustOrder, 1));
};


/** @param {!Array.<!proto.cts.EntrustOrder>} value */
proto.cts.TS2CUpdateEntrustRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.EntrustOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.EntrustOrder}
 */
proto.cts.TS2CUpdateEntrustRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.EntrustOrder, opt_index);
};


proto.cts.TS2CUpdateEntrustRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddHoldRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CAddHoldRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CAddHoldRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddHoldRes.displayName = 'proto.cts.TS2CAddHoldRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CAddHoldRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddHoldRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddHoldRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddHoldRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddHoldRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.HoldOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddHoldRes}
 */
proto.cts.TS2CAddHoldRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddHoldRes;
  return proto.cts.TS2CAddHoldRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddHoldRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddHoldRes}
 */
proto.cts.TS2CAddHoldRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.HoldOrder;
      reader.readMessage(value,proto.cts.HoldOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddHoldRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddHoldRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddHoldRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddHoldRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.HoldOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HoldOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.HoldOrder>}
 */
proto.cts.TS2CAddHoldRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.HoldOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.HoldOrder, 1));
};


/** @param {!Array.<!proto.cts.HoldOrder>} value */
proto.cts.TS2CAddHoldRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.HoldOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.HoldOrder}
 */
proto.cts.TS2CAddHoldRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.HoldOrder, opt_index);
};


proto.cts.TS2CAddHoldRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CUpdateHoldRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CUpdateHoldRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CUpdateHoldRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CUpdateHoldRes.displayName = 'proto.cts.TS2CUpdateHoldRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CUpdateHoldRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CUpdateHoldRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CUpdateHoldRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CUpdateHoldRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CUpdateHoldRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.HoldOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CUpdateHoldRes}
 */
proto.cts.TS2CUpdateHoldRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CUpdateHoldRes;
  return proto.cts.TS2CUpdateHoldRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CUpdateHoldRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CUpdateHoldRes}
 */
proto.cts.TS2CUpdateHoldRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.HoldOrder;
      reader.readMessage(value,proto.cts.HoldOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CUpdateHoldRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateHoldRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CUpdateHoldRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateHoldRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.HoldOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HoldOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.HoldOrder>}
 */
proto.cts.TS2CUpdateHoldRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.HoldOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.HoldOrder, 1));
};


/** @param {!Array.<!proto.cts.HoldOrder>} value */
proto.cts.TS2CUpdateHoldRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.HoldOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.HoldOrder}
 */
proto.cts.TS2CUpdateHoldRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.HoldOrder, opt_index);
};


proto.cts.TS2CUpdateHoldRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CDeleteHoldRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CDeleteHoldRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CDeleteHoldRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CDeleteHoldRes.displayName = 'proto.cts.TS2CDeleteHoldRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CDeleteHoldRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CDeleteHoldRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CDeleteHoldRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CDeleteHoldRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CDeleteHoldRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderidList: jspb.Message.getField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CDeleteHoldRes}
 */
proto.cts.TS2CDeleteHoldRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CDeleteHoldRes;
  return proto.cts.TS2CDeleteHoldRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CDeleteHoldRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CDeleteHoldRes}
 */
proto.cts.TS2CDeleteHoldRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array.<number>} */ (reader.readPackedInt32());
      msg.setOrderidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CDeleteHoldRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CDeleteHoldRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CDeleteHoldRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CDeleteHoldRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderidList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 orderID = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<number>}
 */
proto.cts.TS2CDeleteHoldRes.prototype.getOrderidList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getField(this, 1));
};


/** @param {!Array.<number>} value */
proto.cts.TS2CDeleteHoldRes.prototype.setOrderidList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cts.TS2CDeleteHoldRes.prototype.addOrderid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cts.TS2CDeleteHoldRes.prototype.clearOrderidList = function() {
  this.setOrderidList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddDealRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CAddDealRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CAddDealRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddDealRes.displayName = 'proto.cts.TS2CAddDealRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CAddDealRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddDealRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddDealRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddDealRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddDealRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cts.DealOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddDealRes}
 */
proto.cts.TS2CAddDealRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddDealRes;
  return proto.cts.TS2CAddDealRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddDealRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddDealRes}
 */
proto.cts.TS2CAddDealRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.DealOrder;
      reader.readMessage(value,proto.cts.DealOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddDealRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddDealRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddDealRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddDealRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.DealOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DealOrder order = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.DealOrder>}
 */
proto.cts.TS2CAddDealRes.prototype.getOrderList = function() {
  return /** @type{!Array.<!proto.cts.DealOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.DealOrder, 1));
};


/** @param {!Array.<!proto.cts.DealOrder>} value */
proto.cts.TS2CAddDealRes.prototype.setOrderList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.DealOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.DealOrder}
 */
proto.cts.TS2CAddDealRes.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.DealOrder, opt_index);
};


proto.cts.TS2CAddDealRes.prototype.clearOrderList = function() {
  this.setOrderList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.ConditionOrderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.ConditionOrderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.ConditionOrderInfo.displayName = 'proto.cts.ConditionOrderInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.ConditionOrderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.ConditionOrderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.ConditionOrderInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.ConditionOrderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 6, 0),
    leverrate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pricetype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    compare: jspb.Message.getFieldWithDefault(msg, 10, 0),
    endprice: +jspb.Message.getFieldWithDefault(msg, 11, 0.0),
    endtime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    createtime: jspb.Message.getFieldWithDefault(msg, 14, 0),
    operatetime: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.ConditionOrderInfo}
 */
proto.cts.ConditionOrderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.ConditionOrderInfo;
  return proto.cts.ConditionOrderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.ConditionOrderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.ConditionOrderInfo}
 */
proto.cts.ConditionOrderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeverrate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPricetype(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompare(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndprice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndtime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatetime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.ConditionOrderInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.ConditionOrderInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.ConditionOrderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.ConditionOrderInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getLeverrate();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = this.getType();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = this.getPricetype();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = this.getCompare();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = this.getEndprice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = this.getEndtime();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = this.getCreatetime();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = this.getOperatetime();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.cts.ConditionOrderInfo.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.ConditionOrderInfo.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.cts.ConditionOrderInfo.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.ConditionOrderInfo.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 volume = 4;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 offset = 6;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setOffset = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional int32 leverRate = 7;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getLeverrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setLeverrate = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional int32 type = 8;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setType = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional int32 priceType = 9;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getPricetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setPricetype = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional int32 compare = 10;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getCompare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setCompare = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional double endPrice = 11;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getEndprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 11, 0.0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setEndprice = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional int32 endTime = 12;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setEndtime = function(value) {
  jspb.Message.setField(this, 12, value);
};


/**
 * optional int32 status = 13;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 13, value);
};


/**
 * optional int32 createTime = 14;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getCreatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setCreatetime = function(value) {
  jspb.Message.setField(this, 14, value);
};


/**
 * optional int32 operateTime = 15;
 * @return {number}
 */
proto.cts.ConditionOrderInfo.prototype.getOperatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cts.ConditionOrderInfo.prototype.setOperatetime = function(value) {
  jspb.Message.setField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSConditionOrderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSConditionOrderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSConditionOrderListReq.displayName = 'proto.cts.C2TSConditionOrderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSConditionOrderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSConditionOrderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSConditionOrderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSConditionOrderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSConditionOrderListReq}
 */
proto.cts.C2TSConditionOrderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSConditionOrderListReq;
  return proto.cts.C2TSConditionOrderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSConditionOrderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSConditionOrderListReq}
 */
proto.cts.C2TSConditionOrderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSConditionOrderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSConditionOrderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSConditionOrderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSConditionOrderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COConditionOrderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COConditionOrderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COConditionOrderListReq.displayName = 'proto.cts.TS2COConditionOrderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COConditionOrderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COConditionOrderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COConditionOrderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COConditionOrderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COConditionOrderListReq}
 */
proto.cts.TS2COConditionOrderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COConditionOrderListReq;
  return proto.cts.TS2COConditionOrderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COConditionOrderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COConditionOrderListReq}
 */
proto.cts.TS2COConditionOrderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COConditionOrderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COConditionOrderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COConditionOrderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COConditionOrderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COConditionOrderListReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COConditionOrderListReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userConnType = 2;
 * @return {number}
 */
proto.cts.TS2COConditionOrderListReq.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COConditionOrderListReq.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSAddConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSAddConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSAddConditionOrderReq.displayName = 'proto.cts.C2TSAddConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSAddConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSAddConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSAddConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSAddConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.ConditionOrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSAddConditionOrderReq}
 */
proto.cts.C2TSAddConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSAddConditionOrderReq;
  return proto.cts.C2TSAddConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSAddConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSAddConditionOrderReq}
 */
proto.cts.C2TSAddConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSAddConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSAddConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConditionOrderInfo info = 1;
 * @return {?proto.cts.ConditionOrderInfo}
 */
proto.cts.C2TSAddConditionOrderReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.ConditionOrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.ConditionOrderInfo, 1));
};


/** @param {?proto.cts.ConditionOrderInfo|undefined} value */
proto.cts.C2TSAddConditionOrderReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSAddConditionOrderReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSAddConditionOrderReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COAddConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COAddConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COAddConditionOrderReq.displayName = 'proto.cts.TS2COAddConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COAddConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COAddConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COAddConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COAddConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    info: (f = msg.getInfo()) && proto.cts.ConditionOrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COAddConditionOrderReq}
 */
proto.cts.TS2COAddConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COAddConditionOrderReq;
  return proto.cts.TS2COAddConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COAddConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COAddConditionOrderReq}
 */
proto.cts.TS2COAddConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    case 3:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COAddConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COAddConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COAddConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COAddConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COAddConditionOrderReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COAddConditionOrderReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userConnType = 2;
 * @return {number}
 */
proto.cts.TS2COAddConditionOrderReq.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COAddConditionOrderReq.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional ConditionOrderInfo info = 3;
 * @return {?proto.cts.ConditionOrderInfo}
 */
proto.cts.TS2COAddConditionOrderReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.ConditionOrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.ConditionOrderInfo, 3));
};


/** @param {?proto.cts.ConditionOrderInfo|undefined} value */
proto.cts.TS2COAddConditionOrderReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2COAddConditionOrderReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2COAddConditionOrderReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModityConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModityConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModityConditionOrderReq.displayName = 'proto.cts.C2TSModityConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModityConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModityConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModityConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModityConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.ConditionOrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModityConditionOrderReq}
 */
proto.cts.C2TSModityConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModityConditionOrderReq;
  return proto.cts.C2TSModityConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModityConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModityConditionOrderReq}
 */
proto.cts.C2TSModityConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModityConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModityConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModityConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModityConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConditionOrderInfo info = 1;
 * @return {?proto.cts.ConditionOrderInfo}
 */
proto.cts.C2TSModityConditionOrderReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.ConditionOrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.ConditionOrderInfo, 1));
};


/** @param {?proto.cts.ConditionOrderInfo|undefined} value */
proto.cts.C2TSModityConditionOrderReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSModityConditionOrderReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSModityConditionOrderReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COModityConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COModityConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COModityConditionOrderReq.displayName = 'proto.cts.TS2COModityConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COModityConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COModityConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COModityConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COModityConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    info: (f = msg.getInfo()) && proto.cts.ConditionOrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COModityConditionOrderReq}
 */
proto.cts.TS2COModityConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COModityConditionOrderReq;
  return proto.cts.TS2COModityConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COModityConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COModityConditionOrderReq}
 */
proto.cts.TS2COModityConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    case 3:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COModityConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COModityConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COModityConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COModityConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COModityConditionOrderReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COModityConditionOrderReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userConnType = 2;
 * @return {number}
 */
proto.cts.TS2COModityConditionOrderReq.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COModityConditionOrderReq.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional ConditionOrderInfo info = 3;
 * @return {?proto.cts.ConditionOrderInfo}
 */
proto.cts.TS2COModityConditionOrderReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.ConditionOrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.ConditionOrderInfo, 3));
};


/** @param {?proto.cts.ConditionOrderInfo|undefined} value */
proto.cts.TS2COModityConditionOrderReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2COModityConditionOrderReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2COModityConditionOrderReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSDelConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSDelConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSDelConditionOrderReq.displayName = 'proto.cts.C2TSDelConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSDelConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSDelConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSDelConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSDelConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSDelConditionOrderReq}
 */
proto.cts.C2TSDelConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSDelConditionOrderReq;
  return proto.cts.C2TSDelConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSDelConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSDelConditionOrderReq}
 */
proto.cts.C2TSDelConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSDelConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSDelConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSDelConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSDelConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSDelConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSDelConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CODelConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CODelConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CODelConditionOrderReq.displayName = 'proto.cts.TS2CODelConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CODelConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CODelConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CODelConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CODelConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CODelConditionOrderReq}
 */
proto.cts.TS2CODelConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CODelConditionOrderReq;
  return proto.cts.TS2CODelConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CODelConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CODelConditionOrderReq}
 */
proto.cts.TS2CODelConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CODelConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CODelConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CODelConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CODelConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2CODelConditionOrderReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CODelConditionOrderReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.cts.TS2CODelConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2CODelConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSRunConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSRunConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSRunConditionOrderReq.displayName = 'proto.cts.C2TSRunConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSRunConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSRunConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSRunConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSRunConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSRunConditionOrderReq}
 */
proto.cts.C2TSRunConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSRunConditionOrderReq;
  return proto.cts.C2TSRunConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSRunConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSRunConditionOrderReq}
 */
proto.cts.C2TSRunConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSRunConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSRunConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSRunConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSRunConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSRunConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSRunConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CORunConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CORunConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CORunConditionOrderReq.displayName = 'proto.cts.TS2CORunConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CORunConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CORunConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CORunConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CORunConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CORunConditionOrderReq}
 */
proto.cts.TS2CORunConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CORunConditionOrderReq;
  return proto.cts.TS2CORunConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CORunConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CORunConditionOrderReq}
 */
proto.cts.TS2CORunConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CORunConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CORunConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CORunConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CORunConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2CORunConditionOrderReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CORunConditionOrderReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.cts.TS2CORunConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2CORunConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSPauseConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSPauseConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSPauseConditionOrderReq.displayName = 'proto.cts.C2TSPauseConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSPauseConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSPauseConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSPauseConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSPauseConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSPauseConditionOrderReq}
 */
proto.cts.C2TSPauseConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSPauseConditionOrderReq;
  return proto.cts.C2TSPauseConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSPauseConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSPauseConditionOrderReq}
 */
proto.cts.C2TSPauseConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSPauseConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSPauseConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSPauseConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSPauseConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSPauseConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSPauseConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COPauseConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COPauseConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COPauseConditionOrderReq.displayName = 'proto.cts.TS2COPauseConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COPauseConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COPauseConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COPauseConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COPauseConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COPauseConditionOrderReq}
 */
proto.cts.TS2COPauseConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COPauseConditionOrderReq;
  return proto.cts.TS2COPauseConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COPauseConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COPauseConditionOrderReq}
 */
proto.cts.TS2COPauseConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COPauseConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COPauseConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COPauseConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COPauseConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COPauseConditionOrderReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COPauseConditionOrderReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.cts.TS2COPauseConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COPauseConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSImmediatelyConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSImmediatelyConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSImmediatelyConditionOrderReq.displayName = 'proto.cts.C2TSImmediatelyConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSImmediatelyConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSImmediatelyConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSImmediatelyConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSImmediatelyConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSImmediatelyConditionOrderReq}
 */
proto.cts.C2TSImmediatelyConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSImmediatelyConditionOrderReq;
  return proto.cts.C2TSImmediatelyConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSImmediatelyConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSImmediatelyConditionOrderReq}
 */
proto.cts.C2TSImmediatelyConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSImmediatelyConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSImmediatelyConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSImmediatelyConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSImmediatelyConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSImmediatelyConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSImmediatelyConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COImmediatelyConditionOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COImmediatelyConditionOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COImmediatelyConditionOrderReq.displayName = 'proto.cts.TS2COImmediatelyConditionOrderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COImmediatelyConditionOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COImmediatelyConditionOrderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COImmediatelyConditionOrderReq}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COImmediatelyConditionOrderReq;
  return proto.cts.TS2COImmediatelyConditionOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COImmediatelyConditionOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COImmediatelyConditionOrderReq}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COImmediatelyConditionOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COImmediatelyConditionOrderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COImmediatelyConditionOrderReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.CO2TSOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSOpenReq.displayName = 'proto.cts.CO2TSOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    conditionorderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSOpenReq}
 */
proto.cts.CO2TSOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSOpenReq;
  return proto.cts.CO2TSOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSOpenReq}
 */
proto.cts.CO2TSOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConditionorderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getConditionorderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 conditionOrderID = 1;
 * @return {number}
 */
proto.cts.CO2TSOpenReq.prototype.getConditionorderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSOpenReq.prototype.setConditionorderid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.CO2TSOpenReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.CO2TSOpenReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.cts.CO2TSOpenReq.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.CO2TSOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 volume = 4;
 * @return {number}
 */
proto.cts.CO2TSOpenReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.CO2TSOpenReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.CO2TSOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.CO2TSOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSCloseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.CO2TSCloseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSCloseReq.displayName = 'proto.cts.CO2TSCloseReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSCloseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSCloseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSCloseReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSCloseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    conditionorderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contractcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSCloseReq}
 */
proto.cts.CO2TSCloseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSCloseReq;
  return proto.cts.CO2TSCloseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSCloseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSCloseReq}
 */
proto.cts.CO2TSCloseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConditionorderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSCloseReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSCloseReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSCloseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSCloseReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getConditionorderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getContractcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 conditionOrderID = 1;
 * @return {number}
 */
proto.cts.CO2TSCloseReq.prototype.getConditionorderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSCloseReq.prototype.setConditionorderid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.CO2TSCloseReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.CO2TSCloseReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string contractCode = 3;
 * @return {string}
 */
proto.cts.CO2TSCloseReq.prototype.getContractcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.CO2TSCloseReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 volume = 4;
 * @return {number}
 */
proto.cts.CO2TSCloseReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.CO2TSCloseReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.CO2TSCloseReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.CO2TSCloseReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CConditionOrderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CConditionOrderListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CConditionOrderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CConditionOrderListRes.displayName = 'proto.cts.TS2CConditionOrderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CConditionOrderListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CConditionOrderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CConditionOrderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CConditionOrderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CConditionOrderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.ConditionOrderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CConditionOrderListRes}
 */
proto.cts.TS2CConditionOrderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CConditionOrderListRes;
  return proto.cts.TS2CConditionOrderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CConditionOrderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CConditionOrderListRes}
 */
proto.cts.TS2CConditionOrderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CConditionOrderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CConditionOrderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CConditionOrderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CConditionOrderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionOrderInfo list = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.ConditionOrderInfo>}
 */
proto.cts.TS2CConditionOrderListRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.ConditionOrderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.ConditionOrderInfo, 1));
};


/** @param {!Array.<!proto.cts.ConditionOrderInfo>} value */
proto.cts.TS2CConditionOrderListRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.ConditionOrderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.ConditionOrderInfo}
 */
proto.cts.TS2CConditionOrderListRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.ConditionOrderInfo, opt_index);
};


proto.cts.TS2CConditionOrderListRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSConditionOrderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.CO2TSConditionOrderListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.CO2TSConditionOrderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSConditionOrderListRes.displayName = 'proto.cts.CO2TSConditionOrderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.CO2TSConditionOrderListRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSConditionOrderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSConditionOrderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSConditionOrderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSConditionOrderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.ConditionOrderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSConditionOrderListRes}
 */
proto.cts.CO2TSConditionOrderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSConditionOrderListRes;
  return proto.cts.CO2TSConditionOrderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSConditionOrderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSConditionOrderListRes}
 */
proto.cts.CO2TSConditionOrderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    case 3:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSConditionOrderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSConditionOrderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSConditionOrderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSConditionOrderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.CO2TSConditionOrderListRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSConditionOrderListRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userConnType = 2;
 * @return {number}
 */
proto.cts.CO2TSConditionOrderListRes.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.CO2TSConditionOrderListRes.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * repeated ConditionOrderInfo list = 3;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.ConditionOrderInfo>}
 */
proto.cts.CO2TSConditionOrderListRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.ConditionOrderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.ConditionOrderInfo, 3));
};


/** @param {!Array.<!proto.cts.ConditionOrderInfo>} value */
proto.cts.CO2TSConditionOrderListRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cts.ConditionOrderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.ConditionOrderInfo}
 */
proto.cts.CO2TSConditionOrderListRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cts.ConditionOrderInfo, opt_index);
};


proto.cts.CO2TSConditionOrderListRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CSetConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CSetConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CSetConditionOrderRes.displayName = 'proto.cts.TS2CSetConditionOrderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CSetConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CSetConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CSetConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CSetConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CSetConditionOrderRes}
 */
proto.cts.TS2CSetConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CSetConditionOrderRes;
  return proto.cts.TS2CSetConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CSetConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CSetConditionOrderRes}
 */
proto.cts.TS2CSetConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CSetConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSetConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CSetConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSetConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CSetConditionOrderRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CSetConditionOrderRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes describe = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2CSetConditionOrderRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes describe = 2;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.TS2CSetConditionOrderRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.TS2CSetConditionOrderRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2CSetConditionOrderRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSSetConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.CO2TSSetConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSSetConditionOrderRes.displayName = 'proto.cts.CO2TSSetConditionOrderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSSetConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSSetConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSSetConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userconntype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultcode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    describe: msg.getDescribe_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSSetConditionOrderRes}
 */
proto.cts.CO2TSSetConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSSetConditionOrderRes;
  return proto.cts.CO2TSSetConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSSetConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSSetConditionOrderRes}
 */
proto.cts.CO2TSSetConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserconntype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDescribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSSetConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSSetConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserconntype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getDescribe_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSSetConditionOrderRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userConnType = 2;
 * @return {number}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.getUserconntype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.CO2TSSetConditionOrderRes.prototype.setUserconntype = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 resultCode = 3;
 * @return {number}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.CO2TSSetConditionOrderRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes describe = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.getDescribe = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes describe = 4;
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {string}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.getDescribe_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDescribe()));
};


/**
 * optional bytes describe = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDescribe()`
 * @return {!Uint8Array}
 */
proto.cts.CO2TSSetConditionOrderRes.prototype.getDescribe_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDescribe()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.CO2TSSetConditionOrderRes.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CAddConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddConditionOrderRes.displayName = 'proto.cts.TS2CAddConditionOrderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    list: (f = msg.getList()) && proto.cts.ConditionOrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddConditionOrderRes}
 */
proto.cts.TS2CAddConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddConditionOrderRes;
  return proto.cts.TS2CAddConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddConditionOrderRes}
 */
proto.cts.TS2CAddConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConditionOrderInfo list = 1;
 * @return {?proto.cts.ConditionOrderInfo}
 */
proto.cts.TS2CAddConditionOrderRes.prototype.getList = function() {
  return /** @type{?proto.cts.ConditionOrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.ConditionOrderInfo, 1));
};


/** @param {?proto.cts.ConditionOrderInfo|undefined} value */
proto.cts.TS2CAddConditionOrderRes.prototype.setList = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.TS2CAddConditionOrderRes.prototype.clearList = function() {
  this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CAddConditionOrderRes.prototype.hasList = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSAddConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.CO2TSAddConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSAddConditionOrderRes.displayName = 'proto.cts.CO2TSAddConditionOrderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSAddConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSAddConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSAddConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSAddConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    list: (f = msg.getList()) && proto.cts.ConditionOrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSAddConditionOrderRes}
 */
proto.cts.CO2TSAddConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSAddConditionOrderRes;
  return proto.cts.CO2TSAddConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSAddConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSAddConditionOrderRes}
 */
proto.cts.CO2TSAddConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.setList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSAddConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSAddConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSAddConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSAddConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.CO2TSAddConditionOrderRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSAddConditionOrderRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional ConditionOrderInfo list = 2;
 * @return {?proto.cts.ConditionOrderInfo}
 */
proto.cts.CO2TSAddConditionOrderRes.prototype.getList = function() {
  return /** @type{?proto.cts.ConditionOrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.ConditionOrderInfo, 2));
};


/** @param {?proto.cts.ConditionOrderInfo|undefined} value */
proto.cts.CO2TSAddConditionOrderRes.prototype.setList = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.cts.CO2TSAddConditionOrderRes.prototype.clearList = function() {
  this.setList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.CO2TSAddConditionOrderRes.prototype.hasList = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CUpdateConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CUpdateConditionOrderRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CUpdateConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CUpdateConditionOrderRes.displayName = 'proto.cts.TS2CUpdateConditionOrderRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CUpdateConditionOrderRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CUpdateConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CUpdateConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CUpdateConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CUpdateConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.ConditionOrderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CUpdateConditionOrderRes}
 */
proto.cts.TS2CUpdateConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CUpdateConditionOrderRes;
  return proto.cts.TS2CUpdateConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CUpdateConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CUpdateConditionOrderRes}
 */
proto.cts.TS2CUpdateConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CUpdateConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CUpdateConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionOrderInfo list = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.ConditionOrderInfo>}
 */
proto.cts.TS2CUpdateConditionOrderRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.ConditionOrderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.ConditionOrderInfo, 1));
};


/** @param {!Array.<!proto.cts.ConditionOrderInfo>} value */
proto.cts.TS2CUpdateConditionOrderRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.ConditionOrderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.ConditionOrderInfo}
 */
proto.cts.TS2CUpdateConditionOrderRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.ConditionOrderInfo, opt_index);
};


proto.cts.TS2CUpdateConditionOrderRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.CO2TSUpdateConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.CO2TSUpdateConditionOrderRes.repeatedFields_, null);
};
goog.inherits(proto.cts.CO2TSUpdateConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.CO2TSUpdateConditionOrderRes.displayName = 'proto.cts.CO2TSUpdateConditionOrderRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.CO2TSUpdateConditionOrderRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.CO2TSUpdateConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.CO2TSUpdateConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.CO2TSUpdateConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.ConditionOrderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.CO2TSUpdateConditionOrderRes}
 */
proto.cts.CO2TSUpdateConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.CO2TSUpdateConditionOrderRes;
  return proto.cts.CO2TSUpdateConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.CO2TSUpdateConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.CO2TSUpdateConditionOrderRes}
 */
proto.cts.CO2TSUpdateConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.cts.ConditionOrderInfo;
      reader.readMessage(value,proto.cts.ConditionOrderInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.CO2TSUpdateConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSUpdateConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cts.ConditionOrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated ConditionOrderInfo list = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.ConditionOrderInfo>}
 */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.ConditionOrderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.ConditionOrderInfo, 2));
};


/** @param {!Array.<!proto.cts.ConditionOrderInfo>} value */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cts.ConditionOrderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.ConditionOrderInfo}
 */
proto.cts.CO2TSUpdateConditionOrderRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cts.ConditionOrderInfo, opt_index);
};


proto.cts.CO2TSUpdateConditionOrderRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2COUpdateConditionOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2COUpdateConditionOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2COUpdateConditionOrderRes.displayName = 'proto.cts.TS2COUpdateConditionOrderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2COUpdateConditionOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2COUpdateConditionOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2COUpdateConditionOrderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2COUpdateConditionOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conditionorderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorcode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2COUpdateConditionOrderRes}
 */
proto.cts.TS2COUpdateConditionOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2COUpdateConditionOrderRes;
  return proto.cts.TS2COUpdateConditionOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2COUpdateConditionOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2COUpdateConditionOrderRes}
 */
proto.cts.TS2COUpdateConditionOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConditionorderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2COUpdateConditionOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COUpdateConditionOrderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2COUpdateConditionOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2COUpdateConditionOrderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getConditionorderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getErrorcode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2COUpdateConditionOrderRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2COUpdateConditionOrderRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 conditionOrderID = 2;
 * @return {number}
 */
proto.cts.TS2COUpdateConditionOrderRes.prototype.getConditionorderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2COUpdateConditionOrderRes.prototype.setConditionorderid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 errorCode = 3;
 * @return {number}
 */
proto.cts.TS2COUpdateConditionOrderRes.prototype.getErrorcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.TS2COUpdateConditionOrderRes.prototype.setErrorcode = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.UserApiInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.UserApiInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.UserApiInfo.displayName = 'proto.cts.UserApiInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.UserApiInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.UserApiInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.UserApiInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.UserApiInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    apitype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accesskey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    secretkey: jspb.Message.getFieldWithDefault(msg, 6, ""),
    passphrase: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createtime: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.UserApiInfo}
 */
proto.cts.UserApiInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.UserApiInfo;
  return proto.cts.UserApiInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.UserApiInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.UserApiInfo}
 */
proto.cts.UserApiInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApitype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesskey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretkey(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassphrase(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.UserApiInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.UserApiInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.UserApiInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.UserApiInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getApitype();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = this.getAccesskey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = this.getSecretkey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = this.getPassphrase();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = this.getCreatetime();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.UserApiInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.UserApiInfo.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 apiType = 2;
 * @return {number}
 */
proto.cts.UserApiInfo.prototype.getApitype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.UserApiInfo.prototype.setApitype = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.cts.UserApiInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.UserApiInfo.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.cts.UserApiInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cts.UserApiInfo.prototype.setName = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional string accessKey = 5;
 * @return {string}
 */
proto.cts.UserApiInfo.prototype.getAccesskey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cts.UserApiInfo.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional string secretKey = 6;
 * @return {string}
 */
proto.cts.UserApiInfo.prototype.getSecretkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cts.UserApiInfo.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional string passPhrase = 7;
 * @return {string}
 */
proto.cts.UserApiInfo.prototype.getPassphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cts.UserApiInfo.prototype.setPassphrase = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional int32 createTime = 8;
 * @return {number}
 */
proto.cts.UserApiInfo.prototype.getCreatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cts.UserApiInfo.prototype.setCreatetime = function(value) {
  jspb.Message.setField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSApiListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSApiListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSApiListReq.displayName = 'proto.cts.C2TSApiListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSApiListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSApiListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSApiListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSApiListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSApiListReq}
 */
proto.cts.C2TSApiListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSApiListReq;
  return proto.cts.C2TSApiListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSApiListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSApiListReq}
 */
proto.cts.C2TSApiListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSApiListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSApiListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSApiListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSApiListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CApiListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CApiListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CApiListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CApiListRes.displayName = 'proto.cts.TS2CApiListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CApiListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CApiListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CApiListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CApiListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CApiListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.UserApiInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CApiListRes}
 */
proto.cts.TS2CApiListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CApiListRes;
  return proto.cts.TS2CApiListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CApiListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CApiListRes}
 */
proto.cts.TS2CApiListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserApiInfo;
      reader.readMessage(value,proto.cts.UserApiInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CApiListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CApiListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CApiListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CApiListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.UserApiInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserApiInfo list = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.UserApiInfo>}
 */
proto.cts.TS2CApiListRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.UserApiInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.UserApiInfo, 1));
};


/** @param {!Array.<!proto.cts.UserApiInfo>} value */
proto.cts.TS2CApiListRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.UserApiInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.UserApiInfo}
 */
proto.cts.TS2CApiListRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.UserApiInfo, opt_index);
};


proto.cts.TS2CApiListRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSAddApiReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSAddApiReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSAddApiReq.displayName = 'proto.cts.C2TSAddApiReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSAddApiReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSAddApiReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSAddApiReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSAddApiReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.UserApiInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSAddApiReq}
 */
proto.cts.C2TSAddApiReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSAddApiReq;
  return proto.cts.C2TSAddApiReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSAddApiReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSAddApiReq}
 */
proto.cts.C2TSAddApiReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserApiInfo;
      reader.readMessage(value,proto.cts.UserApiInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSAddApiReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddApiReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSAddApiReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddApiReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.UserApiInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserApiInfo info = 1;
 * @return {?proto.cts.UserApiInfo}
 */
proto.cts.C2TSAddApiReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserApiInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserApiInfo, 1));
};


/** @param {?proto.cts.UserApiInfo|undefined} value */
proto.cts.C2TSAddApiReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSAddApiReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSAddApiReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddApiRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CAddApiRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddApiRes.displayName = 'proto.cts.TS2CAddApiRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddApiRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddApiRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddApiRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddApiRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.UserApiInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddApiRes}
 */
proto.cts.TS2CAddApiRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddApiRes;
  return proto.cts.TS2CAddApiRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddApiRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddApiRes}
 */
proto.cts.TS2CAddApiRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserApiInfo;
      reader.readMessage(value,proto.cts.UserApiInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddApiRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddApiRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddApiRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddApiRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.UserApiInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserApiInfo info = 1;
 * @return {?proto.cts.UserApiInfo}
 */
proto.cts.TS2CAddApiRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserApiInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserApiInfo, 1));
};


/** @param {?proto.cts.UserApiInfo|undefined} value */
proto.cts.TS2CAddApiRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.TS2CAddApiRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CAddApiRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModityApiReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModityApiReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModityApiReq.displayName = 'proto.cts.C2TSModityApiReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModityApiReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModityApiReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModityApiReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModityApiReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.UserApiInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModityApiReq}
 */
proto.cts.C2TSModityApiReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModityApiReq;
  return proto.cts.C2TSModityApiReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModityApiReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModityApiReq}
 */
proto.cts.C2TSModityApiReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserApiInfo;
      reader.readMessage(value,proto.cts.UserApiInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModityApiReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModityApiReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModityApiReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModityApiReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.UserApiInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserApiInfo info = 1;
 * @return {?proto.cts.UserApiInfo}
 */
proto.cts.C2TSModityApiReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserApiInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserApiInfo, 1));
};


/** @param {?proto.cts.UserApiInfo|undefined} value */
proto.cts.C2TSModityApiReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSModityApiReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSModityApiReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CUpdateApiRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CUpdateApiRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CUpdateApiRes.displayName = 'proto.cts.TS2CUpdateApiRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CUpdateApiRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CUpdateApiRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CUpdateApiRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CUpdateApiRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.UserApiInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CUpdateApiRes}
 */
proto.cts.TS2CUpdateApiRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CUpdateApiRes;
  return proto.cts.TS2CUpdateApiRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CUpdateApiRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CUpdateApiRes}
 */
proto.cts.TS2CUpdateApiRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserApiInfo;
      reader.readMessage(value,proto.cts.UserApiInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CUpdateApiRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateApiRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CUpdateApiRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUpdateApiRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.UserApiInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserApiInfo info = 1;
 * @return {?proto.cts.UserApiInfo}
 */
proto.cts.TS2CUpdateApiRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserApiInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserApiInfo, 1));
};


/** @param {?proto.cts.UserApiInfo|undefined} value */
proto.cts.TS2CUpdateApiRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.TS2CUpdateApiRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CUpdateApiRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSDelApiReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSDelApiReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSDelApiReq.displayName = 'proto.cts.C2TSDelApiReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSDelApiReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSDelApiReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSDelApiReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSDelApiReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSDelApiReq}
 */
proto.cts.C2TSDelApiReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSDelApiReq;
  return proto.cts.C2TSDelApiReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSDelApiReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSDelApiReq}
 */
proto.cts.C2TSDelApiReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSDelApiReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSDelApiReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSDelApiReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSDelApiReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSDelApiReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSDelApiReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CDelApiRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CDelApiRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CDelApiRes.displayName = 'proto.cts.TS2CDelApiRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CDelApiRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CDelApiRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CDelApiRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CDelApiRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CDelApiRes}
 */
proto.cts.TS2CDelApiRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CDelApiRes;
  return proto.cts.TS2CDelApiRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CDelApiRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CDelApiRes}
 */
proto.cts.TS2CDelApiRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CDelApiRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CDelApiRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CDelApiRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CDelApiRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.TS2CDelApiRes.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CDelApiRes.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSOpenApiReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSOpenApiReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSOpenApiReq.displayName = 'proto.cts.C2TSOpenApiReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSOpenApiReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSOpenApiReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSOpenApiReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSOpenApiReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSOpenApiReq}
 */
proto.cts.C2TSOpenApiReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSOpenApiReq;
  return proto.cts.C2TSOpenApiReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSOpenApiReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSOpenApiReq}
 */
proto.cts.C2TSOpenApiReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSOpenApiReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSOpenApiReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSOpenApiReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSOpenApiReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSOpenApiReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSOpenApiReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSCloseApiReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSCloseApiReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSCloseApiReq.displayName = 'proto.cts.C2TSCloseApiReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSCloseApiReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSCloseApiReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSCloseApiReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSCloseApiReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSCloseApiReq}
 */
proto.cts.C2TSCloseApiReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSCloseApiReq;
  return proto.cts.C2TSCloseApiReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSCloseApiReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSCloseApiReq}
 */
proto.cts.C2TSCloseApiReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSCloseApiReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSCloseApiReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSCloseApiReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSCloseApiReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.C2TSCloseApiReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSCloseApiReq.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.PerPrincipalInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.PerPrincipalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.PerPrincipalInfo.displayName = 'proto.cts.PerPrincipalInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.PerPrincipalInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.PerPrincipalInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.PerPrincipalInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.PerPrincipalInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    principal: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.PerPrincipalInfo}
 */
proto.cts.PerPrincipalInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.PerPrincipalInfo;
  return proto.cts.PerPrincipalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.PerPrincipalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.PerPrincipalInfo}
 */
proto.cts.PerPrincipalInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrincipal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.PerPrincipalInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.PerPrincipalInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.PerPrincipalInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.PerPrincipalInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getPrincipal();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional double principal = 1;
 * @return {number}
 */
proto.cts.PerPrincipalInfo.prototype.getPrincipal = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.cts.PerPrincipalInfo.prototype.setPrincipal = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.cts.PerPrincipalInfo.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.PerPrincipalInfo.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.StrategyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.StrategyInfo.repeatedFields_, null);
};
goog.inherits(proto.cts.StrategyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.StrategyInfo.displayName = 'proto.cts.StrategyInfo';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.StrategyInfo.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.StrategyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.StrategyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.StrategyInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.StrategyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    victoryrate: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    profit: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    lastdayprofitrate: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    yearprofitrate: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    maxdrawdownrate: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    describe: jspb.Message.getFieldWithDefault(msg, 8, ""),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.PerPrincipalInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.StrategyInfo}
 */
proto.cts.StrategyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.StrategyInfo;
  return proto.cts.StrategyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.StrategyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.StrategyInfo}
 */
proto.cts.StrategyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVictoryrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastdayprofitrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setYearprofitrate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxdrawdownrate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescribe(value);
      break;
    case 9:
      var value = new proto.cts.PerPrincipalInfo;
      reader.readMessage(value,proto.cts.PerPrincipalInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.StrategyInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.StrategyInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.StrategyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.StrategyInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getVictoryrate();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getLastdayprofitrate();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getYearprofitrate();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = this.getMaxdrawdownrate();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getDescribe();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.cts.PerPrincipalInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cts.StrategyInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.StrategyInfo.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cts.StrategyInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.StrategyInfo.prototype.setName = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double victoryRate = 3;
 * @return {number}
 */
proto.cts.StrategyInfo.prototype.getVictoryrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.StrategyInfo.prototype.setVictoryrate = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double profit = 4;
 * @return {number}
 */
proto.cts.StrategyInfo.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.StrategyInfo.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double lastDayProfitRate = 5;
 * @return {number}
 */
proto.cts.StrategyInfo.prototype.getLastdayprofitrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.StrategyInfo.prototype.setLastdayprofitrate = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double yearProfitRate = 6;
 * @return {number}
 */
proto.cts.StrategyInfo.prototype.getYearprofitrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cts.StrategyInfo.prototype.setYearprofitrate = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double maxDrawdownRate = 7;
 * @return {number}
 */
proto.cts.StrategyInfo.prototype.getMaxdrawdownrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.StrategyInfo.prototype.setMaxdrawdownrate = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional string describe = 8;
 * @return {string}
 */
proto.cts.StrategyInfo.prototype.getDescribe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cts.StrategyInfo.prototype.setDescribe = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * repeated PerPrincipalInfo list = 9;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.PerPrincipalInfo>}
 */
proto.cts.StrategyInfo.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.PerPrincipalInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.PerPrincipalInfo, 9));
};


/** @param {!Array.<!proto.cts.PerPrincipalInfo>} value */
proto.cts.StrategyInfo.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.cts.PerPrincipalInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.PerPrincipalInfo}
 */
proto.cts.StrategyInfo.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.cts.PerPrincipalInfo, opt_index);
};


proto.cts.StrategyInfo.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSStrategyListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSStrategyListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSStrategyListReq.displayName = 'proto.cts.C2TSStrategyListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSStrategyListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSStrategyListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSStrategyListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSStrategyListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSStrategyListReq}
 */
proto.cts.C2TSStrategyListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSStrategyListReq;
  return proto.cts.C2TSStrategyListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSStrategyListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSStrategyListReq}
 */
proto.cts.C2TSStrategyListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSStrategyListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSStrategyListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSStrategyListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSStrategyListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CStrategyListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CStrategyListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CStrategyListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CStrategyListRes.displayName = 'proto.cts.TS2CStrategyListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CStrategyListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CStrategyListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CStrategyListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CStrategyListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CStrategyListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.StrategyInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CStrategyListRes}
 */
proto.cts.TS2CStrategyListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CStrategyListRes;
  return proto.cts.TS2CStrategyListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CStrategyListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CStrategyListRes}
 */
proto.cts.TS2CStrategyListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.StrategyInfo;
      reader.readMessage(value,proto.cts.StrategyInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CStrategyListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CStrategyListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CStrategyListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CStrategyListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.StrategyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StrategyInfo list = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.StrategyInfo>}
 */
proto.cts.TS2CStrategyListRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.StrategyInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.StrategyInfo, 1));
};


/** @param {!Array.<!proto.cts.StrategyInfo>} value */
proto.cts.TS2CStrategyListRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.StrategyInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.StrategyInfo}
 */
proto.cts.TS2CStrategyListRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.StrategyInfo, opt_index);
};


proto.cts.TS2CStrategyListRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.UserStrategyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.UserStrategyInfo.repeatedFields_, null);
};
goog.inherits(proto.cts.UserStrategyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.UserStrategyInfo.displayName = 'proto.cts.UserStrategyInfo';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.UserStrategyInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.UserStrategyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.UserStrategyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.UserStrategyInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.UserStrategyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    strategyid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractidList: jspb.Message.getField(msg, 2),
    rate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userapiid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    profit: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.UserStrategyInfo}
 */
proto.cts.UserStrategyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.UserStrategyInfo;
  return proto.cts.UserStrategyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.UserStrategyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.UserStrategyInfo}
 */
proto.cts.UserStrategyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStrategyid(value);
      break;
    case 2:
      var value = /** @type {!Array.<number>} */ (reader.readPackedInt32());
      msg.setContractidList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserapiid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.UserStrategyInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.UserStrategyInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.UserStrategyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.UserStrategyInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getStrategyid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getContractidList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = this.getRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getUserapiid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 strategyID = 1;
 * @return {number}
 */
proto.cts.UserStrategyInfo.prototype.getStrategyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.UserStrategyInfo.prototype.setStrategyid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated int32 contractID = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<number>}
 */
proto.cts.UserStrategyInfo.prototype.getContractidList = function() {
  return /** @type {!Array.<number>} */ (jspb.Message.getField(this, 2));
};


/** @param {!Array.<number>} value */
proto.cts.UserStrategyInfo.prototype.setContractidList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cts.UserStrategyInfo.prototype.addContractid = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cts.UserStrategyInfo.prototype.clearContractidList = function() {
  this.setContractidList([]);
};


/**
 * optional int32 rate = 3;
 * @return {number}
 */
proto.cts.UserStrategyInfo.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.UserStrategyInfo.prototype.setRate = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 userApiID = 4;
 * @return {number}
 */
proto.cts.UserStrategyInfo.prototype.getUserapiid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.UserStrategyInfo.prototype.setUserapiid = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double profit = 5;
 * @return {number}
 */
proto.cts.UserStrategyInfo.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.UserStrategyInfo.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 status = 6;
 * @return {number}
 */
proto.cts.UserStrategyInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.UserStrategyInfo.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSUserStrategyListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSUserStrategyListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSUserStrategyListReq.displayName = 'proto.cts.C2TSUserStrategyListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSUserStrategyListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSUserStrategyListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSUserStrategyListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSUserStrategyListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSUserStrategyListReq}
 */
proto.cts.C2TSUserStrategyListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSUserStrategyListReq;
  return proto.cts.C2TSUserStrategyListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSUserStrategyListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSUserStrategyListReq}
 */
proto.cts.C2TSUserStrategyListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSUserStrategyListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSUserStrategyListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSUserStrategyListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSUserStrategyListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CUserStrategyListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CUserStrategyListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CUserStrategyListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CUserStrategyListRes.displayName = 'proto.cts.TS2CUserStrategyListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CUserStrategyListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CUserStrategyListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CUserStrategyListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CUserStrategyListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CUserStrategyListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.UserStrategyInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CUserStrategyListRes}
 */
proto.cts.TS2CUserStrategyListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CUserStrategyListRes;
  return proto.cts.TS2CUserStrategyListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CUserStrategyListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CUserStrategyListRes}
 */
proto.cts.TS2CUserStrategyListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserStrategyInfo;
      reader.readMessage(value,proto.cts.UserStrategyInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CUserStrategyListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUserStrategyListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CUserStrategyListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CUserStrategyListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.UserStrategyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserStrategyInfo list = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.UserStrategyInfo>}
 */
proto.cts.TS2CUserStrategyListRes.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.UserStrategyInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.UserStrategyInfo, 1));
};


/** @param {!Array.<!proto.cts.UserStrategyInfo>} value */
proto.cts.TS2CUserStrategyListRes.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.UserStrategyInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.UserStrategyInfo}
 */
proto.cts.TS2CUserStrategyListRes.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.UserStrategyInfo, opt_index);
};


proto.cts.TS2CUserStrategyListRes.prototype.clearListList = function() {
  this.setListList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSAddUserStrategyReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSAddUserStrategyReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSAddUserStrategyReq.displayName = 'proto.cts.C2TSAddUserStrategyReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSAddUserStrategyReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSAddUserStrategyReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSAddUserStrategyReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSAddUserStrategyReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.UserStrategyInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSAddUserStrategyReq}
 */
proto.cts.C2TSAddUserStrategyReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSAddUserStrategyReq;
  return proto.cts.C2TSAddUserStrategyReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSAddUserStrategyReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSAddUserStrategyReq}
 */
proto.cts.C2TSAddUserStrategyReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserStrategyInfo;
      reader.readMessage(value,proto.cts.UserStrategyInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSAddUserStrategyReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddUserStrategyReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSAddUserStrategyReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddUserStrategyReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.UserStrategyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserStrategyInfo info = 1;
 * @return {?proto.cts.UserStrategyInfo}
 */
proto.cts.C2TSAddUserStrategyReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserStrategyInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserStrategyInfo, 1));
};


/** @param {?proto.cts.UserStrategyInfo|undefined} value */
proto.cts.C2TSAddUserStrategyReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSAddUserStrategyReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSAddUserStrategyReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddUserStrategyRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CAddUserStrategyRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddUserStrategyRes.displayName = 'proto.cts.TS2CAddUserStrategyRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddUserStrategyRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddUserStrategyRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddUserStrategyRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddUserStrategyRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.cts.UserStrategyInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddUserStrategyRes}
 */
proto.cts.TS2CAddUserStrategyRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddUserStrategyRes;
  return proto.cts.TS2CAddUserStrategyRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddUserStrategyRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddUserStrategyRes}
 */
proto.cts.TS2CAddUserStrategyRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    case 3:
      var value = new proto.cts.UserStrategyInfo;
      reader.readMessage(value,proto.cts.UserStrategyInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddUserStrategyRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddUserStrategyRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddUserStrategyRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddUserStrategyRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.UserStrategyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CAddUserStrategyRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CAddUserStrategyRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.TS2CAddUserStrategyRes.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CAddUserStrategyRes.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional UserStrategyInfo info = 3;
 * @return {?proto.cts.UserStrategyInfo}
 */
proto.cts.TS2CAddUserStrategyRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserStrategyInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserStrategyInfo, 3));
};


/** @param {?proto.cts.UserStrategyInfo|undefined} value */
proto.cts.TS2CAddUserStrategyRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2CAddUserStrategyRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CAddUserStrategyRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModifyUserStrategyReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModifyUserStrategyReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModifyUserStrategyReq.displayName = 'proto.cts.C2TSModifyUserStrategyReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModifyUserStrategyReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModifyUserStrategyReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModifyUserStrategyReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModifyUserStrategyReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.UserStrategyInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModifyUserStrategyReq}
 */
proto.cts.C2TSModifyUserStrategyReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModifyUserStrategyReq;
  return proto.cts.C2TSModifyUserStrategyReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModifyUserStrategyReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModifyUserStrategyReq}
 */
proto.cts.C2TSModifyUserStrategyReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.UserStrategyInfo;
      reader.readMessage(value,proto.cts.UserStrategyInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModifyUserStrategyReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyUserStrategyReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModifyUserStrategyReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyUserStrategyReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.UserStrategyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserStrategyInfo info = 1;
 * @return {?proto.cts.UserStrategyInfo}
 */
proto.cts.C2TSModifyUserStrategyReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserStrategyInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserStrategyInfo, 1));
};


/** @param {?proto.cts.UserStrategyInfo|undefined} value */
proto.cts.C2TSModifyUserStrategyReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSModifyUserStrategyReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSModifyUserStrategyReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CModifyUserStrategyRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CModifyUserStrategyRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CModifyUserStrategyRes.displayName = 'proto.cts.TS2CModifyUserStrategyRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CModifyUserStrategyRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CModifyUserStrategyRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CModifyUserStrategyRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.cts.UserStrategyInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CModifyUserStrategyRes}
 */
proto.cts.TS2CModifyUserStrategyRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CModifyUserStrategyRes;
  return proto.cts.TS2CModifyUserStrategyRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CModifyUserStrategyRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CModifyUserStrategyRes}
 */
proto.cts.TS2CModifyUserStrategyRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    case 3:
      var value = new proto.cts.UserStrategyInfo;
      reader.readMessage(value,proto.cts.UserStrategyInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CModifyUserStrategyRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyUserStrategyRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.UserStrategyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CModifyUserStrategyRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CModifyUserStrategyRes.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional UserStrategyInfo info = 3;
 * @return {?proto.cts.UserStrategyInfo}
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.UserStrategyInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.UserStrategyInfo, 3));
};


/** @param {?proto.cts.UserStrategyInfo|undefined} value */
proto.cts.TS2CModifyUserStrategyRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2CModifyUserStrategyRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CModifyUserStrategyRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TraderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TraderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TraderInfo.displayName = 'proto.cts.TraderInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TraderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TraderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TraderInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TraderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    face: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sharerate: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    followminfund: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    onevolume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dayvolume: jspb.Message.getFieldWithDefault(msg, 7, 0),
    introduction: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    profit: +jspb.Message.getFieldWithDefault(msg, 10, 0.0),
    followers: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalfollowers: jspb.Message.getFieldWithDefault(msg, 12, 0),
    days: jspb.Message.getFieldWithDefault(msg, 13, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 14, 0),
    victoryrate: +jspb.Message.getFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TraderInfo}
 */
proto.cts.TraderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TraderInfo;
  return proto.cts.TraderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TraderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TraderInfo}
 */
proto.cts.TraderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSharerate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFollowminfund(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOnevolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayvolume(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntroduction(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFollowers(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalfollowers(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDays(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVolume(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVictoryrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TraderInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TraderInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TraderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TraderInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getFace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = this.getSharerate();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getFollowminfund();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getOnevolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getDayvolume();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = this.getIntroduction();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = this.getFollowers();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = this.getTotalfollowers();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = this.getDays();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = this.getVictoryrate();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cts.TraderInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TraderInfo.prototype.setName = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string face = 3;
 * @return {string}
 */
proto.cts.TraderInfo.prototype.getFace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.TraderInfo.prototype.setFace = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double shareRate = 4;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getSharerate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setSharerate = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double followMinFund = 5;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getFollowminfund = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setFollowminfund = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 oneVolume = 6;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getOnevolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setOnevolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional int32 dayVolume = 7;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getDayvolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setDayvolume = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional string introduction = 8;
 * @return {string}
 */
proto.cts.TraderInfo.prototype.getIntroduction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cts.TraderInfo.prototype.setIntroduction = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional int32 status = 9;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional double profit = 10;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 10, 0.0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 10, value);
};


/**
 * optional int32 followers = 11;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getFollowers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setFollowers = function(value) {
  jspb.Message.setField(this, 11, value);
};


/**
 * optional int32 totalFollowers = 12;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getTotalfollowers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setTotalfollowers = function(value) {
  jspb.Message.setField(this, 12, value);
};


/**
 * optional int32 days = 13;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setDays = function(value) {
  jspb.Message.setField(this, 13, value);
};


/**
 * optional int64 volume = 14;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 14, value);
};


/**
 * optional double victoryRate = 15;
 * @return {number}
 */
proto.cts.TraderInfo.prototype.getVictoryrate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 15, 0.0));
};


/** @param {number} value */
proto.cts.TraderInfo.prototype.setVictoryrate = function(value) {
  jspb.Message.setField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSSelfTraderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSSelfTraderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSSelfTraderReq.displayName = 'proto.cts.C2TSSelfTraderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSSelfTraderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSSelfTraderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSSelfTraderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSSelfTraderReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSSelfTraderReq}
 */
proto.cts.C2TSSelfTraderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSSelfTraderReq;
  return proto.cts.C2TSSelfTraderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSSelfTraderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSSelfTraderReq}
 */
proto.cts.C2TSSelfTraderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSSelfTraderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSSelfTraderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSSelfTraderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSSelfTraderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CSelfTraderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CSelfTraderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CSelfTraderRes.displayName = 'proto.cts.TS2CSelfTraderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CSelfTraderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CSelfTraderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CSelfTraderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CSelfTraderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.TraderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CSelfTraderRes}
 */
proto.cts.TS2CSelfTraderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CSelfTraderRes;
  return proto.cts.TS2CSelfTraderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CSelfTraderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CSelfTraderRes}
 */
proto.cts.TS2CSelfTraderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.TraderInfo;
      reader.readMessage(value,proto.cts.TraderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CSelfTraderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSelfTraderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CSelfTraderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CSelfTraderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.TraderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional TraderInfo info = 1;
 * @return {?proto.cts.TraderInfo}
 */
proto.cts.TS2CSelfTraderRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.TraderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.TraderInfo, 1));
};


/** @param {?proto.cts.TraderInfo|undefined} value */
proto.cts.TS2CSelfTraderRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.TS2CSelfTraderRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CSelfTraderRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSApplyTraderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSApplyTraderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSApplyTraderReq.displayName = 'proto.cts.C2TSApplyTraderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSApplyTraderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSApplyTraderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSApplyTraderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSApplyTraderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    face: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sharerate: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    followminfund: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    onevolume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dayvolume: jspb.Message.getFieldWithDefault(msg, 5, 0),
    introduction: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSApplyTraderReq}
 */
proto.cts.C2TSApplyTraderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSApplyTraderReq;
  return proto.cts.C2TSApplyTraderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSApplyTraderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSApplyTraderReq}
 */
proto.cts.C2TSApplyTraderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSharerate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFollowminfund(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOnevolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayvolume(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntroduction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSApplyTraderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSApplyTraderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSApplyTraderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSApplyTraderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getFace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getSharerate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getFollowminfund();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getOnevolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDayvolume();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getIntroduction();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string face = 1;
 * @return {string}
 */
proto.cts.C2TSApplyTraderReq.prototype.getFace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.C2TSApplyTraderReq.prototype.setFace = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double shareRate = 2;
 * @return {number}
 */
proto.cts.C2TSApplyTraderReq.prototype.getSharerate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.C2TSApplyTraderReq.prototype.setSharerate = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double followMinFund = 3;
 * @return {number}
 */
proto.cts.C2TSApplyTraderReq.prototype.getFollowminfund = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.C2TSApplyTraderReq.prototype.setFollowminfund = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 oneVolume = 4;
 * @return {number}
 */
proto.cts.C2TSApplyTraderReq.prototype.getOnevolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.C2TSApplyTraderReq.prototype.setOnevolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 dayVolume = 5;
 * @return {number}
 */
proto.cts.C2TSApplyTraderReq.prototype.getDayvolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.C2TSApplyTraderReq.prototype.setDayvolume = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional string introduction = 6;
 * @return {string}
 */
proto.cts.C2TSApplyTraderReq.prototype.getIntroduction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cts.C2TSApplyTraderReq.prototype.setIntroduction = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CApplyTraderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CApplyTraderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CApplyTraderRes.displayName = 'proto.cts.TS2CApplyTraderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CApplyTraderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CApplyTraderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CApplyTraderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CApplyTraderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.cts.TraderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CApplyTraderRes}
 */
proto.cts.TS2CApplyTraderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CApplyTraderRes;
  return proto.cts.TS2CApplyTraderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CApplyTraderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CApplyTraderRes}
 */
proto.cts.TS2CApplyTraderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    case 3:
      var value = new proto.cts.TraderInfo;
      reader.readMessage(value,proto.cts.TraderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CApplyTraderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CApplyTraderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CApplyTraderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CApplyTraderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.TraderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CApplyTraderRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CApplyTraderRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.TS2CApplyTraderRes.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CApplyTraderRes.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional TraderInfo info = 3;
 * @return {?proto.cts.TraderInfo}
 */
proto.cts.TS2CApplyTraderRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.TraderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.TraderInfo, 3));
};


/** @param {?proto.cts.TraderInfo|undefined} value */
proto.cts.TS2CApplyTraderRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2CApplyTraderRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CApplyTraderRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModifyTraderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModifyTraderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModifyTraderReq.displayName = 'proto.cts.C2TSModifyTraderReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModifyTraderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModifyTraderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModifyTraderReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModifyTraderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    face: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sharerate: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    followminfund: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    onevolume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dayvolume: jspb.Message.getFieldWithDefault(msg, 5, 0),
    introduction: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModifyTraderReq}
 */
proto.cts.C2TSModifyTraderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModifyTraderReq;
  return proto.cts.C2TSModifyTraderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModifyTraderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModifyTraderReq}
 */
proto.cts.C2TSModifyTraderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSharerate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFollowminfund(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOnevolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayvolume(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntroduction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModifyTraderReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyTraderReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModifyTraderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyTraderReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getFace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getSharerate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getFollowminfund();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getOnevolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDayvolume();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getIntroduction();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string face = 1;
 * @return {string}
 */
proto.cts.C2TSModifyTraderReq.prototype.getFace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.C2TSModifyTraderReq.prototype.setFace = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double shareRate = 2;
 * @return {number}
 */
proto.cts.C2TSModifyTraderReq.prototype.getSharerate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.C2TSModifyTraderReq.prototype.setSharerate = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double followMinFund = 3;
 * @return {number}
 */
proto.cts.C2TSModifyTraderReq.prototype.getFollowminfund = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.C2TSModifyTraderReq.prototype.setFollowminfund = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 oneVolume = 4;
 * @return {number}
 */
proto.cts.C2TSModifyTraderReq.prototype.getOnevolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.C2TSModifyTraderReq.prototype.setOnevolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 dayVolume = 5;
 * @return {number}
 */
proto.cts.C2TSModifyTraderReq.prototype.getDayvolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.C2TSModifyTraderReq.prototype.setDayvolume = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional string introduction = 6;
 * @return {string}
 */
proto.cts.C2TSModifyTraderReq.prototype.getIntroduction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cts.C2TSModifyTraderReq.prototype.setIntroduction = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CModifyTraderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CModifyTraderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CModifyTraderRes.displayName = 'proto.cts.TS2CModifyTraderRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CModifyTraderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CModifyTraderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CModifyTraderRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CModifyTraderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.cts.TraderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CModifyTraderRes}
 */
proto.cts.TS2CModifyTraderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CModifyTraderRes;
  return proto.cts.TS2CModifyTraderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CModifyTraderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CModifyTraderRes}
 */
proto.cts.TS2CModifyTraderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    case 3:
      var value = new proto.cts.TraderInfo;
      reader.readMessage(value,proto.cts.TraderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CModifyTraderRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyTraderRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CModifyTraderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyTraderRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.TraderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CModifyTraderRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CModifyTraderRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.TS2CModifyTraderRes.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CModifyTraderRes.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional TraderInfo info = 3;
 * @return {?proto.cts.TraderInfo}
 */
proto.cts.TS2CModifyTraderRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.TraderInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.TraderInfo, 3));
};


/** @param {?proto.cts.TraderInfo|undefined} value */
proto.cts.TS2CModifyTraderRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2CModifyTraderRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CModifyTraderRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSTraderListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSTraderListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSTraderListReq.displayName = 'proto.cts.C2TSTraderListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSTraderListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSTraderListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSTraderListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSTraderListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSTraderListReq}
 */
proto.cts.C2TSTraderListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSTraderListReq;
  return proto.cts.C2TSTraderListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSTraderListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSTraderListReq}
 */
proto.cts.C2TSTraderListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSTraderListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSTraderListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSTraderListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSTraderListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CTraderListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CTraderListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CTraderListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CTraderListRes.displayName = 'proto.cts.TS2CTraderListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CTraderListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CTraderListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CTraderListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CTraderListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CTraderListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.TraderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CTraderListRes}
 */
proto.cts.TS2CTraderListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CTraderListRes;
  return proto.cts.TS2CTraderListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CTraderListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CTraderListRes}
 */
proto.cts.TS2CTraderListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.TraderInfo;
      reader.readMessage(value,proto.cts.TraderInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CTraderListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CTraderListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CTraderListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CTraderListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.TraderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TraderInfo info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.TraderInfo>}
 */
proto.cts.TS2CTraderListRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.TraderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.TraderInfo, 1));
};


/** @param {!Array.<!proto.cts.TraderInfo>} value */
proto.cts.TS2CTraderListRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.TraderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.TraderInfo}
 */
proto.cts.TS2CTraderListRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.TraderInfo, opt_index);
};


proto.cts.TS2CTraderListRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.FollowInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.FollowInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.FollowInfo.displayName = 'proto.cts.FollowInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.FollowInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.FollowInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.FollowInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.FollowInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    multiple: jspb.Message.getFieldWithDefault(msg, 2, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 3, 0),
    profit: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    followprofit: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.FollowInfo}
 */
proto.cts.FollowInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.FollowInfo;
  return proto.cts.FollowInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.FollowInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.FollowInfo}
 */
proto.cts.FollowInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiple(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFollowprofit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.FollowInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.FollowInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.FollowInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.FollowInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getMultiple();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getFollowprofit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.FollowInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.FollowInfo.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 multiple = 2;
 * @return {number}
 */
proto.cts.FollowInfo.prototype.getMultiple = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.FollowInfo.prototype.setMultiple = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 volume = 3;
 * @return {number}
 */
proto.cts.FollowInfo.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.FollowInfo.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double profit = 4;
 * @return {number}
 */
proto.cts.FollowInfo.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.FollowInfo.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double followProfit = 5;
 * @return {number}
 */
proto.cts.FollowInfo.prototype.getFollowprofit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.FollowInfo.prototype.setFollowprofit = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 status = 6;
 * @return {number}
 */
proto.cts.FollowInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.FollowInfo.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSFollowListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSFollowListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSFollowListReq.displayName = 'proto.cts.C2TSFollowListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSFollowListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSFollowListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSFollowListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSFollowListReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSFollowListReq}
 */
proto.cts.C2TSFollowListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSFollowListReq;
  return proto.cts.C2TSFollowListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSFollowListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSFollowListReq}
 */
proto.cts.C2TSFollowListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSFollowListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSFollowListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSFollowListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSFollowListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CFollowListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CFollowListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CFollowListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CFollowListRes.displayName = 'proto.cts.TS2CFollowListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CFollowListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CFollowListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CFollowListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CFollowListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CFollowListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.FollowInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CFollowListRes}
 */
proto.cts.TS2CFollowListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CFollowListRes;
  return proto.cts.TS2CFollowListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CFollowListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CFollowListRes}
 */
proto.cts.TS2CFollowListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.FollowInfo;
      reader.readMessage(value,proto.cts.FollowInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CFollowListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CFollowListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CFollowListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CFollowListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.FollowInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FollowInfo info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.FollowInfo>}
 */
proto.cts.TS2CFollowListRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.FollowInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.FollowInfo, 1));
};


/** @param {!Array.<!proto.cts.FollowInfo>} value */
proto.cts.TS2CFollowListRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.FollowInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.FollowInfo}
 */
proto.cts.TS2CFollowListRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.FollowInfo, opt_index);
};


proto.cts.TS2CFollowListRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSAddFollowReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSAddFollowReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSAddFollowReq.displayName = 'proto.cts.C2TSAddFollowReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSAddFollowReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSAddFollowReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSAddFollowReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSAddFollowReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.FollowInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSAddFollowReq}
 */
proto.cts.C2TSAddFollowReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSAddFollowReq;
  return proto.cts.C2TSAddFollowReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSAddFollowReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSAddFollowReq}
 */
proto.cts.C2TSAddFollowReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.FollowInfo;
      reader.readMessage(value,proto.cts.FollowInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSAddFollowReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddFollowReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSAddFollowReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSAddFollowReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.FollowInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional FollowInfo info = 1;
 * @return {?proto.cts.FollowInfo}
 */
proto.cts.C2TSAddFollowReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.FollowInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.FollowInfo, 1));
};


/** @param {?proto.cts.FollowInfo|undefined} value */
proto.cts.C2TSAddFollowReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSAddFollowReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSAddFollowReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CAddFollowRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CAddFollowRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CAddFollowRes.displayName = 'proto.cts.TS2CAddFollowRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CAddFollowRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CAddFollowRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CAddFollowRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CAddFollowRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.cts.FollowInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CAddFollowRes}
 */
proto.cts.TS2CAddFollowRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CAddFollowRes;
  return proto.cts.TS2CAddFollowRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CAddFollowRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CAddFollowRes}
 */
proto.cts.TS2CAddFollowRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    case 3:
      var value = new proto.cts.FollowInfo;
      reader.readMessage(value,proto.cts.FollowInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CAddFollowRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddFollowRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CAddFollowRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CAddFollowRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.FollowInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CAddFollowRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CAddFollowRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.TS2CAddFollowRes.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CAddFollowRes.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional FollowInfo info = 3;
 * @return {?proto.cts.FollowInfo}
 */
proto.cts.TS2CAddFollowRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.FollowInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.FollowInfo, 3));
};


/** @param {?proto.cts.FollowInfo|undefined} value */
proto.cts.TS2CAddFollowRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2CAddFollowRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CAddFollowRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSModifyFollowReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSModifyFollowReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSModifyFollowReq.displayName = 'proto.cts.C2TSModifyFollowReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSModifyFollowReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSModifyFollowReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSModifyFollowReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSModifyFollowReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.cts.FollowInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSModifyFollowReq}
 */
proto.cts.C2TSModifyFollowReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSModifyFollowReq;
  return proto.cts.C2TSModifyFollowReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSModifyFollowReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSModifyFollowReq}
 */
proto.cts.C2TSModifyFollowReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.FollowInfo;
      reader.readMessage(value,proto.cts.FollowInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSModifyFollowReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyFollowReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSModifyFollowReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSModifyFollowReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cts.FollowInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional FollowInfo info = 1;
 * @return {?proto.cts.FollowInfo}
 */
proto.cts.C2TSModifyFollowReq.prototype.getInfo = function() {
  return /** @type{?proto.cts.FollowInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.FollowInfo, 1));
};


/** @param {?proto.cts.FollowInfo|undefined} value */
proto.cts.C2TSModifyFollowReq.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cts.C2TSModifyFollowReq.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.C2TSModifyFollowReq.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CModifyFollowRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2CModifyFollowRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CModifyFollowRes.displayName = 'proto.cts.TS2CModifyFollowRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CModifyFollowRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CModifyFollowRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CModifyFollowRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CModifyFollowRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    info: (f = msg.getInfo()) && proto.cts.FollowInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CModifyFollowRes}
 */
proto.cts.TS2CModifyFollowRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CModifyFollowRes;
  return proto.cts.TS2CModifyFollowRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CModifyFollowRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CModifyFollowRes}
 */
proto.cts.TS2CModifyFollowRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    case 3:
      var value = new proto.cts.FollowInfo;
      reader.readMessage(value,proto.cts.FollowInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CModifyFollowRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyFollowRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CModifyFollowRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CModifyFollowRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.FollowInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.TS2CModifyFollowRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2CModifyFollowRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.TS2CModifyFollowRes.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.TS2CModifyFollowRes.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional FollowInfo info = 3;
 * @return {?proto.cts.FollowInfo}
 */
proto.cts.TS2CModifyFollowRes.prototype.getInfo = function() {
  return /** @type{?proto.cts.FollowInfo} */ (
    jspb.Message.getWrapperField(this, proto.cts.FollowInfo, 3));
};


/** @param {?proto.cts.FollowInfo|undefined} value */
proto.cts.TS2CModifyFollowRes.prototype.setInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.TS2CModifyFollowRes.prototype.clearInfo = function() {
  this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.TS2CModifyFollowRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.FollowerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.FollowerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.FollowerInfo.displayName = 'proto.cts.FollowerInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.FollowerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.FollowerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.FollowerInfo} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.FollowerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    face: jspb.Message.getFieldWithDefault(msg, 2, ""),
    followfund: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    profit: +jspb.Message.getFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.FollowerInfo}
 */
proto.cts.FollowerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.FollowerInfo;
  return proto.cts.FollowerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.FollowerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.FollowerInfo}
 */
proto.cts.FollowerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFace(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFollowfund(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.FollowerInfo} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.FollowerInfo.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.FollowerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.FollowerInfo.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getFace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getFollowfund();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.cts.FollowerInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.FollowerInfo.prototype.setName = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string face = 2;
 * @return {string}
 */
proto.cts.FollowerInfo.prototype.getFace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.FollowerInfo.prototype.setFace = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double followFund = 3;
 * @return {number}
 */
proto.cts.FollowerInfo.prototype.getFollowfund = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.FollowerInfo.prototype.setFollowfund = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double profit = 4;
 * @return {number}
 */
proto.cts.FollowerInfo.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.FollowerInfo.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2TSTraderFollowerListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2TSTraderFollowerListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2TSTraderFollowerListReq.displayName = 'proto.cts.C2TSTraderFollowerListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2TSTraderFollowerListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2TSTraderFollowerListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2TSTraderFollowerListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2TSTraderFollowerListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2TSTraderFollowerListReq}
 */
proto.cts.C2TSTraderFollowerListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2TSTraderFollowerListReq;
  return proto.cts.C2TSTraderFollowerListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2TSTraderFollowerListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2TSTraderFollowerListReq}
 */
proto.cts.C2TSTraderFollowerListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2TSTraderFollowerListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSTraderFollowerListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2TSTraderFollowerListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2TSTraderFollowerListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.C2TSTraderFollowerListReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.C2TSTraderFollowerListReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2CTraderFollowerListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TS2CTraderFollowerListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TS2CTraderFollowerListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2CTraderFollowerListRes.displayName = 'proto.cts.TS2CTraderFollowerListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TS2CTraderFollowerListRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2CTraderFollowerListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2CTraderFollowerListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2CTraderFollowerListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2CTraderFollowerListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.FollowerInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2CTraderFollowerListRes}
 */
proto.cts.TS2CTraderFollowerListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2CTraderFollowerListRes;
  return proto.cts.TS2CTraderFollowerListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2CTraderFollowerListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2CTraderFollowerListRes}
 */
proto.cts.TS2CTraderFollowerListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.FollowerInfo;
      reader.readMessage(value,proto.cts.FollowerInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2CTraderFollowerListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CTraderFollowerListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2CTraderFollowerListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2CTraderFollowerListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.FollowerInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FollowerInfo info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.FollowerInfo>}
 */
proto.cts.TS2CTraderFollowerListRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.FollowerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.FollowerInfo, 1));
};


/** @param {!Array.<!proto.cts.FollowerInfo>} value */
proto.cts.TS2CTraderFollowerListRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.FollowerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.FollowerInfo}
 */
proto.cts.TS2CTraderFollowerListRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.FollowerInfo, opt_index);
};


proto.cts.TS2CTraderFollowerListRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2TIOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2TIOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2TIOpenReq.displayName = 'proto.cts.TS2TIOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2TIOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2TIOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2TIOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2TIOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractcode: msg.getContractcode_asB64(),
    orderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderpricetype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    delaymatchinterval: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2TIOpenReq}
 */
proto.cts.TS2TIOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2TIOpenReq;
  return proto.cts.TS2TIOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2TIOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2TIOpenReq}
 */
proto.cts.TS2TIOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderpricetype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelaymatchinterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2TIOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TIOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2TIOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TIOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getOrderpricetype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getDelaymatchinterval();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional bytes contractCode = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2TIOpenReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contractCode = 1;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2TIOpenReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2TIOpenReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2TIOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 orderID = 2;
 * @return {number}
 */
proto.cts.TS2TIOpenReq.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2TIOpenReq.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.cts.TS2TIOpenReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.TS2TIOpenReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 volume = 4;
 * @return {number}
 */
proto.cts.TS2TIOpenReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.TS2TIOpenReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2TIOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2TIOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 orderPriceType = 6;
 * @return {number}
 */
proto.cts.TS2TIOpenReq.prototype.getOrderpricetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2TIOpenReq.prototype.setOrderpricetype = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional int32 delayMatchInterval = 7;
 * @return {number}
 */
proto.cts.TS2TIOpenReq.prototype.getDelaymatchinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cts.TS2TIOpenReq.prototype.setDelaymatchinterval = function(value) {
  jspb.Message.setField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TI2TSOpenRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TI2TSOpenRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TI2TSOpenRes.displayName = 'proto.cts.TI2TSOpenRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TI2TSOpenRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TI2TSOpenRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TI2TSOpenRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TI2TSOpenRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TI2TSOpenRes}
 */
proto.cts.TI2TSOpenRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TI2TSOpenRes;
  return proto.cts.TI2TSOpenRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TI2TSOpenRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TI2TSOpenRes}
 */
proto.cts.TI2TSOpenRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TI2TSOpenRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSOpenRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TI2TSOpenRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSOpenRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 orderID = 1;
 * @return {number}
 */
proto.cts.TI2TSOpenRes.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TI2TSOpenRes.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.cts.TI2TSOpenRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TI2TSOpenRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2TICloseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2TICloseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2TICloseReq.displayName = 'proto.cts.TS2TICloseReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2TICloseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2TICloseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2TICloseReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2TICloseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractcode: msg.getContractcode_asB64(),
    orderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orderpricetype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    delaymatchinterval: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2TICloseReq}
 */
proto.cts.TS2TICloseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2TICloseReq;
  return proto.cts.TS2TICloseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2TICloseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2TICloseReq}
 */
proto.cts.TS2TICloseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderpricetype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelaymatchinterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2TICloseReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TICloseReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2TICloseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TICloseReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getOrderpricetype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getDelaymatchinterval();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional bytes contractCode = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2TICloseReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contractCode = 1;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2TICloseReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2TICloseReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2TICloseReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 orderID = 2;
 * @return {number}
 */
proto.cts.TS2TICloseReq.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2TICloseReq.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.cts.TS2TICloseReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.TS2TICloseReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 volume = 4;
 * @return {number}
 */
proto.cts.TS2TICloseReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.TS2TICloseReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2TICloseReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2TICloseReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 orderPriceType = 6;
 * @return {number}
 */
proto.cts.TS2TICloseReq.prototype.getOrderpricetype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2TICloseReq.prototype.setOrderpricetype = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional int32 delayMatchInterval = 7;
 * @return {number}
 */
proto.cts.TS2TICloseReq.prototype.getDelaymatchinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cts.TS2TICloseReq.prototype.setDelaymatchinterval = function(value) {
  jspb.Message.setField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TI2TSCloseRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TI2TSCloseRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TI2TSCloseRes.displayName = 'proto.cts.TI2TSCloseRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TI2TSCloseRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TI2TSCloseRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TI2TSCloseRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TI2TSCloseRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TI2TSCloseRes}
 */
proto.cts.TI2TSCloseRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TI2TSCloseRes;
  return proto.cts.TI2TSCloseRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TI2TSCloseRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TI2TSCloseRes}
 */
proto.cts.TI2TSCloseRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TI2TSCloseRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSCloseRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TI2TSCloseRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSCloseRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 orderID = 1;
 * @return {number}
 */
proto.cts.TI2TSCloseRes.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TI2TSCloseRes.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.cts.TI2TSCloseRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TI2TSCloseRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2TICancelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2TICancelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2TICancelReq.displayName = 'proto.cts.TS2TICancelReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2TICancelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2TICancelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2TICancelReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2TICancelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2TICancelReq}
 */
proto.cts.TS2TICancelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2TICancelReq;
  return proto.cts.TS2TICancelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2TICancelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2TICancelReq}
 */
proto.cts.TS2TICancelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2TICancelReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TICancelReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2TICancelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TICancelReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 orderID = 1;
 * @return {number}
 */
proto.cts.TS2TICancelReq.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2TICancelReq.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TI2TSCancelRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TI2TSCancelRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TI2TSCancelRes.displayName = 'proto.cts.TI2TSCancelRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TI2TSCancelRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TI2TSCancelRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TI2TSCancelRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TI2TSCancelRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TI2TSCancelRes}
 */
proto.cts.TI2TSCancelRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TI2TSCancelRes;
  return proto.cts.TI2TSCancelRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TI2TSCancelRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TI2TSCancelRes}
 */
proto.cts.TI2TSCancelRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TI2TSCancelRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSCancelRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TI2TSCancelRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSCancelRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 orderID = 1;
 * @return {number}
 */
proto.cts.TI2TSCancelRes.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TI2TSCancelRes.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.cts.TI2TSCancelRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TI2TSCancelRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.DealUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.DealUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.DealUnit.displayName = 'proto.cts.DealUnit';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.DealUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.DealUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.DealUnit} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.DealUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    volume: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealprice: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.DealUnit}
 */
proto.cts.DealUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.DealUnit;
  return proto.cts.DealUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.DealUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.DealUnit}
 */
proto.cts.DealUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDealprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.DealUnit} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.DealUnit.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.DealUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.DealUnit.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getDealprice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 volume = 1;
 * @return {number}
 */
proto.cts.DealUnit.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.DealUnit.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double dealPrice = 2;
 * @return {number}
 */
proto.cts.DealUnit.prototype.getDealprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.DealUnit.prototype.setDealprice = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TI2TSUpdateState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TI2TSUpdateState.repeatedFields_, null);
};
goog.inherits(proto.cts.TI2TSUpdateState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TI2TSUpdateState.displayName = 'proto.cts.TI2TSUpdateState';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TI2TSUpdateState.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TI2TSUpdateState.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TI2TSUpdateState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TI2TSUpdateState} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TI2TSUpdateState.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalvolume: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tradeavgprice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    dealunitList: jspb.Message.toObjectList(msg.getDealunitList(),
    proto.cts.DealUnit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TI2TSUpdateState}
 */
proto.cts.TI2TSUpdateState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TI2TSUpdateState;
  return proto.cts.TI2TSUpdateState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TI2TSUpdateState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TI2TSUpdateState}
 */
proto.cts.TI2TSUpdateState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalvolume(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTradeavgprice(value);
      break;
    case 5:
      var value = new proto.cts.DealUnit;
      reader.readMessage(value,proto.cts.DealUnit.deserializeBinaryFromReader);
      msg.addDealunit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TI2TSUpdateState} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSUpdateState.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TI2TSUpdateState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TI2TSUpdateState.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getTotalvolume();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getTradeavgprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getDealunitList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cts.DealUnit.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 orderID = 1;
 * @return {number}
 */
proto.cts.TI2TSUpdateState.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TI2TSUpdateState.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 status = 2;
 * @return {number}
 */
proto.cts.TI2TSUpdateState.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TI2TSUpdateState.prototype.setStatus = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 totalVolume = 3;
 * @return {number}
 */
proto.cts.TI2TSUpdateState.prototype.getTotalvolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cts.TI2TSUpdateState.prototype.setTotalvolume = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double tradeAvgPrice = 4;
 * @return {number}
 */
proto.cts.TI2TSUpdateState.prototype.getTradeavgprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.TI2TSUpdateState.prototype.setTradeavgprice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * repeated DealUnit dealUnit = 5;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.DealUnit>}
 */
proto.cts.TI2TSUpdateState.prototype.getDealunitList = function() {
  return /** @type{!Array.<!proto.cts.DealUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.DealUnit, 5));
};


/** @param {!Array.<!proto.cts.DealUnit>} value */
proto.cts.TI2TSUpdateState.prototype.setDealunitList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cts.DealUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.DealUnit}
 */
proto.cts.TI2TSUpdateState.prototype.addDealunit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cts.DealUnit, opt_index);
};


proto.cts.TI2TSUpdateState.prototype.clearDealunitList = function() {
  this.setDealunitList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2HUOBITIOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2HUOBITIOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2HUOBITIOpenReq.displayName = 'proto.cts.TS2HUOBITIOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2HUOBITIOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2HUOBITIOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2HUOBITIOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdealid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: msg.getSymbol_asB64(),
    contractcode: msg.getContractcode_asB64(),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    accesskey: msg.getAccesskey_asB64(),
    secretkey: msg.getSecretkey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2HUOBITIOpenReq}
 */
proto.cts.TS2HUOBITIOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2HUOBITIOpenReq;
  return proto.cts.TS2HUOBITIOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2HUOBITIOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2HUOBITIOpenReq}
 */
proto.cts.TS2HUOBITIOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdealid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccesskey(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecretkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2HUOBITIOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2HUOBITIOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2HUOBITIOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderdealid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getAccesskey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = this.getSecretkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
};


/**
 * optional int32 orderDealID = 1;
 * @return {number}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getOrderdealid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setOrderdealid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional bytes symbol = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getSymbol = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes symbol = 3;
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {string}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getSymbol_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSymbol()));
};


/**
 * optional bytes symbol = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getSymbol_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSymbol()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes contractCode = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contractCode = 4;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional bytes accessKey = 8;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getAccesskey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes accessKey = 8;
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {string}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getAccesskey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccesskey()));
};


/**
 * optional bytes accessKey = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getAccesskey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccesskey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional bytes secretKey = 9;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getSecretkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes secretKey = 9;
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {string}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getSecretkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecretkey()));
};


/**
 * optional bytes secretKey = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITIOpenReq.prototype.getSecretkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecretkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITIOpenReq.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2HUOBITICloseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2HUOBITICloseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2HUOBITICloseReq.displayName = 'proto.cts.TS2HUOBITICloseReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2HUOBITICloseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2HUOBITICloseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2HUOBITICloseReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2HUOBITICloseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdealid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: msg.getSymbol_asB64(),
    contractcode: msg.getContractcode_asB64(),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    accesskey: msg.getAccesskey_asB64(),
    secretkey: msg.getSecretkey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2HUOBITICloseReq}
 */
proto.cts.TS2HUOBITICloseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2HUOBITICloseReq;
  return proto.cts.TS2HUOBITICloseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2HUOBITICloseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2HUOBITICloseReq}
 */
proto.cts.TS2HUOBITICloseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdealid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccesskey(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecretkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2HUOBITICloseReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2HUOBITICloseReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITICloseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2HUOBITICloseReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderdealid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getAccesskey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = this.getSecretkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
};


/**
 * optional int32 orderDealID = 1;
 * @return {number}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getOrderdealid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITICloseReq.prototype.setOrderdealid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITICloseReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional bytes symbol = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getSymbol = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes symbol = 3;
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {string}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getSymbol_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSymbol()));
};


/**
 * optional bytes symbol = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getSymbol_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSymbol()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITICloseReq.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes contractCode = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contractCode = 4;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITICloseReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITICloseReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2HUOBITICloseReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.TS2HUOBITICloseReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional bytes accessKey = 8;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getAccesskey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes accessKey = 8;
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {string}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getAccesskey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccesskey()));
};


/**
 * optional bytes accessKey = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getAccesskey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccesskey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITICloseReq.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional bytes secretKey = 9;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getSecretkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes secretKey = 9;
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {string}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getSecretkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecretkey()));
};


/**
 * optional bytes secretKey = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2HUOBITICloseReq.prototype.getSecretkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecretkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2HUOBITICloseReq.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2OKEXTIOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2OKEXTIOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2OKEXTIOpenReq.displayName = 'proto.cts.TS2OKEXTIOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2OKEXTIOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2OKEXTIOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2OKEXTIOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdealid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: msg.getSymbol_asB64(),
    contractcode: msg.getContractcode_asB64(),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    accesskey: msg.getAccesskey_asB64(),
    secretkey: msg.getSecretkey_asB64(),
    passphrase: msg.getPassphrase_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2OKEXTIOpenReq}
 */
proto.cts.TS2OKEXTIOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2OKEXTIOpenReq;
  return proto.cts.TS2OKEXTIOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2OKEXTIOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2OKEXTIOpenReq}
 */
proto.cts.TS2OKEXTIOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdealid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccesskey(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecretkey(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPassphrase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2OKEXTIOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2OKEXTIOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2OKEXTIOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderdealid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getAccesskey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = this.getSecretkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = this.getPassphrase_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional int32 orderDealID = 1;
 * @return {number}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getOrderdealid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setOrderdealid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional bytes symbol = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getSymbol = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes symbol = 3;
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {string}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getSymbol_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSymbol()));
};


/**
 * optional bytes symbol = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getSymbol_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSymbol()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes contractCode = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contractCode = 4;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional bytes accessKey = 8;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getAccesskey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes accessKey = 8;
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {string}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getAccesskey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccesskey()));
};


/**
 * optional bytes accessKey = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getAccesskey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccesskey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional bytes secretKey = 9;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getSecretkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes secretKey = 9;
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {string}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getSecretkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecretkey()));
};


/**
 * optional bytes secretKey = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getSecretkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecretkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional bytes passPhrase = 10;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getPassphrase = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes passPhrase = 10;
 * This is a type-conversion wrapper around `getPassphrase()`
 * @return {string}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getPassphrase_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPassphrase()));
};


/**
 * optional bytes passPhrase = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPassphrase()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTIOpenReq.prototype.getPassphrase_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPassphrase()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTIOpenReq.prototype.setPassphrase = function(value) {
  jspb.Message.setField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2OKEXTICloseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2OKEXTICloseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2OKEXTICloseReq.displayName = 'proto.cts.TS2OKEXTICloseReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2OKEXTICloseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2OKEXTICloseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2OKEXTICloseReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2OKEXTICloseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdealid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: msg.getSymbol_asB64(),
    contractcode: msg.getContractcode_asB64(),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    accesskey: msg.getAccesskey_asB64(),
    secretkey: msg.getSecretkey_asB64(),
    passphrase: msg.getPassphrase_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2OKEXTICloseReq}
 */
proto.cts.TS2OKEXTICloseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2OKEXTICloseReq;
  return proto.cts.TS2OKEXTICloseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2OKEXTICloseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2OKEXTICloseReq}
 */
proto.cts.TS2OKEXTICloseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdealid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccesskey(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecretkey(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPassphrase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2OKEXTICloseReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2OKEXTICloseReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTICloseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2OKEXTICloseReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderdealid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getAccesskey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = this.getSecretkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = this.getPassphrase_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional int32 orderDealID = 1;
 * @return {number}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getOrderdealid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTICloseReq.prototype.setOrderdealid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTICloseReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional bytes symbol = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getSymbol = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes symbol = 3;
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {string}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getSymbol_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSymbol()));
};


/**
 * optional bytes symbol = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getSymbol_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSymbol()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTICloseReq.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes contractCode = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contractCode = 4;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTICloseReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTICloseReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2OKEXTICloseReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.TS2OKEXTICloseReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional bytes accessKey = 8;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getAccesskey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes accessKey = 8;
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {string}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getAccesskey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccesskey()));
};


/**
 * optional bytes accessKey = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getAccesskey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccesskey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTICloseReq.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional bytes secretKey = 9;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getSecretkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes secretKey = 9;
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {string}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getSecretkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecretkey()));
};


/**
 * optional bytes secretKey = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getSecretkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecretkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTICloseReq.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional bytes passPhrase = 10;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getPassphrase = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes passPhrase = 10;
 * This is a type-conversion wrapper around `getPassphrase()`
 * @return {string}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getPassphrase_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPassphrase()));
};


/**
 * optional bytes passPhrase = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPassphrase()`
 * @return {!Uint8Array}
 */
proto.cts.TS2OKEXTICloseReq.prototype.getPassphrase_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPassphrase()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2OKEXTICloseReq.prototype.setPassphrase = function(value) {
  jspb.Message.setField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2BINANTIOpenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2BINANTIOpenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2BINANTIOpenReq.displayName = 'proto.cts.TS2BINANTIOpenReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2BINANTIOpenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2BINANTIOpenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2BINANTIOpenReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2BINANTIOpenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdealid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: msg.getSymbol_asB64(),
    contractcode: msg.getContractcode_asB64(),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    quantity: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    price: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    accesskey: msg.getAccesskey_asB64(),
    secretkey: msg.getSecretkey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2BINANTIOpenReq}
 */
proto.cts.TS2BINANTIOpenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2BINANTIOpenReq;
  return proto.cts.TS2BINANTIOpenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2BINANTIOpenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2BINANTIOpenReq}
 */
proto.cts.TS2BINANTIOpenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdealid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccesskey(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecretkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2BINANTIOpenReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2BINANTIOpenReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTIOpenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2BINANTIOpenReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderdealid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getAccesskey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = this.getSecretkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional int32 orderDealID = 1;
 * @return {number}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getOrderdealid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTIOpenReq.prototype.setOrderdealid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTIOpenReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional bytes symbol = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getSymbol = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes symbol = 3;
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {string}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getSymbol_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSymbol()));
};


/**
 * optional bytes symbol = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getSymbol_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSymbol()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTIOpenReq.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes contractCode = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contractCode = 4;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTIOpenReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTIOpenReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTIOpenReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double quantity = 7;
 * @return {number}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.TS2BINANTIOpenReq.prototype.setQuantity = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double price = 8;
 * @return {number}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cts.TS2BINANTIOpenReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional bytes accessKey = 9;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getAccesskey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes accessKey = 9;
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {string}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getAccesskey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccesskey()));
};


/**
 * optional bytes accessKey = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getAccesskey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccesskey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTIOpenReq.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional bytes secretKey = 10;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getSecretkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes secretKey = 10;
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {string}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getSecretkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecretkey()));
};


/**
 * optional bytes secretKey = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTIOpenReq.prototype.getSecretkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecretkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTIOpenReq.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2BINANTICloseReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2BINANTICloseReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2BINANTICloseReq.displayName = 'proto.cts.TS2BINANTICloseReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2BINANTICloseReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2BINANTICloseReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2BINANTICloseReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2BINANTICloseReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderdealid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: msg.getSymbol_asB64(),
    contractcode: msg.getContractcode_asB64(),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    quantity: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    price: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    accesskey: msg.getAccesskey_asB64(),
    secretkey: msg.getSecretkey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2BINANTICloseReq}
 */
proto.cts.TS2BINANTICloseReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2BINANTICloseReq;
  return proto.cts.TS2BINANTICloseReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2BINANTICloseReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2BINANTICloseReq}
 */
proto.cts.TS2BINANTICloseReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderdealid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContractcode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccesskey(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSecretkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2BINANTICloseReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2BINANTICloseReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTICloseReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2BINANTICloseReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getOrderdealid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getSymbol_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = this.getContractcode_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = this.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getAccesskey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = this.getSecretkey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional int32 orderDealID = 1;
 * @return {number}
 */
proto.cts.TS2BINANTICloseReq.prototype.getOrderdealid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTICloseReq.prototype.setOrderdealid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 userID = 2;
 * @return {number}
 */
proto.cts.TS2BINANTICloseReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTICloseReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional bytes symbol = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTICloseReq.prototype.getSymbol = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes symbol = 3;
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {string}
 */
proto.cts.TS2BINANTICloseReq.prototype.getSymbol_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSymbol()));
};


/**
 * optional bytes symbol = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSymbol()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTICloseReq.prototype.getSymbol_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSymbol()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTICloseReq.prototype.setSymbol = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes contractCode = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTICloseReq.prototype.getContractcode = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contractCode = 4;
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {string}
 */
proto.cts.TS2BINANTICloseReq.prototype.getContractcode_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContractcode()));
};


/**
 * optional bytes contractCode = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractcode()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTICloseReq.prototype.getContractcode_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContractcode()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTICloseReq.prototype.setContractcode = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 direction = 5;
 * @return {number}
 */
proto.cts.TS2BINANTICloseReq.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTICloseReq.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional int32 volume = 6;
 * @return {number}
 */
proto.cts.TS2BINANTICloseReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cts.TS2BINANTICloseReq.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double quantity = 7;
 * @return {number}
 */
proto.cts.TS2BINANTICloseReq.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.TS2BINANTICloseReq.prototype.setQuantity = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double price = 8;
 * @return {number}
 */
proto.cts.TS2BINANTICloseReq.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cts.TS2BINANTICloseReq.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional bytes accessKey = 9;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTICloseReq.prototype.getAccesskey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes accessKey = 9;
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {string}
 */
proto.cts.TS2BINANTICloseReq.prototype.getAccesskey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccesskey()));
};


/**
 * optional bytes accessKey = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccesskey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTICloseReq.prototype.getAccesskey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccesskey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTICloseReq.prototype.setAccesskey = function(value) {
  jspb.Message.setField(this, 9, value);
};


/**
 * optional bytes secretKey = 10;
 * @return {!(string|Uint8Array)}
 */
proto.cts.TS2BINANTICloseReq.prototype.getSecretkey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes secretKey = 10;
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {string}
 */
proto.cts.TS2BINANTICloseReq.prototype.getSecretkey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSecretkey()));
};


/**
 * optional bytes secretKey = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSecretkey()`
 * @return {!Uint8Array}
 */
proto.cts.TS2BINANTICloseReq.prototype.getSecretkey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSecretkey()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.TS2BINANTICloseReq.prototype.setSecretkey = function(value) {
  jspb.Message.setField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TS2TPBrokerageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TS2TPBrokerageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TS2TPBrokerageReq.displayName = 'proto.cts.TS2TPBrokerageReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TS2TPBrokerageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TS2TPBrokerageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TS2TPBrokerageReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TS2TPBrokerageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fee: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    time: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TS2TPBrokerageReq}
 */
proto.cts.TS2TPBrokerageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TS2TPBrokerageReq;
  return proto.cts.TS2TPBrokerageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TS2TPBrokerageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TS2TPBrokerageReq}
 */
proto.cts.TS2TPBrokerageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TS2TPBrokerageReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TPBrokerageReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TS2TPBrokerageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TS2TPBrokerageReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getTime();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TS2TPBrokerageReq.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TS2TPBrokerageReq.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.cts.TS2TPBrokerageReq.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TS2TPBrokerageReq.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double fee = 3;
 * @return {number}
 */
proto.cts.TS2TPBrokerageReq.prototype.getFee = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.TS2TPBrokerageReq.prototype.setFee = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 orderID = 4;
 * @return {number}
 */
proto.cts.TS2TPBrokerageReq.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.TS2TPBrokerageReq.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 time = 5;
 * @return {number}
 */
proto.cts.TS2TPBrokerageReq.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TS2TPBrokerageReq.prototype.setTime = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TP2TSBrokerageRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TP2TSBrokerageRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TP2TSBrokerageRes.displayName = 'proto.cts.TP2TSBrokerageRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TP2TSBrokerageRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TP2TSBrokerageRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TP2TSBrokerageRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TP2TSBrokerageRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    brokerage: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    time: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TP2TSBrokerageRes}
 */
proto.cts.TP2TSBrokerageRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TP2TSBrokerageRes;
  return proto.cts.TP2TSBrokerageRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TP2TSBrokerageRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TP2TSBrokerageRes}
 */
proto.cts.TP2TSBrokerageRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBrokerage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TP2TSBrokerageRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSBrokerageRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TP2TSBrokerageRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSBrokerageRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getBrokerage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getOrderid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getTime();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.TP2TSBrokerageRes.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TP2TSBrokerageRes.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.cts.TP2TSBrokerageRes.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.TP2TSBrokerageRes.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double brokerage = 3;
 * @return {number}
 */
proto.cts.TP2TSBrokerageRes.prototype.getBrokerage = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.TP2TSBrokerageRes.prototype.setBrokerage = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 orderID = 4;
 * @return {number}
 */
proto.cts.TP2TSBrokerageRes.prototype.getOrderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.TP2TSBrokerageRes.prototype.setOrderid = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional int32 time = 5;
 * @return {number}
 */
proto.cts.TP2TSBrokerageRes.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cts.TP2TSBrokerageRes.prototype.setTime = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TP2TSStrategyInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TP2TSStrategyInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TP2TSStrategyInfoRes.displayName = 'proto.cts.TP2TSStrategyInfoRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TP2TSStrategyInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TP2TSStrategyInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TP2TSStrategyInfoRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TP2TSStrategyInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TP2TSStrategyInfoRes}
 */
proto.cts.TP2TSStrategyInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TP2TSStrategyInfoRes;
  return proto.cts.TP2TSStrategyInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TP2TSStrategyInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TP2TSStrategyInfoRes}
 */
proto.cts.TP2TSStrategyInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TP2TSStrategyInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSStrategyInfoRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TP2TSStrategyInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSStrategyInfoRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TP2TSTraderSettlementRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TP2TSTraderSettlementRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TP2TSTraderSettlementRes.displayName = 'proto.cts.TP2TSTraderSettlementRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TP2TSTraderSettlementRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TP2TSTraderSettlementRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TP2TSTraderSettlementRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TP2TSTraderSettlementRes.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TP2TSTraderSettlementRes}
 */
proto.cts.TP2TSTraderSettlementRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TP2TSTraderSettlementRes;
  return proto.cts.TP2TSTraderSettlementRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TP2TSTraderSettlementRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TP2TSTraderSettlementRes}
 */
proto.cts.TP2TSTraderSettlementRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TP2TSTraderSettlementRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSTraderSettlementRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TP2TSTraderSettlementRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSTraderSettlementRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.FollowSettlement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.FollowSettlement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.FollowSettlement.displayName = 'proto.cts.FollowSettlement';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.FollowSettlement.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.FollowSettlement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.FollowSettlement} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.FollowSettlement.toObject = function(includeInstance, msg) {
  var f, obj = {
    followuserid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    traderuserid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    profit: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.FollowSettlement}
 */
proto.cts.FollowSettlement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.FollowSettlement;
  return proto.cts.FollowSettlement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.FollowSettlement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.FollowSettlement}
 */
proto.cts.FollowSettlement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFollowuserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTraderuserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.FollowSettlement} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.FollowSettlement.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.FollowSettlement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.FollowSettlement.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getFollowuserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getTraderuserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 followUserID = 1;
 * @return {number}
 */
proto.cts.FollowSettlement.prototype.getFollowuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.FollowSettlement.prototype.setFollowuserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 traderUserID = 2;
 * @return {number}
 */
proto.cts.FollowSettlement.prototype.getTraderuserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.FollowSettlement.prototype.setTraderuserid = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double profit = 3;
 * @return {number}
 */
proto.cts.FollowSettlement.prototype.getProfit = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.FollowSettlement.prototype.setProfit = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TP2TSFollowSettlementRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TP2TSFollowSettlementRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TP2TSFollowSettlementRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TP2TSFollowSettlementRes.displayName = 'proto.cts.TP2TSFollowSettlementRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TP2TSFollowSettlementRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TP2TSFollowSettlementRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TP2TSFollowSettlementRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TP2TSFollowSettlementRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TP2TSFollowSettlementRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.FollowSettlement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TP2TSFollowSettlementRes}
 */
proto.cts.TP2TSFollowSettlementRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TP2TSFollowSettlementRes;
  return proto.cts.TP2TSFollowSettlementRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TP2TSFollowSettlementRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TP2TSFollowSettlementRes}
 */
proto.cts.TP2TSFollowSettlementRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.FollowSettlement;
      reader.readMessage(value,proto.cts.FollowSettlement.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TP2TSFollowSettlementRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSFollowSettlementRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TP2TSFollowSettlementRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSFollowSettlementRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.FollowSettlement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FollowSettlement info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.FollowSettlement>}
 */
proto.cts.TP2TSFollowSettlementRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.FollowSettlement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.FollowSettlement, 1));
};


/** @param {!Array.<!proto.cts.FollowSettlement>} value */
proto.cts.TP2TSFollowSettlementRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.FollowSettlement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.FollowSettlement}
 */
proto.cts.TP2TSFollowSettlementRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.FollowSettlement, opt_index);
};


proto.cts.TP2TSFollowSettlementRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.PositionBrokerage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.PositionBrokerage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.PositionBrokerage.displayName = 'proto.cts.PositionBrokerage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.PositionBrokerage.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.PositionBrokerage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.PositionBrokerage} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.PositionBrokerage.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brokerage: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.PositionBrokerage}
 */
proto.cts.PositionBrokerage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.PositionBrokerage;
  return proto.cts.PositionBrokerage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.PositionBrokerage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.PositionBrokerage}
 */
proto.cts.PositionBrokerage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBrokerage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.PositionBrokerage} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.PositionBrokerage.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.PositionBrokerage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.PositionBrokerage.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getBrokerage();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 userID = 1;
 * @return {number}
 */
proto.cts.PositionBrokerage.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.PositionBrokerage.prototype.setUserid = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double brokerage = 2;
 * @return {number}
 */
proto.cts.PositionBrokerage.prototype.getBrokerage = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.PositionBrokerage.prototype.setBrokerage = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TP2TSPositionBrokerageRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.TP2TSPositionBrokerageRes.repeatedFields_, null);
};
goog.inherits(proto.cts.TP2TSPositionBrokerageRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TP2TSPositionBrokerageRes.displayName = 'proto.cts.TP2TSPositionBrokerageRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.TP2TSPositionBrokerageRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TP2TSPositionBrokerageRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TP2TSPositionBrokerageRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TP2TSPositionBrokerageRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TP2TSPositionBrokerageRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cts.PositionBrokerage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TP2TSPositionBrokerageRes}
 */
proto.cts.TP2TSPositionBrokerageRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TP2TSPositionBrokerageRes;
  return proto.cts.TP2TSPositionBrokerageRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TP2TSPositionBrokerageRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TP2TSPositionBrokerageRes}
 */
proto.cts.TP2TSPositionBrokerageRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cts.PositionBrokerage;
      reader.readMessage(value,proto.cts.PositionBrokerage.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TP2TSPositionBrokerageRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSPositionBrokerageRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TP2TSPositionBrokerageRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TP2TSPositionBrokerageRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cts.PositionBrokerage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PositionBrokerage info = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.PositionBrokerage>}
 */
proto.cts.TP2TSPositionBrokerageRes.prototype.getInfoList = function() {
  return /** @type{!Array.<!proto.cts.PositionBrokerage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.PositionBrokerage, 1));
};


/** @param {!Array.<!proto.cts.PositionBrokerage>} value */
proto.cts.TP2TSPositionBrokerageRes.prototype.setInfoList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cts.PositionBrokerage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.PositionBrokerage}
 */
proto.cts.TP2TSPositionBrokerageRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cts.PositionBrokerage, opt_index);
};


proto.cts.TP2TSPositionBrokerageRes.prototype.clearInfoList = function() {
  this.setInfoList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.Kline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.Kline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.Kline.displayName = 'proto.cts.Kline';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.Kline.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.Kline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.Kline} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.Kline.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openprice: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    closeprice: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    highprice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    lowprice: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    volume: +jspb.Message.getFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.Kline}
 */
proto.cts.Kline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.Kline;
  return proto.cts.Kline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.Kline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.Kline}
 */
proto.cts.Kline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.Kline} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.Kline.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.Kline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.Kline.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = this.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getHighprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.cts.Kline.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.Kline.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double openPrice = 2;
 * @return {number}
 */
proto.cts.Kline.prototype.getOpenprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.Kline.prototype.setOpenprice = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double closePrice = 3;
 * @return {number}
 */
proto.cts.Kline.prototype.getCloseprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.Kline.prototype.setCloseprice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double highPrice = 4;
 * @return {number}
 */
proto.cts.Kline.prototype.getHighprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.Kline.prototype.setHighprice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double lowPrice = 5;
 * @return {number}
 */
proto.cts.Kline.prototype.getLowprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.Kline.prototype.setLowprice = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double volume = 6;
 * @return {number}
 */
proto.cts.Kline.prototype.getVolume = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cts.Kline.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QI2QSCandlestickRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QI2QSCandlestickRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QI2QSCandlestickRes.displayName = 'proto.cts.QI2QSCandlestickRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QI2QSCandlestickRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QI2QSCandlestickRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QI2QSCandlestickRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QI2QSCandlestickRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    kline: (f = msg.getKline()) && proto.cts.Kline.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QI2QSCandlestickRes}
 */
proto.cts.QI2QSCandlestickRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QI2QSCandlestickRes;
  return proto.cts.QI2QSCandlestickRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QI2QSCandlestickRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QI2QSCandlestickRes}
 */
proto.cts.QI2QSCandlestickRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {!proto.cts.EStampType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.cts.Kline;
      reader.readMessage(value,proto.cts.Kline.deserializeBinaryFromReader);
      msg.setKline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QI2QSCandlestickRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSCandlestickRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QI2QSCandlestickRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSCandlestickRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = this.getKline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.Kline.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QI2QSCandlestickRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.QI2QSCandlestickRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.QI2QSCandlestickRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QI2QSCandlestickRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional EStampType type = 2;
 * @return {!proto.cts.EStampType}
 */
proto.cts.QI2QSCandlestickRes.prototype.getType = function() {
  return /** @type {!proto.cts.EStampType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.cts.EStampType} value */
proto.cts.QI2QSCandlestickRes.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional Kline kline = 3;
 * @return {?proto.cts.Kline}
 */
proto.cts.QI2QSCandlestickRes.prototype.getKline = function() {
  return /** @type{?proto.cts.Kline} */ (
    jspb.Message.getWrapperField(this, proto.cts.Kline, 3));
};


/** @param {?proto.cts.Kline|undefined} value */
proto.cts.QI2QSCandlestickRes.prototype.setKline = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.QI2QSCandlestickRes.prototype.clearKline = function() {
  this.setKline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.QI2QSCandlestickRes.prototype.hasKline = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QuotePriceQty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QuotePriceQty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QuotePriceQty.displayName = 'proto.cts.QuotePriceQty';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QuotePriceQty.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QuotePriceQty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QuotePriceQty} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QuotePriceQty.toObject = function(includeInstance, msg) {
  var f, obj = {
    qbidprice: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    qbidqty: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    qaskprice: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    qaskqty: +jspb.Message.getFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QuotePriceQty}
 */
proto.cts.QuotePriceQty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QuotePriceQty;
  return proto.cts.QuotePriceQty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QuotePriceQty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QuotePriceQty}
 */
proto.cts.QuotePriceQty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQbidprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQbidqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQaskprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQaskqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QuotePriceQty} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QuotePriceQty.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QuotePriceQty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QuotePriceQty.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getQbidprice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = this.getQbidqty();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getQaskprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getQaskqty();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double qBidPrice = 1;
 * @return {number}
 */
proto.cts.QuotePriceQty.prototype.getQbidprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.cts.QuotePriceQty.prototype.setQbidprice = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double qBidQty = 2;
 * @return {number}
 */
proto.cts.QuotePriceQty.prototype.getQbidqty = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.QuotePriceQty.prototype.setQbidqty = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double qAskPrice = 3;
 * @return {number}
 */
proto.cts.QuotePriceQty.prototype.getQaskprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.QuotePriceQty.prototype.setQaskprice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double qAskQty = 4;
 * @return {number}
 */
proto.cts.QuotePriceQty.prototype.getQaskqty = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.QuotePriceQty.prototype.setQaskqty = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QI2QSMarketDepthRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.QI2QSMarketDepthRes.repeatedFields_, null);
};
goog.inherits(proto.cts.QI2QSMarketDepthRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QI2QSMarketDepthRes.displayName = 'proto.cts.QI2QSMarketDepthRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.QI2QSMarketDepthRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QI2QSMarketDepthRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QI2QSMarketDepthRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QI2QSMarketDepthRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QI2QSMarketDepthRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quotepriceqtyList: jspb.Message.toObjectList(msg.getQuotepriceqtyList(),
    proto.cts.QuotePriceQty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QI2QSMarketDepthRes}
 */
proto.cts.QI2QSMarketDepthRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QI2QSMarketDepthRes;
  return proto.cts.QI2QSMarketDepthRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QI2QSMarketDepthRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QI2QSMarketDepthRes}
 */
proto.cts.QI2QSMarketDepthRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new proto.cts.QuotePriceQty;
      reader.readMessage(value,proto.cts.QuotePriceQty.deserializeBinaryFromReader);
      msg.addQuotepriceqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QI2QSMarketDepthRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSMarketDepthRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QI2QSMarketDepthRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSMarketDepthRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = this.getQuotepriceqtyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cts.QuotePriceQty.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QI2QSMarketDepthRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.QI2QSMarketDepthRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.QI2QSMarketDepthRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QI2QSMarketDepthRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.cts.QI2QSMarketDepthRes.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDepthRes.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * repeated QuotePriceQty quotePriceQty = 3;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.QuotePriceQty>}
 */
proto.cts.QI2QSMarketDepthRes.prototype.getQuotepriceqtyList = function() {
  return /** @type{!Array.<!proto.cts.QuotePriceQty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.QuotePriceQty, 3));
};


/** @param {!Array.<!proto.cts.QuotePriceQty>} value */
proto.cts.QI2QSMarketDepthRes.prototype.setQuotepriceqtyList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cts.QuotePriceQty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.QuotePriceQty}
 */
proto.cts.QI2QSMarketDepthRes.prototype.addQuotepriceqty = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cts.QuotePriceQty, opt_index);
};


proto.cts.QI2QSMarketDepthRes.prototype.clearQuotepriceqtyList = function() {
  this.setQuotepriceqtyList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QI2QSMarketDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QI2QSMarketDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QI2QSMarketDetailRes.displayName = 'proto.cts.QI2QSMarketDetailRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QI2QSMarketDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QI2QSMarketDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QI2QSMarketDetailRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QI2QSMarketDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    openprice: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    closeprice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    highprice: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    lowprice: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    volume: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QI2QSMarketDetailRes}
 */
proto.cts.QI2QSMarketDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QI2QSMarketDetailRes;
  return proto.cts.QI2QSMarketDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QI2QSMarketDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QI2QSMarketDetailRes}
 */
proto.cts.QI2QSMarketDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCloseprice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighprice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowprice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QI2QSMarketDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSMarketDetailRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QI2QSMarketDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSMarketDetailRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = this.getOpenprice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getCloseprice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = this.getHighprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = this.getLowprice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = this.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = this.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = this.getCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QI2QSMarketDetailRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double openPrice = 3;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getOpenprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setOpenprice = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional double closePrice = 4;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getCloseprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setCloseprice = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double highPrice = 5;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getHighprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setHighprice = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional double lowPrice = 6;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getLowprice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setLowprice = function(value) {
  jspb.Message.setField(this, 6, value);
};


/**
 * optional double amount = 7;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setAmount = function(value) {
  jspb.Message.setField(this, 7, value);
};


/**
 * optional double volume = 8;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getVolume = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setVolume = function(value) {
  jspb.Message.setField(this, 8, value);
};


/**
 * optional int64 count = 9;
 * @return {number}
 */
proto.cts.QI2QSMarketDetailRes.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cts.QI2QSMarketDetailRes.prototype.setCount = function(value) {
  jspb.Message.setField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.TradeDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.TradeDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.TradeDetail.displayName = 'proto.cts.TradeDetail';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.TradeDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.TradeDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.TradeDetail} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.TradeDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.TradeDetail}
 */
proto.cts.TradeDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.TradeDetail;
  return proto.cts.TradeDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.TradeDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.TradeDetail}
 */
proto.cts.TradeDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.TradeDetail} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TradeDetail.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.TradeDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.TradeDetail.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = this.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = this.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = this.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.cts.TradeDetail.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.TradeDetail.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.cts.TradeDetail.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cts.TradeDetail.prototype.setPrice = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.cts.TradeDetail.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.cts.TradeDetail.prototype.setAmount = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 direction = 4;
 * @return {number}
 */
proto.cts.TradeDetail.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cts.TradeDetail.prototype.setDirection = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QI2QSTradeDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.QI2QSTradeDetailRes.repeatedFields_, null);
};
goog.inherits(proto.cts.QI2QSTradeDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QI2QSTradeDetailRes.displayName = 'proto.cts.QI2QSTradeDetailRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.QI2QSTradeDetailRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QI2QSTradeDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QI2QSTradeDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QI2QSTradeDetailRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QI2QSTradeDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    tradedetailList: jspb.Message.toObjectList(msg.getTradedetailList(),
    proto.cts.TradeDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QI2QSTradeDetailRes}
 */
proto.cts.QI2QSTradeDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QI2QSTradeDetailRes;
  return proto.cts.QI2QSTradeDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QI2QSTradeDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QI2QSTradeDetailRes}
 */
proto.cts.QI2QSTradeDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = new proto.cts.TradeDetail;
      reader.readMessage(value,proto.cts.TradeDetail.deserializeBinaryFromReader);
      msg.addTradedetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QI2QSTradeDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSTradeDetailRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QI2QSTradeDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSTradeDetailRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTradedetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cts.TradeDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QI2QSTradeDetailRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.QI2QSTradeDetailRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.QI2QSTradeDetailRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QI2QSTradeDetailRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated TradeDetail tradeDetail = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.TradeDetail>}
 */
proto.cts.QI2QSTradeDetailRes.prototype.getTradedetailList = function() {
  return /** @type{!Array.<!proto.cts.TradeDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.TradeDetail, 2));
};


/** @param {!Array.<!proto.cts.TradeDetail>} value */
proto.cts.QI2QSTradeDetailRes.prototype.setTradedetailList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cts.TradeDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.TradeDetail}
 */
proto.cts.QI2QSTradeDetailRes.prototype.addTradedetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cts.TradeDetail, opt_index);
};


proto.cts.QI2QSTradeDetailRes.prototype.clearTradedetailList = function() {
  this.setTradedetailList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QI2QSTradeBboRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QI2QSTradeBboRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QI2QSTradeBboRes.displayName = 'proto.cts.QI2QSTradeBboRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QI2QSTradeBboRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QI2QSTradeBboRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QI2QSTradeBboRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QI2QSTradeBboRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    codenum: msg.getCodenum_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    quotepriceqty: (f = msg.getQuotepriceqty()) && proto.cts.QuotePriceQty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QI2QSTradeBboRes}
 */
proto.cts.QI2QSTradeBboRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QI2QSTradeBboRes;
  return proto.cts.QI2QSTradeBboRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QI2QSTradeBboRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QI2QSTradeBboRes}
 */
proto.cts.QI2QSTradeBboRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new proto.cts.QuotePriceQty;
      reader.readMessage(value,proto.cts.QuotePriceQty.deserializeBinaryFromReader);
      msg.setQuotepriceqty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QI2QSTradeBboRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSTradeBboRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QI2QSTradeBboRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QI2QSTradeBboRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = this.getQuotepriceqty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cts.QuotePriceQty.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes codeNum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QI2QSTradeBboRes.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes codeNum = 1;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.QI2QSTradeBboRes.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.QI2QSTradeBboRes.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QI2QSTradeBboRes.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.cts.QI2QSTradeBboRes.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.QI2QSTradeBboRes.prototype.setTimestamp = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional QuotePriceQty quotePriceQty = 3;
 * @return {?proto.cts.QuotePriceQty}
 */
proto.cts.QI2QSTradeBboRes.prototype.getQuotepriceqty = function() {
  return /** @type{?proto.cts.QuotePriceQty} */ (
    jspb.Message.getWrapperField(this, proto.cts.QuotePriceQty, 3));
};


/** @param {?proto.cts.QuotePriceQty|undefined} value */
proto.cts.QI2QSTradeBboRes.prototype.setQuotepriceqty = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cts.QI2QSTradeBboRes.prototype.clearQuotepriceqty = function() {
  this.setQuotepriceqty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cts.QI2QSTradeBboRes.prototype.hasQuotepriceqty = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QC2QSLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QC2QSLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QC2QSLoginReq.displayName = 'proto.cts.QC2QSLoginReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QC2QSLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QC2QSLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QC2QSLoginReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QC2QSLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authcode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QC2QSLoginReq}
 */
proto.cts.QC2QSLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QC2QSLoginReq;
  return proto.cts.QC2QSLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QC2QSLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QC2QSLoginReq}
 */
proto.cts.QC2QSLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QC2QSLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSLoginReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QC2QSLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSLoginReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getAuthcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string accountName = 1;
 * @return {string}
 */
proto.cts.QC2QSLoginReq.prototype.getAccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.QC2QSLoginReq.prototype.setAccountname = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.cts.QC2QSLoginReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.QC2QSLoginReq.prototype.setPassword = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional string authCode = 3;
 * @return {string}
 */
proto.cts.QC2QSLoginReq.prototype.getAuthcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cts.QC2QSLoginReq.prototype.setAuthcode = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QS2QCLoginRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QS2QCLoginRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QS2QCLoginRet.displayName = 'proto.cts.QS2QCLoginRet';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QS2QCLoginRet.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QS2QCLoginRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QS2QCLoginRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QS2QCLoginRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultmsg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QS2QCLoginRet}
 */
proto.cts.QS2QCLoginRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QS2QCLoginRet;
  return proto.cts.QS2QCLoginRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QS2QCLoginRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QS2QCLoginRet}
 */
proto.cts.QS2QCLoginRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultmsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QS2QCLoginRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCLoginRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QS2QCLoginRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCLoginRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getResultmsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.QS2QCLoginRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.QS2QCLoginRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string resultMsg = 2;
 * @return {string}
 */
proto.cts.QS2QCLoginRet.prototype.getResultmsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.QS2QCLoginRet.prototype.setResultmsg = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QC2QSSubMarketReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.QC2QSSubMarketReq.repeatedFields_, null);
};
goog.inherits(proto.cts.QC2QSSubMarketReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QC2QSSubMarketReq.displayName = 'proto.cts.QC2QSSubMarketReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.QC2QSSubMarketReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QC2QSSubMarketReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QC2QSSubMarketReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QC2QSSubMarketReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QC2QSSubMarketReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractList: msg.getContractList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QC2QSSubMarketReq}
 */
proto.cts.QC2QSSubMarketReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QC2QSSubMarketReq;
  return proto.cts.QC2QSSubMarketReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QC2QSSubMarketReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QC2QSSubMarketReq}
 */
proto.cts.QC2QSSubMarketReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QC2QSSubMarketReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSSubMarketReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QC2QSSubMarketReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSSubMarketReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.cts.QC2QSSubMarketReq.prototype.getContractList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getField(this, 1));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<string>}
 */
proto.cts.QC2QSSubMarketReq.prototype.getContractList_asB64 = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.bytesListAsB64(
      this.getContractList()));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<!Uint8Array>}
 */
proto.cts.QC2QSSubMarketReq.prototype.getContractList_asU8 = function() {
  return /** @type {!Array.<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getContractList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.cts.QC2QSSubMarketReq.prototype.setContractList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.cts.QC2QSSubMarketReq.prototype.addContract = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cts.QC2QSSubMarketReq.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QS2QCSubMarketRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QS2QCSubMarketRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QS2QCSubMarketRet.displayName = 'proto.cts.QS2QCSubMarketRet';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QS2QCSubMarketRet.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QS2QCSubMarketRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QS2QCSubMarketRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QS2QCSubMarketRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: msg.getContract_asB64(),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QS2QCSubMarketRet}
 */
proto.cts.QS2QCSubMarketRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QS2QCSubMarketRet;
  return proto.cts.QS2QCSubMarketRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QS2QCSubMarketRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QS2QCSubMarketRet}
 */
proto.cts.QS2QCSubMarketRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QS2QCSubMarketRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCSubMarketRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QS2QCSubMarketRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCSubMarketRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContract_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bytes contract = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QS2QCSubMarketRet.prototype.getContract = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contract = 1;
 * This is a type-conversion wrapper around `getContract()`
 * @return {string}
 */
proto.cts.QS2QCSubMarketRet.prototype.getContract_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContract()));
};


/**
 * optional bytes contract = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContract()`
 * @return {!Uint8Array}
 */
proto.cts.QS2QCSubMarketRet.prototype.getContract_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContract()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QS2QCSubMarketRet.prototype.setContract = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.cts.QS2QCSubMarketRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.QS2QCSubMarketRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QC2QSUnSubMarketReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.QC2QSUnSubMarketReq.repeatedFields_, null);
};
goog.inherits(proto.cts.QC2QSUnSubMarketReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QC2QSUnSubMarketReq.displayName = 'proto.cts.QC2QSUnSubMarketReq';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.QC2QSUnSubMarketReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QC2QSUnSubMarketReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QC2QSUnSubMarketReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QC2QSUnSubMarketReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QC2QSUnSubMarketReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractList: msg.getContractList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QC2QSUnSubMarketReq}
 */
proto.cts.QC2QSUnSubMarketReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QC2QSUnSubMarketReq;
  return proto.cts.QC2QSUnSubMarketReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QC2QSUnSubMarketReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QC2QSUnSubMarketReq}
 */
proto.cts.QC2QSUnSubMarketReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QC2QSUnSubMarketReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSUnSubMarketReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QC2QSUnSubMarketReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSUnSubMarketReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContractList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.cts.QC2QSUnSubMarketReq.prototype.getContractList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getField(this, 1));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<string>}
 */
proto.cts.QC2QSUnSubMarketReq.prototype.getContractList_asB64 = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.bytesListAsB64(
      this.getContractList()));
};


/**
 * repeated bytes contract = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContractList()`
 * @return {!Array.<!Uint8Array>}
 */
proto.cts.QC2QSUnSubMarketReq.prototype.getContractList_asU8 = function() {
  return /** @type {!Array.<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getContractList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.cts.QC2QSUnSubMarketReq.prototype.setContractList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.cts.QC2QSUnSubMarketReq.prototype.addContract = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cts.QC2QSUnSubMarketReq.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QS2QCUnSubMarketRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QS2QCUnSubMarketRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QS2QCUnSubMarketRet.displayName = 'proto.cts.QS2QCUnSubMarketRet';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QS2QCUnSubMarketRet.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QS2QCUnSubMarketRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QS2QCUnSubMarketRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QS2QCUnSubMarketRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: msg.getContract_asB64(),
    resultcode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QS2QCUnSubMarketRet}
 */
proto.cts.QS2QCUnSubMarketRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QS2QCUnSubMarketRet;
  return proto.cts.QS2QCUnSubMarketRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QS2QCUnSubMarketRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QS2QCUnSubMarketRet}
 */
proto.cts.QS2QCUnSubMarketRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QS2QCUnSubMarketRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCUnSubMarketRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QS2QCUnSubMarketRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCUnSubMarketRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getContract_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bytes contract = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cts.QS2QCUnSubMarketRet.prototype.getContract = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contract = 1;
 * This is a type-conversion wrapper around `getContract()`
 * @return {string}
 */
proto.cts.QS2QCUnSubMarketRet.prototype.getContract_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContract()));
};


/**
 * optional bytes contract = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContract()`
 * @return {!Uint8Array}
 */
proto.cts.QS2QCUnSubMarketRet.prototype.getContract_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContract()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.QS2QCUnSubMarketRet.prototype.setContract = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 resultCode = 2;
 * @return {number}
 */
proto.cts.QS2QCUnSubMarketRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cts.QS2QCUnSubMarketRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QC2QSHeartbeatReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QC2QSHeartbeatReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QC2QSHeartbeatReq.displayName = 'proto.cts.QC2QSHeartbeatReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QC2QSHeartbeatReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QC2QSHeartbeatReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QC2QSHeartbeatReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QC2QSHeartbeatReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QC2QSHeartbeatReq}
 */
proto.cts.QC2QSHeartbeatReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QC2QSHeartbeatReq;
  return proto.cts.QC2QSHeartbeatReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QC2QSHeartbeatReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QC2QSHeartbeatReq}
 */
proto.cts.QC2QSHeartbeatReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QC2QSHeartbeatReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSHeartbeatReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QC2QSHeartbeatReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QC2QSHeartbeatReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QS2QCHeartbeatRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.QS2QCHeartbeatRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QS2QCHeartbeatRes.displayName = 'proto.cts.QS2QCHeartbeatRes';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QS2QCHeartbeatRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QS2QCHeartbeatRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QS2QCHeartbeatRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QS2QCHeartbeatRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QS2QCHeartbeatRes}
 */
proto.cts.QS2QCHeartbeatRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QS2QCHeartbeatRes;
  return proto.cts.QS2QCHeartbeatRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QS2QCHeartbeatRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QS2QCHeartbeatRes}
 */
proto.cts.QS2QCHeartbeatRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QS2QCHeartbeatRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCHeartbeatRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QS2QCHeartbeatRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2QCHeartbeatRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.QS2QCHeartbeatRes.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.QS2QCHeartbeatRes.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.QS2CContractListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.QS2CContractListRes.repeatedFields_, null);
};
goog.inherits(proto.cts.QS2CContractListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.QS2CContractListRes.displayName = 'proto.cts.QS2CContractListRes';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.QS2CContractListRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.QS2CContractListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.QS2CContractListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.QS2CContractListRes} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.QS2CContractListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    rate: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    contractList: jspb.Message.toObjectList(msg.getContractList(),
    proto.cts.Contract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.QS2CContractListRes}
 */
proto.cts.QS2CContractListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.QS2CContractListRes;
  return proto.cts.QS2CContractListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.QS2CContractListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.QS2CContractListRes}
 */
proto.cts.QS2CContractListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    case 2:
      var value = new proto.cts.Contract;
      reader.readMessage(value,proto.cts.Contract.deserializeBinaryFromReader);
      msg.addContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.QS2CContractListRes} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2CContractListRes.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.QS2CContractListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.QS2CContractListRes.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = this.getContractList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cts.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional double rate = 1;
 * @return {number}
 */
proto.cts.QS2CContractListRes.prototype.getRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.cts.QS2CContractListRes.prototype.setRate = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated Contract contract = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.Contract>}
 */
proto.cts.QS2CContractListRes.prototype.getContractList = function() {
  return /** @type{!Array.<!proto.cts.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.Contract, 2));
};


/** @param {!Array.<!proto.cts.Contract>} value */
proto.cts.QS2CContractListRes.prototype.setContractList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cts.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.Contract}
 */
proto.cts.QS2CContractListRes.prototype.addContract = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cts.Contract, opt_index);
};


proto.cts.QS2CContractListRes.prototype.clearContractList = function() {
  this.setContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.C2KSKlineListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cts.C2KSKlineListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.C2KSKlineListReq.displayName = 'proto.cts.C2KSKlineListReq';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.C2KSKlineListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.C2KSKlineListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.C2KSKlineListReq} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.C2KSKlineListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    codenum: msg.getCodenum_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.C2KSKlineListReq}
 */
proto.cts.C2KSKlineListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.C2KSKlineListReq;
  return proto.cts.C2KSKlineListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.C2KSKlineListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.C2KSKlineListReq}
 */
proto.cts.C2KSKlineListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {!proto.cts.EStampType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.C2KSKlineListReq} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2KSKlineListReq.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.C2KSKlineListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.C2KSKlineListReq.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string accountName = 1;
 * @return {string}
 */
proto.cts.C2KSKlineListReq.prototype.getAccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cts.C2KSKlineListReq.prototype.setAccountname = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.cts.C2KSKlineListReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cts.C2KSKlineListReq.prototype.setPassword = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional EStampType type = 3;
 * @return {!proto.cts.EStampType}
 */
proto.cts.C2KSKlineListReq.prototype.getType = function() {
  return /** @type {!proto.cts.EStampType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cts.EStampType} value */
proto.cts.C2KSKlineListReq.prototype.setType = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional bytes codeNum = 4;
 * @return {!(string|Uint8Array)}
 */
proto.cts.C2KSKlineListReq.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes codeNum = 4;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.C2KSKlineListReq.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.C2KSKlineListReq.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.C2KSKlineListReq.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cts.KS2CKlineListRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cts.KS2CKlineListRet.repeatedFields_, null);
};
goog.inherits(proto.cts.KS2CKlineListRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cts.KS2CKlineListRet.displayName = 'proto.cts.KS2CKlineListRet';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cts.KS2CKlineListRet.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cts.KS2CKlineListRet.prototype.toObject = function(opt_includeInstance) {
  return proto.cts.KS2CKlineListRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cts.KS2CKlineListRet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.cts.KS2CKlineListRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    codenum: msg.getCodenum_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cts.Kline.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cts.KS2CKlineListRet}
 */
proto.cts.KS2CKlineListRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cts.KS2CKlineListRet;
  return proto.cts.KS2CKlineListRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cts.KS2CKlineListRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cts.KS2CKlineListRet}
 */
proto.cts.KS2CKlineListRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultcode(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCodenum(value);
      break;
    case 3:
      var value = /** @type {!proto.cts.EStampType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.cts.Kline;
      reader.readMessage(value,proto.cts.Kline.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.cts.KS2CKlineListRet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.KS2CKlineListRet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cts.KS2CKlineListRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.cts.KS2CKlineListRet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getResultcode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getCodenum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = this.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cts.Kline.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 resultCode = 1;
 * @return {number}
 */
proto.cts.KS2CKlineListRet.prototype.getResultcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cts.KS2CKlineListRet.prototype.setResultcode = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional bytes codeNum = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cts.KS2CKlineListRet.prototype.getCodenum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes codeNum = 2;
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {string}
 */
proto.cts.KS2CKlineListRet.prototype.getCodenum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCodenum()));
};


/**
 * optional bytes codeNum = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCodenum()`
 * @return {!Uint8Array}
 */
proto.cts.KS2CKlineListRet.prototype.getCodenum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCodenum()));
};


/** @param {!(string|Uint8Array)} value */
proto.cts.KS2CKlineListRet.prototype.setCodenum = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional EStampType type = 3;
 * @return {!proto.cts.EStampType}
 */
proto.cts.KS2CKlineListRet.prototype.getType = function() {
  return /** @type {!proto.cts.EStampType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.cts.EStampType} value */
proto.cts.KS2CKlineListRet.prototype.setType = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * repeated Kline list = 4;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.cts.Kline>}
 */
proto.cts.KS2CKlineListRet.prototype.getListList = function() {
  return /** @type{!Array.<!proto.cts.Kline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cts.Kline, 4));
};


/** @param {!Array.<!proto.cts.Kline>} value */
proto.cts.KS2CKlineListRet.prototype.setListList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cts.Kline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cts.Kline}
 */
proto.cts.KS2CKlineListRet.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cts.Kline, opt_index);
};


proto.cts.KS2CKlineListRet.prototype.clearListList = function() {
  this.setListList([]);
};


/**
 * @enum {number}
 */
proto.cts.EStampType = {
  ENUMEST1MIN: 0,
  ENUMEST3MIN: 1,
  ENUMEST5MIN: 2,
  ENUMEST15MIN: 3,
  ENUMEST30MIN: 4,
  ENUMEST1HOUR: 5,
  ENUMEST2HOUR: 6,
  ENUMEST4HOUR: 7,
  ENUMEST1DAY: 8,
  ENUMEST1WEEK: 9,
  ENUMEST1MON: 10
};

goog.object.extend(exports, proto.cts);
