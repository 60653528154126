import { baseMethods } from '@/utils/methods.js';
import store from '@/store'

//价格格式化
export function moneyFormat(x,n){

	var f = parseFloat(x);
	var basenum=1;
	for (var i = 0; i < n; i++) {
		basenum=basenum+"0"
	}
	basenum=basenum*1;
	if (isNaN(f)) {
		return false;
	}
	f = Math.floor(x * basenum) / basenum;
	var s = f.toString();
	var rs = s.indexOf(".");
	if (rs < 0) {
		rs = s.length;
		s += ".";
	}
	while (s.length <= rs + n) {
		s += "0";
	}
	return s;
}
//最新价格式化
export function newPriceFormat(x, n){
	if(x == 0){
		return '- -'
	} else {
		var f = parseFloat(x);
		var basenum = 1;
		for (var i = 0; i < n; i++) {
			basenum = basenum + "0"
		}
		basenum = basenum * 1;
		if (isNaN(f)) {
			return false;
		}
		f = Math.floor(x * basenum) / basenum;
		var s = f.toString();
		var rs = s.indexOf(".");
		if (rs < 0) {
			rs = s.length;
			s += ".";
		}
		while (s.length <= rs + n) {
			s += "0";
		}
		return s;	
	}
}
//日期时间
export function toDateTY(timestamp,type) {
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + (date.getDate()) : (date.getDate())) + ' ';

    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() ;
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    if(type){
         return Y + M + D
    }else{
         return M + D + h +':'+ m 
    }
};
// 开仓手续费
export function openFee(open,num,val){
	// 手续费=持仓均价*张数*面值*费率
	var holdfee;
    var t1 = baseMethods.accMul(num, store.state.tradeFee.open);
    var t2 = baseMethods.accMul(open,val);
    holdfee = baseMethods.accMul(t1,t2);
    holdfee = baseMethods.moneyFormat(holdfee,3);
	return holdfee;
}
export function hold_floatProfit(nrice, dec, hprice, val, num, deposit, use){
	var balance = 0, d_rate = 0;
	if (dec == 0) {
		// 多单：(最新价-持仓均价)*合约面值*张数
		var n1 = baseMethods.accSub(nrice, hprice) ;
		var n2 = baseMethods.accMul(val, num);
		balance = baseMethods.accMul(n1, n2);
	} else {
		//空单 (持仓均价-最新价)*合约面值*张数
		var n1 = baseMethods.accSub(hprice, nrice) ;
		var n2 = baseMethods.accMul(val, num);
		balance = baseMethods.accMul(n1, n2);
	}
	balance = baseMethods.moneyFormat(balance, 4);
	d_rate= baseMethods.accDiv(balance, deposit) *100;
    d_rate = baseMethods.moneyFormat(d_rate, 2);
    if (use == "float") {
    	return balance;
    } else if(use == "rate"){
    	return d_rate + "%";
    }
}
//价钱格式化
export function price_format(price, code){
	var float=0;//,最小波动小数位数
	store.state.contractList.forEach(item=>{
		if(item.getContractcode() == code){
			var hy_wave=item.getPricetick();//最小波动
			var y = String(hy_wave).indexOf(".") + 1;
			var count = String(hy_wave).length - y;
			if(y > 0) {
				float=count
			} else {
				float=0
			};
		}
	})
	var price = baseMethods.moneyFormat(price, float);
	return price;
}
// export function price_formatQh(price,code){
// 	var float=0;//,最小波动小数位数
// 	store.state.contractListQh.forEach(item=>{
// 		if(item.getContractcode() == code){
// 			var hy_wave=item.getPricetick();//最小波动
// 			var y = String(hy_wave).indexOf(".") + 1;
// 			var count = String(hy_wave).length - y;
// 			if(y > 0) {
// 				float=count
// 			} else {
// 				float=0
// 			};
// 		}
// 	})
// 	var price = baseMethods.moneyFormat(price,float);
// 	return price;
// }
//涨跌颜色输出,涨跌百分比
export function classUpOrDown(nprice,openprice,useType){
	if(nprice<=0){
		return;
	}
	var priceRange=0,ncPrice=0,className='',classBgName='';
	if(nprice == 0 || openprice==0){
		priceRange = 0 +'%';
		ncPrice=0;
		className = "color_up";
		classBgName = "bg_up";
	}else{
		ncPrice= nprice - openprice;
		priceRange= ncPrice / openprice;
		priceRange = moneyFormat(priceRange * 100,2);
		priceRange = priceRange + '%';  
	}
	if(ncPrice >= 0){
		priceRange='+'+priceRange;
		className = "color_up";
		classBgName = "bg_up";
	}else{
		className = "color_down";
		classBgName = "bg_down";
	}
	// useType  :  color,bgColor,rate
	if(useType == "color"){
		return className;
	}else if(useType == "bgColor"){
		return classBgName;
	}else{
		return priceRange;
	}
}