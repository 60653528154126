import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n' // 导入i18n配置
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from '@/utils/api'
import '@/assets/scss/reset.scss'
import { getIndexStyle } from '@/tools/theme/index'
import VueQr from 'vue-qr'


// import trade_ws from '@/utils/trade-ws';
// import	quotes_ws from '@/utils/quotes-ws';
// import * as filters from '@/utils/filters.js'
// import { hex_md5 } from '@/utils/md5.js';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
// Vue.prototype.md5 = hex_md5;
// Vue.prototype.tradeWS = trade_ws;
// Vue.prototype.quotesWS = quotes_ws;

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.component('vue-qr', VueQr);

/* 初始化主题样式 */
getIndexStyle().then(() => {
  store.dispatch('changeThemeStyle')
});

var vue = new Vue({
  i18n, // 将i18n实例注入到Vue实例
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

export default vue