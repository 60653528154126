import { render, staticRenderFns } from "./treasure.vue?vue&type=template&id=b625072c&scoped=true&"
import script from "./treasure.vue?vue&type=script&lang=js&"
export * from "./treasure.vue?vue&type=script&lang=js&"
import style0 from "./treasure.vue?vue&type=style&index=0&id=b625072c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b625072c",
  null
  
)

export default component.exports