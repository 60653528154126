<template>
    <div class="accountRecord">
        <div>
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                    @back="goBack" 
                    :content="i18n('heyuezhanghuliushui')"
                    :title="i18n('fanhui')"
                    style="margin-left: 10px;">
                </el-page-header>   
            </div>

            <div class="selectpart flex_row_left" style="background-color: #F9F9F8; border-top: 1px solid #ddd; border-left: 1px solid #ddd;border-right: 1px solid #ddd;">
                <div style="margin-left: 10px;"> {{ i18n('heyuejiaoyiliushuileixing') }} </div>
                <el-select 
                    v-model="reocrdType" 
                    :placeholder="i18n('qingxuanze')" 
                    size="mini" 
                    style="margin-left: 10px;"
                    @change="reocrdTypeChange">
                    <el-option
                        v-for="item in recordTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

                <div style="margin-left: 10px;"> {{ i18n('chaxunriqi') }} </div>
                <el-date-picker
                    v-model="queryDate"
                    size="mini"
                    type="date"
                    style="margin-left: 10px; width: 250px;" 
                    @change="queryDatechange"
                    placeholder="yyyy-MM-dd"></el-date-picker>
            </div>
        </div>
        <div class="orderlist" style="width: 1200px; border: 1px solid #ddd;">
            <el-table
                :data="recordListData"
                style="width: 100%;"
                :empty-text="i18n('zanwushuju')"
                :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                :row-style="{background:'#F9F9F8'}">
                <el-table-column
                    :label="i18n('liushuileixing')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{scope.row.type}} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('shijian')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{scope.row.time | timeFormat('ymdhms')}} </span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('biandongjine')"
                    min-width="100">
                    <template slot-scope="scope">
                        <span :class="scope.row.changeNum < 0 ? 'color_green' : 'color_red'"> 
                            {{ scope.row.changeNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="i18n('yue') + '(USDT)'"
                    min-width="100">
                    <template slot-scope="scope">
                        <span style="color: #000;"> 
                            {{scope.row.remainNum}} </span>
                    </template>
                </el-table-column>
            </el-table>

            <div class="block">
                <el-pagination
                    background
                    hide-on-single-page
                    :page-size=page_size
                    :pager-count="11"
                    layout="prev, pager, next"
                    :current-page.sync=page
                    @current-change="handleCurrentChange"
                    class="flex_row_right"
                    :total=totalCount>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            recordTypeList: [
                {
                    value: '0',
                    label: this.$t("contractAccountRecord")['quanbu']
                }, 
                {
                    value: '1',
                    label: this.$t("contractAccountRecord")['kaicangshouxufei']
                }, 
                {
                    value: '2',
                    label: this.$t("contractAccountRecord")['pingcangshouxufei']
                }, 
                {
                    value: '3',
                    label: this.$t("contractAccountRecord")['pingcangyingkui']
                }, 
                {
                    value: '4',
                    label: this.$t("contractAccountRecord")['fabizhuanheyue']
                },
                {
                    value: '5',
                    label: this.$t("contractAccountRecord")['heyuezhuanfabi']
                },
                {
                    value: '6',
                    label: this.$t("contractAccountRecord")['zhuanzhang']
                },
                {
                    value: '7',
                    label: this.$t("contractAccountRecord")['kaicangyongjin']
                },
                {
                    value: '8',
                    label: this.$t("contractAccountRecord")['pingcangyongjin']
                },
            ],
            reocrdType: '0',
            queryDate: undefined,
            recordListData: [],
            page: 1,
            page_size: 10,
            startime: '',
            endtime: '',
            totalCount: 0,
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getList();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getList();
        };
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("contractAccountRecord")[name];
            }
		},
    },
    filters: {
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() < 10 ? '0' + needdate.getDate() : needdate.getDate();
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + ' ' + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        queryDatechange() {
            if (this.queryDate !== null) {
                this.startime = this.queryDate / 1000;
                this.endtime = this.startime + 24 * 60 * 60 - 1;
            } else {
                this.startime = '';
                this.endtime = '';
            }
            this.getList();
        },
        reocrdTypeChange() {
            this.getList();
        },
        recvdateParse(list) {
            this.recordListData = [];
            list.forEach(item => {
                var strtype = '';
                if (item.type == 1) { 
                    strtype = this.$t("contractAccountRecord")['kaicangshouxufei'];
                } else if (item.type == 2) {
                    strtype = this.$t("contractAccountRecord")['pingcangshouxufei'];
                } else if (item.type == 3) {
                    strtype = this.$t("contractAccountRecord")['pingcangyingkui'];
                } else if (item.type == 4) {
                    strtype = this.$t("contractAccountRecord")['fabizhuanheyue'];
                } else if (item.type == 5) {
                    strtype = this.$t("contractAccountRecord")['heyuezhuanfabi'];
                } else if (item.type == 6) {
                    strtype = this.$t("contractAccountRecord")['zhuanzhang'];
                } else if (item.type == 7) {
                    strtype = this.$t("contractAccountRecord")['kaicangyongjin'];
                } else if (item.type == 8) {
                    strtype = this.$t("contractAccountRecord")['pingcangyongjin'];
                }

                var obj = {};
                obj.type = strtype;
                obj.time = item.time;
                obj.changeNum = item.changeNum;
                obj.remainNum = item.remainNum;
                this.recordListData.push(obj);
            });
        },
        async getList() {
            this.page = 1;
            let data = {
                token: sessionStorage.getItem("tk"),
                page: this.page,
                rows: this.page_size,
                type: this.reocrdType,
                createStartTime: this.startime,
                createEndTime: this.endtime,
				lang:'big5'
            }
            this.$api.getcontractTransactionFlowList(data).then((res)=>{
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                    this.totalCount = Number(res.data.data.count);
                } else {
                    this.$message({
                        message: this.$t('contractAccountRecord')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        async getmoreList() {
            let data = {
                token: sessionStorage.getItem("tk"),
                page: this.page,
                rows: this.page_size,
                type: this.reocrdType,
                createStartTime: this.startime,
                createEndTime: this.endtime,
				lang:'big5'
            }
            this.$api.getcontractTransactionFlowList(data).then((res)=>{
                if(res.data.code == 200){
                    this.recvdateParse(res.data.data.list) ;
                } else {
                    this.$message({
                        message: this.$t('contractAccountRecord')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
        handleCurrentChange() {
            this.getmoreList();
        },
    },
    
}
</script>

<style>
.accountRecord {
    background: #F9F9FA;
    height: auto;
    min-width: 1200px;
    margin: 0 auto;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.selectpart {
    padding: 15px 0 20px 0;
    margin: 0 auto;
    width: 1200px;
}

.color_red {
    color: #1763B6;
}

.color_green {
    color: #E12343;
}

</style>

<style scoped>

</style>