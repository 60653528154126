<template>
    <div class="middle">
        <div class="ding"></div>
        <div class="user Sign">
            <div class="user-gang">|</div>
            <h1> {{ i18n('yonghudenglu') }} </h1><br>
            <div class="user-fbex"> 
                {{ i18n('huanyingdenglu') }} 
                <span style="color: #08BD98;"> {{ i18n('xiangmumingcheng') }} </span> 
                {{ i18n('guanfangwangzhan') }} 
            </div>
            <div class="user-id">
                {{ i18n('youxiang') }} <br>
                <el-input 
                    v-model="inputid" 
                    type="text" 
                    size="medium" 
                    class="input-id"
                    :placeholder="i18n('qingshuruyouxiang')"></el-input>
            </div>
            <div class="password">
                {{ i18n('mima') }} <br>
                <el-input 
                    v-model="inputpassword" 
                    size="medium" 
                    class="input-password"
                    :placeholder="i18n('qingshurumima')" 
                    show-password 
                    minlength="6" 
                    maxlength="16"></el-input>
            </div>
            <div class="user-forget" @click="userforgetClick"> {{ i18n('wangjimima') }} </div>
            <div class="user-button">
                <el-button 
                    type="primary" 
                    @click="handleLogin" 
                    :loading=loading> {{ i18n('denglu') }} </el-button>
            </div>
            <div class="user-account">
                {{ i18n('haimeiyouzhanghao') }} 
                <span class="button-register" style="cursor: pointer;" @click="registerClick"> {{ i18n('zhuce') }} </span>
            </div>
        </div>

        <el-dialog 
        :title="i18n('youxiangrenzheng')"
        class="dialogyouxiangrenzheng" 
        :visible.sync="dialogyouxiangrenzheng" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="30%">    
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input"> {{ i18n('youxiangdizhi')}} <br>
                        <!-- <input type="tel" class="email" placeholder="请输入邮箱地址" /> -->
                        <el-input 
                            v-model="emailAddr" 
                            style="width: 400px;"
                            :placeholder="i18n('qingshuruyouxiangdizhi')"></el-input>
                    </div>
                    <div class="Change-input"> {{ i18n('tuxingyanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="emailimgcode flex1" placeholder="请输入图形验证码" />
                            <img onclick="getImgCode()" class="authimg" src="" alt=""> -->
                            <el-input 
                            v-model="imgcode" 
                            style="width: 250px;"
                            :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
                            <img @click="getImgCode" class="authimg" :src="imgsrc" alt="" style="cursor: pointer;">
                        </div>
                    </div>
                    <div class="Change-input"> {{ i18n('yanzhengma')}} <br>
                        <div class="flex_row_left">
                            <!-- <input type="text" class="emailnumcode flex1" placeholder="请输入验证码" />
                            <div class="getemailcode">获取验证码</div> -->
                            <el-input 
                            v-model="emailtextcode" 
                            style="width: 250px;"
                            :placeholder="i18n('qingshuruyouxiangyanzhengma')"></el-input>
                            <div :class="{'getcode': true, 'noclick': !getemailcodecanClick}" data-type="4" @click="getemailcode()"> {{ getemailcodeTitle }} </div>
                        </div>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine emailqueding" @click="emailauthsubmit"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog :title="i18n('xiugaidenglumima')" 
        class="dialogchangepassword" 
        :visible.sync="dialogchangepassword" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="30%">
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input"> {{ i18n('xinmima')}} <br>
                        <!-- <input class="newpsw" type="password" /> -->
                        <el-input 
                            v-model="newpassword" 
                            style="width: 400px;"
                            :placeholder="i18n('qingshuruxinmima')"></el-input>
                    </div>
                    <div class="Change-input"> {{ i18n('quedingxinmima')}} <br>
                        <!-- <input class="surenewpsw" type="password" /> -->
                        <el-input 
                            v-model="newpassword2" 
                            style="width: 400px;"
                            :placeholder="i18n('qingzaicishuruxinmima')"></el-input>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine changePsw" @click="changePswclick"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import config from '@/utils/configs'

export default {
    data() {
        return {
            loading: false,
            error: '',
            inputid: undefined,
            inputpassword: undefined,
            dialogyouxiangrenzheng: false,
            emailAddr: undefined,
            imgcode: undefined,
            emailtextcode: undefined,
            getemailcodecanClick: true,
            emailcount: 0,
            emailcountDowntimer: null,
            getemailcodeTitle: this.$t('login')['huoquyouxiangyanzhengma'],
            emailtextcodecid: undefined,
            imgsrc: undefined,
            imgcodeid: undefined,
            dialogchangepassword: false,
            newpassword: undefined,
            newpassword2: undefined,
            account: undefined,
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (msg) => {
            if (msg.loginStatus) {
                //登录成功提示
                this.$message({
                    message: this.$t("login")['dengluchenggong'],
                    duration:2000,
                    type: 'success'
                });
                sessionStorage.setItem('username', this.$store.state.username);
                sessionStorage.setItem('userpass', this.$store.state.userpass);
                // this.$router.back();
                this.$router.replace({name: 'homeLayout'});
            } else {
                //登录失败提示
                this.$message.error(this.$t("login")['denglushibai']);
            }
        });
    },
    beforeDestroy() {
        this.$root.$off('tradelogin');
    },
    computed: {
        i18n() {
            return function (name) {
                return this.$t("login")[name]
            }
        },
    },
    methods: {
        handleLogin() {
            //先判断输入是否为空
            if (this.inputid === '' || this.inputpassword === '') {
                this.$message.error(this.$t("login")['qingshuruyonghuminghemima']);
                return;
            }
            //判断密码长度
            if (this.inputpassword.length < 6) {
                this.$message.error(this.$t("login")['mimachangdubunengxiaoyuliuwei']);
                return;
            }

            this.loading = true;
            this.error = '';

            let conn_data = {
                url: config.tradeAddress,
                user: this.inputid,
                userpass: this.inputpassword
            }
            this.$store.state.username = this.inputid;
            this.$store.state.userpass = this.inputpassword;
            this.$store.dispatch('tradeWS/startWebSocket', conn_data);
            let conn_data_spot = {
                url: config.tradeSpotAddress,
                user: this.inputid,
                userpass: this.inputpassword
            }
            this.$store.dispatch('tradeSpotWS/startWebSocket', conn_data_spot);
            let conn_data_options = {
                url: config.tradeOptionsAddress,
                user: this.inputid,
                userpass: this.inputpassword
            }
            this.$store.dispatch('tradeOptionsWS/startWebSocket', conn_data_options);

            var that = this;
            setTimeout(() => {
                that.loading = false;
            }, 3000);
        },
        registerClick() {
            this.$router.replace({name: 'registerLayout'});
        },
        async textCodeCheck(textcode, cid) {
            let data = {
                cid: cid,
                textcode: textcode,
            }
            this.$api.textCodeCheck_Register(data).then((res)=>{
                if(res.data.code == 200) {
                    this.account = this.emailAddr;
                    this.emailAddr = undefined;
                    this.imgcode = undefined;
                    this.emailtextcode = undefined;
                    this.dialogyouxiangrenzheng = false;
                    //打开修改密码的第二步
                    this.dialogchangepassword = true;
                    this.$message({
                        message: this.$t('login')['youxiangyanzhengchenggong'],
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        message: this.$t('login')['yanzhengmacuowu'] + res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        emailauthsubmit() {
            if (this.emailtextcode !== undefined && this.emailtextcode !== '') {
                this.textCodeCheck(this.emailtextcode, this.emailtextcodecid);
            } else {
                this.$message({
                    message: this.$t('login')['qingshuruyouxiangyanzhengma'],
                    type: 'warning'
                })
            }
        },
        userforgetClick() {
            this.getImgCode();
            this.dialogyouxiangrenzheng = true;
        },
        async getImgCode() {
            let data = {}
            this.$api.getmakeVerifyBase64(data).then((res)=>{
                if(res.data.code == 200){
                    this.imgsrc = res.data.img_base64;
                    this.imgcodeid = res.data.id;
                } else {
                    this.$message({
                        message: this.$t('login')['huoqutuxingyanzhengmashibaiqingchongxinqingqiu'],
                        type: 'warning'
                    });
                }
            });
        },
        getemailcode() {
            if (this.emailAddr !== undefined && this.emailAddr !== '') {
                if (this.imgcode !== undefined && this.imgcode !== '') {
                    this.getEmailTextCode(this.imgcode, 3, this.emailAddr, this.imgcodeid);
                } else {
                    this.$message({
                        message: this.$t('login')['qingshurutuxingyanzhengma'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('login')['qingxianwanchengyouxiangrenzheng'],
                    type: 'warning'
                });
            }
        },
        async getEmailTextCode(textcode, codeuse, useremail, imgcodeid) {
            let data = {
                textcode: textcode,
                codeuse: codeuse,
                useremail: useremail,
                id: imgcodeid,
            }
            this.$api.getEmailTextCode_Register(data).then((res)=>{
                if(res.data.code == 200){
                    this.emailtextcodecid = res.data.cid;
                    this.emailcountDown();
                } else {
                    this.$message({
                        message: this.$t('login')['huoquyouxiangyanzhengmashibai'],
                    })
                }
            })
        },
        emailcountDown() {
            this.emailcount = 60;
            clearInterval(this.emailcountDowntimer);
            this.emailcountDowntimer = setInterval(() => {
                this.emailcount--;
                this.getemailcodeTitle = this.emailcount + this.$t('login')['houchongxinfasong'] ;
                if (this.emailcount === 0) {
                    this.emailcount = 60;
                    this.getemailcodecanClick = true;
                    clearInterval(this.emailcountDowntimer);
                    this.getemailcodeTitle = this.$t('login')['huoquyouxiangyanzhengma'] ;
                }
            }, 1000);
        },
        changePswclick() {
            if (this.newpassword === undefined || this.newpassword === '' || this.newpassword2 === undefined || this.newpassword2 === '') {
                this.$message({
                    message: this.$t('login')['qingshuruxinmima'],
                    type: 'warning'
                });
                return;
            };
            if (this.newpassword !== this.newpassword2) {
                this.$message({
                    message: this.$t('login')['liangcimimashurubuyizhi'],
                    type: 'warning'
                });
                return;
            };
            this.changePassword(this.newpassword, this.account);
        },
        async changePassword(newpassword, account) {
            let data = {
                cid: this.emailtextcodecid,
                account: account,
                password: newpassword,
                cf_password: newpassword,
            }
            this.$api.resetPwd(data).then((res)=>{
                if(res.data.code == 200){
                    this.$message({
                        message: this.$t('login')['mimaxiugaichenggong'],
                    })
                    this.dialogchangepassword = false;
                } else {
                    this.$message({
                        message: this.$t('login')['huoquyouxiangyanzhengmashibai'],
                    })
                }
            })
        },
    }, 
};
</script>

<style scoped>
.middle {
    min-width: 686px;
    width: 100%;
    height: 1000px;
    background: url(@/assets/images/bg.png) no-repeat center;
    background-size: cover;
    position: relative;
}

.user {
    width: 364px;
    height: 548px;
    background-color: #0A162B;
    margin-left: 70%;
    /* margin-top: 25px; */
    padding-left: 32px;
    padding-top: 74px;
}

.ding {
    min-width: 686px;
    width: 100%;
    height: 130px;
}

.user-gang {
    font-size: 20px;
    color: #08BD98;
    margin-right: 16px;
    float: left;
}

.user h1 {
    color: #fff;
    font-size: 20px
}

.user-fbex {
    font-size: 16px;
    color: #ddd;
}

.input-id {
    margin-top: 20px;
    width: 300px;
    height: 44px;
    color: #fff;
    font-size: 16px;
}

.input-password {
    margin-top: 20px;
    width: 300px;
    height: 44px;
    color: #fff;
    font-size: 16px;
}

.user-id {
    margin-top: 35px;
    color: #B5BCCB;
    font-size: 14px;
    position: relative;
}

.password {
    margin-top: 35px;
    color: #B5BCCB;
    font-size: 14px;
    position: relative;
}

.user-forget {
    color: #08BD98;
    font-size: 14px;
    text-align: right;
    margin-right: 70px;
    line-height: 40px;
    cursor: pointer;
}

.user-button button {
    width: 300px;
    height: 44px;
}

.user-account {
    width: 300px;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
}

.user-account span {
    color: #08BD98;
}


</style>