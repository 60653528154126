import { DecodePack } from '@/utils/ByteArray.js';
import { parseData } from '@/utils/parseQuotesSpot.js';

const quotesSpotWSModule = {
    namespaced: true,
    state: {
        ws: null,
    },
    mutations: {
        SET_WS(state, ws) {
            state.ws = ws;
            // console.log("SET_WS", state.ws);
        },
        CLEAR_WS(state) {
            state.ws = null;
        },
    },
    actions: {
        startWebSocket({commit, state}, connUrl) {
            // commit('SET_LOGINDATA', loginMsg);
            if (state.ws) state.ws.close();
            const ws = new WebSocket(connUrl);
            ws.onopen = function () {
                console.log('quotesSpot WebSocket connected');

                commit('SET_WS', ws);
            };
            ws.onmessage = function (event) {
                // console.log('Received:', event.data);
                // 处理接收到的消息
                
                var recvData = event.data;
                // 将 Blob 转换为 ArrayBuffer
                if (recvData instanceof Blob) {
                    recvData.arrayBuffer().then(function(arrayBuffer) {
                        var bbt = DecodePack(arrayBuffer);
                        parseData(bbt);
                    });
                } else if (recvData instanceof ArrayBuffer) {
                    var bbt = DecodePack(recvData);
                    parseData(bbt);
                } else {
                    console.error("Unsupported data type in event.data");
                }
            };
            ws.onerror = function (error) {
                console.error('quotesSpot WebSocket error:', error);
            };
            ws.onclose = function () {
                console.log('quotesSpot WebSocket closed');
                // 可能需要重连逻辑
            };
        },
        sendMessage({state}, message) {
            if (state.ws && state.ws.readyState === WebSocket.OPEN) {
                state.ws.send(message);
            } else {
                console.warn('WebSocket not connected, unable to send message');
            }
        },
        close({state}) {
            console.log('close_quotesSpot');
            if (state.ws) state.ws.close();
        },
    }
}

export default quotesSpotWSModule
