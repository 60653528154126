<template>
    <div>
        <div class="treasureAssets">
        <div class="head-part">
            <div class="head-top">
                <div class="flex_col_center" style="align-items: flex-start;">
                    <div class="title">coin{{i18n('jijinzongzichang')}}(USDT)</div>
					 <div class="title-desc">{{lcMoney.productMoney}} </div>
                    <!-- <div class="title-desc">0.0000 <span>≈ 0.00 CNY</span></div> -->
                </div>
                <div>
                    <div class="flex_row_left">
                        <div class="flex_col_center zcpart" style="align-items: flex-start;">
                            <div class="subtitle">{{i18n('leijishouyi')}}(USDT)</div>
                            <div class="bignum">{{lcMoney.productAccumIncomeMoney}}</div>
                            <!-- <div class="smallnum">≈ 0.00 CNY</div> -->
                        </div>
                        <div class="flex_col_center zcpart" style="align-items: flex-start;">
                            <div class="subtitle">{{i18n('zuorishouyi')}}(USDT)</div>
                            <div class="bignum">{{lcMoney.productAccumYesterdayIncomeMoney}}</div>
                            <!-- <div class="smallnum">≈ 0.00 CNY</div> -->
                        </div>
                    </div>
                    <div class="flex_row_left">
                        <div class="detailbtn" @click="goOrder">{{i18n('dingdanxiangqing')}}</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="content-wrap">
            <div class="flex_row_left changepart">
                <div class="changebtn" :class="tabType==1?'ons':''" @click="changeTab(1)">{{i18n('linghuolicai')}}</div>
                <div class="changebtn" :class="tabType==2?'ons':''" @click="changeTab(2)">{{i18n('dingqilicai')}}</div>
                <div class="changebtn" :class="tabType==3?'ons':''" @click="changeTab(3)">{{i18n('dingelicai')}}</div>
            </div>
            <div class="nolist" v-if="orderList.length==0">
                <table class="trlist">
                    <tr>
                        <th>{{i18n('chanpinmingcheng')}}</th>
						<th>{{i18n('goumaifenshu')}}</th>
						<th>{{i18n('chanpinzhouqi')}}</th>
                        <th>{{i18n('goumaijine')}}</th>
						<th>{{i18n('shengxiaoshijian')}}</th>
                       <th>{{i18n('yichanshengshouyi')}}</th>
                        <!-- <th>操作</th> -->
                    </tr>
                </table>
                <img class="nolistimg" src="@/assets/images/wudingdan.png" alt="">
                <div class="fontSize14 color_666 text_center" style="padding-bottom: 150px;">{{i18n('zanwushujui')}}</div>
            </div>
            <div class="orlistss" v-if="orderList.length>0">
                <table class="trlist">
                    <tr>
                        <th>{{i18n('chanpinmingcheng')}}</th>
						<!-- <th v-if="tabType==3">購買份數</th> -->
						<th v-if="tabType!=1">{{i18n('chanpinzhouqi')}}</th>
                        <th>{{i18n('goumaijine')}}</th>
						<th v-if="tabType!=1">{{i18n('goumaishijian')}}</th>
                        <th v-if="tabType!=1">{{i18n('shengxiaoshijian')}}</th>
                        <th>{{i18n('yichanshengshouyi')}}</th>
                        <th>{{i18n('caozuo')}}</th>
                    </tr>
                    <tr v-for="item in orderList">
                        <td>{{item.productName}}</td>
                        <td v-if="tabType!=1">{{item.productPeriod?item.productPeriod:''}}</td>
                        <td>{{item.opNumber}}</td>
                        <td v-if="tabType!=1 && item.createTime">{{item.createTime | formatDate}}</td>
                        <td v-if="tabType!=1 && item.effectiveTime">{{item.effectiveTime | formatDate}}</td>
                        <td class="color_green">{{item.productAccumIncomeMoney}}</td>
                        <td><el-button type="primary" @click="goAssShow(item)">{{i18n('chakanxiangqing')}}</el-button></td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
    <!-- 弹窗部分 -->
    <!-- 资产详情 -->
    <div class="assetdetail flex_row_center" v-if="assetShow">
        <div class="show-box">
            <div class="box-title flex_row_between pos_relative" style="height: 64px;">
                <div class="">{{i18n('zichanxiangqing')}}</div>
                <img src="@/assets/images/guanbi.png" @click="assetShow = false" class="close">
            </div>
            <div class="show-list">
                <!-- <div class="fontSize14 color_666 text_center">充幣金額</div>
                <div class="color_333 fontSize25 text_center money">0.00 USDT</div> -->
                <div class="delist">
                    <div class="flex_row_between">
                        <div>{{i18n('chanpinmingcheng')}}</div>
                        <div>{{orderDetails.productName}}</div>
                    </div>
                    <div class="flex_row_between">
                        <div>{{i18n('goumaijine')}}（USDT）</div>
                        <div>{{orderDetails.opNumber}}</div>
                    </div>
                    
                    <div class="type2S" v-if="tabType!=1">
                        <div class="flex_row_between">
                            <div>{{i18n('chanpinzhouqi')}}（{{i18n('tian')}}）</div>
                            <div>{{orderDetails.productPeriod}}</div>
                        </div>
                        <div class="flex_row_between">
                            <div>{{i18n('shengxiaoshijian')}}</div>
                            <div>{{orderDetails.effectiveTime | formatDate}}</div>
                        </div>
                        <div class="flex_row_between">
                            <div>{{i18n('yujishouyi')}}</div>
                            <div>{{orderDetails.estimatedRevenue}}</div>
                        </div>
                    </div>
                    <div class="flex_row_between">
                        <div>{{i18n('yichanshengshouyi')}}</div>
                        <div>{{orderDetails.productAccumIncomeMoney}}</div>
                    </div>
                    <div class="flex_row_between">
                        <div>{{i18n('shuhuifangshi')}}</div>
                        <div v-if="tabType!=1">{{i18n('daoqizidongshuhui')}}</div>
                         <div v-if="tabType==1">{{i18n('suishishuhui')}}</div>
                    </div>
                </div>
                <div class="orderbtn" v-if="tabType==1">
                    <!-- <button class="redemption">
                        贖回
                    </button> -->
                    <button class="recharge" @click="shShow()">
                        {{i18n('shuhui')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- 赎回 -->
    <div class="step1 flex_row_center" v-if="step1shShow">
        <div class="show-box">
            <div class="box-title flex_row_between pos_relative">
                <div>{{i18n('shuhui')}}USDT</div>
                <img src="@/assets/images/guanbi.png" @click="step1shShow = false" class="close">
            </div>
            <div class="show-list">
                <div class="flex_row_between">
                    <div>{{i18n('shuhuijine')}}</div>
                    <div>{{i18n('keshuhuijine')}}<span class="avaluemoney">{{orderDetails.opNumber}}</span> USDT</div>
                </div>
                <div class="flex_row_left MT12">
                    <div class="flex1 flex_row_between inputparts">
                       <el-input :placeholder="i18n('shuhuijine')" v-model="buyNum">
                            <template slot="append">
                            <el-button   @click="maxMoney()">{{i18n('zuida')}}</el-button>
                            </template>
                        </el-input>
                    </div>
                </div>
                <!-- <div class="flex_row_left" style="margin-top: 20px;">
                    <div class="common_radio"></div>
                    <div class="isure">我已瞭解並同意<span class="color_green">《Coin基金协议》</span></div>
                </div> -->
                <div class="orderbtn">
                    <button class="but-cancel" @click="step1shShow=false">
                        {{i18n('quxiao')}}
                    </button>
                    <button class="but-determine" :class="buyNum?'':'noclick'" @click="goSh()">
                        {{i18n('shuhui')}}
                    </button>
                </div>
            </div>
        </div>
    </div>

       
    </div>
</template>
<script>


export default {
    data() {
        return {
           tabType:1,
           assetShow:false,
           step1shShow:false,
           lcMoney:{},
           orderList:[],
           orderDetails:{},
           buyNum:''
        };
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    mounted() {
        
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getLcAccountInfo();
                this.getUserProductCoinList();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getLcAccountInfo();
            this.getUserProductCoinList();
        };
    },
    beforeDestroy() {
        
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    methods: {
        changeTab(val){
            this.tabType = val;
            this.getUserProductCoinList()
        },
         getLcAccountInfo(){
            this.$api.getUserCunbiAccount().then((res)=>{
                this.lcMoney = res.data.data
            })
        },
        getUserProductCoinList(){
            let data = {
                product_type: this.tabType,
            }
            this.$api.userProductCoin(data).then((res)=>{
                this.orderList = res.data.data.list
            })
        },
        goAssShow(item){
            this.assetShow = true;
            this.orderDetails = item
        },
        shShow(){
            this.step1shShow = true
        },
        maxMoney(){
            this.buyNum = parseFloat(this.orderDetails.opNumber)
        },
        goOrder(){
            this.$router.push("/treasureOrder");
        },
        goSh(){
            let data = {
                product_id: this.orderDetails.id,
                product_number: this.buyNum,
            }
            this.$api.ransomCoin(data).then((res)=>{
                if(res.data.code==200){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.getUserProductCoinList();
                    this.getLcAccountInfo();
                    this.step1shShow = false;
                    this.assetShow = false;
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("treasure")[name];
            }
		},
    },
}
</script>
<style scoped>
@import '@/css/treasure.css';
</style> 