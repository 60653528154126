<template>
  <div class="container">
    <el-container>
      <!--侧栏-->
      <el-container>
        <!--头部-->
        <el-header>
          <header-layout></header-layout>
        </el-header>
        <!--内容-->
        <router-view></router-view>
        <el-footer>
          <footer-layout></footer-layout>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderLayout from "./HeaderLayout.vue";
import footerLayout from "./footerLayout.vue";
export default {
  name: "BasicLayout",
  components: {
    HeaderLayout,
    footerLayout
  },

  data() {
     return{
      
     }
  }
}
</script>

<style scoped>
</style>
